import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import {
  getAllBatchesSuccess,
  BatchFailure,
  getBatchByIdSuccess,
  cancelBatchRequestSuccess,
  getBatchByIdRequest,
  closeBatchRequestSuccess,
} from './actions';
import { refreshTokenRequest } from '../auth/actions';
import { store } from '../../index';

export function* getBatches({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const {
      limit = 10,
      page = 1,
      institutionId,
      status,
      batchId,
      documentId,
      initialDate,
      finalDate,
      onSuccess,
    } = payload;
    let url = '/management/registry-batch?';
    url += `limit=${limit}`;
    url += `&page=${page}`;
    if (institutionId) url += `&institutionId=${institutionId}`;
    if (status && status !== '') url += `&state=${status}`;
    if (batchId && batchId !== '') url += `&batchId=${batchId}`;
    if (documentId && documentId !== '') url += `&documentId=${documentId}`;
    if (initialDate && initialDate !== '') url += `&initialDate=${initialDate}`;
    if (finalDate && finalDate !== '') url += `&finalDate=${finalDate}`;

    const response = yield call(api.get, url);
    const documents = response.data;

    yield put(getAllBatchesSuccess(documents));

    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(BatchFailure());
      toast.error(`Falha ao listar lotes`);
    }
  }
}
export function* getBatchById({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id } = payload;
    const response = yield call(api.get, `/management/registry-batch/${id}`);
    const document = response.data;

    yield put(getBatchByIdSuccess(document));
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(BatchFailure());
      toast.error('Falha ao obter dados do lote');
    }
  }
}
export function* cancelBatch() {
  const { refresh_token } = store.getState().auth;
  const { batch } = store.getState().batchRegistry;

  try {
    yield call(api.delete, `/management/registry-batch/${batch.alternativeId}`);
    yield put(cancelBatchRequestSuccess());
    yield put(getBatchByIdRequest(batch._id));
    toast.success('Solicitado com sucesso');
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(BatchFailure());
      toast.error('Falha ao cancelar o lote');
    }
  }
}
export function* closeBatch() {
  const { refresh_token } = store.getState().auth;
  const { batch } = store.getState().batchRegistry;

  try {
    yield call(
      api.post,
      `/management/registry-batch/${batch.alternativeId}/close`
    );
    yield put(closeBatchRequestSuccess());
    yield put(getBatchByIdRequest(batch._id));
    toast.success('Solicitado com sucesso');
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(BatchFailure());
      toast.error('Falha ao fechar o lote');
    }
  }
}

export default all([
  takeLatest('@batches/GET_ALL_BATCHES_REQUEST', getBatches),
  takeLatest('@batches/GET_BATCH_BY_ID_REQUEST', getBatchById),
  takeLatest('@batches/CANCEL_REQUEST', cancelBatch),
  takeLatest('@batches/CLOSE_REQUEST', closeBatch),
]);
