import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { responseErrors } from '../../../utils/responseErros';
import api from '../../../services/api';
import {
  getAllSignatureItemsSuccess,
  getSignatureItemByIdSuccess,
  storeSignatureItemSuccess,
  updateSignatureItemSuccess,
  deleteSignatureItemSuccess,
  signatureItemFailure,
} from './actions';
import { refreshTokenRequest } from '../auth/actions';
import { store } from '../../index';

export function* getSigItems() {
  const { refresh_token } = store.getState().auth;
  const { selectedInstitution } = store.getState().selectedData;

  try {
    const response = yield call((url) => {
      return api.get(url, {
        params: {
          institutionId: selectedInstitution,
        },
      });
    }, '/management/sigitens');

    const sigItems = response.data;

    yield put(getAllSignatureItemsSuccess(sigItems));
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(signatureItemFailure());
      toast.error(`Falha ao listar itens de assinatura`);
    }
  }
}
export function* getSigItemById({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, index } = payload;
    const response = yield call(api.get, `/management/sigitens/${id}`);
    const sigItem = response.data;

    yield put(getSignatureItemByIdSuccess(sigItem, index));
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(signatureItemFailure());
      toast.error('Falha ao obter dados do item de assinatura');
    }
  }
}
export function* storeSigItem({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { data, onSuccess } = payload;
    const response = yield call(api.post, '/management/sigitens', data);
    const sigItem = response.data;
    sigItem.signer = {
      ...sigItem.signer,
      institution: { _id: data.institution },
    };
    yield put(storeSignatureItemSuccess(sigItem));
    toast.success('Item de assinatura criado com sucesso');
    onSuccess && onSuccess();
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(signatureItemFailure());
      Swal.fire(
        responseErrors(err.response, 'Falha ao criar item de assinatura')
      );
    }
  }
}

export function* updateSigItem({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, data, index, onSuccess } = payload;
    const response = yield call(api.patch, `/management/sigitens/${id}`, data);
    const sigItem = response.data;
    sigItem.signer = {
      ...sigItem.signer,
      institution: { _id: data.institution },
    };
    yield put(updateSignatureItemSuccess(index, sigItem));
    toast.success('Item de assinatura atualizado com sucesso');
    onSuccess && onSuccess();
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(signatureItemFailure());
      Swal.fire(
        responseErrors(err.response, 'Falha ao criar item de assinatura')
      );
    }
  }
}

export function* deleteSigItem({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, index } = payload;
    yield call(api.delete, `/management/sigitens/${id}`);

    yield put(deleteSignatureItemSuccess(index));
    toast.success('Item de assinatura excluído com sucesso');
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(signatureItemFailure());
      toast.error('Falha ao excluir item de assinatura');
    }
  }
}

export default all([
  takeLatest('@signatureItem/GET_ALL_SIGITEMS_REQUEST', getSigItems),
  takeLatest('@signatureItem/GET_SIGITEM_BY_ID_REQUEST', getSigItemById),
  takeLatest('@signatureItem/STORE_SIGITEM_REQUEST', storeSigItem),
  takeLatest('@signatureItem/UPDATE_SIGITEM_REQUEST', updateSigItem),
  takeLatest('@signatureItem/DELETE_SIGITEM_REQUEST', deleteSigItem),
]);
