import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Tooltip,
  Cell,
  Legend,
} from 'recharts';
import * as dateFns from 'date-fns';
import { useCurrentPng } from 'recharts-to-png';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { toast } from 'react-toastify';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  ListGroup,
  ListGroupItem,
  Form,
  FormCheckbox,
  Collapse,
  FormSelect,
  FormInput,
} from 'shards-react';

import {
  FaCheckCircle,
  FaRandom,
  FaArrowUp,
  FaArrowDown,
  FaBook,
  FaRegCopy,
} from 'react-icons/fa';

import { useInput } from '../../hooks/input-hook';

import PageTitle from '../../components/common/PageTitle';
import LoadingContainer from '../../components/layout/LodingContainer';

import SearchSelect from '../../components/common/SearchSelect';

import { getAllInstitutionsRequest } from '../../store/modules/institution/actions';
import {
  getTimestampReport,
  cleanState,
} from '../../store/modules/reports/actions';
import * as SelectedData from '../../store/modules/selectedData/actions';

import { normalOrManager } from '../../config/UserPermission';
import ErrorBox from '../../components/common/ErrorBox';
import { scrollToBottom } from '../../utils/scrolls';
import { generateColors } from '../../utils/chartColors';
import { providers, timestampTypes } from '../../config/Constants';

export default function TimestampReports() {
  const dispatch = useDispatch();

  const [getTypeChart, timestampTypeChart] = useCurrentPng();

  const institutions = useSelector((state) => state.institution.institutions);
  const { loading, data } = useSelector((state) => state.reports);

  const selectedInstitution = useSelector(
    (state) => state.selectedData.selectedInstitution
  );

  const { user, permission } = useSelector((state) => state.auth);
  const { institution } = user;

  const isNormalUser = normalOrManager.includes(permission);

  const [error, setError] = useState(null);
  const [showFilter, setShowFilter] = useState(true);

  const { value: filteredInstitution, setValue: setFilteredInstitution } =
    useInput(selectedInstitution);

  const { value: timestampType, setValue: setTimestampType } = useInput('');
  const { value: signProvider, setValue: setSignProvider } = useInput('');
  const { value: timestampProvider, setValue: setTimestampProvider } =
    useInput('');
  const { value: initialDate, setValue: setInitialDate } = useInput('');
  const { value: finalDate, setValue: setFinalDate } = useInput('');

  const [chartTimestampType, setChartTimestampType] = useState([]);

  const [isAll, setIsAll] = useState({
    institution: false,
    timestampType: true,
    timestampProvider: true,
    signProvider: true,
    period: true,
  });

  /*eslint-disable */
  useEffect(() => {
    if (isNormalUser) {
      setFilteredInstitution(institution.id_institution.toString());
    } else {
      dispatch(getAllInstitutionsRequest());
    }
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(cleanState());
    }
  }, []);

  useEffect(() => {
    const colors = generateColors(3);
    setChartTimestampType([
      {
        name: 'Carimbo de assinatura',
        value: getQuantityByType(data.quantityByTimestampType, 'SignatureTimeStamp'),
        color: colors[0]
      },
      {
        name: 'Carimbo de referências',
        value: getQuantityByType(data.quantityByTimestampType, 'SigAndRefsTimeStamp'),
        color: colors[1]
      },
      {
        name: 'Carimbo de arquivamento',
        value: getQuantityByType(data.quantityByTimestampType, 'ArchiveTimeStamp'),
        color: colors[2]
      },
    ])
  }, [data]);

  useEffect(() => {
    dispatch(SelectedData.selectInstitution(filteredInstitution));
  }, [filteredInstitution]);

  useEffect(() => {
    if (isAll.institution || filteredInstitution)
      setError(null);
  }, [isAll.institution, filteredInstitution]);

  function handleInstitutionFilter(institution) {
    setFilteredInstitution(institution.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setError(null);

    if (!isAll.institution &&
      (!filteredInstitution || filteredInstitution === ''))
      return setError("Selecione uma instituição");

      if (!isAll.period
        && (initialDate && initialDate !== '')
        && (finalDate && finalDate !== '')
        && dateFns.isAfter(dateFns.parseISO(initialDate),
          dateFns.parseISO(finalDate))
      ) {
        return setError("A data final precisa ser maior que a data inicial");
      }

    const query = {};

    const findInstitution = institutions.find(
      item => item._id === filteredInstitution
    );

    Object.assign(
      query,
      !isAll.institution && findInstitution && {
        clientId: findInstitution.client_id,
      },
      !isAll.signProvider && {
        signProvider
      },
      !isAll.timestampProvider && {
        timestampProvider
      },
      !isAll.timestampType && {
        timestampType
      },
      !isAll.period && {
        initialDate,
        finalDate
      }
    )

    dispatch(getTimestampReport(query, () => {
      setShowFilter(false);
      scrollToBottom();
    }))
  }

  function getQuantityByType(listOfTypes, type) {
    if (!listOfTypes) return 0;
    return listOfTypes.find(item => {
      return item.type === type;
    }).quantity;
  }

  const generateImageFromChart = useCallback(async (getPngFromChart) => {
    const png = await getPngFromChart();

    try {
      if (!png) throw new Error();

      const data = await fetch(png);
      const blob = await data.blob();

      await navigator.clipboard.write([
        new ClipboardItem({
          [blob.type]: blob
        })
      ]);

      toast.info('Copiado!', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 'custom-id-yes',
      });
    } catch (error) {
      toast.error('Erro ao copiar gráfico', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 'custom-id-yes',
      });
    }
  }, [getTypeChart]);

  function changeIsAll(name) {
    setIsAll(old => ({ ...old, [name]: !old[name] }))
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Registros de carimbo"
          subtitle="Estatísticas"
          className="text-sm-left"
        />
      </Row>
      {/* Filters */}
      <Row id="form-container">
        <Col>
          <Card className="mb-3" small>
            <CardHeader className="border-bottom">
              <Row>
                <Col sm="6">
                  <h6 className="m-0 font-weight-bold">
                    Filtros
                  </h6>
                </Col>
                <Col sm="6" className="text-right">
                  <Button theme="light" onClick={() => setShowFilter(!showFilter)}>
                    <span className="mr-2">
                      {showFilter ? <FaArrowUp /> : <FaArrowDown />}
                    </span>
                    {showFilter ? "Esconder" : "Mostrar"}
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <Collapse open={showFilter}>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form onSubmit={handleSubmit}>
                        <Row form>
                          <Col md="6" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Instituição
                            </span>
                            <Row className="pt-2">
                              <Col md="8">
                                <SearchSelect
                                  placeholder="Pesquisa por nome, sigla ou client_id"
                                  onSelect={handleInstitutionFilter}
                                  value={filteredInstitution}
                                  options={
                                    institutions &&
                                    institutions.map(i => ({
                                      label: `${i.name} - ${i.initials}`,
                                      value: i._id,
                                      client_id: i.client_id
                                    }))
                                  }
                                  styleContainer={{
                                    marginTop: '0px'
                                  }}
                                  disabled={isAll.institution}
                                />
                              </Col>
                              <Col md="4" className="pt-2 pl-0">
                                <FormCheckbox
                                  checked={isAll.institution}
                                  onChange={() => changeIsAll('institution')}
                                >
                                  <span className="text-dark">
                                    Ver todas
                                  </span>
                                </FormCheckbox>
                              </Col>
                            </Row>
                          </Col>
                          <Col md="6" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Tipo de carimbo
                            </span>
                            <Row className="pt-2">
                              <Col md="8">
                                <FormSelect
                                  id="timestampType"
                                  value={timestampType}
                                  onChange={e =>
                                    setTimestampType(e.target.value)
                                  }
                                  required={!isAll.timestampType}
                                  disabled={isAll.timestampType}
                                >
                                  <option value="" disabled>
                                    Escolher...
                                  </option>
                                  {timestampTypes &&
                                    timestampTypes.map(type => (
                                      <option
                                        key={type.value}
                                        value={type.value}
                                      >
                                        {type.label}
                                      </option>
                                    ))}
                                </FormSelect>
                              </Col>
                              <Col md="4" className="pt-2 pl-0">
                                <FormCheckbox
                                  checked={isAll.timestampType}
                                  onChange={() => changeIsAll('timestampType')}
                                >
                                  <span className="text-dark">
                                    Ver todos
                                  </span>
                                </FormCheckbox>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="6" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Provedor de carimbo
                            </span>
                            <Row className="pt-2">
                              <Col md="8">
                                <FormSelect
                                  id="timestampProvider"
                                  value={timestampProvider}
                                  onChange={e =>
                                    setTimestampProvider(e.target.value)
                                  }
                                  required={!isAll.timestampProvider}
                                  disabled={isAll.timestampProvider}
                                >
                                  <option value="1" selected>
                                    Bry
                                  </option>
                                </FormSelect>
                              </Col>
                              <Col md="4" className="pt-2 pl-0">
                                <FormCheckbox
                                  checked={isAll.timestampProvider}
                                  onChange={() => changeIsAll('timestampProvider')}
                                >
                                  <span className="text-dark">
                                    Ver todos
                                  </span>
                                </FormCheckbox>
                              </Col>
                            </Row>
                          </Col>
                          <Col md="6" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Provedor de assinatura
                            </span>
                            <Row className="pt-2">
                              <Col md="8">
                                <FormSelect
                                  id="signProvider"
                                  value={signProvider}
                                  onChange={e =>
                                    setSignProvider(e.target.value)
                                  }
                                  required={!isAll.signProvider}
                                  disabled={isAll.signProvider}
                                >
                                  <option value="" disabled>
                                    Escolher...
                                  </option>
                                  {providers &&
                                    providers.map(type => (
                                      <option
                                        key={type.value}
                                        value={type.value}
                                      >
                                        {type.label}
                                      </option>
                                    ))}
                                </FormSelect>
                              </Col>
                              <Col md="4" className="pt-2 pl-0">
                                <FormCheckbox
                                  checked={isAll.signProvider}
                                  onChange={() => changeIsAll('signProvider')}
                                >
                                  <span className="text-dark">
                                    Ver todos
                                  </span>
                                </FormCheckbox>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="4" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Data inicial
                            </span>
                            <FormInput
                              type="date"
                              className="mt-2"
                              disabled={isAll.period}
                              value={initialDate}
                              onChange={(e) => setInitialDate(e.target.value)}
                              required={!isAll.period}
                            />
                          </Col>
                          <Col md="8" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Data Final
                            </span>
                            <Row className="pt-2">
                              <Col md="6">
                                <FormInput
                                  type="date"
                                  disabled={isAll.period}
                                  value={finalDate}
                                  onChange={(e) => setFinalDate(e.target.value)}
                                  required={!isAll.period}
                                />
                              </Col>
                              <Col md="4" className="pt-2 pl-0">
                                <FormCheckbox
                                  checked={isAll.period}
                                  onChange={() => changeIsAll('period')}
                                >
                                  <span className="text-dark">
                                    Intervalo completo
                                  </span>
                                </FormCheckbox>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="8">
                            <ErrorBox error={error} />
                          </Col>
                          <Col md="4" className="text-right">
                            <Button
                              className="btn-primary ml-3"
                            // onClick={handleCleanState}
                            >
                              Consultar
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Collapse>
          </Card>
        </Col>
      </Row>
      {loading && <LoadingContainer loading={loading} />}
      {/* DASHBOARD */}
      {!loading && data && data.quantityByTimestampType && (
        <>
          <Row className="p-3">
            <Col sm="12" className="text-center mt-2">
              <h4>
                Resultados obtidos
              </h4>
            </Col>
          </Row>
          <Row>
            <Col sm="4">
              <Card>
                <CardBody className="text-center px-3 py-2">
                  <h4 className="text-muted mb-1">
                    <FaBook />
                  </h4>
                  <h3 className="m-0 font-weight-bold">
                    {getQuantityByType(data.quantityByTimestampType, 'SignatureTimeStamp')}
                  </h3>
                  <span className="text-muted">
                    Carimbos de assinatura
                  </span>
                </CardBody>
              </Card>
            </Col>
            <Col sm="4">
              <Card>
                <CardBody className="text-center px-3 py-2">
                  <h4 className="text-success mb-1">
                    <FaCheckCircle />
                  </h4>
                  <h3 className="m-0 font-weight-bold">
                    {getQuantityByType(data.quantityByTimestampType, 'SigAndRefsTimeStamp')}
                  </h3>
                  <span className="text-muted">
                    Carimbos de referências
                  </span>
                </CardBody>
              </Card>
            </Col>
            <Col sm="4">
              <Card>
                <CardBody className="text-center px-3 py-2">
                  <h4 className="text-primary mb-1">
                    <FaRandom />
                  </h4>
                  <h3 className="m-0 font-weight-bold">
                    {getQuantityByType(data.quantityByTimestampType, 'ArchiveTimeStamp')}
                  </h3>
                  <span className="text-muted">
                    Carimbos de arquivamento
                  </span>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12" className="mt-4">
              <Card>
                <CardBody className="text-center px-3 py-0">
                  <Col md="12" className="d-flex pt-1">
                    <h5 className="pt-3 flex-fill">
                      Quantidade por tipo de carimbo
                    </h5>
                    <Button
                      data-tip="Copiar gráfico"
                      outline
                      pill
                      onClick={() => generateImageFromChart(getTypeChart)}
                      theme="light"
                      disabled={timestampTypeChart.isLoading}
                    >
                      <FaRegCopy />
                    </Button>
                  </Col>
                  <Col md="12" className="px-0">
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart width={400} height={400} ref={timestampTypeChart.ref}>
                        <Pie
                          dataKey="value"
                          isAnimationActive={true}
                          data={chartTimestampType}
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          label
                        >
                          {chartTimestampType.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend
                          layout="vertical"
                          verticalAlign="middle"
                          align="left"
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  </Col>
                </CardBody>
              </Card>
            </Col>
            <ReactTooltip />
          </Row>
        </>
      )}
    </Container>
  );
}
