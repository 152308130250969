/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ButtonGroup,
  Button,
  Form,
  ListGroup,
  ListGroupItem,
  FormInput,
  FormSelect,
} from 'shards-react';

import { FiAlertCircle } from 'react-icons/fi';
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';

import Swal from 'sweetalert2';
import { InputWrapper, FilterWrapper } from '../common/styles';

import { useInput } from '../../hooks/input-hook';

import PageTitle from '../../components/common/PageTitle';
import LoadingContainer from '../../components/layout/LodingContainer';

import {
  getAllSignatureItemsRequest,
  getSignatureItemByIdRequest,
  storeSignatureItemRequest,
  updateSignatureItemRequest,
  updateSignatureItemCancel,
  deleteSignatureItemRequest,
} from '../../store/modules/signatureItem/actions';

import { getAllInstitutionsRequest } from '../../store/modules/institution/actions';
import * as SelectedData from '../../store/modules/selectedData/actions';

import { scrollToTop, scrollToForm } from '../../utils/scrolls';

import SearchSelect from '../../components/common/SearchSelect';
import OptionsBox from '../../components/common/OptionsBox';
import { SignatureTypes } from '../../config/Constants';

export default function SignatureItems() {
  const dispatch = useDispatch();

  const institutions = useSelector((state) => state.institution.institutions);
  const sigItems = useSelector((state) => state.signatureItem.sigItems);
  const sigItem = useSelector((state) => state.signatureItem.sigItem);
  const { loading, isSubmitting } = useSelector((state) => state.signatureItem);
  const selectedInstitution = useSelector(
    (state) => state.selectedData.selectedInstitution
  );

  const {
    value: name,
    setValue: setName,
    bind: bindName,
    reset: resetName,
  } = useInput('');
  const {
    value: level,
    setValue: setLevel,
    bind: bindLevel,
    reset: resetLevel,
  } = useInput('1');
  const {
    value: signType,
    setValue: setSignType,
    bind: bindSignType,
    reset: resetSignType,
  } = useInput('');
  const {
    value: signPacking,
    setValue: setSignPacking,
    bind: bindSignPacking,
    reset: resetSignPacking,
  } = useInput('');
  const {
    value: signPolicy,
    setValue: setSignPolicy,
    bind: bindSignPolicy,
    reset: resetSignPolicy,
  } = useInput('');
  const {
    value: policyVersion,
    setValue: setPolicyVersion,
    bind: bindPolicyVersion,
    reset: resetPolicyVersion,
  } = useInput('');
  const {
    value: hashAlgorithm,
    setValue: setHashAlgorithm,
    bind: bindHashAlgorithm,
    reset: resetHashAlgorithm,
  } = useInput('');

  const [filteredInstitution, setFilteredInstitution] =
    useState(selectedInstitution);
  const [sigItemIdToUpdate, setSigItemIdToUpdate] = useState('');
  const [updating, setUpdating] = useState(false);
  const [institutionFilterStatus, setInstitutionFilterStatus] = useState(false);
  const [filteredSigItems, setFilteredSigItems] = useState([]);

  const optionsData = [
    { label: 'Institucional', value: 'institution' },
    { label: 'Arquivamento', value: 'archive' },
    { label: 'Pessoa Física', value: 'person' },
    { label: 'Customizado', value: 'custom' },
  ];

  /*eslint-disable */
  useEffect(() => {
    dispatch(getAllInstitutionsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(sigItem).length !== 0 && updating) {
      setName(sigItem.name);
      setLevel(sigItem.level);
      setSignType(sigItem.signType);
      setSignPacking(sigItem.signPacking);
      setSignPolicy(sigItem.signPolicy);
      setPolicyVersion(sigItem.policyVersion);
      setHashAlgorithm(sigItem.hashAlgorithm);

      setSigItemIdToUpdate(sigItem._id);
    }
  }, [sigItem]);

  useEffect(() => {
    setFilteredSigItems(sigItems);
  }, [sigItems]);

  useEffect(() => {
    handleFiltering();
  }, [filteredInstitution]);

  /* eslint-enable */
  function resetState() {
    resetName();
    resetLevel();
    resetSignType();
    resetSignPacking();
    resetSignPolicy();
    resetPolicyVersion();
    resetHashAlgorithm();
    setSigItemIdToUpdate('');
    setUpdating(false);
  }

  function handleCleanState(e) {
    e.preventDefault();
    scrollToTop();
    resetName();
    resetLevel();
    resetSignType();
    resetSignPacking();
    resetSignPolicy();
    resetPolicyVersion();
    resetHashAlgorithm();
    dispatch(updateSignatureItemCancel());
    setSigItemIdToUpdate('');
    setUpdating(false);
  }

  function getSigItemData(e, identifier) {
    e.preventDefault();
    const index = sigItems.findIndex((s) => s._id === identifier);
    dispatch(getSignatureItemByIdRequest(identifier, index));
    setUpdating(true);
    scrollToForm();
  }

  async function handleUpdateSigItem(e) {
    e.preventDefault();
    const identifier = sigItemIdToUpdate;
    const data = {
      name,
      level,
      signType,
      signPacking,
      signPolicy,
      policyVersion,
      hashAlgorithm,
      institutionId: filteredInstitution,
    };
    dispatch(
      updateSignatureItemRequest(identifier, data, sigItem.index, () => {
        scrollToTop();
        resetState();
      })
    );
  }

  function handleDeleteSigItem(e, identifier) {
    e.preventDefault();
    const index = sigItems.findIndex((s) => s._id === identifier);
    dispatch(deleteSignatureItemRequest(identifier, index));
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const obj = {
      name,
      level,
      signType,
      signPacking,
      signPolicy,
      policyVersion,
      hashAlgorithm,
      institutionId: filteredInstitution,
    };

    dispatch(
      storeSignatureItemRequest(obj, () => {
        scrollToTop();
        resetState();
      })
    );
  }

  function handleFiltering() {
    if (!filteredInstitution) {
      setFilteredSigItems([]);
      setInstitutionFilterStatus(false);

      return;
    }
    resetState();

    dispatch(SelectedData.selectInstitution(filteredInstitution));

    dispatch(getAllSignatureItemsRequest());
    setInstitutionFilterStatus(true);
  }
  function handleInstitutionFilter(institution) {
    setFilteredInstitution(institution.value);
  }

  function handleConfirmation(e, identifier, name) {
    Swal.fire({
      title: 'Confirme para excluir',
      text: `Tem certeza que deseja remover ${name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        return handleDeleteSigItem(e, identifier);
      }
      return false;
    });
  }

  function getInitials(id) {
    const institution = institutions.find((value) => value._id === id);

    return institution.initials;
  }

  function handleSignatureType(type) {
    scrollToForm();
    const nameInput = document.getElementById('signatureName');
    const signerInput = document.getElementById('signatureSigner');

    if (type === 'custom') {
      resetState();
      // eslint-disable-next-line no-unused-expressions
      nameInput && nameInput.focus();
    }

    const data = SignatureTypes[type];

    if (data) {
      const initials = getInitials(filteredInstitution, institutions);
      const newName = initials
        ? data.name.replace('<INST>', initials)
        : data.name;
      setName(newName);
      setSignPacking(data.signPacking);
      setSignPolicy(data.signPolicy);
      setPolicyVersion(data.policyVersion);
      setHashAlgorithm(data.hashAlgorithm);
      setSignType(data.signType);
      setLevel(data.level);

      if (type === 'person') {
        setTimeout(() => {
          // eslint-disable-next-line no-unused-expressions
          nameInput && nameInput.focus();
          // eslint-disable-next-line no-unused-expressions
          nameInput && nameInput.setSelectionRange(0, 13);
        }, 50);
      } else {
        // eslint-disable-next-line no-unused-expressions
        signerInput && signerInput.focus();
      }
    }
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Item de assinatura"
          subtitle="Gerenciamento"
          className="text-sm-left"
        />
      </Row>

      {/* Default Light Table */}
      <Row>
        <Col>
          <FilterWrapper>
            <Card small className="col-sm-6 mb-3">
              <CardHeader className="border-bottom">
                <h6 className="m-0 font-weight-bold">
                  <FiAlertCircle className="mr-2 " />
                  Por favor, antes de prosseguir selecione uma instituição:
                </h6>
                <div className="col-sm-12">
                  <SearchSelect
                    onSelect={handleInstitutionFilter}
                    value={filteredInstitution}
                    options={
                      institutions &&
                      institutions.map((i) => ({
                        label: `${i.name} - ${i.initials}`,
                        value: i._id,
                      }))
                    }
                  />
                </div>
              </CardHeader>
            </Card>
          </FilterWrapper>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0 font-weight-bold">
                Lista de itens de assinatura
              </h6>
            </CardHeader>
            <CardBody className="p-0 pb-3 pl-3 pr-3 overflow-auto">
              {loading ? (
                <LoadingContainer loading={loading} />
              ) : (
                <>
                  {filteredSigItems.length !== 0 ? (
                    <table className="table mb-0">
                      <thead className="bg-light">
                        <tr>
                          <th scope="col" className="border-0">
                            #
                          </th>
                          <th scope="col" className="border-0">
                            Nome
                          </th>
                          <th scope="col" className="border-0">
                            Política de Assinatura
                          </th>
                          {!institutionFilterStatus ? null : (
                            <th scope="col" className="border-0">
                              Ações
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {filteredSigItems &&
                          filteredSigItems.map((s, index) => (
                            <tr key={s._id}>
                              <td>{index}</td>
                              <td>{s.name}</td>
                              <td>{s.signPolicy}</td>
                              {!institutionFilterStatus ? null : (
                                <td>
                                  <ButtonGroup size="sm">
                                    <Button
                                      theme="white"
                                      onClick={(e) => getSigItemData(e, s._id)}
                                    >
                                      <span className="text-light">
                                        <FaRegEdit />
                                      </span>{' '}
                                      Editar
                                    </Button>
                                    <Button
                                      onClick={(e) =>
                                        handleConfirmation(e, s._id, s.name)
                                      }
                                      theme="white"
                                    >
                                      <span className="text-light">
                                        <FaRegTrashAlt />
                                      </span>{' '}
                                      Excluir
                                    </Button>
                                  </ButtonGroup>
                                </td>
                              )}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="col-sm-12 d-flex justify-content-center">
                      <h5 className="mt-3">
                        <FiAlertCircle className="mr-2" />
                        Não existem itens de assinatura cadastrados na
                        instituição selecionada.
                      </h5>
                    </div>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      {!institutionFilterStatus ? null : (
        <>
          {!updating && (
            <Row>
              <Col>
                <Card className="mb-3" small>
                  <CardHeader className="border-bottom">
                    <h6 className="m-0 font-weight-bold">
                      Selecione o tipo da assinatura
                    </h6>
                  </CardHeader>
                  <ListGroup flush>
                    <ListGroupItem className="p-3">
                      <Row>
                        <Col>
                          <OptionsBox
                            data={optionsData}
                            checked="custom"
                            onChange={handleSignatureType}
                          />
                        </Col>
                      </Row>
                    </ListGroupItem>
                  </ListGroup>
                </Card>
              </Col>
            </Row>
          )}
          <Row id="form-container">
            <Col>
              <Card className="mb-3" small>
                <CardHeader className="border-bottom">
                  <h6 className="m-0 font-weight-bold">
                    Informações sobre item de assinatura
                  </h6>
                </CardHeader>

                <ListGroup flush>
                  <ListGroupItem className="p-3">
                    <Row>
                      <Col>
                        <Form onSubmit={handleSubmit} id="edit">
                          <Row form>
                            <Col md="6" className="form-group">
                              <InputWrapper>
                                <label htmlFor="signatureName">Nome</label>
                                <FormInput
                                  id="signatureName"
                                  name="name"
                                  type="text"
                                  placeholder="Item exemplo"
                                  required
                                  {...bindName}
                                />
                                <span className="text-muted">
                                  *Insira um nome para o item de assinatura.
                                </span>
                              </InputWrapper>
                            </Col>
                            <Col md="6" className="form-group">
                              <InputWrapper>
                                <label htmlFor="signaturePackType">
                                  Tipo de empacotamento
                                </label>
                                <FormSelect
                                  id="signaturePackType"
                                  name="signPacking"
                                  required
                                  {...bindSignPacking}
                                >
                                  <option disabled value="">
                                    {' '}
                                    Escolher...
                                  </option>
                                  <option value="enveloped">Enveloped</option>
                                  <option value="enveloping">Enveloping</option>
                                  <option value="detached">Detached</option>
                                </FormSelect>
                                <span className="text-muted">
                                  *Selecione um tipo de empacotamento para o
                                  item de assinatura.
                                </span>
                              </InputWrapper>
                            </Col>
                          </Row>
                          <Row form>
                            <Col md="6" className="form-group">
                              <InputWrapper>
                                <label htmlFor="signaturePolicy">
                                  Política de assinatura
                                </label>
                                <FormSelect
                                  id="signaturePolicy"
                                  name="signPolicy"
                                  required
                                  {...bindSignPolicy}
                                >
                                  <option disabled value="">
                                    {' '}
                                    Escolher...
                                  </option>
                                  <option value="AD_RB">AD_RB</option>
                                  <option value="AD_RT">AD_RT</option>
                                  <option value="AD_RV">AD_RV</option>
                                  <option value="AD_RC">AD_RC</option>
                                  <option value="AD_RA">AD_RA</option>
                                </FormSelect>
                                <span className="text-muted">
                                  *Selecione uma política de assinatura para o
                                  item de assinatura.
                                </span>
                              </InputWrapper>
                            </Col>
                            <Col md="6" className="form-group">
                              <InputWrapper>
                                <label htmlFor="signaturePolicyVersion">
                                  Versão da política
                                </label>
                                <FormSelect
                                  id="signaturePolicyVersion"
                                  name="policyVersion"
                                  required
                                  {...bindPolicyVersion}
                                >
                                  <option disabled value="">
                                    {' '}
                                    Escolher...
                                  </option>
                                  <option value="1.0">1.0</option>
                                  <option value="1.1">1.1</option>
                                  <option value="1.2">1.2</option>
                                  <option value="2.3">2.3</option>
                                  <option value="2.4">2.4</option>
                                </FormSelect>
                                <span className="text-muted">
                                  *Selecione uma versão de política para o item
                                  de assinatura.
                                </span>
                              </InputWrapper>
                            </Col>
                          </Row>
                          <Row form>
                            <Col md="6" className="form-group">
                              <InputWrapper>
                                <label htmlFor="signatureHash">
                                  Algoritmo de Hash
                                </label>
                                <FormSelect
                                  id="signatureHash"
                                  name="hashAlgorithm"
                                  required
                                  {...bindHashAlgorithm}
                                >
                                  <option disabled value="">
                                    {' '}
                                    Escolher...
                                  </option>
                                  <option value="sha-256">sha-256</option>
                                  <option value="sha-512">sha-512</option>
                                </FormSelect>
                                <span className="text-muted">
                                  *Selecione um algoritmo de hash para o item de
                                  assinatura.
                                </span>
                              </InputWrapper>
                            </Col>
                            <Col md="6" className="form-group">
                              <InputWrapper>
                                <label htmlFor="signatureLevel">Nível</label>
                                <FormInput
                                  name="level"
                                  id="signatureLevel"
                                  type="number"
                                  placeholder="1"
                                  min="1"
                                  required
                                  {...bindLevel}
                                />
                                <span className="text-muted">
                                  *Insira um nível de assinatura.
                                </span>
                              </InputWrapper>
                            </Col>
                          </Row>
                          <Row form>
                            <Col md="6" className="form-group">
                              <InputWrapper>
                                <label htmlFor="signatureType">
                                  Tipo de assinatura
                                </label>
                                <FormSelect
                                  id="signatureType"
                                  name="signType"
                                  required
                                  {...bindSignType}
                                >
                                  <option disabled value="">
                                    {' '}
                                    Escolher...
                                  </option>
                                  <option value="default">default</option>
                                  <option value="PARALLEL_SIGNATURE">
                                    PARALLEL_SIGNATURE
                                  </option>
                                  <option value="COMPOSITE_SIGNATURE">
                                    COMPOSITE_SIGNATURE
                                  </option>
                                </FormSelect>
                                <span className="text-muted">
                                  *Selecione um tipo de assinatura.
                                </span>
                              </InputWrapper>
                            </Col>
                          </Row>

                          <Row form className="mt-2">
                            <Col md="12" className="text-right">
                              {updating ? (
                                <>
                                  <Button
                                    className="btn btn-danger btn-sm"
                                    onClick={handleCleanState}
                                    disabled={isSubmitting}
                                  >
                                    Cancelar
                                  </Button>
                                  <Button
                                    className="btn-primary ml-3"
                                    onClick={handleUpdateSigItem}
                                    disabled={isSubmitting}
                                  >
                                    {isSubmitting
                                      ? 'Carregando...'
                                      : 'Atualizar'}
                                  </Button>
                                </>
                              ) : (
                                <Button disabled={isSubmitting} type="submit">
                                  {isSubmitting
                                    ? 'Carregando...'
                                    : 'Criar novo item'}
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}
