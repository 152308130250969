/* eslint-disable import/no-cycle */
import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import {
  ReportsFailure,
  getDocumentsReportSuccess,
  getSignatureReportSuccess,
  getTimestampReportSuccess,
  getRankingReportSuccess,
  getBlockchainReportSuccess,
} from './actions';
import { refreshTokenRequest } from '../auth/actions';
import { store } from '../../index';

export function* getDocumentsReport({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const {
      institutionId,
      docTypeId,
      initialDate,
      finalDate,
      dateFilterCriteria,
      onSuccess,
    } = payload;

    const response = yield call(
      (url) =>
        api.get(url, {
          params: {
            institutionId,
            docTypeId,
            initialDate,
            finalDate,
            dateFilterCriteria,
          },
        }),
      '/management/reports/document'
    );
    const { data } = response;

    yield put(getDocumentsReportSuccess(data));

    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(ReportsFailure());
      toast.error(`Falha ao listar estatísticas`);
    }
  }
}

export function* getSignatureReport({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { institutionId, docTypeId, onSuccess, initialDate, finalDate } =
      payload;

    const response = yield call(
      (url) =>
        api.get(url, {
          params: {
            institutionId,
            docTypeId,
            initialDate,
            finalDate,
          },
        }),
      '/management/reports/signature'
    );
    const { data } = response;

    yield put(getSignatureReportSuccess(data));

    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(ReportsFailure());
      toast.error(`Falha ao listar estatísticas`);
    }
  }
}

export function* getTimestampReport({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const {
      clientId,
      signProvider,
      timestampProvider,
      timestampType,
      initialDate,
      finalDate,
      onSuccess,
    } = payload;

    const response = yield call(
      (url) =>
        api.get(url, {
          params: {
            clientId,
            signProvider,
            timestampProvider,
            timestampType,
            initialDate,
            finalDate,
          },
        }),
      '/management/reports/timestamp'
    );
    const { data } = response;

    yield put(getTimestampReportSuccess(data));

    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(ReportsFailure());
      toast.error(`Falha ao listar estatísticas`);
    }
  }
}

export function* getRankingReport({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { limit, docTypeId, initialDate, finalDate, onSuccess } = payload;

    const response = yield call(
      (url) =>
        api.get(url, {
          params: {
            limit,
            docTypeId,
            initialDate,
            finalDate,
          },
        }),
      '/management/reports/ranking'
    );
    const { data } = response;

    yield put(getRankingReportSuccess(data));

    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(ReportsFailure());
      toast.error(`Falha ao listar ranking de instituições`);
    }
  }
}

export function* getBlockchainReport({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { limit, page, type, initialDate, finalDate, onSuccess } = payload;

    const response = yield call(
      (url) =>
        api.get(url, {
          params: {
            limit,
            page,
            type,
            initialDate,
            finalDate,
          },
        }),
      '/management/statistic'
    );
    const { data } = response;

    yield put(getBlockchainReportSuccess(data));

    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(ReportsFailure());
      toast.error(`Falha ao listar estatísticas`);
    }
  }
}

export default all([
  takeLatest('@reports/REQUEST_DOCUMENTS', getDocumentsReport),
  takeLatest('@reports/REQUEST_SIGNATURES', getSignatureReport),
  takeLatest('@reports/REQUEST_TIMESTAMP', getTimestampReport),
  takeLatest('@reports/REQUEST_RANKING', getRankingReport),
  takeLatest('@reports/REQUEST_BLOCKCHAINSTATS', getBlockchainReport),
]);
