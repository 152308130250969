import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useSelector } from 'react-redux';
import DefaultLayout from '../pages/_layouts/Default';

import { getPermission, minimumPermission } from '../utils/permissionHelper';

export function RequireAuth({ children, permissionRequired }) {
  const { signed, user, permission } = useSelector((store) => store.auth);
  const location = useLocation();

  if (!signed || !user) {
    toast.error('Você deve fazer o login para acessar o sistema', {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: 'custom-id-yes',
    });

    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (
    signed &&
    !minimumPermission(permissionRequired, getPermission(permission))
  ) {
    toast.error('Você não tem permissão para acessar a página solicitada', {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: 'custom-id-yes',
    });

    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <DefaultLayout>{children}</DefaultLayout>;
}
