import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormCheckbox,
  Collapse,
  ListGroup,
  ListGroupItem,
  Form,
} from 'shards-react';

import { FiAlertCircle } from 'react-icons/fi';

import { FaArrowUp, FaArrowDown } from 'react-icons/fa';

import PageTitle from '../../components/common/PageTitle';
import LoadingContainer from '../../components/layout/LodingContainer';
import Pagination from '../../components/common/Pagination';

import SearchSelect from '../../components/common/SearchSelect';

import { getVersions } from '../../store/modules/logs/actions';

import { getAllInstitutionsRequest } from '../../store/modules/institution/actions';
import * as SelectedData from '../../store/modules/selectedData/actions';

import { scrollToTop } from '../../utils/scrolls';

import { normalOrManager } from '../../config/UserPermission';

import ErrorBox from '../../components/common/ErrorBox';
import { dateAndHour } from '../../utils/dateFormatter';

export default function LogVersions() {
  const dispatch = useDispatch();

  const institutions = useSelector((state) => state.institution.institutions);

  const { versions, loading } = useSelector((state) => state.logs);

  const selectedInstitution = useSelector(
    (state) => state.selectedData.selectedInstitution
  );

  const [filteredInstitution, setFilteredInstitution] =
    useState(selectedInstitution);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [itemsSize, setItemsSize] = useState(20);

  const [error, setError] = useState(null);
  const [showFilter, setShowFilter] = useState(true);
  const [isAllInstitutions, setIsAllInstitutions] = useState(false);

  const { user, permission } = useSelector((state) => state.auth);
  const { institution } = user;

  const isNormalUser = normalOrManager.includes(permission);

  /*eslint-disable */
  useEffect(() => {
    if (isNormalUser) {
      setFilteredInstitution(institution.id_institution.toString());
    } else {
      dispatch(getAllInstitutionsRequest());
    }
  }, [dispatch]);

  function handleVersions() {
    const query = {
      page,
      limit: itemsSize
    };

    const findInstitution = institutions
      .find(item => item._id === filteredInstitution);

    Object.assign(
      query,
      !isAllInstitutions && findInstitution && {
        client_id: findInstitution.client_id,
      },
    )

    dispatch(getVersions(query, () => {
      setShowFilter(false);
    }))
  }

  useEffect(() => {
    if (versions.content)
      setTotal(versions.total);
  }, [versions.total]);

  useEffect(() => {
    if (versions.content)
      handleVersions();
  }, [page, itemsSize]);

  useEffect(() => {
    dispatch(SelectedData.selectInstitution(filteredInstitution));
  }, [filteredInstitution]);


  function handleInstitutionFilter(institution) {
    setFilteredInstitution(institution.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setError(null);
    setPage(1);

    if (!isAllInstitutions &&
      (!filteredInstitution || filteredInstitution === ''))
      return setError("Selecione uma instituição");

    handleVersions();
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Registros de Versão"
          subtitle="Logs"
          className="text-sm-left"
        />
      </Row>
      {/* Filters */}
      <Row id="form-container">
        <Col>
          <Card className="mb-3" small>
            <CardHeader className="border-bottom">
              <Row>
                <Col sm="6">
                  <h6 className="m-0 font-weight-bold">
                    Filtros
                  </h6>
                </Col>
                <Col sm="6" className="text-right">
                  <Button theme="light" onClick={() => setShowFilter(!showFilter)}>
                    <span className="mr-2">
                      {showFilter ? <FaArrowUp /> : <FaArrowDown />}
                    </span>
                    {showFilter ? "Esconder" : "Mostrar"}
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <Collapse open={showFilter}>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form onSubmit={handleSubmit}>
                        <Row form>
                          <Col md="6" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Instituição
                            </span>
                            <Row className="pt-2">
                              <Col md="8">
                                <SearchSelect
                                  placeholder="Pesquisa por nome, sigla ou client_id"
                                  onSelect={handleInstitutionFilter}
                                  value={filteredInstitution}
                                  options={
                                    institutions &&
                                    institutions.map(i => ({
                                      label: `${i.name} - ${i.initials}`,
                                      value: i._id,
                                      client_id: i.client_id
                                    }))
                                  }
                                  styleContainer={{
                                    marginTop: '0px'
                                  }}
                                  disabled={isAllInstitutions}
                                />
                              </Col>
                              <Col md="4" className="pt-2 pl-0">
                                <FormCheckbox
                                  checked={isAllInstitutions}
                                  onChange={() => setIsAllInstitutions(!isAllInstitutions)}
                                >
                                  <span className="text-dark">
                                    Ver todas
                                  </span>
                                </FormCheckbox>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="8">
                            <ErrorBox error={error} />
                          </Col>
                          <Col md="4" className="text-right">
                            <Button
                              className="btn-primary ml-3"
                            // onClick={handleCleanState}
                            >
                              Consultar
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Collapse>
          </Card>
        </Col>
      </Row>
      {loading && (
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3 pl-3 pr-3 overflow-auto">
                {loading && <LoadingContainer loading={loading} />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      {!loading && versions.content && versions.content.length > 0 && (
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3 pl-3 pr-3 overflow-auto">
                <>
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          #
                        </th>
                        <th scope="col" className="border-0">
                          Nome
                        </th>
                        <th scope="col" className="border-0">
                          Versão
                        </th>
                        <th scope="col" className="border-0">
                          ID do cliente
                        </th>
                        <th scope="col" className="border-0">
                          Data
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {versions.content &&
                        versions.content.map((s, index) => (
                          <tr key={s.id}>
                            <td>{s.id}</td>
                            <td>{s.name}</td>
                            <td>{s.version}</td>
                            <td>{s.client_id}</td>
                            <td>{dateAndHour(s.createdAt)}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <Pagination
                    total={total}
                    page={page}
                    items={itemsSize}
                    onChangePage={e => {
                      scrollToTop(e);
                      setPage(e);
                    }}
                    onChangeItems={e => {
                      scrollToTop();
                      setItemsSize(e);
                    }}
                  />
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      {!loading && versions.content && versions.content.length === 0 && (
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3 pl-3 pr-3 overflow-auto">
                <div className="col-sm-12 d-flex justify-content-center">
                  <h5 className="mt-3">
                    <FiAlertCircle className="mr-2" />
                    Não existem logs encontrados para os filtros escolhidos.
                  </h5>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
}
