/* general documents */
export function getGeneralDocumentsRequest({ client_id } = {}, onSuccess) {
  return {
    type: '@generalDocuments/GET_GENERAL_DOCUMENTS',
    payload: {
      client_id,
      onSuccess,
    },
  };
}
export function getGeneralDocumentsSuccess(data) {
  return {
    type: '@generalDocuments/GET_GENERAL_DOCUMENTS_SUCCESS',
    payload: { data },
  };
}

/* Failure */
export function generalDocumentsFailure() {
  return {
    type: '@generalDocuments/REQUEST_FAILURE',
  };
}
