/* List */
export function getAllTagsRequest() {
  return {
    type: '@tag/GET_ALL_TAGS_REQUEST',
  };
}
export function getAllTagsSuccess(tags) {
  return {
    type: '@tag/GET_ALL_TAGS_SUCCESS',
    payload: { tags },
  };
}
export function getTagByIdRequest(id, index) {
  return {
    type: '@tag/GET_TAG_BY_ID_REQUEST',
    payload: { id, index },
  };
}
export function getTagByIdSuccess(tag, index) {
  return {
    type: '@tag/GET_TAG_BY_ID_SUCCESS',
    payload: { tag, index },
  };
}

/* Store */
export function storeTagRequest(data, onSuccess) {
  return {
    type: '@tag/STORE_TAG_REQUEST',
    payload: { data, onSuccess },
  };
}
export function storeTagSuccess(tag) {
  return {
    type: '@tag/STORE_TAG_SUCCESS',
    payload: { tag },
  };
}

/* Update */
export function updateTagRequest(id, data, index) {
  return {
    type: '@tag/UPDATE_TAG_REQUEST',
    payload: { id, data, index },
  };
}
export function updateTagSuccess(index, data, onSuccess) {
  return {
    type: '@tag/UPDATE_TAG_SUCCESS',
    payload: { index, data, onSuccess },
  };
}
export function updateTagCancel() {
  return {
    type: '@tag/UPDATE_TAG_CANCEL',
  };
}

/* Delete */
export function deleteTagRequest(id, index) {
  return {
    type: '@tag/DELETE_TAG_REQUEST',
    payload: { id, index },
  };
}
export function deleteTagSuccess(index) {
  return {
    type: '@tag/DELETE_TAG_SUCCESS',
    payload: { index },
  };
}

/* Failure */
export function tagFailure() {
  return {
    type: '@tag/TAG_FAILURE',
  };
}
