import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as dateFns from 'date-fns';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  ListGroup,
  ListGroupItem,
  Form,
  Collapse,
  FormSelect,
  FormInput,
  FormCheckbox,
} from 'shards-react';

import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { FiAlertCircle } from 'react-icons/fi';
import { useInput } from '../../hooks/input-hook';

import PageTitle from '../../components/common/PageTitle';
import LoadingContainer from '../../components/layout/LodingContainer';
import Pagination from '../../components/common/Pagination';

import { scrollMore, scrollToTop } from '../../utils/scrolls';
import { dateAndHour } from '../../utils/dateFormatter';
import {
  cleanState,
  getBlockchainReport,
} from '../../store/modules/reports/actions';
import ErrorBox from '../../components/common/ErrorBox';

export default function BlockChainStats() {
  const dispatch = useDispatch();

  const { loading, list, total } = useSelector((state) => state.reports);

  const [showFilter, setShowFilter] = useState(true);
  const [page, setPage] = useState(1);
  const [itemsSize, setItemsSize] = useState(10);

  const { value: type, setValue: setType } = useInput('register');
  const { value: initialDate, setValue: setInitialDate } = useInput('');
  const { value: finalDate, setValue: setFinalDate } = useInput('');
  const [isAllPeriod, setIsAllPeriod] = useState(true);
  const [error, setError] = useState(null);

  const getRegistries = useCallback(() => {
    const query = {
      type,
      page,
      limit: itemsSize,
      ...(!isAllPeriod && initialDate !== '' && { initialDate }),
      ...(!isAllPeriod && finalDate !== '' && { finalDate }),
    };

    dispatch(
      getBlockchainReport(query, () => {
        setShowFilter(false);
        scrollMore(180);
      })
    );
  }, [type, page, itemsSize, initialDate, finalDate, isAllPeriod, dispatch]);

  useEffect(() => {
    if (total > 0) getRegistries();
  }, [page, itemsSize, total]);

  useEffect(() => {
    dispatch(cleanState());
    setPage(1);
  }, [type]);

  useEffect(
    () => () => {
      dispatch(cleanState());
    },
    [dispatch]
  );

  function handleSubmit(e) {
    e.preventDefault();

    if (
      !isAllPeriod &&
      initialDate &&
      initialDate !== '' &&
      finalDate &&
      finalDate !== '' &&
      dateFns.isAfter(
        dateFns.parseISO(initialDate),
        dateFns.parseISO(finalDate)
      )
    ) {
      return setError('A data final precisa ser maior que a data inicial');
    }

    return getRegistries();
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Blockchain"
          subtitle="Estatísticas"
          className="text-sm-left"
        />
      </Row>
      {/* Filters */}
      <Row id="form-container">
        <Col>
          <Card className="mb-3" small>
            <CardHeader className="border-bottom">
              <Row>
                <Col sm="6">
                  <h6 className="m-0 font-weight-bold">Filtros</h6>
                </Col>
                <Col sm="6" className="text-right">
                  <Button
                    theme="light"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <span className="mr-2">
                      {showFilter ? <FaArrowUp /> : <FaArrowDown />}
                    </span>
                    {showFilter ? 'Esconder' : 'Mostrar'}
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <Collapse open={showFilter}>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form onSubmit={handleSubmit}>
                        <Row form>
                          <Col md="4" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Tipo do lote
                            </span>
                            <Row className="pt-2">
                              <Col md="6">
                                <FormSelect
                                  id="status"
                                  value={type}
                                  onChange={(e) => setType(e.target.value)}
                                >
                                  <option value="register">Registrados</option>
                                  <option value="revoke">Revogados</option>
                                </FormSelect>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="4" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Data inicial
                            </span>
                            <FormInput
                              type="date"
                              className="mt-2"
                              disabled={isAllPeriod}
                              value={initialDate}
                              onChange={(e) => setInitialDate(e.target.value)}
                              required={!isAllPeriod}
                            />
                          </Col>
                          <Col md="8" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Data Final
                            </span>
                            <Row className="pt-2">
                              <Col md="6">
                                <FormInput
                                  type="date"
                                  disabled={isAllPeriod}
                                  value={finalDate}
                                  onChange={(e) => setFinalDate(e.target.value)}
                                  required={!isAllPeriod}
                                />
                              </Col>
                              <Col md="4" className="pt-2 pl-0">
                                <FormCheckbox
                                  checked={isAllPeriod}
                                  onChange={() => setIsAllPeriod(!isAllPeriod)}
                                >
                                  <span className="text-dark">
                                    Intervalo completo
                                  </span>
                                </FormCheckbox>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="8">
                            <ErrorBox error={error} />
                          </Col>
                          <Col md="12" className="text-right">
                            <Button className="btn-primary ml-3">
                              Consultar
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Collapse>
          </Card>
        </Col>
      </Row>
      {loading && <LoadingContainer loading={loading} />}
      {/* list */}
      {!loading && list && (
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3 pl-3 pr-3 overflow-auto">
                {loading && <LoadingContainer loading={loading} />}
                {!loading && list.length > 0 && (
                  <>
                    <table className="table mb-0">
                      <thead className="bg-light">
                        <tr>
                          <th scope="col" className="border-0">
                            Data
                          </th>
                          <th scope="col" className="border-0">
                            Quantidade de registros
                          </th>
                          <th scope="col" className="border-0">
                            Recibo
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {list &&
                          list.map((s) => (
                            <tr key={s.tx_receipt}>
                              <td>{dateAndHour(s.tx_date)}</td>
                              <td>{s.count}</td>
                              <td>{s.tx_receipt}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <Pagination
                      total={total}
                      page={page}
                      items={itemsSize}
                      onChangePage={(e) => {
                        scrollToTop(e);
                        setPage(e);
                      }}
                      onChangeItems={(e) => {
                        scrollToTop();
                        setItemsSize(e);
                      }}
                    />
                  </>
                )}
                {list?.length === 0 && (
                  <div className="col-sm-12 d-flex justify-content-center">
                    <h5 className="mt-3">
                      <FiAlertCircle className="mr-2" />
                      Não existem registros para os filtros selecionados
                    </h5>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
}
