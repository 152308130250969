import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { responseErrors } from '../../../utils/responseErros';
import api from '../../../services/api';
import {
  getAllCoursesSuccess,
  getCourseByIdSuccess,
  storeCourseSuccess,
  updateCourseSuccess,
  deleteCourseSuccess,
  courseFailure,
} from './actions';
import { refreshTokenRequest } from '../auth/actions';
import { store } from '../../index';

export function* getCourse() {
  const { refresh_token } = store.getState().auth;
  const { selectedInstitution } = store.getState().selectedData;

  try {
    const response = yield call((url) => {
      return api.get(url, {
        params: {
          institutionId: selectedInstitution,
        },
      });
    }, '/management/course');
    const course = response.data;

    yield put(getAllCoursesSuccess(course));
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(courseFailure());
      toast.error(`Falha ao listar course`);
    }
  }
}
export function* getCourseById({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, index } = payload;
    const response = yield call(api.get, `/management/course/${id}`);
    const course = response.data;

    yield put(getCourseByIdSuccess(course, index));
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(courseFailure());
      toast.error('Falha ao obter dados da course');
    }
  }
}
export function* storeCourse({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { data, onSuccess } = payload;

    const response = yield call(api.post, '/management/course', {
      ...data,
      institution: data.institution._id,
    });

    const course = response.data;
    course.institution = payload.data.institution;

    yield put(storeCourseSuccess(course));
    toast.success('Grupo de cursos criado com sucesso');
    onSuccess && onSuccess();
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(courseFailure());
      Swal.fire(responseErrors(err.response, 'Falha ao criar grupo de cursos'));
    }
  }
}

export function* updateCourse({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, data, index, onSuccess } = payload;
    const response = yield call(api.patch, `/management/course/${id}`, data);
    const course = response.data;

    yield put(updateCourseSuccess(index, course));
    toast.success('Course atualizada com sucesso');
    onSuccess && onSuccess();
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(courseFailure());
      Swal.fire(
        responseErrors(err.response, 'Falha ao atualizar grupo de cursos')
      );
    }
  }
}

export function* deleteCourse({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, index } = payload;
    yield call(api.delete, `/management/course/${id}`);

    yield put(deleteCourseSuccess(index));
    toast.success('Curso excluído com sucesso');
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(courseFailure());
      toast.error('Falha ao excluir curso');
    }
  }
}

export default all([
  takeLatest('@course/GET_ALL_COURSES_REQUEST', getCourse),
  takeLatest('@course/GET_COURSE_BY_ID_REQUEST', getCourseById),
  takeLatest('@course/STORE_COURSE_REQUEST', storeCourse),
  takeLatest('@course/UPDATE_COURSE_REQUEST', updateCourse),
  takeLatest('@course/DELETE_COURSE_REQUEST', deleteCourse),
]);
