/* List */
export function getAllUsersRequest() {
  return {
    type: '@user/GET_ALL_USERS_REQUEST',
  };
}
export function getAllUsersSuccess(users) {
  return {
    type: '@user/GET_ALL_USERS_SUCCESS',
    payload: { users },
  };
}
export function getUserByIdRequest(id, index) {
  return {
    type: '@user/GET_USER_BY_ID_REQUEST',
    payload: { id, index },
  };
}
export function getUserByIdSuccess(user, index) {
  return {
    type: '@user/GET_USER_BY_ID_SUCCESS',
    payload: { user, index },
  };
}
/* Store */
export function storeUserRequest(data, onSuccess) {
  return {
    type: '@user/STORE_USER_REQUEST',
    payload: { data, onSuccess },
  };
}
export function storeUserSuccess(user) {
  return {
    type: '@user/STORE_USER_SUCCESS',
    payload: { user },
  };
}
/* Update */
export function updateUserRequest(id, data, index) {
  return {
    type: '@user/UPDATE_USER_REQUEST',
    payload: { id, data, index },
  };
}
export function updateUserSuccess(index, data) {
  return {
    type: '@user/UPDATE_USER_SUCCESS',
    payload: { index, data },
  };
}
export function updateUserCancel() {
  return {
    type: '@user/UPDATE_USER_CANCEL',
  };
}
/* Delete */
export function deleteUserRequest(id, index) {
  return {
    type: '@user/DELETE_USER_REQUEST',
    payload: { id, index },
  };
}
export function deleteUserSuccess(index) {
  return {
    type: '@user/DELETE_USER_SUCCESS',
    payload: { index },
  };
}
/* Failure */
export function userFailure() {
  return {
    type: '@user/USER_FAILURE',
  };
}
