/* eslint-disable import/no-cycle */
import { all, takeLatest, call, put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import {
  signInSuccess,
  // refreshTokenSuccess,
  signFailure,
  // signOut as signOutAction,
  // refreshTokenStart,
} from './actions';

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;

    const response = yield call(api.post, 'auth', {
      email,
      password,
    });

    yield put({
      type: '@auth/TAKE_PERMISSION',
      payload: response.data.permission,
    });

    const {
      accessToken: token,
      userId,
      name,
      email: userEmail,
      refreshToken,
      institution,
      cpf,
    } = response.data;

    const user = {
      id: userId,
      name,
      email: userEmail,
      institution,
      cpf,
    };
    api.defaults.headers.Authorization = `Bearer ${token}`;
    api.defaults.headers['X-RefreshToken'] = refreshToken;

    yield put(signInSuccess(token, user, refreshToken));
    toast.success('Acesso permitido, bem-vindo(a)!');
  } catch (err) {
    toast.error('Verifique o email ou senha e tente novamente');
    yield put(signFailure());
  }
}

export function* refreshToken() {
  // const {
  //   updatingToken,
  //   refresh_token: refresh_tokenOld,
  // } = store.getState().auth;
  // if (updatingToken) {
  //   return;
  // }
  // yield put(refreshTokenStart());
  // try {
  //   const response = yield call(api.post, 'auth/refresh', {
  //     refresh_token: refresh_tokenOld,
  //   });
  //   const {
  //     accessToken: token,
  //     userId,
  //     name,
  //     email,
  //     refreshToken: refresh_token,
  //   } = response.data;
  //   const user = {
  //     id: userId,
  //     name,
  //     email,
  //   };
  //   api.defaults.headers.Authorization = `Bearer ${token}`;
  //   yield put(refreshTokenSuccess(token, user, refresh_token));
  //   window.location.reload();
  // } catch (err) {
  //   toast.error('Sessão expirada');
  //   yield put(signOutAction());
  // }
}
// Handling token to private requests in redux-persist
export function setToken({ payload }) {
  if (!payload) return;

  const { token, refresh_token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
    api.defaults.headers['X-RefreshToken'] = refresh_token;
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeEvery('@auth/REFRESH_TOKEN_REQUEST', refreshToken),
]);
