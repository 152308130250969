import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { responseErrors } from '../../../utils/responseErros';
import api from '../../../services/api';
import { getUserSuccess, updateUserSuccess, userFailure } from './actions';

import { refreshTokenRequest } from '../auth/actions';
import { store } from '../../index';

export function* getUser() {
  const { refresh_token, user } = store.getState().auth;

  try {
    const response = yield call(api.get, `/users/${user.id}`);
    const userData = response.data;
    yield put(getUserSuccess(userData));
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(userFailure());
      Swal.fire(responseErrors(err.response, 'Falha ao listar os dados'));
    }
  }
}

export function* updateUser({ payload }) {
  const { refresh_token, user: userAuth } = store.getState().auth;

  try {
    const { data } = payload;
    const response = yield call(api.patch, `/users/${userAuth.id}`, data);
    const user = response.data;
    yield put(updateUserSuccess(user));
    toast.success('Usuário atualizado com sucesso');
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(userFailure());
      Swal.fire(responseErrors(err.response, 'Falha ao atualizar usuário'));
    }
  }
}

export default all([
  takeLatest('@profile/GET_USER_REQUEST', getUser),
  takeLatest('@profile/UPDATE_USER_REQUEST', updateUser),
]);
