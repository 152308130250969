/* eslint-disable import/no-cycle */
import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import institution from './institution/sagas';
import signer from './signer/sagas';
import presigner from './presigner/sagas';
import precourse from './preCourse/sagas';
import signatureItem from './signatureItem/sagas';
import tag from './tag/sagas';
import course from './course/sagas';
import documentType from './documentType/sagas';
import documentGroup from './documentGroup/sagas';
import signatureConfiguration from './signatureConfig/sagas';
import documentConfiguration from './documentConfig/sagas';
import user from './user/sagas';
import profile from './profile/sagas';
import documents from './documents/sagas';
import reports from './reports/sagas';
import logs from './logs/sagas';
import ddrconfig from './DDRConfig/sagas';
import generalDocuments from './generalDocuments/sagas';
import batchRegistry from './batchRegistry/sagas';

export default function* rootSaga() {
  return yield all([
    auth,
    institution,
    signer,
    presigner,
    precourse,
    signatureItem,
    tag,
    course,
    documentType,
    documentGroup,
    signatureConfiguration,
    documentConfiguration,
    user,
    profile,
    documents,
    reports,
    logs,
    ddrconfig,
    generalDocuments,
    batchRegistry,
  ]);
}
