/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ButtonGroup,
  Button,
  Form,
  ListGroup,
  ListGroupItem,
  FormInput,
  FormSelect,
  FormGroup,
  InputGroup,
  InputGroupAddon,
} from 'shards-react';

import { toast } from 'react-toastify';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { FaRegEdit, FaRegTrashAlt, FaPlus } from 'react-icons/fa';
import { FiAlertCircle, FiCheckCircle, FiInfo } from 'react-icons/fi';

import Swal from 'sweetalert2';

import { useInput } from '../../hooks/input-hook';

import { InputWrapper, FilterWrapper } from '../common/styles';

import PageTitle from '../../components/common/PageTitle';
import LoadingContainer from '../../components/layout/LodingContainer';
import ErrorBox from '../../components/common/ErrorBox';

import {
  getAllSignersRequest,
  getSignerByIdRequest,
  storeSignerRequest,
  updateSignerRequest,
  updateSignerCancel,
  deleteSignerRequest,
} from '../../store/modules/signer/actions';

import { getAllCoursesRequest } from '../../store/modules/course/actions';
import { getAllSignatureItemsRequest } from '../../store/modules/signatureItem/actions';
import { getAllInstitutionsRequest } from '../../store/modules/institution/actions';
import * as SelectedData from '../../store/modules/selectedData/actions';

import SearchSelect from '../../components/common/SearchSelect';

import {
  isCnpj,
  isValidCpfCnpjLength,
  isValidField,
} from '../../utils/validations';
import { scrollToForm, scrollToTop } from '../../utils/scrolls';
import {
  extractCert,
  extractCertFromCer,
  extractTextFromCer,
  getAdditionalDataFromCnpj,
} from '../../utils/extractCert';
import './signer.css';
import CustomRadio from '../../components/common/CustomRadio';
import { CertLabelContent } from './style';
import CollapsibleRow from '../../components/common/CollapsibleRow';
import { Mask } from '../../utils/stringUtils';

export default function Signers() {
  const dispatch = useDispatch();

  const institutions = useSelector((state) => state.institution.institutions);
  const sigItems = useSelector((state) => state.signatureItem.sigItems);
  const courses = useSelector((state) => state.course.courses);
  const signers = useSelector((state) => state.signer.signers);
  const signer = useSelector((state) => state.signer.signer);
  const { loading, isSubmitting } = useSelector((state) => state.signer);
  const selectedInstitution = useSelector(
    (state) => state.selectedData.selectedInstitution
  );

  const {
    value: id,
    setValue: setId,
    bind: bindId,
    reset: resetId,
  } = useInput('');
  const {
    value: cpfDocument,
    setValue: setCpfDocument,
    bind: bindCpfDocument,
    reset: resetCpfDocument,
  } = useInput('');
  const {
    value: name,
    setValue: setName,
    bind: bindName,
    reset: resetName,
  } = useInput('');
  const {
    value: attrib,
    setValue: setAttrib,
    bind: bindAttrib,
    reset: resetAttrib,
  } = useInput('');
  const {
    value: certificate,
    setValue: setCertificate,
    bind: bindCertificate,
    reset: resetCertificate,
  } = useInput('');
  const {
    value: course,
    setValue: setCourse,
    bind: bindCourse,
    reset: resetCourse,
  } = useInput('');

  const [filteredInstitution, setFilteredInstitution] =
    useState(selectedInstitution);
  const [signerIdToUpdate, setSignerIdToUpdate] = useState('');
  const [updating, setUpdating] = useState(false);
  const [institutionFilterStatus, setInstitutionFilterStatus] = useState(false);
  const [sigItens, setSigItens] = useState([]);
  const [signersArray, setSignersArray] = useState([]);
  const [filteredSigners, setFilteredSigners] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [filteredSigItems, setFilteredSigItems] = useState([]);
  const [error, setError] = useState(null);
  const [certificateDone, setCertificateDone] = useState(false);
  const [certificateType, setCertificateType] = useState('file');
  const [showRows, setShowRows] = useState(false);

  useEffect(() => {
    dispatch(getAllInstitutionsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(signer).length !== 0) {
      console.log(
        'Signer',
        JSON.stringify({ _id: signer.id, name: signer.name })
      );
      setId(signer.id);
      setName(signer.name);
      setAttrib(signer.attrib);
      setCertificate(signer.certificate);
      setCpfDocument(signer?.additionalInfo?.cpfDocument || '');

      if (signer.courseGroup) {
        setCourse(signer.courseGroup._id);
      } else {
        setCourse('');
      }
      console.log(
        'signItems to signer',
        JSON.stringify(signer.signItems.map(({ _id, name }) => ({ _id, name })))
      );
      const keySearch = filteredInstitution.toLowerCase();
      console.log('filtered institution', keySearch);
      const sigItemResult = sigItems.filter((item) => {
        if (item && item.institution && item.institution._id) {
          const match = item.institution._id;
          return match === keySearch;
        }
        return false;
      });
      setFilteredSigItems(sigItemResult);
      signer.signItems && signer.signItems.length > 0
        ? setSigItens(signer.signItems.map((item) => item._id))
        : setSigItens([]);

      signer.signerSubstitutes && signer.signerSubstitutes.length > 0
        ? setSignersArray(signer.signerSubstitutes.map((item) => item._id))
        : setSignersArray([]);

      setSignerIdToUpdate(signer.id);
    }
  }, [signer]);

  useEffect(() => {
    console.log(
      `signItems for institution ${filteredInstitution?.toLowerCase()}`,
      JSON.stringify(filteredSigItems.map(({ _id, name }) => ({ _id, name })))
    );
  }, [filteredSigItems]);

  useEffect(() => {
    setFilteredSigners(signers);
  }, [signers]);

  useEffect(() => {
    setFilteredSigItems(sigItems);
  }, [sigItems]);

  useEffect(() => {
    setFilteredCourses(courses);
  }, [courses]);

  useEffect(() => {
    handleFiltering();
  }, [filteredInstitution]);

  function getSignerData(e, identifier, index) {
    e.preventDefault();
    dispatch(getSignerByIdRequest(identifier, index));
    setUpdating(true);
    setCertificateType('text');
    resetCertificateFile();
    scrollToForm();
  }
  function resetState() {
    resetId();
    resetName();
    resetAttrib();
    resetCertificate();
    resetCourse();
    setSignerIdToUpdate('');
    setUpdating(false);
    setCertificateDone(false);
    setSigItens([]);
    setSignersArray([]);
    setCertificateType('file');
    resetCertificateFile();
    resetCpfDocument();
  }
  function handleUpdateSigner(e) {
    e.preventDefault();
    const identifier = signerIdToUpdate;

    const data = {
      id,
      institutionId: filteredInstitution,
      name,
      attrib,
      certificate,
      courseGroupId: course,
      ...(isCnpj(id) && {
        additionalInfo: {
          cpfDocument,
        },
      }),
      signItems: sigItens,
      signerSubstitutes: signersArray,
    };

    if (
      !validateCpfFromCnpjSigner(
        data.certificate,
        data.id,
        data?.additionalInfo?.cpfDocument
      )
    )
      return;

    dispatch(
      updateSignerRequest(identifier, data, signer.index, () => {
        resetState();
        setSignerIdToUpdate('');
        setUpdating(false);
        scrollToTop();
        dispatch(getAllSignersRequest());
      })
    );
  }

  function handleDeleteSigner(e, identifier) {
    e.preventDefault();
    const index = signers.findIndex((element) => element.id === identifier);

    dispatch(deleteSignerRequest(identifier, index));
  }

  function handleCleanState(e) {
    e.preventDefault();
    resetId();
    resetName();
    resetAttrib();
    resetCertificate();
    setSignerIdToUpdate('');
    setUpdating(false);
    setSigItens([]);
    setSignersArray([]);
    dispatch(updateSignerCancel());
    resetCertificateFile();
    setCertificateType('file');
    scrollToTop();
  }

  function fillCpfDocumentFromCnpjSigner(certificate, signerDocument) {
    const showError = () =>
      Swal.fire({
        icon: 'warning',
        title: 'Ops! Aconteceu algo errado...',
        text: 'Não foi possível encontrar o CPF do responsável através do certificado digital, por favor, entre em contato com o suporte.',
      });

    try {
      if (!isCnpj(signerDocument)) {
        return;
      }

      const certificateData = extractCertFromCer(certificate);
      const additionalInfo = getAdditionalDataFromCnpj(
        certificateData,
        signerDocument
      );

      if (!additionalInfo?.cpf) {
        showError();
        return;
      }

      setCpfDocument(additionalInfo.cpf);

      Swal.fire({
        icon: 'success',
        title: 'CPF obtido!',
        text: `O CPF ${Mask.cpf(
          additionalInfo.cpf
        )} foi obtido de forma automática, por favor, valide se os dados estão corretos.`,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: 'Ok, estou ciente!',
      });
    } catch (error) {
      console.error(error);
      showError();
    }
  }

  function validateCpfFromCnpjSigner(
    certificate,
    signerCnpjDocument,
    signerCpfDocument
  ) {
    try {
      if (!isCnpj(signerCnpjDocument)) {
        return true;
      }

      const certificateData = extractCertFromCer(certificate);
      const additionalInfo = getAdditionalDataFromCnpj(
        certificateData,
        signerCnpjDocument
      );

      if (!additionalInfo?.cpf) {
        throw new Error('Erro ao extrair dados do certificado digital');
      }

      if (additionalInfo.cpf === signerCpfDocument) return true;

      Swal.fire({
        icon: 'warning',
        title: 'Ops! Erro de validação...',
        text: 'O CPF do responsável do não está de acordo com o CPF cadastrado no certificado digital, por favor, verifique e tente novamente.',
      });
      return false;
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Ops! Erro interno...',
        text: 'Não foi possível processar o certificado, contate o suporte informando o ocorrido.',
      });

      return false;
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const obj = {
      id,
      name,
      attrib,
      institutionId: filteredInstitution,
      certificate,
      courseGroupId: course,
      ...(isCnpj(id) && {
        additionalInfo: {
          cpfDocument,
        },
      }),
    };

    if (sigItens.length >= 1 && sigItens[0]) {
      Object.assign(obj, {
        signItems: sigItens,
      });
    }

    if (signersArray.length >= 1 && signersArray[0]) {
      Object.assign(obj, {
        signerSubstitutes: signersArray,
      });
    }
    if (!validationSchema(obj)) return;
    dispatch(
      storeSignerRequest(obj, () => {
        scrollToTop();
        resetState();
      })
    );
  }

  function handleFiltering() {
    if (!filteredInstitution) {
      setFilteredSigners([]);
      setFilteredSigItems([]);
      setInstitutionFilterStatus(false);

      return;
    }
    resetState();

    dispatch(SelectedData.selectInstitution(filteredInstitution));
    dispatch(getAllSignersRequest());
    dispatch(getAllSignatureItemsRequest());
    dispatch(getAllCoursesRequest());
    setInstitutionFilterStatus(true);
  }
  function handleInstitutionFilter(institution) {
    setFilteredInstitution(institution.value);
  }

  function handleConfirmation(e, identifier, name) {
    Swal.fire({
      title: 'Confirme para excluir',
      text: `Tem certeza que deseja remover ${name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        return handleDeleteSigner(e, identifier);
      }
      return false;
    });
  }

  function validationSchema(obj) {
    if (!isValidField(obj.id)) {
      setError('O CPF/CNPJ é obrigatório');
      return false;
    }
    if (!isValidCpfCnpjLength(obj.id)) {
      setError('Informe um CPF ou CNPJ');
      return false;
    }
    if (!isValidField(obj.name)) {
      setError('O nome é obrigatório');
      return false;
    }
    if (!isValidField(obj.attrib)) {
      setError('A atribuiição é obrigatória');
      return false;
    }
    if (!isValidField(obj.institutionId)) {
      setError('Selecione uma instituição');
      return false;
    }
    if (!isValidField(obj.certificate)) {
      setError('O certificado é obrigatório');
      return false;
    }
    if (
      !validateCpfFromCnpjSigner(
        obj.certificate,
        obj.id,
        obj?.additionalInfo?.cpfDocument
      )
    ) {
      return false;
    }

    setError(null);
    return true;
  }

  async function handleCertificate() {
    const fileInput = document.getElementById('signerCertificate');
    const { value } = fileInput;
    if (!value) return;
    const extension = value
      .split(/(\\|\/)/g)
      .pop()
      .split('.')
      .pop();

    if (!['pfx', 'cer', 'p12'].includes(extension)) {
      resetCertificateFile();
      toast.error('Formato não suportado', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 'custom-id-yes',
      });
    }

    const file = fileInput.files[0];

    if (extension === 'pfx' || extension === 'p12') {
      const { value: password } = await Swal.fire({
        title: 'Digite a senha',
        input: 'password',
        inputLabel: 'Digite a senha do seu certificado',
        inputValue: '',
        showCancelButton: false,
        showCloseButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        focusCancel: false,
        focusConfirm: false,
        focusDeny: false,
        inputAutoFocus: true,
        // eslint-disable-next-line consistent-return
        preDeny: () => {
          if (!certificateDone) {
            return false;
          }
        },
        inputValidator: (value) => {
          if (!value) {
            return 'Você deve inserir uma senha';
          }
        },
        didClose: () => {
          scrollToForm();
        },
      });
      extractCert(file, password)
        .then(({ cert, data }) => {
          setCertificate(cert);
          if (data) {
            setName(data.name);
            setId(data.number);
            fillCpfDocumentFromCnpjSigner(cert, data.number);
          }
          setCertificateDone(true);
        })
        .catch(() => {
          setCertificateDone(false);
          resetCertificateFile();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Verifique a senha/certificado informado.',
          });
        });
    } else {
      extractTextFromCer(file)
        .then(({ cert, data }) => {
          setCertificate(cert);
          if (data) {
            setName(data.name);
            setId(data.number);
            fillCpfDocumentFromCnpjSigner(cert, data.number);
          }
          setCertificateDone(true);
        })
        .catch((error) => {
          resetCertificateFile();
          setCertificateDone(false);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error || 'Verifique o certificado informado.',
          });
        });
    }
  }

  function resetCertificateFile() {
    const fileInput = document.getElementById('signerCertificate');
    if (fileInput) {
      fileInput.value = '';
      setCertificateDone(false);
    }
  }

  function addInput(e) {
    e.preventDefault();
    if (e.target.name === 'signer') {
      const auxSigner = [];
      if (signersArray !== undefined) {
        setSignersArray([...signersArray, undefined]);
      } else {
        auxSigner.push(undefined);
        setSignersArray(auxSigner);
      }
    } else {
      const auxSignerItems = [];
      if (sigItens !== undefined) {
        setSigItens([...sigItens, undefined]);
      } else {
        auxSignerItems.push(undefined);
        setSigItens(auxSignerItems);
      }
    }
  }
  async function handleDinamicInputChange(e, index) {
    e.preventDefault();
    const { value, name: targetName } = e.target;

    const aux_sigItems = [...sigItens];
    const aux_signers = [...signersArray];

    if (targetName === 'signer') {
      aux_signers[index] = value;
      setSignersArray(aux_signers);
    } else {
      aux_sigItems[index] = value;
      setSigItens(aux_sigItems);
    }
  }

  function handleSignerItemDelete(e, index) {
    e.preventDefault();
    if (e.target.name === 'signer') {
      const auxSigners = [...signersArray];

      auxSigners.splice(index, 1);

      setSignersArray(auxSigners);
    } else {
      const auxSignerItems = [...sigItens];

      auxSignerItems.splice(index, 1);

      setSigItens(auxSignerItems);
    }
  }
  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Assinadores"
          subtitle="Gerenciamento"
          className="text-sm-left"
        />
      </Row>

      {/* Default Light Table */}
      <Row>
        <Col>
          <FilterWrapper>
            <Card small className="col-sm-6 mb-3">
              <CardHeader className="border-bottom">
                <h6 className="m-0 font-weight-bold">
                  <FiAlertCircle className="mr-2 " />
                  Por favor, antes de prosseguir selecione uma instituição:
                </h6>
                <div className="col-sm-12">
                  <SearchSelect
                    onSelect={handleInstitutionFilter}
                    value={filteredInstitution}
                    options={
                      institutions &&
                      institutions.map((i) => ({
                        label: `${i.name} - ${i.initials}`,
                        value: i._id,
                      }))
                    }
                  />
                </div>
              </CardHeader>
            </Card>
          </FilterWrapper>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <Row>
                <Col md="6">
                  <h6 className="m-0 font-weight-bold">Lista de assinadores</h6>
                </Col>
                <Col md="6">
                  <Row className="d-flex justify-content-end mr-2">
                    <Button
                      theme="secondary"
                      size="sm"
                      onClick={() => setShowRows(!showRows)}
                    >
                      {showRows
                        ? 'Ocultar itens de assinatura'
                        : 'Mostrar itens de assinatura'}
                    </Button>
                  </Row>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="p-0 pb-3 pl-3 pr-3 overflow-auto">
              {loading ? (
                <LoadingContainer loading={loading} />
              ) : (
                <>
                  {filteredSigners.length !== 0 ? (
                    <table className="table mb-0">
                      <thead className="bg-light">
                        <tr>
                          <th scope="col" className="border-0">
                            #
                          </th>
                          <th scope="col" className="border-0">
                            Nome
                          </th>
                          <th scope="col" className="border-0">
                            Grupo de curso
                          </th>
                          <th scope="col" className="border-0">
                            Atribuição
                          </th>
                          <th scope="col" className="border-0">
                            Ações
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredSigners &&
                          filteredSigners.map((s, index) => (
                            <CollapsibleRow
                              show={showRows}
                              Component={() => (
                                <tr>
                                  <td colSpan={4}>
                                    {s.signItems.length > 0 && (
                                      <h6>Itens de assinatura para {s.name}</h6>
                                    )}
                                    {s.signItems.length === 0 && (
                                      <h6>
                                        Sem itens de assinatura para {s.name}
                                      </h6>
                                    )}
                                    <table className="table mb-5 bg-info">
                                      <tbody>
                                        {s.signItems.map((signItem) => (
                                          <tr key={signItem._id}>
                                            <td>{signItem._id}</td>
                                            <td>{signItem.name}</td>
                                            <td>{signItem.signPolicy}</td>
                                            <td>{signItem.signType}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              )}
                            >
                              <td>{s._id}</td>
                              <td>{s.name}</td>
                              <td>{s.courseGroup?.tag ?? 'Sem grupo'}</td>
                              <td>{s.attrib}</td>
                              {!institutionFilterStatus ? null : (
                                <td>
                                  <ButtonGroup size="sm">
                                    <Button
                                      theme="white"
                                      onClick={(e) =>
                                        getSignerData(e, s.id, index)
                                      }
                                    >
                                      <span className="text-light">
                                        <FaRegEdit />
                                      </span>{' '}
                                      Editar
                                    </Button>
                                    <Button
                                      onClick={(e) =>
                                        handleConfirmation(e, s.id, s.name)
                                      }
                                      theme="white"
                                    >
                                      <span className="text-light">
                                        <FaRegTrashAlt />
                                      </span>{' '}
                                      Excluir
                                    </Button>
                                  </ButtonGroup>
                                </td>
                              )}
                            </CollapsibleRow>
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="col-sm-12 d-flex justify-content-center">
                      <h5 className="mt-3">
                        <FiAlertCircle className="mr-2" />
                        Não existem assinadores cadastrados.
                      </h5>
                    </div>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {!institutionFilterStatus ? null : (
        <Row id="form-container">
          <Col>
            <Card className="mb-3" small>
              <CardHeader className="border-bottom">
                <h6 className="m-0 font-weight-bold">
                  Informações sobre assinador
                </h6>
              </CardHeader>
              {institutions.length !== 0 ? (
                <ListGroup flush>
                  <ListGroupItem className="p-3">
                    <Row>
                      <Col>
                        <ErrorBox error={error} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form onSubmit={handleSubmit} id="edit">
                          <Row form>
                            <Col md="6" className="form-group">
                              <InputWrapper>
                                <label htmlFor="signerCpf">CPF/CNPJ</label>
                                <FormInput
                                  id="signerCpf"
                                  name="id"
                                  type="text"
                                  placeholder="000.000.000-00 ou 99.999.999/9999-9"
                                  maxLength="14"
                                  pattern="\d{11}|\d{14}"
                                  required
                                  {...bindId}
                                />
                                <span className="text-muted">
                                  *Insira o CPF/CNPJ do assinador.
                                </span>
                              </InputWrapper>
                            </Col>
                            <Col md="6" className="form-group">
                              <InputWrapper>
                                <label htmlFor="signerName">
                                  Nome do assinador
                                </label>
                                <FormInput
                                  name="name"
                                  id="signerName"
                                  type="text"
                                  placeholder="Assinador exemplo"
                                  required
                                  {...bindName}
                                />
                                <span className="text-muted">
                                  *Insira o nome completo da assinador.
                                </span>
                              </InputWrapper>
                            </Col>
                          </Row>
                          <Row form>
                            <Col md="6" className="form-group">
                              <InputWrapper>
                                <label htmlFor="signerAtribution">
                                  Atribuição
                                </label>
                                <FormInput
                                  name="attrib"
                                  id="signerAtribution"
                                  type="text"
                                  placeholder="Professor"
                                  required
                                  {...bindAttrib}
                                />
                                <span className="text-muted">
                                  *Insira a atribuição do assinador.
                                </span>
                              </InputWrapper>
                            </Col>
                            <Col md="6" className="form-group">
                              <InputWrapper>
                                <label htmlFor="signerCpfDocument">
                                  CPF do Responsável
                                </label>
                                <FormInput
                                  name="cpfDocument"
                                  id="signerCpfDocument"
                                  type="text"
                                  placeholder="00000000000"
                                  required={isCnpj(id)}
                                  disabled={!isCnpj(id)}
                                  pattern="[0-9]{11}"
                                  {...bindCpfDocument}
                                />
                                <span className="text-muted">
                                  *Apenas para certificados CNPJ.
                                </span>
                              </InputWrapper>
                            </Col>
                          </Row>
                          <Row form>
                            <Col md="6" className="form-group">
                              <InputWrapper>
                                <label htmlFor="course">Curso</label>
                                <FormSelect
                                  id="course"
                                  name="course"
                                  required
                                  {...bindCourse}
                                >
                                  <option value="" disabled>
                                    Escolher...
                                  </option>
                                  {filteredCourses &&
                                    filteredCourses.map((s) => (
                                      <option key={s._id} value={s._id}>
                                        {s.tag}
                                      </option>
                                    ))}
                                </FormSelect>
                                <span className="text-muted">
                                  *Selecione um assinador para o item de
                                  assinatura.
                                </span>
                              </InputWrapper>
                            </Col>
                            <Col md="6" className="form-group">
                              <InputWrapper>
                                <CertLabelContent>
                                  <label htmlFor="signerCertificate">
                                    Certificado
                                  </label>
                                  <CustomRadio
                                    data={[
                                      { label: 'Arquivo', value: 'file' },
                                      { label: 'Texto', value: 'text' },
                                    ]}
                                    checked={certificateType}
                                    onChange={setCertificateType}
                                  />
                                </CertLabelContent>
                                {certificateType === 'file' && (
                                  <>
                                    <FormInput
                                      id="signerCertificate"
                                      name="certificate"
                                      type="file"
                                      accept=".pfx,.p12,.cer"
                                      placeholder="UFPB"
                                      required={!updating}
                                      onChange={handleCertificate}
                                    />
                                    <div id="doneCertificate">
                                      {certificateDone && (
                                        <button
                                          type="button"
                                          style={{
                                            outline: 0,
                                            border: 0,
                                            background: 'transparent',
                                          }}
                                          data-tip="Certificado válido"
                                          data-for="certificateInfo"
                                        >
                                          <FiCheckCircle
                                            size={20}
                                            className="mr-2"
                                          />
                                        </button>
                                      )}
                                      {updating && !certificateDone && (
                                        <button
                                          type="button"
                                          style={{
                                            outline: 0,
                                            border: 0,
                                            background: 'transparent',
                                          }}
                                          data-tip="Deixe em branco para manter o mesmo certificado"
                                          data-for="certificateInfo"
                                        >
                                          <FiInfo size={20} className="mr-2" />
                                        </button>
                                      )}
                                    </div>
                                    <span className="text-muted">
                                      *Insira o certificado no formato .pfx,
                                      .p12 ou .cer
                                    </span>
                                  </>
                                )}
                                {certificateType === 'text' && (
                                  <>
                                    <FormInput
                                      id="signerCertificate"
                                      name="certificate"
                                      type="text"
                                      placeholder="UFPB"
                                      required
                                      {...bindCertificate}
                                    />
                                    <span className="text-muted">
                                      *Insira o certificado em base64
                                    </span>
                                  </>
                                )}
                              </InputWrapper>
                            </Col>
                          </Row>
                          <Row form>
                            <Col md="6">
                              <FormGroup>
                                <label
                                  className="text-dark"
                                  htmlFor="signatureItem"
                                >
                                  Itens de assinatura
                                </label>
                                {sigItens !== undefined &&
                                  sigItens.length !== 0 &&
                                  sigItens.map((s, index) => (
                                    <InputWrapper key={index} className="mb-4">
                                      <InputGroup className="mb-1">
                                        <FormSelect
                                          id="signatureItem"
                                          name="sigItens"
                                          value={
                                            s === undefined ? '' : s.toString()
                                          }
                                          required={sigItens.length !== 1}
                                          onChange={(e) =>
                                            handleDinamicInputChange(e, index)
                                          }
                                        >
                                          <option disabled value="">
                                            Escolher...
                                          </option>
                                          {filteredSigItems &&
                                            filteredSigItems.map((sig) => (
                                              <option
                                                key={sig._id}
                                                value={sig._id}
                                              >
                                                {sig.name}
                                              </option>
                                            ))}
                                        </FormSelect>
                                        <InputGroupAddon type="append">
                                          <Button
                                            onClick={(e) =>
                                              handleSignerItemDelete(e, index)
                                            }
                                            theme="white"
                                          >
                                            <span className="text-light">
                                              <FaRegTrashAlt />
                                            </span>{' '}
                                            Excluir
                                          </Button>
                                        </InputGroupAddon>
                                      </InputGroup>
                                      <span className="text-muted">
                                        *Selecione o item de assinatura{' '}
                                        {index + 1}.
                                      </span>
                                    </InputWrapper>
                                  ))}
                                {sigItens !== undefined &&
                                  sigItens.length === 0 && (
                                    <InputWrapper>
                                      <p className="m-2 text-secondary">
                                        Nenhum item de assinatura foi adicionado
                                      </p>
                                    </InputWrapper>
                                  )}
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <label
                                  className="text-dark"
                                  htmlFor="signerItem"
                                >
                                  Assinadores substitutos
                                </label>
                                {signersArray !== undefined &&
                                  signersArray.length !== 0 &&
                                  signersArray.map((s, index) => (
                                    <InputWrapper key={index} className="mb-4">
                                      <InputGroup className="mb-1">
                                        <FormSelect
                                          id="signerItem"
                                          name="signer"
                                          value={
                                            s === undefined ? '' : s._id || s
                                          }
                                          required={signersArray.length !== 1}
                                          onChange={(e) =>
                                            handleDinamicInputChange(e, index)
                                          }
                                        >
                                          <option disabled value="">
                                            Escolher...
                                          </option>
                                          {filteredSigners &&
                                            filteredSigners.map((sig) => (
                                              <option
                                                key={sig._id}
                                                value={sig._id}
                                              >
                                                {sig.name}
                                              </option>
                                            ))}
                                        </FormSelect>
                                        <InputGroupAddon type="append">
                                          <Button
                                            name="signer"
                                            onClick={(e) =>
                                              handleSignerItemDelete(e, index)
                                            }
                                            theme="white"
                                          >
                                            <span className="text-light">
                                              <FaRegTrashAlt />
                                            </span>{' '}
                                            Excluir
                                          </Button>
                                        </InputGroupAddon>
                                      </InputGroup>
                                      <span className="text-muted">
                                        *Selecione o assinador substituto{' '}
                                        {index + 1}.
                                      </span>
                                    </InputWrapper>
                                  ))}
                                {signersArray !== undefined &&
                                  signersArray.length === 0 && (
                                    <InputWrapper>
                                      <p className="m-2 text-secondary">
                                        Nenhum assinador substituto foi
                                        adicionado
                                      </p>
                                    </InputWrapper>
                                  )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6" className="p-0">
                              <Button
                                name="signerItem"
                                onClick={(e) => addInput(e)}
                                theme="white"
                                className="ml-3"
                                disabled={isSubmitting}
                              >
                                <span className="text-light">
                                  <FaPlus />
                                </span>{' '}
                                Adicionar Item de Assinatura
                              </Button>
                              <Button
                                name="signer"
                                onClick={(e) => addInput(e)}
                                theme="white"
                                className="ml-3"
                                disabled={isSubmitting}
                              >
                                <span className="text-light">
                                  <FaPlus />
                                </span>{' '}
                                Adicionar Assinador Substituto
                              </Button>
                            </Col>
                            <Col md="6" className="text-right">
                              {updating ? (
                                <>
                                  <Button
                                    className="btn btn-danger btn-sm"
                                    onClick={handleCleanState}
                                    disabled={isSubmitting}
                                  >
                                    Cancelar
                                  </Button>
                                  <Button
                                    className="btn-primary ml-3"
                                    onClick={handleUpdateSigner}
                                    disabled={isSubmitting}
                                  >
                                    {isSubmitting
                                      ? 'Carregando...'
                                      : 'Atualizar'}
                                  </Button>
                                </>
                              ) : (
                                <Button disabled={isSubmitting} type="submit">
                                  {isSubmitting
                                    ? 'Carregando...'
                                    : 'Criar novo assinador'}
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              ) : (
                <div className="col-sm-12 d-flex justify-content-center">
                  <h5 className="mt-3">
                    <FiAlertCircle className="mr-2" />
                    Não é possível continuar, não encontramos instituições
                    cadastradas, para continuar é preciso cadastrar ao menos uma
                    instituição!
                  </h5>
                </div>
              )}
            </Card>
          </Col>
          <ReactTooltip id="certificateInfo" />
        </Row>
      )}
    </Container>
  );
}
