/* List */
export function getAllPreSignersRequest({ limit, page, institutionId } = {}) {
  return {
    type: '@presigner/GET_ALL_PRE_SIGNERS_REQUEST',
    payload: { limit, page, institutionId },
  };
}
export function getAllPreSignersSuccess(preSigners) {
  return {
    type: '@presigner/GET_ALL_PRE_SIGNERS_SUCCESS',
    payload: { preSigners },
  };
}
export function getPreSignerByIdRequest(id, index) {
  return {
    type: '@presigner/GET_PRE_SIGNER_BY_ID_REQUEST',
    payload: { id, index },
  };
}
export function getPreSignerByIdSuccess(signer, index) {
  return {
    type: '@presigner/GET_PRE_SIGNER_BY_ID_SUCCESS',
    payload: { signer, index },
  };
}

/* Store */
export function storePreSignerRequest(data, onSuccess) {
  return {
    type: '@presigner/STORE_PRE_SIGNER_REQUEST',
    payload: { data, onSuccess },
  };
}
export function storePreSignerSuccess(signer) {
  return {
    type: '@presigner/STORE_PRE_SIGNER_SUCCESS',
    payload: { signer },
  };
}

/* Update */
export function updatePreSignerRequest(id, data, onSuccess) {
  return {
    type: '@presigner/UPDATE_PRE_SIGNER_REQUEST',
    payload: { id, data, onSuccess },
  };
}
export function updatePreSignerSuccess(data) {
  return {
    type: '@presigner/UPDATE_PRE_SIGNER_SUCCESS',
    payload: { data },
  };
}
export function updatePreSignerCancel() {
  return {
    type: '@presigner/UPDATE_PRE_SIGNER_CANCEL',
  };
}
/* Delete */
export function cancelPreSignerRequest(id, index) {
  return {
    type: '@presigner/DELETE_PRE_SIGNER_REQUEST',
    payload: { id, index },
  };
}
export function deletePreSignerSuccess(data, index) {
  return {
    type: '@presigner/DELETE_PRE_SIGNER_SUCCESS',
    payload: { data, index },
  };
}

/* Failure */
export function preSignerFailure() {
  return {
    type: '@presigner/PRE_SIGNER_FAILURE',
  };
}
