import { produce } from 'immer';

const INITIAL_STATE = {
  documents: null,
  document: {},
  selectedId: null,
  total: 0,
  numberOfPages: 0,
  loading: false,
  getLast: false,
  isSubmitting: false,
  search: '',
};

export default function document(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@documents/REVOKE_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@documents/REVOKE_SUCCESS': {
        draft.loading = false;
        draft.document = { ...draft.document, documentStatus: 'revoked' };
        break;
      }
      case '@documents/CANCEL_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@documents/CANCEL_SUCCESS': {
        draft.loading = false;
        draft.document = { ...draft.document, documentStatus: 'canceled' };
        break;
      }
      case '@documents/UPDATE_STATUS_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@documents/UPDATE_STATUS_SUCCESS': {
        draft.loading = false;
        draft.document = {
          ...draft.document,
          documentStatus: action.payload.status,
        };
        break;
      }
      case '@documents/DOWNLOAD_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@documents/DOWNLOAD_SUCCESS': {
        draft.loading = false;
        break;
      }
      case '@documents/SET_GET_LAST_DOCUMENT': {
        draft.getLast = action.payload.getLast;
        break;
      }
      case '@documents/SET_SEARCH_QUERY': {
        draft.search = action.payload.search;
        break;
      }
      case '@documents/SET_SELECTED_ID': {
        draft.selectedId = action.payload.selectedId;
        break;
      }
      case '@documents/GET_ALL_DOCUMENTS_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@documents/GET_ALL_DOCUMENTS_SUCCESS': {
        draft.documents = action.payload.documents.content;
        draft.total = action.payload.documents.total;
        draft.numberOfPages = action.payload.documents.numberOfPages;
        draft.loading = false;
        break;
      }
      case '@documents/GET_DOCUMENT_BY_ID_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@documents/GET_DOCUMENT_BY_ID_SUCCESS': {
        draft.loading = false;
        draft.document = action.payload.document;
        break;
      }
      case '@documents/UPDATE_DOCUMENT_REQUEST': {
        // draft.loading = true;
        draft.isSubmitting = true;
        break;
      }
      case '@documents/UPDATE_DOCUMENT_SUCCESS': {
        const { data } = action.payload;
        const aux = draft.documents;
        const documentsAux = state.documents.findIndex((item) => {
          return item._id === data._id;
        });
        aux[documentsAux] = data;
        draft.documents = aux;
        draft.isSubmitting = false;
        break;
      }
      case '@documents/UPDATE_DOCUMENT_CANCEL': {
        draft.document = {};
        break;
      }
      case '@documents/DOCUMENT_FAILURE': {
        draft.loading = false;
        draft.isSubmitting = false;
        break;
      }
      default:
    }
  });
}
