import { produce } from 'immer';

const INITIAL_STATE = {
  institutions: [],
  institution: {},
  loading: false,
  isSubmitting: false,
};

export default function institution(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@institution/GET_ALL_INSTITUTIONS_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@institution/GET_ALL_INSTITUTIONS_SUCCESS': {
        draft.institutions = action.payload.institutions;
        draft.loading = false;
        break;
      }
      case '@institution/GET_INSTITUTION_BY_ID_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@institution/GET_INSTITUTION_BY_ID_SUCCESS': {
        draft.loading = false;
        draft.institution = action.payload.institution;
        draft.institution.index = action.payload.index;
        break;
      }
      case '@institution/STORE_INSTITUTION_REQUEST': {
        draft.isSubmitting = true;
        break;
      }
      case '@institution/STORE_INSTITUTION_SUCCESS': {
        const aux = [...draft.institutions];
        aux.push(action.payload.institution);
        draft.institutions = aux;
        draft.isSubmitting = false;
        break;
      }
      case '@institution/UPDATE_INSTITUTION_REQUEST': {
        draft.isSubmitting = true;
        break;
      }
      case '@institution/UPDATE_INSTITUTION_SUCCESS': {
        const { data } = action.payload;
        const aux = draft.institutions;
        aux[draft.institution.index] = data;
        draft.institutions = aux;
        draft.isSubmitting = false;
        break;
      }
      case '@institution/UPDATE_INSTITUTION_CANCEL': {
        draft.institution = {};
        break;
      }
      case '@institution/DELETE_INSTITUTION_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@institution/DELETE_INSTITUTION_SUCCESS': {
        const aux = draft.institutions;
        aux.splice(action.payload.index, 1);
        draft.institutions = aux;
        draft.loading = false;
        break;
      }
      case '@institution/INSTITUTION_FAILURE': {
        draft.loading = false;
        draft.isSubmitting = false;
        break;
      }
      default:
    }
  });
}
