/* List */
export function getAllDocConfigsRequest() {
  return {
    type: '@docconfig/GET_ALL_DOCCONFIGS_REQUEST',
  };
}
export function getAllDocConfigsSuccess(docConfigs) {
  return {
    type: '@docconfig/GET_ALL_DOCCONFIGS_SUCCESS',
    payload: { docConfigs },
  };
}
export function getDocConfigByIdRequest(id, index) {
  return {
    type: '@docconfig/GET_DOCCONFIG_BY_ID_REQUEST',
    payload: { id, index },
  };
}
export function getDocConfigByIdSuccess(docConfig, index) {
  return {
    type: '@docconfig/GET_DOCCONFIG_BY_ID_SUCCESS',
    payload: { docConfig, index },
  };
}

/* Store */
export function storeDocConfigRequest(data, onSuccess) {
  return {
    type: '@docconfig/STORE_DOCCONFIG_REQUEST',
    payload: { data, onSuccess },
  };
}
export function storeDocConfigSuccess(docConfig) {
  return {
    type: '@docconfig/STORE_DOCCONFIG_SUCCESS',
    payload: { docConfig },
  };
}

/* Update */
export function updateDocConfigRequest(id, data, index, onSuccess) {
  return {
    type: '@docconfig/UPDATE_DOCCONFIG_REQUEST',
    payload: { id, data, index, onSuccess },
  };
}
export function updateDocConfigSuccess(index, data) {
  return {
    type: '@docconfig/UPDATE_DOCCONFIG_SUCCESS',
    payload: { index, data },
  };
}

/* Delete */
export function deleteDocConfigRequest(id, index) {
  return {
    type: '@docconfig/DELETE_DOCCONFIG_REQUEST',
    payload: { id, index },
  };
}
export function deleteDocConfigSuccess(index) {
  return {
    type: '@docconfig/DELETE_DOCCONFIG_SUCCESS',
    payload: { index },
  };
}

/* Failure */
export function docConfigFailure() {
  return {
    type: '@docconfig/DOCCONFIG_FAILURE',
  };
}
