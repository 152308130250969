import { all, takeLatest, call, put, take } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { responseErrors } from '../../../utils/responseErros';
import api from '../../../services/api';
import {
  getAllSignersSuccess,
  getSignerByIdSuccess,
  storeSignerSuccess,
  updateSignerSuccess,
  deleteSignerSuccess,
  signerFailure,
} from './actions';
import { refreshTokenRequest } from '../auth/actions';
import { store } from '../../index';

export function* getSigners() {
  const { refresh_token } = store.getState().auth;
  const { selectedInstitution } = store.getState().selectedData;

  try {
    const response = yield call((url) => {
      return api.get(url, {
        params: {
          institutionId: selectedInstitution,
        },
      });
    }, '/management/signers');
    const signers = response.data;

    yield put(getAllSignersSuccess(signers));
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(signerFailure());
      toast.error(`Falha ao listar assinadores`);
    }
  }
}
export function* getSignerById({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, index } = payload;
    const response = yield call(api.get, `/management/signers/${id}`);
    const signer = response.data;

    yield put(getSignerByIdSuccess(signer, index));
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(signerFailure());
      toast.error('Falha ao obter dados do assinador');
    }
  }
}
export function* storeSigner({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { data, onSuccess } = payload;

    const response = yield call(api.post, '/management/signers', data);
    const signer = response.data;

    yield put(storeSignerSuccess(signer));
    toast.success('Assinador criado com sucesso');
    onSuccess && onSuccess();
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(signerFailure());
      Swal.fire(responseErrors(err.response, 'Falha ao criar assinador'));
    }
  }
}

export function* updateSigner({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, index, data, onSuccess } = payload;

    const response = yield call(api.patch, `/management/signers/${id}`, data);
    const signer = response.data;

    yield put(updateSignerSuccess(index, signer));
    toast.success('Assinador atualizado com sucesso');
    onSuccess && onSuccess();
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(signerFailure());
      Swal.fire(responseErrors(err.response, 'Falha ao atualizar assinador'));
    }
  }
}
export function* updateCancel() {
  yield take('@signer/UPDATE_SIGNER_CANCEL');
}

export function* deleteSigner({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, index } = payload;
    yield call(api.delete, `/management/signers/${id}`);

    yield put(deleteSignerSuccess(index));
    toast.success('Assinador excluído com sucesso');
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(signerFailure());
      toast.error('Falha ao excluir assinador');
    }
  }
}

export default all([
  takeLatest('@signer/GET_ALL_SIGNERS_REQUEST', getSigners),
  takeLatest('@signer/GET_SIGNER_BY_ID_REQUEST', getSignerById),
  takeLatest('@signer/STORE_SIGNER_REQUEST', storeSigner),
  takeLatest('@signer/UPDATE_SIGNER_REQUEST', updateSigner),
  takeLatest('@signer/DELETE_SIGNER_REQUEST', deleteSigner),
]);
