/* eslint-disable no-plusplus */
/* eslint-disable eqeqeq */
export function isValidField(item) {
  return !(item === undefined || item === null || item.trim().length === 0);
}
export function isValidFieldArray(items) {
  if (items.length === 0) return false;
  const itemContainErrors = items.map((item) => {
    return !!(item === undefined || item === null || item.trim().length === 0);
  });
  return !itemContainErrors.includes(true);
}
export function isNumber(item) {
  return /^-?\d+$/.test(item);
}
export function isValidCnpjArray(cnpj) {
  const cnpjContainErrors = cnpj.map((item) => {
    if (
      item === undefined ||
      item === null ||
      item.trim().length === 0 ||
      item.trim().length !== 14
    )
      return true;

    if (
      item === '00000000000000' ||
      item === '11111111111111' ||
      item === '22222222222222' ||
      item === '33333333333333' ||
      item === '44444444444444' ||
      item === '55555555555555' ||
      item === '66666666666666' ||
      item === '77777777777777' ||
      item === '88888888888888' ||
      item === '99999999999999'
    )
      return true;

    let tamanho = item.length - 2;
    let numeros = item.substring(0, tamanho);
    const digitos = item.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(0)) return true;

    tamanho += 1;
    numeros = item.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(1)) return true;

    return false;
  });

  if (cnpjContainErrors.includes(true)) return false;

  return true;
}

export function isValidMimeType(item) {
  return /[a-z]{1,}\/[a-z]{1,}/.test(item);
}

export function isValidJson(item) {
  try {
    JSON.parse(item);
    return true;
  } catch (err) {
    return false;
  }
}

export function isValidKeyProperty(key) {
  if (!/^([a-zA-Z][^\s]{1,}\.[a-zA-Z]{1,}).*/.test(key)) return false;
  if (!/[^\s]/.test(key)) return false;

  return true;
}

export function isValidProperties(items) {
  const itemContainErrors = items.map((item) => {
    if (!isValidField(item.name)) return true;
    if (!isValidField(item.key)) return true;
    if (!isValidKeyProperty(item.key)) return true;

    return false;
  });
  if (itemContainErrors.includes(true)) return false;

  return true;
}

export function isValidCpfCnpjLength(item) {
  const regex = /(^\d{11}$)|(^\d{14}$)/;
  return regex.test(item);
}

export function isCnpj(item) {
  return item?.length === 14;
}

export function isValidCPF(cpf) {
  if (typeof cpf !== 'string') return false;
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;
  cpf = cpf.split('').map((el) => +el);
  const rest = (count) =>
    ((cpf
      .slice(0, count - 12)
      .reduce((soma, el, index) => soma + el * (count - index), 0) *
      10) %
      11) %
    10;
  return rest(10) === cpf[9] && rest(11) === cpf[10];
}
