import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ButtonGroup,
  Button,
  Form,
  ListGroup,
  ListGroupItem,
  FormInput,
  FormSelect,
} from 'shards-react';

import { FaRegEdit, FaRegTrashAlt, FaMagic } from 'react-icons/fa';
import { FiAlertCircle } from 'react-icons/fi';

import { MdContentCopy } from 'react-icons/md';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { useInput } from '../../hooks/input-hook';
import { InputWrapper, FilterWrapper } from '../common/styles';
import PageTitle from '../../components/common/PageTitle';
import LoadingContainer from '../../components/layout/LodingContainer';

import {
  getAllUsersRequest,
  getUserByIdRequest,
  storeUserRequest,
  updateUserRequest,
  updateUserCancel,
  deleteUserRequest,
} from '../../store/modules/user/actions';

import { MessageWarning } from './styles';

import { scrollToTop, scrollToForm } from '../../utils/scrolls';

import { getAllInstitutionsRequest } from '../../store/modules/institution/actions';
import * as SelectedData from '../../store/modules/selectedData/actions';
import { generateHash } from '../../utils/generateHash';
import { copyToClipboard } from '../../utils/copyToClipboard';
import { Permissions } from '../../config/Constants';
import { canEditUser, getPermission } from '../../utils/permissionHelper';

export default function User() {
  const dispatch = useDispatch();

  const institutions = useSelector((state) => state.institution.institutions);
  const { permission: authPermission } = useSelector((state) => state.auth);
  const users = useSelector((state) => state.user.users);
  const user = useSelector((state) => state.user.user);
  const { loading, isSubmitting } = useSelector((state) => state.user);
  const selectedPermissionType = useSelector(
    (state) => state.selectedData.selectedPermissionType
  );

  const permission = getPermission(authPermission);

  const { NORMAL_USER, MAINTAINER, MANAGER, REPORTER } = Permissions;

  const {
    value: name,
    setValue: setName,
    bind: bindName,
    reset: resetName,
  } = useInput('');
  const {
    value: email,
    setValue: setEmail,
    bind: bindEmail,
    reset: resetEmail,
  } = useInput('');
  const {
    value: password,
    setValue: setPassword,
    bind: bindPassword,
    reset: resetPassword,
  } = useInput('');
  const {
    value: cpf,
    setValue: setCpf,
    bind: bindCpf,
    reset: resetCpf,
  } = useInput('');
  const {
    value: institution,
    setValue: setInstitution,
    bind: bindInstitution,
    reset: resetInstitution,
  } = useInput('');
  const { value: filteredPermissions, bind: bindFilteredPermissions } =
    useInput(selectedPermissionType);

  const [userIdToUpdate, setUserIdToUpdate] = useState('');
  const [updating, setUpdating] = useState(false);
  const [permissionFilterStatus, setPermissionFilterStatus] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [passwordMessage, setPasswordMessage] = useState();
  const [institutionMessage, setInstitutionMessage] = useState();
  const [cpfMessage, setCpfMessage] = useState();
  const [userCanEdit, setUserCanEdit] = useState(false);

  /*eslint-disable */
  useEffect(() => {
    dispatch(getAllUsersRequest());
    dispatch(getAllInstitutionsRequest());
  }, []);



  useEffect(() => {
    if (Object.keys(user).length !== 0) {
      setName(user.name);
      setCpf(user.cpf);
      setEmail(user.email);
      setPassword('');
      setInstitution(user.client_id);
      setUserIdToUpdate(user._id);
    }
  }, [setCpf, setEmail, setInstitution, setName, setPassword, user]);

  useEffect(() => {
    if (users.length !== 0) {
      handleFiltering();
    }
  }, [users]);

  useEffect(() => {
    setUserCanEdit(canEditUser(permission, selectedPermissionType));
  }, [selectedPermissionType]);
  /* eslint-enable */

  function resetState() {
    resetName();
    resetCpf();
    resetEmail();
    resetPassword();
    resetInstitution();

    setUserIdToUpdate('');
    setUpdating(false);
  }

  function handleCleanState(e) {
    e.preventDefault();
    resetName();
    resetCpf();
    resetEmail();
    resetPassword();
    resetInstitution();
    setUserIdToUpdate('');
    setUpdating(false);
    dispatch(updateUserCancel());
    scrollToTop();
  }

  function getUserData(e, id, index) {
    e.preventDefault();

    dispatch(getUserByIdRequest(id, index));
    setUpdating(true);
    scrollToForm();
  }
  function handleUpdateUser(e) {
    e.preventDefault();
    const id = userIdToUpdate;

    const data = {
      name,
      cpf,
      email,
      password,
      institution,
    };

    if (!userCanEdit) {
      delete data.cpf;
      delete data.institution;
    }

    const userIndex = users.findIndex((item) => {
      return item._id === user._id;
    });

    dispatch(updateUserRequest(id, data, userIndex));
    resetState();
  }
  function handleDeleteUser(e, id) {
    e.preventDefault();
    const index = users.findIndex((user) => user.id === id);
    dispatch(deleteUserRequest(id, index));
    resetState();
  }

  function handleConfirmation(e, identifier, name) {
    Swal.fire({
      title: 'Confirme para excluir',
      text: `Tem certeza que deseja remover o usuário ${name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        return handleDeleteUser(e, identifier);
      }
      return false;
    });
  }

  function verifyField() {
    let isValid = true;
    if (password.length < 8) {
      setPasswordMessage('A senha deve ter no mínimo 8 caracteres');
      isValid = false;
    }

    if (cpf.length !== 11) {
      setCpfMessage('O cpf informado não é valído');
      isValid = false;
    }

    if (institution.trim().length === 0 || institution === '') {
      setInstitutionMessage('Selecione uma instituição para o usuário');
      isValid = false;
    }

    if (isValid) {
      setCpfMessage('');
      setPasswordMessage('');
    }
    return isValid;
  }

  function handleSubmit(e) {
    e.preventDefault();
    const isValid = verifyField();

    if (!isValid) return;
    const institutionData = institutions.find(
      (element) => institution === element.client_id
    );
    const obj = {
      name,
      cpf,
      email: email.trim().toLowerCase(),
      password,
      institution: institutionData && institutionData._id,
      client_id: institutionData && institutionData.client_id,
      id_permission: filteredPermissions,
    };
    dispatch(
      storeUserRequest(obj, () => {
        resetState();
        scrollToTop();
      })
    );
  }

  function handleFiltering() {
    if (!filteredPermissions || filteredPermissions.includes('Selecionar')) {
      setFilteredUsers([]);
      setPermissionFilterStatus(false);

      return;
    }
    resetState();
    const keySearch = parseInt(filteredPermissions, 10);

    // Filtering signers
    const usersResult = users.filter((item) => {
      if (item && item.permission) {
        const match = item.permission;
        return match === keySearch;
      }
      return false;
    });
    dispatch(SelectedData.selectPermissionType(filteredPermissions));
    setFilteredUsers(usersResult);
    setPermissionFilterStatus(true);
  }
  function handleInstitutionFilter(e) {
    e.preventDefault();
    handleFiltering();
  }

  function handleGeneratePassword() {
    const newPassword = generateHash(10);
    setPassword(newPassword);
  }

  function handleCopyPassword() {
    if (password.trim() === '') {
      toast.error('Antes de copiar você deve gerar ou criar uma senha', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 'custom-id-yes',
      });
    }
    if (copyToClipboard(password)) {
      toast.info('Copiado!', {
        position: 'bottom-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 'custom-id-yes',
      });
    } else {
      toast.error(
        'Ocorreu um erro ao copiar, por gentileza, copie de forma manual.',
        {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: 'custom-id-yes',
        }
      );
    }
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Usuários"
          subtitle="Gerenciamento"
          className="text-sm-left"
        />
      </Row>
      {/* Default Light Table */}
      <Row>
        <Col>
          <FilterWrapper>
            <Card small className="col-sm-6 mb-3">
              <CardHeader className="border-bottom">
                <form onSubmit={handleInstitutionFilter}>
                  <h6 className="m-0 font-weight-bold">
                    <FiAlertCircle className="mr-2 " />
                    Por favor, antes de prosseguir selecione o tipo de
                    permissão:
                  </h6>
                  <div className="col-sm-12">
                    <FormSelect
                      className="col-sm-9 mt-3"
                      name="filteredUsers"
                      {...bindFilteredPermissions}
                      id="userPermission"
                    >
                      <option disabled selected>
                        Escolher...
                      </option>
                      <option value="1024">Mantedor</option>
                      <option value="512">Gestor</option>
                      <option value="256">Repórter</option>
                      <option value="1">Normal</option>
                    </FormSelect>
                    <Button className="btn-sm" type="submit">
                      Selecionar
                    </Button>
                  </div>
                </form>
              </CardHeader>
            </Card>
          </FilterWrapper>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0 font-weight-bold">Lista de usuários</h6>
            </CardHeader>
            <CardBody className="p-0 pb-3 pl-3 pr-3 overflow-auto">
              {loading ? (
                <LoadingContainer loading={loading} />
              ) : (
                <>
                  {filteredUsers.length !== 0 ? (
                    <table className="table mb-0">
                      <thead className="bg-light">
                        <tr>
                          <th scope="col" className="border-0">
                            Nome
                          </th>
                          <th scope="col" className="border-0">
                            Email
                          </th>
                          <th scope="col" className="border-0">
                            Permissão
                          </th>
                          {filteredUsers && userCanEdit ? (
                            <th scope="col" className="border-0">
                              Ações
                            </th>
                          ) : null}
                        </tr>
                      </thead>
                      <tbody>
                        {filteredUsers &&
                          filteredUsers.map((u, index) => (
                            <tr key={u._id}>
                              <td>{u.name}</td>
                              <td>{u.email}</td>
                              <td>
                                {u.permission === NORMAL_USER && 'Normal'}
                                {u.permission === REPORTER && 'Repórter'}
                                {u.permission === MANAGER && 'Gestor'}
                                {u.permission === MAINTAINER && 'Mantedor'}
                              </td>
                              {userCanEdit ? (
                                <td>
                                  <ButtonGroup size="sm">
                                    <Button
                                      theme="white"
                                      onClick={(e) =>
                                        getUserData(e, u._id, index)
                                      }
                                    >
                                      <span className="text-light">
                                        <FaRegEdit />
                                      </span>{' '}
                                      Editar
                                    </Button>
                                    <Button
                                      onClick={(e) =>
                                        handleConfirmation(e, u._id, u.name)
                                      }
                                      theme="white"
                                    >
                                      <span className="text-light">
                                        <FaRegTrashAlt />
                                      </span>{' '}
                                      Excluir
                                    </Button>
                                  </ButtonGroup>
                                </td>
                              ) : null}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="col-sm-12 d-flex justify-content-center">
                      <h5 className="mt-3">
                        <FiAlertCircle className="mr-2" />
                        {!permissionFilterStatus
                          ? 'Selecione o tipo de permissão'
                          : 'Não existem usuários cadastrados'}
                      </h5>
                    </div>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {!permissionFilterStatus ? null : (
        <Row id="form-container">
          <Col>
            <Card className="mb-3" small>
              <CardHeader className="border-bottom">
                <h6 className="m-0 font-weight-bold">
                  Informações sobre usuários
                </h6>
              </CardHeader>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form onSubmit={handleSubmit} id="edit">
                        <Row form>
                          <Col md="6" className="form-group">
                            <InputWrapper>
                              <label htmlFor="userName">Nome</label>
                              <FormInput
                                value={name}
                                name="name"
                                id="userName"
                                type="text"
                                required
                                autoComplete="off"
                                {...bindName}
                              />
                              <span className="text-muted">
                                *Insira o nome completo do usuário.
                              </span>
                            </InputWrapper>
                          </Col>
                          <Col md="6" className="form-group">
                            <InputWrapper>
                              <label htmlFor="userCpf">CPF</label>
                              <FormInput
                                value={cpf}
                                name="cpf"
                                id="userCpf"
                                type="text"
                                maxLength="11"
                                pattern="([0-9]){11}"
                                required
                                disabled={updating && !userCanEdit}
                                {...bindCpf}
                              />
                              {cpfMessage ? (
                                <MessageWarning>{cpfMessage}</MessageWarning>
                              ) : (
                                <span className="text-muted">
                                  *Insira o CPF do usuário sem pontos ou hífen.
                                </span>
                              )}
                            </InputWrapper>
                          </Col>
                          <Col md="6" className="form-group">
                            <InputWrapper>
                              <label htmlFor="userMail">Email</label>
                              <FormInput
                                value={email}
                                name="email"
                                id="userMail"
                                type="email"
                                required
                                {...bindEmail}
                              />
                              <span className="text-muted">
                                *Insira o email do usuário.
                              </span>
                            </InputWrapper>
                          </Col>
                          <Col md="6" className="form-group">
                            <InputWrapper>
                              <label htmlFor="userPassword">Senha</label>
                              <div className="d-flex">
                                <FormInput
                                  value={password}
                                  name="password"
                                  id="userPassword"
                                  type="text"
                                  autoComplete="new-password"
                                  minLength="8"
                                  required
                                  {...bindPassword}
                                />
                                <Button
                                  data-tip="Gerar senha"
                                  data-for="generatePassword"
                                  className="bg-info btn-sm ml-2 border-0"
                                  onClick={handleGeneratePassword}
                                >
                                  <FaMagic className="text-white" />
                                </Button>
                                <Button
                                  data-tip="Copiar"
                                  data-for="copyPassword"
                                  className="bg-secondary btn-sm ml-2 border-0"
                                  onClick={handleCopyPassword}
                                >
                                  <MdContentCopy className="text-white" />
                                </Button>
                              </div>
                              {passwordMessage ? (
                                <MessageWarning>
                                  {passwordMessage}
                                </MessageWarning>
                              ) : (
                                <span className="text-muted">
                                  *Insira uma senha para o usuário cadastrado.
                                </span>
                              )}
                            </InputWrapper>
                          </Col>
                          <Col md="6" className="form-group">
                            <InputWrapper>
                              <label htmlFor="signerInstitution">
                                Instituição
                              </label>
                              <FormSelect
                                name="institutionId"
                                {...bindInstitution}
                                id="signerInstitution"
                                disabled={updating && !userCanEdit}
                              >
                                <option disabled value="">
                                  Escolher...
                                </option>
                                {/* {filteredPermissions === '1' &&
                                  institutions &&
                                  institutions.map(i => {
                                    return (
                                      <option key={i._id} value={i.client_id}>
                                        {i.name}
                                      </option>
                                    );
                                  })} */}
                                {institutions &&
                                  institutions.map((i) => {
                                    return (
                                      <option key={i._id} value={i.client_id}>
                                        {i.name}
                                      </option>
                                    );
                                  })}
                              </FormSelect>
                              {institutionMessage ? (
                                <MessageWarning>
                                  {institutionMessage}
                                </MessageWarning>
                              ) : (
                                <span className="text-muted">
                                  *Selecione uma instituição para o assinador.
                                </span>
                              )}
                            </InputWrapper>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12" className="text-right">
                            {updating ? (
                              <>
                                <Button
                                  className="btn btn-danger btn-sm"
                                  onClick={handleCleanState}
                                  disabled={isSubmitting}
                                >
                                  Cancelar
                                </Button>
                                <Button
                                  className="btn-primary ml-3"
                                  onClick={handleUpdateUser}
                                  disabled={isSubmitting}
                                >
                                  {isSubmitting ? 'Carregando...' : 'Atualizar'}
                                </Button>
                              </>
                            ) : (
                              <Button disabled={isSubmitting} type="submit">
                                {isSubmitting
                                  ? 'Carregando...'
                                  : 'Criar novo usuário'}
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
          <ReactTooltip id="generatePassword" type="info" />
          <ReactTooltip id="copyPassword" />
        </Row>
      )}
    </Container>
  );
}
