export const responseErrors = (response, title) => {
  let errors = [{ msg: 'Erro desconhecido' }];

  const catchError =
    response?.data?.message ||
    response?.data?.error?.message ||
    response?.data?.error?.errors ||
    response?.data?.errors ||
    null;

  if (catchError && typeof catchError === 'string') {
    errors[0].msg = catchError;
  } else if (catchError && typeof catchError !== 'string') {
    const keys = Object.keys(catchError);

    if (keys.includes('0')) {
      errors = [];
      keys.forEach((key) => {
        if (key !== 'message') {
          const obj = {};

          Object.assign(
            obj,
            catchError[key].param && {
              param: catchError[key].param,
            },
            (catchError[key].msg || catchError[key].message) && {
              msg: catchError[key].msg || catchError[key].message,
            }
          );

          errors.push(obj);
        }
      });
    }
  }
  return {
    icon: 'error',
    title,
    html: `
    <ul style="display: table; margin:0 auto">
    ${errors
      .map((error) =>
        error.param
          ? `<li>${error.param} - ${error.msg}</li>`
          : `<li>${error.msg}</li>`
      )
      .join('')}
    </ul>
    `,
  };
};
