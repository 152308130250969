import styled from 'styled-components';

export const NavTitle = styled.span`
  font-size: 0.8125rem;
  font-weight: 400;
  color: #8d97ad;
  white-space: normal !important;

  &:hover {
    cursor: pointer;
  }
`;

export const Container = styled.div`
  margin-left: 15px;
`;

export const NavWrapper = styled.div`
  ul {
    .nav-link {
      padding: 14px !important;
      cursor: pointer;
    }
  }
  .navbar-toggler {
    border-radius: 0;
    border: none !important;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c4c4c4;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
  #signOut {
    cursor: pointer;
  }
`;

export const IconWrapper = styled.div`
  font-size: 17px;
  font-weight: 500;
  color: #8d97ad;

  &:hover {
    cursor: pointer;
  }
`;
