import { produce } from 'immer';

const INITIAL_STATE = {
  tags: [],
  tag: {},
  loading: false,
  isSubmitting: false,
};

export default function tag(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@tag/GET_ALL_TAGS_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@tag/GET_ALL_TAGS_SUCCESS': {
        draft.tags = action.payload.tags;
        draft.loading = false;
        break;
      }
      case '@tag/GET_TAG_BY_ID_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@tag/GET_TAG_BY_ID_SUCCESS': {
        draft.loading = false;
        draft.tag = action.payload.tag;
        draft.tag.index = action.payload.index;
        break;
      }
      case '@tag/STORE_TAG_REQUEST': {
        // draft.loading = true;
        draft.isSubmitting = true;
        break;
      }
      case '@tag/STORE_TAG_SUCCESS': {
        const aux = [...draft.tags];
        aux.push(action.payload.tag);
        draft.tags = aux;
        // draft.loading = false;
        draft.isSubmitting = false;
        break;
      }
      case '@tag/UPDATE_TAG_REQUEST': {
        // draft.loading = true;
        draft.isSubmitting = true;
        break;
      }
      case '@tag/UPDATE_TAG_SUCCESS': {
        const { data } = action.payload;
        const aux = draft.tags;
        const tagIndex = state.tags.findIndex((item) => {
          return item._id === data._id;
        });
        aux[tagIndex] = data;
        draft.tags = aux;
        // draft.loading = false;
        draft.isSubmitting = false;
        break;
      }
      case '@tag/UPDATE_TAG_CANCEL': {
        draft.tag = {};
        break;
      }
      case '@tag/DELETE_TAG_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@tag/DELETE_TAG_SUCCESS': {
        const aux = draft.tags;
        aux.splice(action.payload.index, 1);
        draft.tags = aux;
        draft.loading = false;
        break;
      }
      case '@tag/TAG_FAILURE': {
        draft.loading = false;
        draft.isSubmitting = false;
        break;
      }
      default:
    }
  });
}
