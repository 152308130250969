/* DOCUMENTS REPORTS */
export function getDocumentsReport(
  { institutionId, docTypeId, initialDate, finalDate, dateFilterCriteria } = {},
  onSuccess
) {
  return {
    type: '@reports/REQUEST_DOCUMENTS',
    payload: {
      institutionId,
      docTypeId,
      initialDate,
      finalDate,
      dateFilterCriteria,
      onSuccess,
    },
  };
}
export function getDocumentsReportSuccess(data) {
  return {
    type: '@reports/REQUEST_DOCUMENTS_SUCCESS',
    payload: { data },
  };
}

/* SIGNATURE REPORTS */
export function getSignatureReport(
  { institutionId, docTypeId, initialDate, finalDate } = {},
  onSuccess
) {
  return {
    type: '@reports/REQUEST_SIGNATURES',
    payload: {
      institutionId,
      docTypeId,
      initialDate,
      finalDate,
      onSuccess,
    },
  };
}
export function getSignatureReportSuccess(data) {
  return {
    type: '@reports/REQUEST_SIGNATURES_SUCCESS',
    payload: { data },
  };
}

/* TIMESTAMP REPORTS */
export function getTimestampReport(
  {
    clientId,
    signProvider,
    timestampProvider,
    timestampType,
    initialDate,
    finalDate,
  } = {},
  onSuccess
) {
  return {
    type: '@reports/REQUEST_TIMESTAMP',
    payload: {
      clientId,
      signProvider,
      timestampProvider,
      timestampType,
      initialDate,
      finalDate,
      onSuccess,
    },
  };
}
export function getTimestampReportSuccess(data) {
  return {
    type: '@reports/REQUEST_TIMESTAMP_SUCCESS',
    payload: { data },
  };
}

export function cleanState() {
  return {
    type: '@reports/CLEAN',
  };
}

/* RANKING REPORTS */
export function getRankingReport(
  { limit, docTypeId, initialDate, finalDate } = {},
  onSuccess
) {
  return {
    type: '@reports/REQUEST_RANKING',
    payload: {
      limit,
      docTypeId,
      initialDate,
      finalDate,
      onSuccess,
    },
  };
}
export function getRankingReportSuccess(data) {
  return {
    type: '@reports/REQUEST_RANKING_SUCCESS',
    payload: { data },
  };
}

/* BLOCK CHAIN REPORTS */
export function getBlockchainReport(
  { limit, page, type, initialDate, finalDate } = {},
  onSuccess
) {
  return {
    type: '@reports/REQUEST_BLOCKCHAINSTATS',
    payload: {
      limit,
      page,
      type,
      initialDate,
      finalDate,
      onSuccess,
    },
  };
}
export function getBlockchainReportSuccess(data) {
  return {
    type: '@reports/REQUEST_BLOCKCHAINSTATS_SUCCESS',
    payload: { data },
  };
}

/* Failure */
export function ReportsFailure() {
  return {
    type: '@reports/REQUEST_FAILURE',
  };
}
