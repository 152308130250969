import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Tooltip,
  Cell,
  Legend,
} from 'recharts';
import { useCurrentPng } from 'recharts-to-png';
import * as dateFns from 'date-fns';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { toast } from 'react-toastify';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormInput,
  ListGroup,
  ListGroupItem,
  Form,
  FormCheckbox,
  Collapse,
  FormSelect,
} from 'shards-react';

import {
  FaCheckCircle,
  FaRandom,
  FaWindowClose,
  FaArrowUp,
  FaArrowDown,
  FaUndo,
  FaUnlink,
  FaBook,
  FaRegCopy,
  FaTrophy,
} from 'react-icons/fa';

import { useInput } from '../../hooks/input-hook';

import PageTitle from '../../components/common/PageTitle';
import LoadingContainer from '../../components/layout/LodingContainer';

import SearchSelect from '../../components/common/SearchSelect';

import { getAllInstitutionsRequest } from '../../store/modules/institution/actions';

import {
  getDocumentsReport,
  cleanState,
  getRankingReport,
} from '../../store/modules/reports/actions';
import * as SelectedData from '../../store/modules/selectedData/actions';

import { normalOrManager } from '../../config/UserPermission';
import ErrorBox from '../../components/common/ErrorBox';
import { scrollMore, scrollToElement } from '../../utils/scrolls';
import { generateColors } from '../../utils/chartColors';
import { dateFilterCriteriaList, documentTypes } from '../../config/Constants';

export default function DocumentsReports() {
  const dispatch = useDispatch();

  const [getStatusChart, statusChartRef] = useCurrentPng();
  const [getDocTypeChart, docTypeChartRef] = useCurrentPng();

  const institutions = useSelector((state) => state.institution.institutions);
  const { loading, data } = useSelector((state) => state.reports);

  const selectedInstitution = useSelector(
    (state) => state.selectedData.selectedInstitution
  );

  const { user, permission } = useSelector((state) => state.auth);
  const { institution } = user;

  const isNormalUser = normalOrManager.includes(permission);

  const [error, setError] = useState(null);
  const [showFilter, setShowFilter] = useState(true);
  const [isAllPeriod, setIsAllPeriod] = useState(true);
  const [isAllInstitutions, setIsAllInstitutions] = useState(false);
  const [isAllDocumentType, setIsAllDocumentType] = useState(false);
  const [isAllRanking, setIsAllRanking] = useState(false);
  const { value: filteredInstitution, setValue: setFilteredInstitution } =
    useInput(selectedInstitution);

  const { value: docTypeId, setValue: setDocTypeId } = useInput('');
  const { value: initialDate, setValue: setInitialDate } = useInput('');
  const { value: finalDate, setValue: setFinalDate } = useInput('');
  const { value: dateFilterCriteria, setValue: setDateFilterCriteria } =
    useInput(dateFilterCriteriaList[0].value);

  const [chartDocTypeData, setChartDocTypeData] = useState([]);
  const [chartStatusData, setChartStatusData] = useState([]);

  /*eslint-disable */
  useEffect(() => {
    if (isNormalUser) {
      setFilteredInstitution(institution.id_institution.toString());
    } else {
      dispatch(getAllInstitutionsRequest());
    }
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(cleanState());
    }
  }, []);

  useEffect(() => {
    const colorsToDocTypeChart = generateColors(documentTypes.length);
    setChartDocTypeData(documentTypes.map((docType, index) => ({
      name: docType.label,
      value: getQuantityByDocType(data.quantityByDocumentType, docType.value),
      color: colorsToDocTypeChart[index]
    })));

    const colorsToStatusChart = generateColors(5);
    setChartStatusData([
      {
        name: 'Processados',
        value: getQuantityByStatus(data.quantityByStatus, 'preserved'),
        color: colorsToStatusChart[0]
      },
      {
        name: 'Processando',
        value: sumProcessingStatus(data.quantityByStatus),
        color: colorsToStatusChart[1]
      },
      {
        name: 'Com status de erro',
        value: sumErrorStatus(data.quantityByStatus),
        color: colorsToStatusChart[2]
      },
      {
        name: 'Revogados',
        value: getQuantityByStatus(data.quantityByStatus, 'revoked'),
        color: colorsToStatusChart[3]
      },
      {
        name: 'Cancelados',
        value: getQuantityByStatus(data.quantityByStatus, 'canceled'),
        color: colorsToStatusChart[4]
      },
    ])
  }, [data]);

  useEffect(() => {
    dispatch(SelectedData.selectInstitution(filteredInstitution));
  }, [filteredInstitution]);

  useEffect(() => {
    if (isAllInstitutions || filteredInstitution)
      setError(null);
  }, [isAllInstitutions, filteredInstitution]);

  function handleInstitutionFilter(institution) {
    setFilteredInstitution(institution.value);
  }

  function handleIsAllRanking() {
    handleRanking({ showAll: !isAllRanking, docTypeId, initialDate, finalDate }, true);
    setIsAllRanking(!isAllRanking);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setError(null);

    if (!isAllInstitutions &&
      (!filteredInstitution || filteredInstitution === ''))
      return setError("Selecione uma instituição");

    if (!isAllPeriod
      && (initialDate && initialDate !== '')
      && (finalDate && finalDate !== '')
      && dateFns.isAfter(dateFns.parseISO(initialDate),
        dateFns.parseISO(finalDate))
    ) {
      return setError("A data final precisa ser maior que a data inicial");
    }

    const query = {};

    Object.assign(
      query,
      !isAllInstitutions && {
        institutionId: filteredInstitution,
      },
      !isAllDocumentType && {
        docTypeId
      },
      !isAllPeriod && {
        initialDate,
        finalDate,
        dateFilterCriteria
      }
    )

    dispatch(getDocumentsReport(query, () => {
      setShowFilter(false);
      scrollMore(180);
      handleRanking({ showAll: isAllRanking, ...query }, false);
    }))
  }

  function handleRanking({ showAll, docTypeId, initialDate, finalDate }, scroll = true) {
    dispatch(getRankingReport({
      ...(!showAll && { limit: 5 }),
      ...(docTypeId !== '' && { docTypeId }),
      ...(initialDate !== '' && { initialDate }),
      ...(finalDate !== '' && { finalDate }),
    }, () => {
      if (scroll) scrollToElement('ranking');
    }))
  }

  function getQuantityByStatus(listOfStatus, status) {
    if (!listOfStatus) return 0;
    const findStatus = listOfStatus.find(item => {
      return item.status === status;
    });

    return findStatus ? findStatus.quantity : 0;
  }

  function getQuantityByDocType(listOsDocTypes, docTypeId) {
    if (!listOsDocTypes) return 0;

    const findDocType = listOsDocTypes.find(item => {
      return item.id_document_type === docTypeId;
    });

    return findDocType ? findDocType.quantity : 0;
  }

  function sumProcessingStatus(listOfStatus) {
    let sum = 0;
    const statusToSum = [1, 2, 3, 4, 5, 6, 8, 9, 10, 15];

    if (listOfStatus)
      listOfStatus.forEach(item => {
        if (statusToSum.includes(item.id_document_status))
          sum += item.quantity;
      })

    return sum;
  }

  function sumErrorStatus(listOfStatus) {
    let sum = 0;
    const statusToSum = [7, 11];

    if (listOfStatus)
      listOfStatus.forEach(item => {
        if (statusToSum.includes(item.id_document_status))
          sum += item.quantity;
      })

    return sum;
  }

  const generateImageFromChart = useCallback(async (getPngFromChart) => {
    const png = await getPngFromChart();

    try {
      if (!png) throw new Error();

      const data = await fetch(png);
      const blob = await data.blob();

      await navigator.clipboard.write([
        new ClipboardItem({
          [blob.type]: blob
        })
      ]);

      toast.info('Copiado!', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 'custom-id-yes',
      });
    } catch (error) {
      toast.error('Erro ao copiar gráfico', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 'custom-id-yes',
      });
    }
  }, [getStatusChart, getDocTypeChart]);

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Registros de documento"
          subtitle="Estatísticas"
          className="text-sm-left"
        />
      </Row>
      {/* Filters */}
      <Row id="form-container">
        <Col>
          <Card className="mb-3" small>
            <CardHeader className="border-bottom">
              <Row>
                <Col sm="6">
                  <h6 className="m-0 font-weight-bold">
                    Filtros
                  </h6>
                </Col>
                <Col sm="6" className="text-right">
                  <Button theme="light" onClick={() => setShowFilter(!showFilter)}>
                    <span className="mr-2">
                      {showFilter ? <FaArrowUp /> : <FaArrowDown />}
                    </span>
                    {showFilter ? "Esconder" : "Mostrar"}
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <Collapse open={showFilter}>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form onSubmit={handleSubmit}>
                        <Row form>
                          <Col md="6" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Instituição
                            </span>
                            <Row className="pt-2">
                              <Col md="8">
                                <SearchSelect
                                  placeholder="Pesquisa por nome, sigla ou client_id"
                                  onSelect={handleInstitutionFilter}
                                  value={filteredInstitution}
                                  options={
                                    institutions &&
                                    institutions.map(i => ({
                                      label: `${i.name} - ${i.initials}`,
                                      value: i._id,
                                      client_id: i.client_id
                                    }))
                                  }
                                  styleContainer={{
                                    marginTop: '0px'
                                  }}
                                  disabled={isAllInstitutions}
                                />
                              </Col>
                              <Col md="4" className="pt-2 pl-0">
                                <FormCheckbox
                                  checked={isAllInstitutions}
                                  onChange={() => setIsAllInstitutions(!isAllInstitutions)}
                                >
                                  <span className="text-dark">
                                    Ver todas
                                  </span>
                                </FormCheckbox>
                              </Col>
                            </Row>
                          </Col>
                          <Col md="6" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Tipo de documento
                            </span>
                            <Row className="pt-2">
                              <Col md="8">
                                <FormSelect
                                  id="documentType"
                                  value={docTypeId}
                                  onChange={e =>
                                    setDocTypeId(e.target.value)
                                  }
                                  required={!isAllDocumentType}
                                  disabled={isAllDocumentType}
                                >
                                  <option value="" disabled>
                                    Escolher...
                                  </option>
                                  {documentTypes &&
                                    documentTypes.map(dType => (
                                      <option
                                        key={dType.value}
                                        value={dType.value}
                                      >
                                        {dType.label}
                                      </option>
                                    ))}
                                </FormSelect>
                              </Col>
                              <Col md="4" className="pt-2 pl-0">
                                <FormCheckbox
                                  checked={isAllDocumentType}
                                  onChange={() => setIsAllDocumentType(!isAllDocumentType)}
                                >
                                  <span className="text-dark">
                                    Ver todos
                                  </span>
                                </FormCheckbox>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row form className="d-flex align-items-center">
                          <Col md="3" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Data inicial
                            </span>
                            <FormInput
                              type="date"
                              disabled={isAllPeriod}
                              value={initialDate}
                              onChange={(e) => setInitialDate(e.target.value)}
                              required={!isAllPeriod}
                            />
                          </Col>
                          <Col md="3" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Data Final
                            </span>
                            <FormInput
                              type="date"
                              disabled={isAllPeriod}
                              value={finalDate}
                              onChange={(e) => setFinalDate(e.target.value)}
                              required={!isAllPeriod}
                            />
                          </Col>
                          <Col md="3" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Filtrar por
                            </span>
                            <FormSelect
                              id="dateCriteria"
                              value={dateFilterCriteria}
                              onChange={e =>
                                setDateFilterCriteria(e.target.value)
                              }
                              required={!isAllPeriod}
                              disabled={isAllPeriod}
                            >
                              {dateFilterCriteriaList &&
                                dateFilterCriteriaList.map(dType => (
                                  <option
                                    key={dType.value}
                                    value={dType.value}
                                  >
                                    {dType.label}
                                  </option>
                                ))}
                            </FormSelect>
                          </Col>
                          <Col md="3" className="d-flex align-items-center pt-3">
                            <FormCheckbox
                              checked={isAllPeriod}
                              onChange={() => setIsAllPeriod(!isAllPeriod)}
                            >
                              <span className="text-dark">
                                Intervalo completo
                              </span>
                            </FormCheckbox>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="8">
                            <ErrorBox error={error} />
                          </Col>
                          <Col md="4" className="text-right">
                            <Button
                              className="btn-primary ml-3"
                            // onClick={handleCleanState}
                            >
                              Consultar
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Collapse>
          </Card>
        </Col>
      </Row>
      {loading && <LoadingContainer loading={loading} />}
      {/* DASHBOARD */}
      {!loading && data && data.quantityByStatus && (
        <>
          <Row className="p-3">
            <Col sm="12" className="text-center mt-2">
              <h4>
                Resultados obtidos
              </h4>
            </Col>
          </Row>
          <Row>
            <Col sm="4">
              <Card>
                <CardBody className="text-center px-3 py-2">
                  <h4 className="text-muted mb-1">
                    <FaBook />
                  </h4>
                  <h3 className="m-0 font-weight-bold">
                    {data.total || 0}
                  </h3>
                  <span className="text-muted">
                    Total de documentos
                  </span>
                </CardBody>
              </Card>
            </Col>
            <Col sm="4">
              <Card>
                <CardBody className="text-center px-3 py-2">
                  <h4 className="text-success mb-1">
                    <FaCheckCircle />
                  </h4>
                  <h3 className="m-0 font-weight-bold">
                    {getQuantityByStatus(data.quantityByStatus, 'preserved')}
                  </h3>
                  <span className="text-muted">
                    Documentos processados
                  </span>
                </CardBody>
              </Card>
            </Col>
            <Col sm="4">
              <Card>
                <CardBody className="text-center px-3 py-2">
                  <h4 className="text-primary mb-1">
                    <FaRandom />
                  </h4>
                  <h3 className="m-0 font-weight-bold">
                    {sumProcessingStatus(data.quantityByStatus)}
                  </h3>
                  <span className="text-muted">
                    Documentos em processamento
                  </span>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col sm="4">
              <Card>
                <CardBody className="text-center px-3 py-2">
                  <h4 className="text-danger mb-1">
                    <FaWindowClose />
                  </h4>
                  <h3 className="m-0 font-weight-bold">
                    {sumErrorStatus(data.quantityByStatus)}
                  </h3>
                  <span className="text-muted">
                    Documentos em status de erro
                  </span>
                </CardBody>
              </Card>
            </Col>
            <Col sm="4">
              <Card>
                <CardBody className="text-center px-3 py-2">
                  <h4 className="text-warning mb-1">
                    <FaUndo />
                  </h4>
                  <h3 className="m-0 font-weight-bold">
                    {getQuantityByStatus(data.quantityByStatus, 'revoked')}
                  </h3>
                  <span className="text-muted">
                    Documentos revogados
                  </span>
                </CardBody>
              </Card>
            </Col>
            <Col sm="4">
              <Card>
                <CardBody className="text-center px-3 py-2">
                  <h4 className="text-info mb-1">
                    <FaUnlink />
                  </h4>
                  <h3 className="m-0 font-weight-bold">
                    {getQuantityByStatus(data.quantityByStatus, 'canceled')}
                  </h3>
                  <span className="text-muted">
                    Documentos cancelados
                  </span>
                </CardBody>
              </Card>
            </Col>

          </Row>
          <Row>
            <Col md="6" className="mt-4">
              <Card>
                <CardBody className="text-center px-3 py-0">
                  <Col md="12" className="d-flex pt-1">
                    <h5 className="pt-3 flex-fill">
                      Tipos de documento
                    </h5>
                    <Button
                      data-tip="Copiar gráfico"
                      outline
                      pill
                      onClick={() => generateImageFromChart(getDocTypeChart)}
                      theme="light"
                      disabled={docTypeChartRef.isLoading}
                    >
                      <FaRegCopy />
                    </Button>
                  </Col>
                  <Col md="12" className="px-0">
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart width={400} height={400} ref={docTypeChartRef.ref}>
                        <Pie
                          dataKey="value"
                          isAnimationActive={true}
                          data={chartDocTypeData}
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          fill="#8884d8"
                          label
                        >
                          {chartDocTypeData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend
                          layout="vertical"
                          verticalAlign="middle"
                          align="left"
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  </Col>
                </CardBody>
              </Card>
            </Col>

            <Col md="6" className="mt-4">
              <Card>
                <CardBody className="text-center px-3 py-0">
                  <Col md="12" className="d-flex pt-1">
                    <h5 className="pt-3 flex-fill">
                      Status
                    </h5>
                    <Button
                      data-tip="Copiar gráfico"
                      outline
                      pill
                      onClick={() => generateImageFromChart(getStatusChart)}
                      theme="light"
                      disabled={statusChartRef.isLoading}
                    >
                      <FaRegCopy />
                    </Button>
                  </Col>
                  <Col md="12" className="px-0">
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart width={400} height={400} ref={statusChartRef.ref}>
                        <Pie
                          dataKey="value"
                          isAnimationActive={true}
                          data={chartStatusData}
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          fill="#8884d8"
                          label
                        >
                          {chartDocTypeData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend
                          layout="vertical"
                          verticalAlign="middle"
                          align="left"
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  </Col>
                </CardBody>
              </Card>
            </Col>
            <ReactTooltip />
          </Row>
          {data.ranking && (
            <Row className="mt-4">
              <Col id="ranking" sm="12" className="my-2 d-flex">
                <h4 class="flex-fill text-center">
                  Ranking de instituições emissoras
                </h4>
                <Button
                  onClick={() => handleIsAllRanking()}
                  size="sm"
                  outline
                  pill
                >
                  {isAllRanking ? 'Ver top 5' : 'Ver todas'}
                </Button>
              </Col>
              <Col sm="12">
                <Card>
                  <CardBody className="text-center px-3 py-2">
                    <table className="table mb-0">
                      <thead className="bg-light">
                        <tr>
                          <th scope="col" className="border-0">
                            Colocação <FaTrophy />
                          </th>
                          <th scope="col" className="border-0">
                            Instituição
                          </th>
                          <th scope="col" className="border-0">
                            Documentos preservados
                          </th>
                          <th scope="col" className="border-0">
                            Documentos revogados
                          </th>
                          <th scope="col" className="border-0">
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.ranking.map((item, index) => (
                          <tr key={item.institution._id}>
                            <td>{index + 1}º lugar</td>
                            <td>{item.institution?.name}</td>
                            <td>{item.preserved}</td>
                            <td>{item.revoked}</td>
                            <td>{item.total}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </>
      )}
    </Container>
  );
}
