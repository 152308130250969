/* List */
export function getAllCoursesRequest() {
  return {
    type: '@course/GET_ALL_COURSES_REQUEST',
  };
}
export function getAllCoursesSuccess(courses) {
  return {
    type: '@course/GET_ALL_COURSES_SUCCESS',
    payload: { courses },
  };
}
export function getCourseByIdRequest(id, index) {
  return {
    type: '@course/GET_COURSE_BY_ID_REQUEST',
    payload: { id, index },
  };
}
export function getCourseByIdSuccess(course, index) {
  return {
    type: '@course/GET_COURSE_BY_ID_SUCCESS',
    payload: { course, index },
  };
}

/* Store */
export function storeCourseRequest(data, onSuccess) {
  return {
    type: '@course/STORE_COURSE_REQUEST',
    payload: { data, onSuccess },
  };
}
export function storeCourseSuccess(course) {
  return {
    type: '@course/STORE_COURSE_SUCCESS',
    payload: { course },
  };
}

/* Update */
export function updateCourseRequest(id, data, index, onSuccess) {
  return {
    type: '@course/UPDATE_COURSE_REQUEST',
    payload: { id, data, index, onSuccess },
  };
}
export function updateCourseSuccess(index, data) {
  return {
    type: '@course/UPDATE_COURSE_SUCCESS',
    payload: { index, data },
  };
}

/* Delete */
export function deleteCourseRequest(id, index) {
  return {
    type: '@course/DELETE_COURSE_REQUEST',
    payload: { id, index },
  };
}
export function deleteCourseSuccess(index) {
  return {
    type: '@course/DELETE_COURSE_SUCCESS',
    payload: { index },
  };
}

/* Failure */
export function courseFailure() {
  return {
    type: '@course/COURSE_FAILURE',
  };
}
