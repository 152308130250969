import React from 'react';
import { Nav, NavItem, NavLink, Badge } from 'shards-react';

import { NavLink as RouteNavLink, useNavigate } from 'react-router-dom';
import {
  FaCogs,
  FaPowerOff,
  FaCaretDown,
  FaHome,
  FaRegUser,
  FaRegEdit,
  FaBook,
  FaUser,
  FaChartLine,
  FaDatabase,
  FaLaptopCode,
  FaDesktop,
  FaCubes,
} from 'react-icons/fa';
import { TiDocumentText } from 'react-icons/ti';
import { useDispatch, useSelector } from 'react-redux';
import { NavTitle, IconWrapper, NavWrapper, Container } from './styles';

import { signOut } from '../../../store/modules/auth/actions';
import {
  admin,
  adminAndMaintainer,
  normal,
  normalOrManager,
} from '../../../config/UserPermission';

export default function SidebarNavItems() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { permission } = useSelector((state) => state.auth);

  function handleSignOut() {
    dispatch(signOut());
    navigate('/');
  }

  function RenderItem({ userLevel = [], children: MenuItem }) {
    if (!userLevel.includes(permission)) return null;
    return MenuItem;
  }

  return (
    <NavWrapper className="nav-wrapper">
      <Nav className="nav--no-borders flex-column">
        <RenderItem userLevel={normalOrManager}>
          <NavItem>
            <NavLink tag={RouteNavLink} to="/profile">
              <IconWrapper className="d-inline-block item-icon-wrapper mr-2">
                <FaUser />
              </IconWrapper>
              <NavTitle>Meus dados</NavTitle>
            </NavLink>
          </NavItem>
        </RenderItem>

        <RenderItem userLevel={adminAndMaintainer}>
          <NavItem>
            <NavLink tag={RouteNavLink} to="/institution">
              <IconWrapper className="d-inline-block item-icon-wrapper mr-2">
                <FaHome />
              </IconWrapper>
              <NavTitle>Gestão de instituições</NavTitle>
            </NavLink>
          </NavItem>
        </RenderItem>

        <RenderItem userLevel={adminAndMaintainer}>
          <NavItem>
            <NavLink tag={RouteNavLink} to="/user">
              <IconWrapper className="d-inline-block item-icon-wrapper mr-2">
                <FaRegUser size={15.5} />
              </IconWrapper>
              <NavTitle>Gestão de usuários</NavTitle>
            </NavLink>
          </NavItem>
        </RenderItem>

        <RenderItem userLevel={adminAndMaintainer}>
          <NavItem>
            <NavLink
              className="navbar-toggler"
              data-toggle="collapse"
              data-target="#collapsedPre"
              aria-controls="collapsedPre"
              aria-expanded="true"
              aria-label="Toggle navigation"
            >
              <IconWrapper className="d-inline-block item-icon-wrapper mr-1">
                <TiDocumentText size={18} />
              </IconWrapper>
              <NavTitle> Pré-cadastro</NavTitle>
              <FaCaretDown className="float-right" />
            </NavLink>
          </NavItem>

          <Container className="collapse" id="collapsedPre">
            <NavItem>
              <NavLink tag={RouteNavLink} to="/precourses">
                <IconWrapper className="d-inline-block item-icon-wrapper mr-2">
                  <FaRegEdit size={15.5} />
                </IconWrapper>
                <NavTitle>Grupos de cursos</NavTitle>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={RouteNavLink} to="/presigners">
                <IconWrapper className="d-inline-block item-icon-wrapper mr-2">
                  <FaRegEdit size={15.5} />
                </IconWrapper>
                <NavTitle>Assinadores</NavTitle>
              </NavLink>
            </NavItem>
          </Container>
        </RenderItem>

        <RenderItem userLevel={normal}>
          <NavItem>
            <NavLink tag={RouteNavLink} to="/general/signitems">
              <IconWrapper className="d-inline-block item-icon-wrapper mr-2">
                <FaRegEdit size={15.5} />
              </IconWrapper>
              <NavTitle>Itens de assinatura</NavTitle>
            </NavLink>
          </NavItem>
        </RenderItem>

        <RenderItem userLevel={adminAndMaintainer}>
          <NavItem>
            <NavLink
              className="navbar-toggler"
              data-toggle="collapse"
              data-target="#collapsedItem"
              aria-controls="collapsedItem"
              aria-expanded="true"
              aria-label="Toggle navigation"
            >
              <IconWrapper className="d-inline-block item-icon-wrapper mr-1">
                <TiDocumentText size={18} />
              </IconWrapper>
              <NavTitle> Gestão de documentos</NavTitle>
              <FaCaretDown className="float-right" />
            </NavLink>
          </NavItem>
        </RenderItem>

        <Container className="collapse" id="collapsedItem">
          <RenderItem userLevel={adminAndMaintainer}>
            <NavItem>
              <NavLink tag={RouteNavLink} to="/course">
                <IconWrapper className="d-inline-block item-icon-wrapper mr-2">
                  <FaBook size={15.5} />
                </IconWrapper>
                <NavTitle>Grupos de cursos</NavTitle>
              </NavLink>
            </NavItem>
          </RenderItem>

          <RenderItem userLevel={adminAndMaintainer}>
            <NavItem>
              <NavLink tag={RouteNavLink} to="/sigitems">
                <IconWrapper className="d-inline-block item-icon-wrapper mr-2">
                  <FaRegEdit size={15.5} />
                </IconWrapper>
                <NavTitle>Itens de assinatura</NavTitle>
              </NavLink>
            </NavItem>
          </RenderItem>

          <RenderItem userLevel={adminAndMaintainer}>
            <NavItem>
              <NavLink tag={RouteNavLink} to="/signers">
                <IconWrapper className="d-inline-block item-icon-wrapper mr-2">
                  <FaRegEdit size={15.5} />
                </IconWrapper>
                <NavTitle>Assinadores</NavTitle>
              </NavLink>
            </NavItem>
          </RenderItem>

          <RenderItem userLevel={adminAndMaintainer}>
            <NavItem>
              <NavLink tag={RouteNavLink} to="/tags">
                <IconWrapper className="d-inline-block item-icon-wrapper mr-2">
                  <FaRegEdit size={15.5} />
                </IconWrapper>
                <NavTitle>Tags</NavTitle>
              </NavLink>
            </NavItem>
          </RenderItem>

          <RenderItem userLevel={adminAndMaintainer}>
            <NavItem>
              <NavLink tag={RouteNavLink} to="/signconfig">
                <IconWrapper className="d-inline-block item-icon-wrapper mr-2">
                  <FaCogs />
                </IconWrapper>
                <NavTitle>
                  Configuração de <span>Assinatura</span>
                </NavTitle>
              </NavLink>
            </NavItem>
          </RenderItem>

          <RenderItem userLevel={adminAndMaintainer}>
            <NavItem>
              <NavLink tag={RouteNavLink} to="/docconfig">
                <IconWrapper className="d-inline-block item-icon-wrapper mr-2">
                  <FaCogs />
                </IconWrapper>
                <NavTitle>
                  Configuração de <span>Documento</span>
                </NavTitle>
              </NavLink>
            </NavItem>
          </RenderItem>
        </Container>

        <RenderItem userLevel={admin}>
          <NavItem>
            <NavLink
              className="navbar-toggler"
              data-toggle="collapse"
              data-target="#collapsedSystemConfig"
              aria-controls="collapsedSystemConfig"
              aria-expanded="true"
              aria-label="Toggle navigation"
            >
              <IconWrapper className="d-inline-block item-icon-wrapper mr-1">
                <TiDocumentText size={18} />
              </IconWrapper>
              <NavTitle> Configurações gerais</NavTitle>
              <FaCaretDown className="float-right" />
            </NavLink>
          </NavItem>
        </RenderItem>

        <Container className="collapse" id="collapsedSystemConfig">
          <RenderItem userLevel={admin}>
            <NavItem>
              <NavLink tag={RouteNavLink} to="/doctype">
                <IconWrapper className="d-inline-block item-icon-wrapper mr-2">
                  <FaRegEdit size={15.5} />
                </IconWrapper>
                <NavTitle>Tipo de Documento</NavTitle>
              </NavLink>
            </NavItem>
          </RenderItem>

          <RenderItem userLevel={admin}>
            <NavItem>
              <NavLink tag={RouteNavLink} to="/docgroup">
                <IconWrapper className="d-inline-block item-icon-wrapper mr-2">
                  <FaRegEdit size={15.5} />
                </IconWrapper>
                <NavTitle>Grupo de Documentos</NavTitle>
              </NavLink>
            </NavItem>
          </RenderItem>

          <RenderItem userLevel={admin}>
            <NavItem>
              <NavLink tag={RouteNavLink} to="/ddrconfig">
                <IconWrapper className="d-inline-block item-icon-wrapper mr-2">
                  <FaRegEdit size={15.5} />
                </IconWrapper>
                <NavTitle>Controle de versão</NavTitle>
              </NavLink>
            </NavItem>
          </RenderItem>
        </Container>

        <RenderItem userLevel={adminAndMaintainer}>
          <NavItem>
            <NavLink
              className="navbar-toggler"
              data-toggle="collapse"
              data-target="#collapsedRegistries"
              aria-controls="collapsedRegistries"
              aria-expanded="true"
              aria-label="Toggle navigation"
            >
              <IconWrapper className="d-inline-block item-icon-wrapper mr-1">
                <FaBook size={18} />
              </IconWrapper>
              <NavTitle> Gestão de registros</NavTitle>
              <FaCaretDown className="float-right" />
            </NavLink>
          </NavItem>
        </RenderItem>

        <Container className="collapse" id="collapsedRegistries">
          <RenderItem userLevel={admin}>
            <NavItem>
              <NavLink tag={RouteNavLink} to="/documents">
                <IconWrapper className="d-inline-block item-icon-wrapper mr-2">
                  <FaBook size={15.5} />
                </IconWrapper>
                <NavTitle>Gerenciar registros</NavTitle>
              </NavLink>
            </NavItem>
          </RenderItem>
          <RenderItem userLevel={admin}>
            <NavItem>
              <NavLink tag={RouteNavLink} to="/registry-batch">
                <IconWrapper className="d-inline-block item-icon-wrapper mr-2">
                  <FaBook size={15.5} />
                </IconWrapper>
                <NavTitle>Gerenciar lotes</NavTitle>
              </NavLink>
            </NavItem>
          </RenderItem>
          <RenderItem userLevel={adminAndMaintainer}>
            <NavItem>
              <NavLink tag={RouteNavLink} to="/storage">
                <IconWrapper className="d-inline-block item-icon-wrapper mr-2">
                  <FaDatabase size={15.5} />
                </IconWrapper>
                <NavTitle>Armazenamento</NavTitle>
              </NavLink>
            </NavItem>
          </RenderItem>
        </Container>

        <RenderItem userLevel={adminAndMaintainer}>
          <NavItem>
            <NavLink
              className="navbar-toggler"
              data-toggle="collapse"
              data-target="#collapsedStats"
              aria-controls="collapsedStats"
              aria-expanded="true"
              aria-label="Toggle navigation"
            >
              <IconWrapper className="d-inline-block item-icon-wrapper mr-1">
                <FaChartLine size={18} />
              </IconWrapper>
              <NavTitle> Estatísticas</NavTitle>
              <FaCaretDown className="float-right" />
            </NavLink>
          </NavItem>
        </RenderItem>

        <Container className="collapse" id="collapsedStats">
          <RenderItem userLevel={adminAndMaintainer}>
            <NavItem>
              <NavLink tag={RouteNavLink} to="/reports/documents">
                <IconWrapper className="d-inline-block item-icon-wrapper mr-2">
                  <FaBook size={15.5} />
                </IconWrapper>
                <NavTitle>Registros de documentos</NavTitle>
              </NavLink>
            </NavItem>
          </RenderItem>

          <RenderItem userLevel={adminAndMaintainer}>
            <NavItem>
              <NavLink tag={RouteNavLink} to="/reports/signatures">
                <IconWrapper className="d-inline-block item-icon-wrapper mr-2">
                  <FaRegEdit size={15.5} />
                </IconWrapper>
                <NavTitle>Assinaturas</NavTitle>
              </NavLink>
            </NavItem>
          </RenderItem>

          <RenderItem userLevel={adminAndMaintainer}>
            <NavItem>
              <NavLink tag={RouteNavLink} to="/reports/timestamp">
                <IconWrapper className="d-inline-block item-icon-wrapper mr-2">
                  <FaRegEdit size={15.5} />
                </IconWrapper>
                <NavTitle>Carimbos</NavTitle>
              </NavLink>
            </NavItem>
          </RenderItem>

          <RenderItem userLevel={adminAndMaintainer}>
            <NavItem>
              <NavLink tag={RouteNavLink} to="/reports/blockchain">
                <IconWrapper className="d-inline-block item-icon-wrapper mr-2">
                  <FaCubes size={15.5} />
                </IconWrapper>
                <NavTitle>Blockchain</NavTitle>
              </NavLink>
            </NavItem>
          </RenderItem>
        </Container>

        <RenderItem userLevel={admin}>
          <NavItem>
            <NavLink
              className="navbar-toggler"
              data-toggle="collapse"
              data-target="#collapsedLogs"
              aria-controls="collapsedLogs"
              aria-expanded="true"
              aria-label="Toggle navigation"
            >
              <IconWrapper className="d-inline-block item-icon-wrapper mr-1">
                <FaDatabase size={18} />
              </IconWrapper>
              <NavTitle> Logs</NavTitle>
              <FaCaretDown className="float-right" />
            </NavLink>
          </NavItem>
        </RenderItem>

        <Container className="collapse" id="collapsedLogs">
          <NavItem>
            <NavLink tag={RouteNavLink} to="/logs/version">
              <IconWrapper className="d-inline-block item-icon-wrapper mr-2">
                <FaLaptopCode size={15.5} />
              </IconWrapper>
              <NavTitle>Registros de Versão</NavTitle>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={RouteNavLink} to="/logs/signature">
              <IconWrapper className="d-inline-block item-icon-wrapper mr-2">
                <FaLaptopCode size={15.5} />
              </IconWrapper>
              <NavTitle>Registros de Assinatura</NavTitle>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={RouteNavLink} to="/logs/timestamp">
              <IconWrapper className="d-inline-block item-icon-wrapper mr-2">
                <FaLaptopCode size={15.5} />
              </IconWrapper>
              <NavTitle>Registros de Carimbo</NavTitle>
            </NavLink>
          </NavItem>
        </Container>

        <RenderItem userLevel={adminAndMaintainer}>
          <NavItem>
            <NavLink
              className="navbar-toggler"
              data-toggle="collapse"
              data-target="#collapsedSystemData"
              aria-controls="collapsedSystemData"
              aria-expanded="true"
              aria-label="Toggle navigation"
            >
              <IconWrapper className="d-inline-block item-icon-wrapper mr-1">
                <FaDesktop size={18} />
              </IconWrapper>
              <NavTitle> Dados do Sistema</NavTitle>
              <Badge pill outline className="ml-3 p-1">
                Novo
              </Badge>
              <FaCaretDown className="float-right" />
            </NavLink>
          </NavItem>
        </RenderItem>

        <RenderItem userLevel={adminAndMaintainer}>
          <Container className="collapse" id="collapsedSystemData">
            <NavItem>
              <NavLink tag={RouteNavLink} to="/system-data/xsd-validity">
                <IconWrapper className="d-inline-block item-icon-wrapper mr-2">
                  <FaLaptopCode size={15.5} />
                </IconWrapper>
                <NavTitle>Vigência dos XSDs</NavTitle>
                <Badge pill outline className="ml-3 p-1">
                  Novo
                </Badge>
              </NavLink>
            </NavItem>
          </Container>
        </RenderItem>

        <NavItem>
          <NavLink id="signOut" onClick={handleSignOut}>
            <IconWrapper className="d-inline-block item-icon-wrapper mr-2">
              <FaPowerOff />
            </IconWrapper>
            <NavTitle>Sair</NavTitle>
          </NavLink>
        </NavItem>
      </Nav>
    </NavWrapper>
  );
}
