import { Permissions } from '../config/Constants';

export function minimumPermission(
  minimum = Permissions.NORMAL_USER,
  userPermission = Permissions.NORMAL_USER
) {
  return userPermission >= minimum;
}

export function canEditUser(userPermission, filter) {
  if (userPermission === Permissions.ADMIN) return true;
  if (userPermission > filter) return true;

  return false;
}

export function getPermission(permission) {
  const find = Object.keys(Permissions).find((key) => permission === key);
  if (find) {
    return Permissions[find];
  }
  return Permissions.NORMAL_USER;
}
