/* eslint-disable import/no-cycle */
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import createStore from './createStore';
import persistReducers from './persistReducer';

import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(persistReducers(rootReducer), sagaMiddleware);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
