/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { RadioContainer, RadioInput } from './style';

export default function CustomRadio({ data = [], checked, onChange, padding }) {
  const [checkedRadio, setCheckedRadio] = useState();
  useEffect(() => {
    setCheckedRadio(checked);
  }, [checked]);
  function handleChange(value) {
    setCheckedRadio(value);
    onChange && onChange(value);
  }
  return (
    <RadioContainer class="radio">
      {data.map((item, index) => (
        <RadioInput
          key={item.label}
          label={item.label}
          type="radio"
          id={item.value}
          name="gender"
          value={item.value}
          checked={verifyChecked(checkedRadio, index, item.value)}
          onClick={() => handleChange(item.value)}
          padding={padding}
        />
      ))}
    </RadioContainer>
  );
}
function verifyChecked(checkedRadio, index, value) {
  if (!checkedRadio && index === 0) {
    return true;
  }
  if (checkedRadio && checkedRadio === value) {
    return true;
  }

  return false;
}
