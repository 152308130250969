/* eslint-disable consistent-return */
import { animateScroll as scroll } from 'react-scroll';

export function scrollToForm() {
  // the form id should be form-container
  const form = document.getElementById('form-container');
  const footer = document.querySelectorAll('.main-footer')[0];

  if (!form) {
    return;
  }
  if (!footer) {
    return scroll.scrollTo(form.getBoundingClientRect().top + window.scrollY);
  }
  return scroll.scrollTo(
    form.getBoundingClientRect().top + window.scrollY - footer.offsetHeight / 2
  );
}

export function scrollToElement(id) {
  // the form id should be form-container
  const element = document.getElementById(id);
  const footer = document.querySelectorAll('.main-footer')[0];

  if (!element) {
    return;
  }
  if (!footer) {
    return scroll.scrollTo(
      element.getBoundingClientRect().top + window.scrollY
    );
  }
  return scroll.scrollTo(
    element.getBoundingClientRect().top +
      window.scrollY -
      footer.offsetHeight * 1.2
  );
}

export function scrollMore(px) {
  return scroll.scrollMore(px);
}

export function scrollToTop() {
  return scroll.scrollToTop();
}

export function scrollToBottom() {
  return scroll.scrollToBottom();
}
