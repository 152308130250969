import { produce } from 'immer';

const INITIAL_STATE = {
  doctypes: [],
  doctype: {},
  loading: false,
  isSubmitting: false,
};

export default function documentType(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@doctype/GET_ALL_DOCTYPES_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@doctype/GET_ALL_DOCTYPES_SUCCESS': {
        draft.doctypes = action.payload.doctypes;
        draft.loading = false;
        break;
      }
      case '@doctype/GET_DOCTYPE_BY_ID_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@doctype/GET_DOCTYPE_BY_ID_SUCCESS': {
        draft.loading = false;
        draft.doctype = action.payload.doctype;
        draft.doctype.index = action.payload.index;
        break;
      }
      case '@doctype/STORE_DOCTYPE_REQUEST': {
        draft.isSubmitting = true;
        break;
      }
      case '@doctype/STORE_DOCTYPE_SUCCESS': {
        const aux = [...draft.doctypes];
        aux.push(action.payload.doctype);
        draft.doctypes = aux;
        draft.isSubmitting = false;
        break;
      }
      case '@doctype/UPDATE_DOCTYPE_REQUEST': {
        draft.isSubmitting = true;
        break;
      }
      case '@doctype/UPDATE_DOCTYPE_SUCCESS': {
        const { data } = action.payload;
        const aux = draft.doctypes;
        aux[draft.doctype.index] = data;
        draft.doctypes = aux;
        draft.isSubmitting = false;
        break;
      }
      case '@doctype/DELETE_DOCTYPE_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@doctype/DELETE_DOCTYPE_SUCCESS': {
        const aux = draft.doctypes;
        aux.splice(action.payload.index, 1);
        draft.doctypes = aux;
        draft.loading = false;
        break;
      }
      case '@doctype/DOCTYPE_FAILURE': {
        draft.loading = false;
        draft.isSubmitting = false;
        break;
      }
      default:
    }
  });
}
