import { produce } from 'immer';

const INITIAL_STATE = {
  preCourses: [],
  preCourse: {},
  total: 0,
  numberOfPages: 0,
  loading: false,
  isSubmitting: false,
};

export default function preCouse(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@precourse/GET_ALL_PRE_COURSES_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@precourse/GET_ALL_PRE_COURSES_SUCCESS': {
        draft.preCourses = action.payload.preCourses.content;
        draft.total = action.payload.preCourses.total;
        draft.numberOfPages = action.payload.preCourses.numberOfPages;
        draft.loading = false;
        break;
      }
      case '@precourse/GET_PRE_COURSE_BY_ID_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@precourse/GET_PRE_COURSE_BY_ID_SUCCESS': {
        draft.loading = false;
        draft.course = action.payload.course;
        draft.course.index = action.payload.index;
        break;
      }
      case '@precourse/STORE_PRE_COURSE_REQUEST': {
        // draft.loading = true;
        draft.isSubmitting = true;
        break;
      }
      case '@precourse/STORE_PRE_COURSE_SUCCESS': {
        const aux = [...draft.preCourses];
        aux.unshift(action.payload.course);
        draft.preCourses = aux;
        draft.total += 1;
        // draft.loading = false;
        draft.isSubmitting = false;
        break;
      }
      case '@precourse/UPDATE_PRE_COURSE_REQUEST': {
        // draft.loading = true;
        draft.isSubmitting = true;
        break;
      }
      case '@precourse/UPDATE_PRE_COURSE_SUCCESS': {
        const { data } = action.payload;
        const aux = draft.preCourses;
        const signerAux = state.preCourses.findIndex((item) => {
          return item._id === data._id;
        });
        aux[signerAux] = data;
        draft.preCourses = aux;
        draft.isSubmitting = false;
        break;
      }
      case '@precourse/UPDATE_PRE_COURSE_CANCEL': {
        draft.preCourse = {};
        break;
      }
      case '@precourse/DELETE_PRE_COURSE_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@precourse/DELETE_PRE_COURSE_SUCCESS': {
        const aux = draft.preCourses;
        aux[action.payload.index] = action.payload.data;
        draft.preCourses = aux;
        draft.loading = false;
        break;
      }
      case '@precourse/PRE_COURSE_FAILURE': {
        draft.loading = false;
        draft.isSubmitting = false;
        break;
      }
      default:
    }
  });
}
