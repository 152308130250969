/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

export default function SearchSelect({
  options = [],
  placeholder = 'Selecione uma instituição',
  onSelect,
  value = null,
  styleContainer = {},
  disabled,
}) {
  function handleFilter(optionsSearch, input) {
    if (optionsSearch.data.label.toLowerCase().includes(input.toLowerCase()))
      return true;
    if (
      optionsSearch.data.client_id &&
      optionsSearch.data.client_id.includes(input)
    )
      return true;
    return false;
  }
  return (
    <Select
      styles={{
        menuList: (provided) => ({
          ...provided,
          color: '#5a6169',
        }),
        container: (provided) => ({
          ...provided,
          marginTop: styleContainer.marginTop || 15,
        }),
      }}
      options={options}
      placeholder={placeholder}
      onChange={(onSelect && onSelect) || null}
      // defaultValue={options.find(item => item.value === value)}
      value={value ? options.find((item) => item.value === value) : ''}
      autoComplete="false"
      name="asasa"
      noOptionsMessage={() => 'Sem opções'}
      filterOption={handleFilter}
      isDisabled={disabled}
      required
    />
  );
}

SearchSelect.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  styleContainer: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};
