import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { responseErrors } from '../../../utils/responseErros';
import api from '../../../services/api';
import {
  getAllDocConfigsSuccess,
  getDocConfigByIdSuccess,
  storeDocConfigSuccess,
  updateDocConfigSuccess,
  deleteDocConfigSuccess,
  docConfigFailure,
} from './actions';
import { refreshTokenRequest } from '../auth/actions';
import { store } from '../../index';

export function* getDocConfigs() {
  const { refresh_token } = store.getState().auth;
  const { selectedInstitution } = store.getState().selectedData;

  try {
    const response = yield call((url) => {
      return api.get(url, {
        params: {
          institutionId: selectedInstitution,
        },
      });
    }, '/management/docconfigs');
    const docConfigs = response.data;

    yield put(getAllDocConfigsSuccess(docConfigs));
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(docConfigFailure());
      toast.error(`Falha ao listar configurações de assinatura`);
    }
  }
}
export function* getDocConfigById({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, index } = payload;
    const response = yield call(api.get, `/management/docconfigs/${id}`);
    const docConfig = response.data;

    yield put(getDocConfigByIdSuccess(docConfig, index));
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(docConfigFailure());
      toast.error('Falha ao obter dados da configuração de assinatura');
    }
  }
}
export function* storeDocConfig({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { data, onSuccess } = payload;

    const response = yield call(api.post, '/management/docconfigs', data);
    const docConfig = response.data;

    yield put(storeDocConfigSuccess(docConfig));
    toast.success('Configuração de assinatura criada com sucesso');
    onSuccess && onSuccess();
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(docConfigFailure());
      Swal.fire(
        responseErrors(
          err.response,
          'Falha ao criar configuração de assinatura'
        )
      );
    }
  }
}

export function* updateDocConfig({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { data, id, index, onSuccess } = payload;

    const response = yield call(
      api.patch,
      `/management/docconfigs/${id}`,
      data
    );
    const docConfig = response.data;

    yield put(updateDocConfigSuccess(index, docConfig));
    toast.success('Configuração de assinatura atualizada com sucesso');
    onSuccess && onSuccess();
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(docConfigFailure());
      Swal.fire(
        responseErrors(
          err.response,
          'Falha ao atualizar configuração de assinatura'
        )
      );
    }
  }
}

export function* deleteDocConfig({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, index } = payload;
    yield call(api.delete, `/management/docconfigs/${id}`);
    yield put(deleteDocConfigSuccess(index));
    toast.success('Configuração de assinatura excluída com sucesso');
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(docConfigFailure());
      toast.error('Falha ao excluir configuração de assinatura');
    }
  }
}

export default all([
  takeLatest('@docconfig/GET_ALL_DOCCONFIGS_REQUEST', getDocConfigs),
  takeLatest('@docconfig/GET_DOCCONFIG_BY_ID_REQUEST', getDocConfigById),
  takeLatest('@docconfig/STORE_DOCCONFIG_REQUEST', storeDocConfig),
  takeLatest('@docconfig/UPDATE_DOCCONFIG_REQUEST', updateDocConfig),
  takeLatest('@docconfig/DELETE_DOCCONFIG_REQUEST', deleteDocConfig),
]);
