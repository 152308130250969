import { all, takeLatest, call, put, take } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { responseErrors } from '../../../utils/responseErros';
import api from '../../../services/api';
import {
  getAllDocumentsSuccess,
  getDocumentByIdSuccess,
  updateDocumentSuccess,
  revokeDocumentRequestSuccess,
  DocumentFailure,
  cancelDocumentRequestSuccess,
  updateStatusRequestSuccess,
  downloadRequestSuccess,
} from './actions';
import { refreshTokenRequest } from '../auth/actions';
import { store } from '../../index';

export function* getDocuments({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const {
      limit = 10,
      page = 1,
      institutionId,
      getLast,
      search,
      initialDate,
      finalDate,
      docTypeId,
      onSuccess,
    } = payload;

    const response = yield call((url) => {
      return api.get(url, {
        params: {
          institutionId,
          getLast: !!getLast,
          search,
          initialDate,
          finalDate,
          docTypeId,
          limit,
          page,
        },
      });
    }, '/management/document');
    const documents = response.data;

    yield put(getAllDocumentsSuccess(documents));

    onSuccess && onSuccess();
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(DocumentFailure());
      toast.error(`Falha ao listar documentos`);
    }
  }
}
export function* getDocumentById({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id } = payload;
    const response = yield call(api.get, `/management/document/${id}`);
    const document = response.data;

    yield put(getDocumentByIdSuccess(document));
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(DocumentFailure());
      toast.error('Falha ao obter dados do documento');
    }
  }
}

export function* revokeDocument() {
  const { refresh_token } = store.getState().auth;
  const { document } = store.getState().documents;

  try {
    const data = {
      meta: {
        our_number: document.ourNumber,
        client_id: document.institution.client_id,
      },
    };
    yield call(api.post, `/management/document/revoke`, data);
    yield put(revokeDocumentRequestSuccess());

    toast.success('Solicitado com sucesso');
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(DocumentFailure());
      toast.error('Falha ao revogar documento');
    }
  }
}

export function* cancelDocument() {
  const { refresh_token } = store.getState().auth;
  const { document } = store.getState().documents;

  try {
    const data = {
      meta: {
        your_number: document.yourNumber,
        client_id: document.institution.client_id,
      },
    };
    yield call(api.post, `/management/document/cancel`, data);
    yield put(cancelDocumentRequestSuccess());
    toast.success('Solicitado com sucesso');
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(DocumentFailure());
      toast.error('Falha ao cancelar documento');
    }
  }
}

export function* changeDocumentStatus({ payload }) {
  const { refresh_token } = store.getState().auth;
  const { document } = store.getState().documents;

  try {
    const { status } = payload;
    yield call(api.post, `/management/document/status/${document._id}`, {
      status,
    });
    yield put(updateStatusRequestSuccess(status));
    toast.success('Atualizado com sucesso');
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(DocumentFailure());
      toast.error('Falha ao atualizar status');
    }
  }
}

export function* downloadDocument() {
  const { refresh_token } = store.getState().auth;
  const { document } = store.getState().documents;

  try {
    const response = yield call(
      api.post,
      `/management/document/download`,
      {
        doc_type: document.docType,
        mime_type: document.mimeType,
        our_number: document.ourNumber,
      },
      { responseType: 'blob' }
    );

    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: response.headers['content-type'],
      })
    );

    const regexFile = /\D+\/\D+$/;
    const contentType = response.headers['content-type'];

    if (!regexFile.test(contentType)) {
      throw new Error('Error on response type');
    }

    const extension = contentType.split('/')[1];

    const link = window.document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${document.ourNumber}.${extension}`);
    window.document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

    yield put(downloadRequestSuccess());
    toast.success('Arquivo gerado');
  } catch (err) {
    if (err.response && err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(DocumentFailure());
      toast.error('Falha ao baixar documento');
    }
  }
}

export function* updateDocumentStatus({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, data, onSuccess } = payload;
    const response = yield call(api.post, `/management/document/${data}/${id}`);
    const document = response.data;

    yield put(updateDocumentSuccess(document));
    toast.success('Atualizado com sucesso');
    onSuccess && onSuccess();
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(DocumentFailure());
      Swal.fire(responseErrors(err.response, 'Falha ao atualizar documento'));
    }
  }
}
export function* updateCancel() {
  yield take('@documents/UPDATE_DOCUMENT_CANCEL');
}

export default all([
  takeLatest('@documents/GET_ALL_DOCUMENTS_REQUEST', getDocuments),
  takeLatest('@documents/GET_DOCUMENT_BY_ID_REQUEST', getDocumentById),
  takeLatest('@documents/UPDATE_DOCUMENT_REQUEST', updateDocumentStatus),
  takeLatest('@documents/REVOKE_REQUEST', revokeDocument),
  takeLatest('@documents/CANCEL_REQUEST', cancelDocument),
  takeLatest('@documents/UPDATE_STATUS_REQUEST', changeDocumentStatus),
  takeLatest('@documents/DOWNLOAD_REQUEST', downloadDocument),
]);
