import { produce } from 'immer';

const INITIAL_STATE = {
  token: null,
  refresh_token: null,
  signed: false,
  loading: false,
  updatingToken: false,
  permission: '',
  user: null,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/SIGN_IN_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@auth/SIGN_IN_SUCCESS': {
        draft.token = action.payload.token;
        draft.refresh_token = action.payload.refreshToken;
        draft.user = action.payload.user;
        draft.signed = true;
        draft.loading = false;
        break;
      }
      case '@auth/REFRESH_TOKEN_REQUEST': {
        // draft.loading = true;
        break;
      }
      case '@auth/REFRESH_TOKEN_SUCCESS': {
        draft.token = action.payload.token;
        draft.refresh_token = action.payload.refreshToken;
        draft.signed = true;
        draft.loading = false;
        draft.updatingToken = false;
        break;
      }
      case '@auth/UPDATE_REFRESH_TOKEN': {
        draft.token = action.payload.token;
        draft.refresh_token = action.payload.refreshToken;
        break;
      }
      case '@auth/UPDATING_TOKEN_START': {
        draft.updatingToken = true;
        break;
      }
      case '@auth/SIGN_FAILURE': {
        draft.loading = false;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.token = null;
        draft.permission = '';
        draft.signed = false;
        draft.loading = false;
        draft.updatingToken = false;
        draft.user = null;
        break;
      }
      case '@auth/TAKE_PERMISSION': {
        draft.permission = action.payload;
        break;
      }
      default:
    }
  });
}
