/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'shards-react';

import { FiArrowLeft, FiRefreshCcw } from 'react-icons/fi';

import PageTitle from '../../components/common/PageTitle';
import LoadingContainer from '../../components/layout/LodingContainer';

import { getTimestampLogById } from '../../store/modules/logs/actions';

export default function TimestampLogDetails() {
  const dispatch = useDispatch();
  const { id: selectedId } = useParams();
  const navigate = useNavigate();

  const { timestampLog, loading } = useSelector((state) => state.logs);

  const getTimestampLog = useCallback(() => {
    dispatch(getTimestampLogById(selectedId));
  }, [dispatch, selectedId]);

  useEffect(() => {
    getTimestampLog();
  }, [getTimestampLog, selectedId]);

  function handleBack() {
    navigate(-1);
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Registros de Carimbo"
          subtitle="Logs"
          className="text-sm-left"
        />
      </Row>
      {selectedId && (
        <Row className="mb-3">
          <Col md="6">
            <a
              onClick={handleBack}
              className="text-decoration-none h5 text-primary"
              style={{ cursor: 'pointer' }}
            >
              <span>
                <FiArrowLeft />
              </span>{' '}
              <span className="ml-1">Voltar</span>
            </a>
          </Col>
          <Col md="6" className="text-right">
            <Button disabled={loading} onClick={getTimestampLog}>
              <span className="text-white">
                <FiRefreshCcw />
              </span>{' '}
              Atualizar
            </Button>
          </Col>
        </Row>
      )}
      {/* Details */}
      {selectedId && timestampLog && (
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="mt-1 mr-3 mb-0">
                  Detalhes do registro de carimbo
                </h6>
              </CardHeader>
              <CardBody className="p-0 overflow-auto">
                {loading && <LoadingContainer loading={loading} />}
                {!loading && timestampLog._id && (
                  <>
                    <table className="table m-0">
                      <tbody>
                        <tr>
                          <td>Identificador</td>
                          <td>{timestampLog._id}</td>
                        </tr>
                        <tr data-tip="client_id">
                          <td>ID do cliente</td>
                          <td>
                            {timestampLog.signLog &&
                              timestampLog.signLog.client_id}
                          </td>
                        </tr>
                        <tr data-tip="provider">
                          <td>Provedor de carimbo</td>
                          <td>{timestampLog.provider}</td>
                        </tr>
                        <tr data-tip="timestampType">
                          <td>Tipo do carimbo</td>
                          <td>
                            {timestampLog.timestampType &&
                              timestampLog.timestampType.type}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <ReactTooltip />
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
}
