import styled, { css } from 'styled-components';

import { Spinner } from '../Loading/styles';

export const Container = styled.div`
  margin-top: 10px;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  ${Spinner} {
    height: 48px;
  }
  ${(props) =>
    props.loading &&
    css`
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    `}
`;
