import * as dateFns from 'date-fns';

export function getDifference(oldDate) {
  const now = new Date();
  const prevDate = dateFns.parseISO(oldDate);

  const seconds = dateFns.differenceInSeconds(now, prevDate);

  if (seconds < 60) {
    return { value: seconds, type: seconds > 1 ? 'segundos' : 'segundo' };
  }

  const minutes = dateFns.differenceInMinutes(now, prevDate);

  if (minutes < 60) {
    return { value: minutes, type: minutes > 1 ? 'minutos' : 'minuto' };
  }

  const hours = dateFns.differenceInHours(now, prevDate);

  if (hours < 24) {
    return { value: hours, type: hours > 1 ? 'horas' : 'hora' };
  }

  const days = dateFns.differenceInDays(now, prevDate);

  if (days < 30) {
    return { value: days, type: days > 1 ? 'dias' : 'dia' };
  }

  const months = dateFns.differenceInMonths(now, prevDate);

  if (months < 12) {
    return { value: months, type: months > 1 ? 'meses' : 'mês' };
  }

  const year = dateFns.differenceInYears(now, prevDate);
  return { value: year, type: year > 1 ? 'anos' : 'ano' };
}
