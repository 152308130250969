/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ButtonGroup,
  Button,
  Form,
  ListGroup,
  ListGroupItem,
  FormInput,
  FormSelect,
  FormGroup,
  InputGroup,
  InputGroupAddon,
} from 'shards-react';

import { toast } from 'react-toastify';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { FaRegTrashAlt, FaPlus, FaRegTimesCircle } from 'react-icons/fa';
import { FiZoomIn, FiCheckCircle, FiAlertCircle } from 'react-icons/fi';

import Swal from 'sweetalert2';
import SearchSelect from '../../components/common/SearchSelect';

import { useInput } from '../../hooks/input-hook';

import { InputWrapper, FilterWrapper } from '../common/styles';

import PageTitle from '../../components/common/PageTitle';
import LoadingContainer from '../../components/layout/LodingContainer';
import Pagination from '../../components/common/Pagination';

import { dateAndHour } from '../../utils/dateFormatter';

import { getAllSignersRequest } from '../../store/modules/signer/actions';
import * as SelectedData from '../../store/modules/selectedData/actions';
import {
  getAllPreSignersRequest,
  storePreSignerRequest,
  cancelPreSignerRequest,
  updatePreSignerRequest,
} from '../../store/modules/presigner/actions';

import { getAllCoursesRequest } from '../../store/modules/course/actions';
import { getAllSignatureItemsRequest } from '../../store/modules/signatureItem/actions';
import { getAllInstitutionsRequest } from '../../store/modules/institution/actions';
import { scrollToForm, scrollToTop } from '../../utils/scrolls';
import { extractCert, extractTextFromCer } from '../../utils/extractCert';
import './signer.css';
import CustomRadio from '../../components/common/CustomRadio';
import { CertLabelContent } from './style';

import { normalOrManager } from '../../config/UserPermission';
import { types, status } from '../../utils/preData';
import { minWidth } from '../../utils/breakPoint';

export default function PreSigners() {
  const dispatch = useDispatch();
  const institutions = useSelector((state) => state.institution.institutions);
  const sigItems = useSelector((state) => state.signatureItem.sigItems);
  const courses = useSelector((state) => state.course.courses);
  const {
    preSigners,
    total: totalPreSigners,
    loading,
    isSubmitting,
  } = useSelector((state) => state.presigner);
  const signers = useSelector((state) => state.signer.signers);
  const { user, permission } = useSelector((state) => state.auth);
  const selectedInstitution = useSelector(
    (state) => state.selectedData.selectedInstitution
  );
  const { institution } = user;

  const isNormalUser = normalOrManager.includes(permission);

  const {
    value: id,
    setValue: setId,
    bind: bindId,
    reset: resetId,
  } = useInput('');
  const {
    value: name,
    setValue: setName,
    bind: bindName,
    reset: resetName,
  } = useInput('');
  const {
    value: attrib,
    setValue: setAttrib,
    bind: bindAttrib,
    reset: resetAttrib,
  } = useInput('');
  const {
    value: certificate,
    setValue: setCertificate,
    bind: bindCertificate,
    reset: resetCertificate,
  } = useInput('');
  const {
    value: course,
    setValue: setCourse,
    bind: bindCourse,
    reset: resetCourse,
  } = useInput('');
  const { value: preSignerType, bind: bindPreSignerType } = useInput('create');

  const [filteredInstitution, setFilteredInstitution] =
    useState(selectedInstitution);
  const [sigItens, setSigItens] = useState([]);
  const [signersArray, setSignersArray] = useState([]);
  const [filteredSigners, setFilteredSigners] = useState([]);
  const [filteredPreSigners, setFilteredPreSigners] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [filteredSigItems, setFilteredSigItems] = useState([]);
  const [certificateDone, setCertificateDone] = useState(false);
  const [certificateType, setCertificateType] = useState('file');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [itemsSize, setItemsSize] = useState(10);
  const [selectedSigner, setSelectedSigner] = useState('');

  useEffect(() => {
    if (isNormalUser) {
      setFilteredInstitution(institution.id_institution.toString());
    } else {
      dispatch(getAllInstitutionsRequest());
    }
  }, []);

  useEffect(() => {
    setTotal(totalPreSigners);
    setFilteredPreSigners(preSigners);
  }, [preSigners]);

  useEffect(() => {
    setFilteredSigners(signers);
  }, [signers]);

  useEffect(() => {
    setFilteredCourses(courses);
  }, [courses]);

  useEffect(() => {
    setFilteredSigItems(sigItems);
  }, [sigItems]);

  useEffect(() => {
    if (filteredInstitution) {
      dispatch(
        getAllPreSignersRequest({
          limit: itemsSize,
          page,
          institutionId: filteredInstitution,
        })
      );
    }
  }, [page, itemsSize]);

  useEffect(() => {
    if (filteredInstitution) {
      setPage(1);
      dispatch(
        getAllPreSignersRequest({
          limit: itemsSize,
          page,
          institutionId: filteredInstitution,
        })
      );
    }
  }, [filteredInstitution]);

  useEffect(() => {
    handleFiltering();
  }, [filteredInstitution]);

  useEffect(() => {
    resetState();
  }, [preSignerType]);

  function handleInstitutionFilter(institution) {
    setFilteredInstitution(institution.value);
  }

  function handleFiltering() {
    const preSignersResult = preSigners.filter(
      (item) => item.status === 'waiting' || item.status === 'approved'
    );

    dispatch(SelectedData.selectInstitution(filteredInstitution));
    dispatch(getAllSignersRequest());
    dispatch(getAllCoursesRequest());
    dispatch(getAllSignatureItemsRequest());
    setFilteredPreSigners(preSignersResult);
  }

  function getSignerData(e, identifier) {
    e.preventDefault();
    const signerData = preSigners.find((item) => item._id === identifier);

    let historyHtml = `
    <tr>
      <td>Sem registros</td>
    </tr>
    `;
    if (signerData.history && signerData.history.length > 0) {
      historyHtml = signerData.history
        .map(
          (item) => `
      <tr>
        <td>${status[item.status]}</td>
        <td>${item.user.name}</td>
        <td>${dateAndHour(item.createdAt)}</td>
      </tr>
      `
        )
        .join('');
    }

    const html = `
    <table className="table mb-0 bg-primary">
                    <tbody>
                          <tr>
                            <td>Identificador</td>
                            <td>${signerData._id}</td>
                          </tr>
                          <tr>
                            <td>Nome</td>
                            <td>${signerData.name || '-'}</td>
                          </tr>
                          <tr>
                            <td>CPF/CNPJ</td>
                            <td>${signerData.id}</td>
                          </tr>
                          <tr>
                            <td>Atribuição</td>
                            <td>${signerData.attrib || '-'}</td>
                          </tr>
                          <tr>
                            <td>Certificado</td>
                            <td>
                            ${signerData.certificate || '-'}
                            </td>
                          </tr>
                          <tr>
                            <td>Tipo da solicitação</td>
                            <td>
                            ${types[signerData.type]}
                            </td>
                          </tr>
                          <tr>
                            <td>Estado da solicitação</td>
                            <td>
                            ${status[signerData.status]}
                            </td>
                          </tr>
                          <tr>
                            <td>Solicitante</td>
                            <td>
                            ${signerData.creator.name}
                            </td>
                          </tr>
                          <tr>
                            <td>Criação</td>
                            <td>
                            ${dateAndHour(signerData.createdAt)}
                            </td>
                          </tr>
                          <tr>
                            <td>Última atualização</td>
                            <td>
                            ${dateAndHour(signerData.updatedAt)}
                            </td>
                          </tr>
                          ${
                            signerData.maintainer
                              ? `
                          <tr>
                            <td>Responsável</td>
                            <td>
                            ${signerData.maintainer.name}
                            </td>
                          </tr>
                          `
                              : ''
                          }
                    </tbody>
    </table>
    <h2 class="swal2-title" id="swal2-title" style="display: flex;">Histórico</h2>
    <table className="table mb-0 bg-primary">
    <thead>
          <tr>
          <td>Status</td>
          <td>Responsável</td>
          <td>Data e hora</td>
          </tr>
          </thead>
          <tbody>
          ${historyHtml}
    </tbody>
    </table>
    `;

    Swal.fire({
      title: 'Detalhes do pré cadastro',
      icon: 'info',
      html,
      showCloseButton: false,
      showCancelButton: false,
      focusConfirm: true,
      customClass: {
        content: 'table',
        container: 'text-center',
      },
      width: minWidth(900) ? 900 : '100%',
    });
  }
  function resetState() {
    resetId();
    resetName();
    resetAttrib();
    resetCertificate();
    resetCourse();
    setCertificateDone(false);
    setSigItens([]);
    setSignersArray([]);
    setCertificateType('file');
    resetCertificateFile();
    setSelectedSigner(null);
  }
  function handleDeleteSigner(e, identifier) {
    const index = preSigners.findIndex((element) => element._id === identifier);
    dispatch(cancelPreSignerRequest(identifier, index));
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const signerSubstitutes = signersArray
      .filter((s) => {
        try {
          const item = JSON.parse(s);
          return item.type === 'signer';
        } catch (_) {
          return false;
        }
      })
      .map((item) => JSON.parse(item)._id);

    const preSignerSubstitutes = signersArray
      .filter((s) => {
        try {
          const item = JSON.parse(s);
          return item.type === 'preSigner';
        } catch (_) {
          return false;
        }
      })
      .map((item) => JSON.parse(item)._id);

    const obj = {
      id,
      type: preSignerType,
    };

    Object.assign(
      obj,
      name !== '' && { name },
      attrib !== '' && { attrib },
      certificate !== '' && { certificate },
      course !== '' && { courseGroupId: course },
      sigItens.length > 0 && sigItens[0] && { signItems: sigItens }
    );

    if (signerSubstitutes.length >= 1) {
      Object.assign(obj, {
        signerSubstitutes,
      });
    }
    if (preSignerSubstitutes.length >= 1) {
      Object.assign(obj, {
        preSignerSubstitutes,
      });
    }
    dispatch(
      storePreSignerRequest(obj, () => {
        scrollToTop();
        resetState();
      })
    );
  }

  function handleConfirmationToCancel(e, identifier, id) {
    Swal.fire({
      title: 'Confirme para cancelar',
      text: `Tem certeza que deseja cancelar a solicitação do assinador ${id}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      reverseButtons: true,
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        return handleDeleteSigner(e, identifier);
      }
      return false;
    });
  }

  function handleConfirmationToApproveOrReprove(e, identifier, id, action) {
    Swal.fire({
      title: `Confirme para ${action}`,
      text: `Tem certeza que deseja ${action} a solicitação do assinador ${id}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      reverseButtons: true,
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        return handleAproveOrReprove(identifier, action);
      }
      return false;
    });
  }

  function handleAproveOrReprove(identifier, action) {
    const mappedAction = action === 'aprovar' ? 'approve' : 'reprove';
    dispatch(
      updatePreSignerRequest(identifier, mappedAction, () => {
        scrollToTop();
      })
    );
  }

  async function handleCertificate() {
    const fileInput = document.getElementById('signerCertificate');
    const { value } = fileInput;
    if (!value) return;
    const extension = value
      .split(/(\\|\/)/g)
      .pop()
      .split('.')
      .pop();

    if (!['pfx', 'cer', 'p12'].includes(extension)) {
      resetCertificateFile();
      toast.error('Formato não suportado', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 'custom-id-yes',
      });
    }

    const file = fileInput.files[0];

    if (extension === 'pfx' || extension === 'p12') {
      const { value: password } = await Swal.fire({
        title: 'Digite a senha',
        input: 'password',
        inputLabel: 'Digite a senha do seu certificado',
        inputValue: '',
        showCancelButton: false,
        showCloseButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        focusCancel: false,
        focusConfirm: false,
        focusDeny: false,
        inputAutoFocus: true,
        // eslint-disable-next-line consistent-return
        preDeny: () => {
          if (!certificateDone) {
            return false;
          }
        },
        // eslint-disable-next-line consistent-return
        inputValidator: (value) => {
          if (!value) {
            return 'Você deve inserir uma senha';
          }
        },
        didClose: () => {
          scrollToForm();
        },
      });
      extractCert(file, password)
        .then(({ cert, data }) => {
          setCertificate(cert);
          if (data) {
            setName(data.name);
            setId(data.number);
          }
          setCertificateDone(true);
        })
        .catch(() => {
          setCertificateDone(false);
          resetCertificateFile();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Verifique a senha/certificado informado.',
          });
        });
    } else {
      extractTextFromCer(file)
        .then((response) => {
          setCertificate(response);
          setCertificateDone(true);
        })
        .catch((error) => {
          resetCertificateFile();
          setCertificateDone(false);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error || 'Verifique o certificado informado.',
          });
        });
    }
  }

  function resetCertificateFile() {
    const fileInput = document.getElementById('signerCertificate');
    if (fileInput) {
      fileInput.value = '';
      setCertificateDone(false);
    }
  }

  function addInput(e) {
    e.preventDefault();
    if (e.target.name === 'signer') {
      const auxSigner = [];
      if (signersArray !== undefined) {
        setSignersArray([...signersArray, undefined]);
      } else {
        auxSigner.push(undefined);
        setSignersArray(auxSigner);
      }
    } else {
      const auxSignerItems = [];
      if (sigItens !== undefined) {
        setSigItens([...sigItens, undefined]);
      } else {
        auxSignerItems.push(undefined);
        setSigItens(auxSignerItems);
      }
    }
  }
  async function handleDinamicInputChange(e, index) {
    e.preventDefault();
    const { value, name: targetName } = e.target;

    const aux_sigItems = [...sigItens];
    const aux_signers = [...signersArray];

    if (targetName === 'signer') {
      aux_signers[index] = value;
      setSignersArray(aux_signers);
    } else {
      aux_sigItems[index] = value;
      setSigItens(aux_sigItems);
    }
  }

  function handleSignerItemDelete(e, index) {
    e.preventDefault();
    if (e.target.name === 'signer') {
      const auxSigners = [...signersArray];

      auxSigners.splice(index, 1);

      setSignersArray(auxSigners);
    } else {
      const auxSignerItems = [...sigItens];

      auxSignerItems.splice(index, 1);

      setSigItens(auxSignerItems);
    }
  }

  // eslint-disable-next-line no-shadow
  function mapToBadge(status) {
    switch (status) {
      case 'approved':
        return <span className="badge badge-success">Aprovado</span>;
      case 'reproved':
        return <span className="badge badge-danger">Reprovado</span>;
      case 'waiting':
        return <span className="badge badge-warning">Aguardando</span>;
      case 'canceled':
        return <span className="badge badge-secondary">Cancelado</span>;
      default:
        return <span className="badge badge-secondary">status</span>;
    }
  }

  function handleSelectSigner({ value: _id }) {
    const findSigner = filteredSigners.find((s) => s._id === _id);
    if (!findSigner) return;

    setSelectedSigner(_id);
    setId(findSigner.id);

    if (preSignerType === 'update') {
      setName(findSigner.name);
      setAttrib(findSigner.attrib);
      setCertificate(findSigner.certificate);
      setCertificateType('text');
      if (findSigner.courseGroup) setCourse(findSigner.courseGroup._id);
      const signItemsIds = findSigner.signItems.map((item) => item._id);
      setSigItens(signItemsIds);

      const substitutesIds = findSigner.signerSubstitutes.map(
        (item) => item._id
      );
      setSignersArray(substitutesIds);
    }
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Pré Assinadores"
          subtitle="Gerenciamento"
          className="text-sm-left"
        />
      </Row>
      {/* Default Light Table */}
      {!isNormalUser && (
        <Row>
          <Col>
            <FilterWrapper>
              <Card small className="col-sm-6 mb-3">
                <CardHeader className="border-bottom">
                  <h6 className="m-0 font-weight-bold">
                    <FiAlertCircle className="mr-2 " />
                    Por favor, antes de prosseguir selecione uma instituição:
                  </h6>
                  <div className="col-sm-12">
                    <SearchSelect
                      onSelect={handleInstitutionFilter}
                      value={filteredInstitution}
                      options={
                        institutions &&
                        institutions.map((i) => ({
                          label: `${i.name} - ${i.initials}`,
                          value: i._id,
                        }))
                      }
                    />
                  </div>
                </CardHeader>
              </Card>
            </FilterWrapper>
          </Col>
        </Row>
      )}
      {/* Default Light Table */}
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0 font-weight-bold">Lista de pré assinadores</h6>
            </CardHeader>
            <CardBody className="p-0 pb-3 pl-3 pr-3 overflow-auto">
              {loading && <LoadingContainer loading={loading} />}
              {!loading && preSigners.length > 0 && (
                <>
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          #
                        </th>
                        <th scope="col" className="border-0">
                          Nome
                        </th>
                        <th scope="col" className="border-0">
                          CPF/CNPJ
                        </th>
                        <th scope="col" className="border-0">
                          Atribuição
                        </th>
                        <th scope="col" className="border-0">
                          Tipo da solicitação
                        </th>
                        <th scope="col" className="border-0">
                          Situação
                        </th>
                        <th scope="col" className="border-0">
                          Ações
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {preSigners &&
                        preSigners.map((s, index) => (
                          <tr key={s._id}>
                            <td>{s._id}</td>
                            <td>{s.name}</td>
                            <td>{s.id}</td>
                            <td>{s.attrib}</td>
                            <td>{types[s.type]}</td>
                            <td>{mapToBadge(s.status)}</td>
                            <td>
                              <ButtonGroup size="sm">
                                <Button
                                  theme="white"
                                  onClick={(e) =>
                                    getSignerData(e, s._id, index)
                                  }
                                >
                                  <span className="text-light">
                                    <FiZoomIn />
                                  </span>{' '}
                                  Detalhes
                                </Button>
                                {isNormalUser && s.status === 'waiting' && (
                                  <Button
                                    onClick={(e) =>
                                      handleConfirmationToCancel(e, s._id, s.id)
                                    }
                                    theme="white"
                                  >
                                    <span className="text-light">
                                      <FaRegTimesCircle />
                                    </span>{' '}
                                    Cancelar
                                  </Button>
                                )}
                                {permission !== `NORMAL_USER` &&
                                  s.status === 'waiting' && (
                                    <>
                                      <Button
                                        onClick={(e) =>
                                          handleConfirmationToApproveOrReprove(
                                            e,
                                            s._id,
                                            s.id,
                                            'aprovar'
                                          )
                                        }
                                        theme="white"
                                      >
                                        <span className="text-light">
                                          <FiCheckCircle />
                                        </span>{' '}
                                        Aprovar
                                      </Button>
                                      <Button
                                        onClick={(e) =>
                                          handleConfirmationToApproveOrReprove(
                                            e,
                                            s._id,
                                            s.name,
                                            'reprovar'
                                          )
                                        }
                                        theme="white"
                                      >
                                        <span className="text-light">
                                          <FaRegTimesCircle />
                                        </span>{' '}
                                        Reprovar
                                      </Button>
                                    </>
                                  )}
                              </ButtonGroup>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <Pagination
                    total={total}
                    page={page}
                    items={itemsSize}
                    onChangePage={(e) => {
                      scrollToTop(e);
                      setPage(e);
                    }}
                    onChangeItems={(e) => {
                      scrollToTop();
                      setItemsSize(e);
                    }}
                  />
                </>
              )}
              {!loading && preSigners.length === 0 && (
                <div className="col-sm-12 d-flex justify-content-center">
                  <h5 className="mt-3">
                    <FiAlertCircle className="mr-2" />
                    Não existem pré assinadores cadastrados.
                  </h5>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {isNormalUser && (
        <Row id="form-container">
          <Col>
            <Card className="mb-3" small>
              <CardHeader className="border-bottom">
                <h6 className="m-0 font-weight-bold">
                  Pré cadastro de assinador
                </h6>
              </CardHeader>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form onSubmit={handleSubmit} id="edit">
                        <Row form>
                          <Col md="4" className="form-group">
                            <InputWrapper>
                              <label htmlFor="preSignerType">
                                Tipo da solicitação
                              </label>
                              <FormSelect
                                id="preSignerType"
                                name="preSignerType"
                                required
                                {...bindPreSignerType}
                              >
                                <option value="create">Criação</option>
                                <option value="update">Atualização</option>
                                <option value="delete">Exclusão</option>
                              </FormSelect>
                            </InputWrapper>
                          </Col>
                          <Col md="4" className="form-group">
                            {preSignerType === 'create' && (
                              <InputWrapper>
                                <label htmlFor="signerCpf">CPF/CNPJ</label>
                                <FormInput
                                  id="signerCpf"
                                  name="id"
                                  type="text"
                                  placeholder="000.000.000-00 ou 99.999.999/9999-9"
                                  maxLength="14"
                                  required
                                  {...bindId}
                                />
                                <span className="text-muted">
                                  *Insira o CPF/CNPJ do assinador.
                                </span>
                              </InputWrapper>
                            )}
                            {preSignerType !== 'create' && (
                              <InputWrapper>
                                <label htmlFor="signerCpf">CPF/CNPJ</label>
                                <SearchSelect
                                  onSelect={handleSelectSigner}
                                  placeholder="Selecione um assinador"
                                  value={selectedSigner}
                                  options={
                                    filteredSigners &&
                                    filteredSigners.map((item) => ({
                                      label: `${item.name} - ${item.id}`,
                                      value: item._id,
                                    }))
                                  }
                                  styleContainer={{
                                    marginTop: '0',
                                  }}
                                />
                                <span className="text-muted">
                                  *Selecione o assinador
                                </span>
                              </InputWrapper>
                            )}
                          </Col>
                          {preSignerType !== 'delete' && (
                            <Col md="4" className="form-group">
                              <InputWrapper>
                                <label htmlFor="signerName">
                                  Nome do assinador
                                </label>
                                <FormInput
                                  name="name"
                                  id="signerName"
                                  type="text"
                                  placeholder="Assinador exemplo"
                                  required={preSignerType === 'create'}
                                  {...bindName}
                                />
                                <span className="text-muted">
                                  *Insira o nome completo da assinador.
                                </span>
                              </InputWrapper>
                            </Col>
                          )}
                        </Row>
                        {preSignerType !== 'delete' && (
                          <>
                            <Row form>
                              <Col md="6" className="form-group">
                                <InputWrapper>
                                  <label htmlFor="signerAtribution">
                                    Atribuição
                                  </label>
                                  <FormInput
                                    name="attrib"
                                    id="signerAtribution"
                                    type="text"
                                    placeholder="Professor"
                                    required={preSignerType === 'create'}
                                    {...bindAttrib}
                                  />
                                  <span className="text-muted">
                                    *Insira a atribuição do assinador.
                                  </span>
                                </InputWrapper>
                              </Col>
                              <Col md="6" className="form-group">
                                <InputWrapper>
                                  <CertLabelContent>
                                    <label htmlFor="signerCertificate">
                                      Certificado
                                    </label>
                                    <CustomRadio
                                      data={[
                                        { label: 'Arquivo', value: 'file' },
                                        { label: 'Texto', value: 'text' },
                                      ]}
                                      checked={certificateType}
                                      onChange={setCertificateType}
                                    />
                                  </CertLabelContent>
                                  {certificateType === 'file' && (
                                    <>
                                      <FormInput
                                        id="signerCertificate"
                                        name="certificate"
                                        type="file"
                                        accept=".pfx,.p12,.cer"
                                        placeholder="UFPB"
                                        onChange={handleCertificate}
                                      />
                                      <div id="doneCertificate">
                                        {certificateDone && (
                                          <button
                                            type="button"
                                            style={{
                                              outline: 0,
                                              border: 0,
                                              background: 'transparent',
                                            }}
                                            data-tip="Certificado válido"
                                            data-for="certificateInfo"
                                          >
                                            <FiCheckCircle
                                              size={20}
                                              className="mr-2"
                                            />
                                          </button>
                                        )}
                                      </div>
                                      <span className="text-muted">
                                        *Insira o certificado no formato .pfx,
                                        .p12 ou .cer
                                      </span>
                                    </>
                                  )}
                                  {certificateType === 'text' && (
                                    <>
                                      <FormInput
                                        id="signerCertificate"
                                        name="certificate"
                                        type="text"
                                        placeholder="UFPB"
                                        required={preSignerType === 'create'}
                                        {...bindCertificate}
                                      />
                                      <span className="text-muted">
                                        *Insira o certificado em base64
                                      </span>
                                    </>
                                  )}
                                </InputWrapper>
                              </Col>
                              <Col md="12" className="form-group">
                                <InputWrapper>
                                  <label htmlFor="course">Curso</label>
                                  <FormSelect
                                    id="course"
                                    name="course"
                                    required={preSignerType === 'create'}
                                    {...bindCourse}
                                  >
                                    <option value="" disabled>
                                      Escolher...
                                    </option>
                                    {filteredCourses &&
                                      filteredCourses.map((s) => (
                                        <option key={s._id} value={s._id}>
                                          {s.tag}
                                        </option>
                                      ))}
                                  </FormSelect>
                                  <span className="text-muted">
                                    *Selecione um assinador para o item de
                                    assinatura.
                                  </span>
                                </InputWrapper>
                              </Col>
                            </Row>
                            <Row form>
                              <Col md="6">
                                <FormGroup>
                                  <label
                                    className="text-dark"
                                    htmlFor="signatureItem"
                                  >
                                    Itens de assinatura
                                  </label>
                                  {sigItens !== undefined &&
                                    sigItens.length !== 0 &&
                                    sigItens.map((s, index) => (
                                      <InputWrapper
                                        key={index}
                                        className="mb-4"
                                      >
                                        <InputGroup className="mb-1">
                                          <FormSelect
                                            id="signatureItem"
                                            name="sigItens"
                                            value={
                                              s === undefined
                                                ? ''
                                                : s.toString()
                                            }
                                            required={
                                              preSignerType === 'create'
                                            }
                                            onChange={(e) =>
                                              handleDinamicInputChange(e, index)
                                            }
                                          >
                                            <option disabled value="">
                                              Escolher...
                                            </option>
                                            {filteredSigItems &&
                                              filteredSigItems.map((sig) => (
                                                <option
                                                  key={sig._id}
                                                  value={sig._id}
                                                >
                                                  {sig.name}
                                                </option>
                                              ))}
                                          </FormSelect>
                                          <InputGroupAddon type="append">
                                            <Button
                                              onClick={(e) =>
                                                handleSignerItemDelete(e, index)
                                              }
                                              theme="white"
                                            >
                                              <span className="text-light">
                                                <FaRegTrashAlt />
                                              </span>{' '}
                                              Excluir
                                            </Button>
                                          </InputGroupAddon>
                                        </InputGroup>
                                        <span className="text-muted">
                                          *Selecione o item de assinatura{' '}
                                          {index + 1}.
                                        </span>
                                      </InputWrapper>
                                    ))}
                                  {sigItens !== undefined &&
                                    sigItens.length === 0 && (
                                      <InputWrapper>
                                        <p className="m-2 text-secondary">
                                          Nenhum Item de assinatura foi
                                          adicionado
                                        </p>
                                      </InputWrapper>
                                    )}
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <FormGroup>
                                  <label
                                    className="text-dark"
                                    htmlFor="signerItem"
                                  >
                                    Assinadores substitutos
                                  </label>
                                  {signersArray !== undefined &&
                                    signersArray.length !== 0 &&
                                    signersArray.map((s, index) => (
                                      <InputWrapper
                                        key={index}
                                        className="mb-4"
                                      >
                                        <InputGroup className="mb-1">
                                          <FormSelect
                                            id="signerItem"
                                            name="signer"
                                            value={s || ''}
                                            required={
                                              signersArray.length !== 1 &&
                                              preSignerType === 'create'
                                            }
                                            onChange={(e) =>
                                              handleDinamicInputChange(e, index)
                                            }
                                          >
                                            <option value="" disabled>
                                              Escolher...
                                            </option>
                                            <optgroup label="Assinadores já cadastrados">
                                              {filteredSigners &&
                                                filteredSigners.map((sig) => (
                                                  <option
                                                    key={sig._id}
                                                    value={JSON.stringify({
                                                      type: 'signer',
                                                      _id: sig._id,
                                                    })}
                                                  >
                                                    {sig.name}
                                                  </option>
                                                ))}
                                            </optgroup>
                                            <optgroup label="Assinadores do pré cadastro">
                                              {filteredPreSigners &&
                                                filteredPreSigners.map(
                                                  (sig) => (
                                                    <option
                                                      key={sig._id}
                                                      value={JSON.stringify({
                                                        type: 'preSigner',
                                                        _id: sig._id,
                                                      })}
                                                    >
                                                      {sig.name}
                                                    </option>
                                                  )
                                                )}
                                            </optgroup>
                                          </FormSelect>
                                          <InputGroupAddon type="append">
                                            <Button
                                              name="signer"
                                              onClick={(e) =>
                                                handleSignerItemDelete(e, index)
                                              }
                                              theme="white"
                                            >
                                              <span className="text-light">
                                                <FaRegTrashAlt />
                                              </span>{' '}
                                              Excluir
                                            </Button>
                                          </InputGroupAddon>
                                        </InputGroup>
                                        <span className="text-muted">
                                          *Selecione o assinador substituto{' '}
                                          {index + 1}.
                                        </span>
                                      </InputWrapper>
                                    ))}
                                  {signersArray !== undefined &&
                                    signersArray.length === 0 && (
                                      <InputWrapper>
                                        <p className="m-2 text-secondary">
                                          Nenhum assinador substituto foi
                                          adicionado
                                        </p>
                                      </InputWrapper>
                                    )}
                                </FormGroup>
                              </Col>
                            </Row>
                          </>
                        )}
                        <Row>
                          <Col md="6" className="p-0">
                            {preSignerType !== 'delete' && (
                              <>
                                <Button
                                  name="signerItem"
                                  onClick={(e) => addInput(e)}
                                  theme="white"
                                  className="ml-3"
                                  disabled={isSubmitting}
                                >
                                  <span className="text-light">
                                    <FaPlus />
                                  </span>{' '}
                                  Adicionar Item de Assinatura
                                </Button>
                                <Button
                                  name="signer"
                                  onClick={(e) => addInput(e)}
                                  theme="white"
                                  className="ml-3"
                                  disabled={isSubmitting}
                                >
                                  <span className="text-light">
                                    <FaPlus />
                                  </span>{' '}
                                  Adicionar Assinador Substituto
                                </Button>
                              </>
                            )}
                          </Col>
                          <Col md="6" className="text-right">
                            <Button disabled={isSubmitting} type="submit">
                              {isSubmitting
                                ? 'Carregando...'
                                : 'Criar novo pré assinador'}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
          <ReactTooltip id="certificateInfo" />
        </Row>
      )}
    </Container>
  );
}
