import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ButtonGroup,
  Button,
  Form,
  ListGroup,
  ListGroupItem,
  FormInput,
  FormSelect,
} from 'shards-react';

import { FiZoomIn, FiAlertCircle, FiCheckCircle } from 'react-icons/fi';
import { FaRegTrashAlt, FaPlus, FaRegTimesCircle } from 'react-icons/fa';

import Swal from 'sweetalert2';
import { ButtonWrapper, InputWrapper, FilterWrapper } from '../common/styles';

import { useInput } from '../../hooks/input-hook';

import PageTitle from '../../components/common/PageTitle';
import LoadingContainer from '../../components/layout/LodingContainer';
import Pagination from '../../components/common/Pagination';

import SearchSelect from '../../components/common/SearchSelect';

import {
  getAllPreCoursesRequest,
  updatePreCourseRequest,
  cancelPreCourseRequest,
  storePreCourseRequest,
} from '../../store/modules/preCourse/actions';

import { getAllCoursesRequest } from '../../store/modules/course/actions';

import { ValueInput } from './styles';

import { getAllInstitutionsRequest } from '../../store/modules/institution/actions';
import * as SelectedData from '../../store/modules/selectedData/actions';

import { scrollToTop } from '../../utils/scrolls';

import { dateAndHour } from '../../utils/dateFormatter';

import { normalOrManager } from '../../config/UserPermission';
import { types, status } from '../../utils/preData';
import { minWidth } from '../../utils/breakPoint';

export default function PreCourse() {
  const dispatch = useDispatch();

  const institutions = useSelector((state) => state.institution.institutions);
  const courses = useSelector((state) => state.course.courses);

  const {
    preCourses,
    total: totalPreCourses,
    loading,
    isSubmitting,
  } = useSelector((state) => state.precourse);

  const selectedInstitution = useSelector(
    (state) => state.selectedData.selectedInstitution
  );

  const {
    value: tagDescription,
    setValue: setTagDescription,
    bind: bindTagDescription,
  } = useInput('');

  const {
    value: selectedCourse,
    setValue: setSelectedCourse,
    bind: bindSelectedCourse,
  } = useInput('');

  const { value: preCourseType, bind: bindPreCourseType } = useInput('create');

  const [filteredInstitution, setFilteredInstitution] =
    useState(selectedInstitution);

  const [courseItens, setCourseItens] = useState([{}]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [itemsSize, setItemsSize] = useState(10);

  const { user, permission } = useSelector((state) => state.auth);
  const { institution } = user;

  const isNormalUser = normalOrManager.includes(permission);

  /*eslint-disable */
  useEffect(() => {
    if (isNormalUser) {
      setFilteredInstitution(institution.id_institution.toString());
    } else {
      dispatch(getAllInstitutionsRequest());
    }
  }, [dispatch]);

  useEffect(() => {
    setTotal(totalPreCourses);
  }, [preCourses]);

  useEffect(() => {
    setFilteredCourses(courses);
  }, [courses]);

  useEffect(() => {
    if (filteredInstitution) {
      dispatch(
        getAllPreCoursesRequest({
          limit: itemsSize,
          page,
          institutionId: filteredInstitution,
        })
      );
    }
  }, [page, itemsSize]);

  useEffect(() => {
    if (filteredInstitution) {
      setPage(1);
      dispatch(
        getAllPreCoursesRequest({
          limit: itemsSize,
          page,
          institutionId: filteredInstitution,
        })
      );
    }
  }, [filteredInstitution]);

  useEffect(() => {
    handleFiltering();
  }, [preCourses, filteredInstitution]);

  useEffect(() => {
    if (selectedCourse !== "" && preCourseType === 'update') {
      const findCourse = courses.find(course => course._id === selectedCourse)
      setTagDescription(findCourse.tag);
      setCourseItens(findCourse.course);
    }
  }, [selectedCourse])

  useEffect(() => {
    resetState()
  }, [preCourseType])

  function resetState() {
    setTagDescription('');
    setCourseItens([{}]);
    setSelectedCourse('');
  }

  function getSignerData(e, identifier) {
    e.preventDefault();
    const courseData = preCourses.find(item => item._id === identifier);

    let historyHtml = `
    <tr>
      <td>Sem registros</td>
    </tr>
    `;
    if (courseData.history && courseData.history.length > 0) {
      historyHtml = courseData.history
        .map(
          item => `
      <tr>
        <td>${status[item.status]}</td>
        <td>${item.user.name}</td>
        <td>${dateAndHour(item.createdAt)}</td>
      </tr>
      `
        )
        .join('');
    }

    const html = `
    <table className="table mb-0 bg-primary">
                    <tbody>
                          <tr>
                            <td>Identificador</td>
                            <td>${courseData._id}</td>
                          </tr>
                          <tr>
                            <td>Tag de identificação</td>
                            <td>${courseData.tag || '-'}</td>
                          </tr>
                          <tr>
                            <td>Tipo da solicitação</td>
                            <td>
                            ${types[courseData.type]}
                            </td>
                          </tr>
                          <tr>
                            <td>Estado da solicitação</td>
                            <td>
                            ${status[courseData.status]}
                            </td>
                          </tr>
                          <tr>
                            <td>Solicitante</td>
                            <td>
                            ${courseData.creator.name}
                            </td>
                          </tr>
                          <tr>
                            <td>Criação</td>
                            <td>
                            ${dateAndHour(courseData.createdAt)}
                            </td>
                          </tr>
                          <tr>
                            <td>Última atualização</td>
                            <td>
                            ${dateAndHour(courseData.updatedAt)}
                            </td>
                          </tr>
                          ${courseData.maintainer
        ? `
                          <tr>
                            <td>Responsável</td>
                            <td>
                            ${courseData.maintainer.name}
                            </td>
                          </tr>
                          `
        : ''
      }
                    </tbody>
    </table>
    <h2 class="swal2-title" id="swal2-title" style="display: flex;">Histórico</h2>
    <table className="table mb-0 bg-primary">
    <thead>
          <tr>
          <td>Status</td>
          <td>Responsável</td>
          <td>Data e hora</td>
          </tr>
          </thead>
          <tbody>
          ${historyHtml}
    </tbody>
    </table>
    `;

    Swal.fire({
      title: 'Detalhes do pré cadastro',
      icon: 'info',
      html,
      showCloseButton: false,
      showCancelButton: false,
      focusConfirm: true,
      customClass: {
        content: 'table',
        container: 'text-center',
      },
      width: minWidth(900) ? 900 : '100%',
    });
  }

  function handleCancelPreCourse(e, identifier) {
    const index = preCourses.findIndex(element => element._id === identifier);
    dispatch(cancelPreCourseRequest(identifier, index));
  }

  function handleConfirmationToCancel(e, identifier, name) {
    Swal.fire({
      title: 'Confirme para cancelar',
      text: `Tem certeza que deseja cancelar a solicitação do grupo de curso ${name || 'sem identificador'}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      reverseButtons: true,
      cancelButtonText: 'Não',
    }).then(result => {
      if (result.isConfirmed) {
        return handleCancelPreCourse(e, identifier);
      }
      return false;
    });
  }

  function handleConfirmationToApproveOrReprove(e, id, action) {
    Swal.fire({
      title: `Confirme para ${action}`,
      text: `Tem certeza que deseja ${action} a solicitação do grupo de curso ${id}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      reverseButtons: true,
      cancelButtonText: 'Não',
    }).then(result => {
      if (result.isConfirmed) {
        return handleAproveOrReprove(id, action);
      }
      return false;
    });
  }

  function handleAproveOrReprove(identifier, action) {
    const mappedAction = action === 'aprovar' ? 'approve' : 'reprove';
    dispatch(
      updatePreCourseRequest(identifier, mappedAction, () => {
        scrollToTop();
      })
    );
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const obj = {
      type: preCourseType,
    };

    const findCourse = courses.find(course => course._id === selectedCourse)

    if (findCourse) {
      Object.assign(obj, {
        courseGroupId: parseInt(findCourse._id, 10)
      })
    }

    if (tagDescription !== "") {
      Object.assign(obj, {
        tag: tagDescription
      })
    }

    if (courseItens[0].course_name && courseItens[0].emec_code) {
      Object.assign(obj, {
        course: courseItens
      })
    }

    dispatch(
      storePreCourseRequest(obj, () => {
        scrollToTop();
        resetState();
      })
    );
  }

  function addInput(e) {
    e.preventDefault();
    const aux = [];
    if (courseItens !== undefined) {
      setCourseItens([...courseItens, {}]);
    } else {
      aux.push(undefined);
      courseItens(aux);
    }
  }

  function handleFiltering() {
    dispatch(SelectedData.selectInstitution(filteredInstitution));
    dispatch(getAllCoursesRequest());
  }

  function handleInstitutionFilter(institution) {
    setFilteredInstitution(institution.value);
  }

  async function handleCourseItensChange(e, index) {
    e.preventDefault();
    const { value, name: targetName } = e.target;
    const aux = _.cloneDeep(courseItens);

    if (targetName === 'course_name') {
      aux[index].course_name = value;
    } else {
      aux[index].emec_code = value;
    }
    setCourseItens(aux);
  }

  function handleCourseItensDelete(e, index) {
    e.preventDefault();
    const aux = [...courseItens];
    aux.splice(index, 1);
    setCourseItens(aux);
  }

  function mapToBadge(status) {
    switch (status) {
      case 'approved':
        return <span className="badge badge-success">Aprovado</span>;
      case 'reproved':
        return <span className="badge badge-danger">Reprovado</span>;
      case 'waiting':
        return <span className="badge badge-warning">Aguardando</span>;
      case 'canceled':
        return <span className="badge badge-secondary">Cancelado</span>;
      default:
        return <span className="badge badge-secondary">status</span>;
    }
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Pré Grupos de Cursos"
          subtitle="Gerenciamento"
          className="text-sm-left"
        />
      </Row>
      {/* Default Light Table */}
      {!isNormalUser && (
        <Row>
          <Col>
            <FilterWrapper>
              <Card small className="col-sm-6 mb-3">
                <CardHeader className="border-bottom">
                  <h6 className="m-0 font-weight-bold">
                    <FiAlertCircle className="mr-2 " />
                    Por favor, antes de prosseguir selecione uma instituição:
                  </h6>
                  <div className="col-sm-12">
                    <SearchSelect
                      onSelect={handleInstitutionFilter}
                      value={filteredInstitution}
                      options={
                        institutions &&
                        institutions.map(i => ({
                          label: `${i.name} - ${i.initials}`,
                          value: i._id,
                        }))
                      }
                    />
                  </div>
                </CardHeader>
              </Card>
            </FilterWrapper>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0 font-weight-bold">
                Lista de pré grupos de cursos
              </h6>
            </CardHeader>
            <CardBody className="p-0 pb-3 pl-3 pr-3 overflow-auto">
              {loading && <LoadingContainer loading={loading} />}
              {!loading && preCourses.length > 0 && (
                <>
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          #
                        </th>
                        <th scope="col" className="border-0">
                          Tag de Identificação
                        </th>
                        <th scope="col" className="border-0">
                          Instituição
                        </th>
                        <th scope="col" className="border-0">
                          Tipo da solicitação
                        </th>
                        <th scope="col" className="border-0">
                          Situação
                        </th>
                        <th scope="col" className="border-0">
                          Ações
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {preCourses &&
                        preCourses.map((s, index) => (
                          <tr key={s._id}>
                            <td>{s._id}</td>
                            <td>{s.tag}</td>
                            <td>{s.institution.name}</td>
                            <td>{types[s.type]}</td>
                            <td>{mapToBadge(s.status)}</td>
                            <td>
                              <ButtonGroup size="sm">
                                <Button
                                  theme="white"
                                  onClick={e => getSignerData(e, s._id, index)}
                                >
                                  <span className="text-light">
                                    <FiZoomIn />
                                  </span>{' '}
                                  Detalhes
                                </Button>
                                {isNormalUser && s.status === 'waiting' && (
                                  <Button
                                    onClick={e =>
                                      handleConfirmationToCancel(
                                        e,
                                        s._id,
                                        s.tag
                                      )
                                    }
                                    theme="white"
                                  >
                                    <span className="text-light">
                                      <FaRegTimesCircle />
                                    </span>{' '}
                                    Cancelar
                                  </Button>
                                )}
                                {permission !== `NORMAL_USER` &&
                                  s.status === 'waiting' && (
                                    <>
                                      <Button
                                        onClick={e =>
                                          handleConfirmationToApproveOrReprove(
                                            e,
                                            s._id,
                                            'aprovar'
                                          )
                                        }
                                        theme="white"
                                      >
                                        <span className="text-light">
                                          <FiCheckCircle />
                                        </span>{' '}
                                        Aprovar
                                      </Button>
                                      <Button
                                        onClick={e =>
                                          handleConfirmationToApproveOrReprove(
                                            e,
                                            s._id,
                                            'reprovar'
                                          )
                                        }
                                        theme="white"
                                      >
                                        <span className="text-light">
                                          <FaRegTimesCircle />
                                        </span>{' '}
                                        Reprovar
                                      </Button>
                                    </>
                                  )}
                              </ButtonGroup>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <Pagination
                    total={total}
                    page={page}
                    items={itemsSize}
                    onChangePage={e => {
                      scrollToTop(e);
                      setPage(e);
                    }}
                    onChangeItems={e => {
                      scrollToTop();
                      setItemsSize(e);
                    }}
                  />
                </>
              )}
              {!loading && preCourses.length === 0 && (
                <div className="col-sm-12 d-flex justify-content-center">
                  <h5 className="mt-3">
                    <FiAlertCircle className="mr-2" />
                    Não existem pré grupos de cursos cadastrados.
                  </h5>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {isNormalUser && (
        <Row id="form-container">
          <Col>
            <Card className="mb-3" small>
              <CardHeader className="border-bottom">
                <h6 className="m-0 font-weight-bold">
                  Pré cadastro de grupo de curso
                </h6>
              </CardHeader>

              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form onSubmit={handleSubmit} id="edit">
                        <Row form>
                          <Col md="4" className="form-group">
                            <InputWrapper>
                              <label htmlFor="preCourseType">
                                Tipo da solicitação
                              </label>
                              <FormSelect
                                id="preCourseType"
                                name="preCourseType"
                                required
                                {...bindPreCourseType}
                              >
                                <option value="create">Criação</option>
                                <option value="update">Atualização</option>
                                <option value="delete">Exclusão</option>
                              </FormSelect>
                            </InputWrapper>
                          </Col>
                          {preCourseType !== 'create' && (
                            <Col md="4" className="form-group">
                              <InputWrapper>
                                <label htmlFor="preCourseType">
                                  Selecione o grupo curso existente
                                </label>
                                <FormSelect
                                  id="preCourseType"
                                  name="preCourseType"
                                  required
                                  {...bindSelectedCourse}
                                >
                                  <option value="" disabled>
                                    Escolher...
                                  </option>
                                  {filteredCourses.map(course => <option value={course._id}>{course.tag}</option>)}
                                </FormSelect>
                              </InputWrapper>
                            </Col>
                          )}
                          {preCourseType !== 'delete' && (
                            <Col md="4" className="form-group">
                              <InputWrapper>
                                <label htmlFor="tagIdentifier">
                                  Nome do Grupo
                                </label>
                                <FormInput
                                  id="tagIdentifier"
                                  type="text"
                                  placeholder="Grupo exemplo"
                                  name="courseID"
                                  required
                                  {...bindTagDescription}
                                />
                                <span className="text-muted">
                                  *Insira o identificador dos cursos.
                                </span>
                              </InputWrapper>
                            </Col>
                          )}
                        </Row>
                        {preCourseType !== 'delete' &&
                          courseItens !== undefined &&
                          courseItens.length !== 0 &&
                          courseItens.map((v, index) => (
                            <Row form key={index}>
                              <Col md="4" className="form-group">
                                <InputWrapper>
                                  <label htmlFor="course_name">
                                    Nome do Curso
                                  </label>
                                  <FormInput
                                    id="course_name"
                                    type="text"
                                    placeholder="Curso exemplo"
                                    required
                                    value={
                                      v.course_name === undefined
                                        ? ''
                                        : v.course_name
                                    }
                                    name="course_name"
                                    onChange={e =>
                                      handleCourseItensChange(e, index)
                                    }
                                  />
                                  <span className="text-muted">
                                    *Nome do Curso
                                  </span>
                                </InputWrapper>
                              </Col>
                              <Col md="4" className="form-group">
                                <ValueInput>
                                  <InputWrapper>
                                    <FormInput
                                      id="emec_code"
                                      name="emec_code"
                                      value={
                                        v.emec_code === undefined
                                          ? ''
                                          : v.emec_code
                                      }
                                      required
                                      type="text"
                                      placeholder="1234"
                                      onChange={e =>
                                        handleCourseItensChange(e, index)
                                      }
                                      pattern="^[0-9]+$"
                                    />
                                    <span className="text-muted">
                                      *Código E-Mec
                                    </span>
                                  </InputWrapper>
                                </ValueInput>
                              </Col>
                              <Col md="4">
                                <ButtonWrapper>
                                  {index !== 0 && (
                                    <Button
                                      onClick={e =>
                                        handleCourseItensDelete(e, index)
                                      }
                                      theme="white"
                                    >
                                      <span className="text-light">
                                        <FaRegTrashAlt />
                                      </span>{' '}
                                      Excluir
                                    </Button>
                                  )}
                                </ButtonWrapper>
                              </Col>
                            </Row>
                          ))}
                        <Row className="mt-2">
                          <Col md="6" className="p-0">
                            {preCourseType !== 'delete' && (
                              <Button
                                onClick={e => addInput(e)}
                                theme="white"
                                className="ml-3"
                                disabled={isSubmitting}
                              >
                                <span className="text-light">
                                  <FaPlus />
                                </span>{' '}
                                Adicionar Curso
                              </Button>
                            )}
                          </Col>
                          <Col md="6" className="text-right">
                            <Button disabled={isSubmitting} type="submit">
                              {isSubmitting
                                ? 'Carregando...'
                                : preCourseType === 'create'
                                  ? 'Solicitar a criação do grupo de curso'
                                  : preCourseType === 'update'
                                    ? 'Solicitar a atualização do grupo de curso'
                                    : 'Solicitar a exclusão do grupo de curso'}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
}
