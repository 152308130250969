const INITIAL_STATE = {
  selectedInstitution: null,
  selectedPermissionType: null,
};

export default function selectedDate(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@selectedData/SELECTED_INSTITUTION':
      return { ...state, selectedInstitution: action.selectedInstitution };
    case '@selectedData/PERMISSION_TYPE':
      return {
        ...state,
        selectedPermissionType: action.selectedPermissionType,
      };
    default:
      return state;
  }
}
