import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
  Form,
  ListGroup,
  ListGroupItem,
  FormInput,
} from 'shards-react';

import { FaMagic } from 'react-icons/fa';
import { MdContentCopy } from 'react-icons/md';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { toast } from 'react-toastify';
import { useInput } from '../../hooks/input-hook';
import { InputWrapper } from '../common/styles';
import PageTitle from '../../components/common/PageTitle';
import LoadingContainer from '../../components/layout/LodingContainer';

import {
  getUserRequest,
  updateUserRequest,
} from '../../store/modules/profile/actions';

import { MessageWarning } from './styles';

import { generateHash } from '../../utils/generateHash';
import { copyToClipboard } from '../../utils/copyToClipboard';

export default function User() {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  const {
    loading,
    isSubmitting,
    user: userProfile,
  } = useSelector((state) => state.profile);

  const { value: name, setValue: setName, bind: bindName } = useInput('');
  const { value: email, setValue: setEmail, bind: bindEmail } = useInput('');
  const {
    value: password,
    setValue: setPassword,
    bind: bindPassword,
  } = useInput('');
  const { value: cpf, setValue: setCpf, bind: bindCpf } = useInput('');
  const { bind: bindInstitution } = useInput(user.institution.name);

  const [passwordMessage, setPasswordMessage] = useState();

  const getUserData = useCallback(() => {
    dispatch(getUserRequest());
    setPassword('');
  }, [dispatch, setPassword]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  useEffect(() => {
    if (userProfile) {
      setName(userProfile.name);
      setCpf(userProfile.cpf);
      setEmail(userProfile.email);
    }
  }, [setCpf, setEmail, setName, userProfile]);

  function handleUpdateUser(e) {
    e.preventDefault();
    if (!verifyField) {
      return;
    }
    const data = {
      name,
      email,
      password,
    };
    dispatch(updateUserRequest(data));
  }

  function verifyField() {
    if (password.length < 8) {
      setPasswordMessage('A senha deve ter no mínimo 8 caracteres');
      return false;
    }

    return true;
  }

  function handleGeneratePassword() {
    const newPassword = generateHash(10);
    setPassword(newPassword);
  }

  function handleCopyPassword() {
    if (password.trim() === '') {
      toast.error('Antes de copiar você deve gerar ou criar uma senha', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 'custom-id-yes',
      });
    }
    if (copyToClipboard(password)) {
      toast.info('Copiado!', {
        position: 'bottom-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 'custom-id-yes',
      });
    } else {
      toast.error(
        'Ocorreu um erro ao copiar, por gentileza, copie de forma manual.',
        {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: 'custom-id-yes',
        }
      );
    }
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Usuário"
          subtitle="Perfil do"
          className="text-sm-left"
        />
      </Row>
      {/* Default Light Table */}

      <Row id="form-container">
        <Col>
          <Card className="mb-3" small>
            <CardHeader className="border-bottom">
              <h6 className="m-0 font-weight-bold">Atualização de cadastro</h6>
            </CardHeader>
            {loading ? (
              <LoadingContainer loading={loading} />
            ) : (
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form onSubmit={handleUpdateUser} id="edit">
                        <Row form>
                          <Col md="6" className="form-group">
                            <InputWrapper>
                              <label htmlFor="userName">Nome</label>
                              <FormInput
                                value={name}
                                name="name"
                                id="userName"
                                type="text"
                                required
                                autoComplete="off"
                                {...bindName}
                              />
                              <span className="text-muted">
                                *Insira o nome completo do usuário.
                              </span>
                            </InputWrapper>
                          </Col>
                          <Col md="6" className="form-group">
                            <InputWrapper>
                              <label htmlFor="userCpf">CPF</label>
                              <FormInput
                                value={cpf}
                                name="cpf"
                                id="userCpf"
                                type="text"
                                maxLength="11"
                                pattern="([0-9]){11}"
                                required
                                disabled
                                {...bindCpf}
                              />
                            </InputWrapper>
                          </Col>
                          <Col md="6" className="form-group">
                            <InputWrapper>
                              <label htmlFor="userMail">Email</label>
                              <FormInput
                                value={email}
                                name="email"
                                id="userMail"
                                type="email"
                                required
                                {...bindEmail}
                              />
                              <span className="text-muted">
                                *Insira o email do usuário.
                              </span>
                            </InputWrapper>
                          </Col>
                          <Col md="6" className="form-group">
                            <InputWrapper>
                              <label htmlFor="userPassword">Nova senha</label>
                              <div className="d-flex">
                                <FormInput
                                  value={password}
                                  name="password"
                                  id="userPassword"
                                  type="text"
                                  autoComplete="new-password"
                                  minLength="8"
                                  {...bindPassword}
                                />
                                <Button
                                  data-tip="Gerar senha"
                                  data-for="generatePassword"
                                  className="bg-info btn-sm ml-2 border-0"
                                  onClick={handleGeneratePassword}
                                >
                                  <FaMagic className="text-white" />
                                </Button>
                                <Button
                                  data-tip="Copiar"
                                  data-for="copyPassword"
                                  className="bg-secondary btn-sm ml-2 border-0"
                                  onClick={handleCopyPassword}
                                >
                                  <MdContentCopy className="text-white" />
                                </Button>
                              </div>
                              {passwordMessage ? (
                                <MessageWarning>
                                  {passwordMessage}
                                </MessageWarning>
                              ) : (
                                <span className="text-muted">
                                  Nova senha, deixe em branco para manter a
                                  senha anterior.
                                </span>
                              )}
                            </InputWrapper>
                          </Col>
                          <Col md="6" className="form-group">
                            <InputWrapper>
                              <label htmlFor="signerInstitution">
                                Instituição
                              </label>
                              <FormInput
                                name="institutionId"
                                {...bindInstitution}
                                id="signerInstitution"
                                disabled
                              />
                            </InputWrapper>
                          </Col>
                        </Row>
                        <Button className="btn-primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Carregando...' : 'Atualizar'}
                        </Button>

                        <Button
                          className="btn btn-danger btn-sm ml-3"
                          onClick={getUserData}
                          disabled={isSubmitting}
                        >
                          Cancelar
                        </Button>
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            )}
          </Card>
        </Col>
        <ReactTooltip id="generatePassword" type="info" />
        <ReactTooltip id="copyPassword" />
      </Row>
    </Container>
  );
}
