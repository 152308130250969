import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import Loading from '../Loading';

export default function LodingContainer({ loading }) {
  return (
    <Container loading={loading}>
      <Loading />
    </Container>
  );
}

LodingContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
};
