/* eslint-disable react/prop-types */
import React from 'react';
import CustomRadio from '../CustomRadio';

export default function OptionsBox({ data = [], onChange, checked }) {
  return (
    <>
      <CustomRadio
        data={data}
        padding={[2, 45]}
        onChange={onChange}
        checked={checked}
      />
    </>
  );
}
