import { produce } from 'immer';

const INITIAL_STATE = {
  users: [],
  user: {},
  loading: false,
  isSubmitting: false,
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@user/GET_ALL_USERS_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@user/GET_ALL_USERS_SUCCESS': {
        draft.users = action.payload.users;
        draft.loading = false;
        break;
      }
      case '@user/GET_USER_BY_ID_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@user/GET_USER_BY_ID_SUCCESS': {
        draft.loading = false;
        draft.user = action.payload.user;
        draft.user.index = action.payload.index;
        break;
      }
      case '@user/STORE_USER_REQUEST': {
        draft.isSubmitting = true;
        break;
      }
      case '@user/STORE_USER_SUCCESS': {
        const aux = [...draft.users];
        aux.push(action.payload.user);
        draft.users = aux;
        draft.isSubmitting = false;
        break;
      }
      case '@user/UPDATE_USER_REQUEST': {
        draft.isSubmitting = true;
        break;
      }
      case '@user/UPDATE_USER_SUCCESS': {
        const { data } = action.payload;
        const aux = draft.users;
        const userItemIndex = state.users.findIndex((item) => {
          return item._id === data._id;
        });
        aux[userItemIndex] = data;
        draft.users = aux;
        draft.isSubmitting = false;
        break;
      }
      case '@user/UPDATE_USER_CANCEL': {
        draft.user = {};
        break;
      }
      case '@user/DELETE_USER_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@user/DELETE_USER_SUCCESS': {
        const aux = draft.users;
        aux.splice(action.payload.index, 1);
        draft.users = aux;
        draft.loading = false;
        break;
      }
      case '@user/USER_FAILURE': {
        draft.loading = false;
        draft.isSubmitting = false;
        break;
      }
      default:
    }
  });
}
