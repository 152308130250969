/**
 * Gera um hash com números e letras
 * @param {number} size
 * @returns {string}
 */
export function generateHash(size) {
  return Array(size)
    .fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz')
    .map((x) => x[Math.floor(Math.random() * x.length)])
    .join('');
}
