import React, { useReducer, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  FormInput,
  FormFeedback,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from 'shards-react';

import { MdLock, MdEmail } from 'react-icons/md';
import { Navigate, useLocation } from 'react-router-dom';
import { signInRequest } from '../../store/modules/auth/actions';
import logoImg from '../../assets/logo-text.png';
import { Container, Content } from './styles';
import { getPermission, minimumPermission } from '../../utils/permissionHelper';
import { Permissions } from '../../config/Constants';

function SignIn() {
  const [homePage, setHomePage] = useState();
  const dispatch = useDispatch();
  const location = useLocation();
  const { signed, permission, loading } = useSelector((state) => state.auth);

  useEffect(() => {
    if (signed) {
      const permissionValue = getPermission(permission);

      if (minimumPermission(Permissions.MAINTAINER, permissionValue)) {
        setHomePage('/institution');
      } else {
        setHomePage('/profile');
      }
    }
  }, [signed]);

  const [authInput, setAuthInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      email: '',
      password: '',
    }
  );

  function handleInputChange(e) {
    e.preventDefault();
    const { value, name } = e.target;

    setAuthInput({ [name]: value });
  }

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(signInRequest(authInput.email, authInput.password));
  }

  if (homePage)
    return <Navigate to={homePage} state={{ from: location }} replace />;

  return (
    <Container>
      <Content>
        <form onSubmit={handleSubmit}>
          <img src={logoImg} alt="Logo RAP" />
          <h1>Entre no Portal</h1>
          <Row form>
            <Col md="12" className="form-group">
              <InputGroup>
                <InputGroupAddon type="prepend">
                  <InputGroupText>
                    <MdEmail />
                  </InputGroupText>
                </InputGroupAddon>
                <FormInput
                  value={authInput.email}
                  name="email"
                  onChange={handleInputChange}
                  placeholder="Email"
                  type="email"
                  required
                />
                <FormFeedback invalid>
                  Endereço de email incorreto.
                </FormFeedback>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12" className="form-group">
              <InputGroup>
                <InputGroupAddon type="prepend">
                  <InputGroupText>
                    <MdLock />
                  </InputGroupText>
                </InputGroupAddon>
                <FormInput
                  value={authInput.password}
                  name="password"
                  onChange={handleInputChange}
                  placeholder="Senha"
                  type="password"
                  required
                />
                <FormFeedback invalid>Senha incorreta.</FormFeedback>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12" className="form-group">
              <Button theme="primary" className="mb-2 mr-1">
                {loading ? 'Loading...' : 'Entrar'}
              </Button>
            </Col>
          </Row>
        </form>
      </Content>
    </Container>
  );
}

export default SignIn;
