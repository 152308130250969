/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  FaFolder,
  FaFile,
  FaAngleLeft,
  FaCheck,
  FaTrash,
  FaDownload,
} from 'react-icons/fa';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Breadcrumb,
  BreadcrumbItem,
  FormCheckbox,
  Button,
} from 'shards-react';
import Swal from 'sweetalert2';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PageTitle from '../../components/common/PageTitle';
import LoadingContainer from '../../components/layout/LodingContainer';
import api from '../../services/api';
import {
  DirButton,
  Downloadutton,
  FolderButton,
  LoadingOverlay,
} from './style';
import { getDifference } from '../../utils/dateUtils';
import { scrollToTop } from '../../utils/scrolls';
import { responseErrors } from '../../utils/responseErros';
import { admin } from '../../config/UserPermission';

export default function Storage() {
  const [loading, setLoading] = useState(false);
  const [path, setPath] = useState(['~']);
  const [storageList, setStorageList] = useState({});
  const [all, setAll] = useState(false);
  const [filesLength, setFilesLength] = useState(0);
  const [selected, setSelected] = useState(0);
  const loaderRef = useRef(null);

  const { permission } = useSelector((state) => state.auth);

  const isAdmin = admin.includes(permission);

  const getFileName = useCallback(
    (file) => {
      if (file.split('/').length === 2) {
        return file.split('/').pop();
      }
      return file.replace(`${path.slice(1).join('/')}/`, '');
    },
    [path]
  );

  const resolvePath = useCallback(() => {
    const resolvePath = [...path];
    resolvePath.shift();

    return `${resolvePath.join('/')}/`;
  }, [path]);

  const getStorage = useCallback(
    async (isNext) => {
      setLoading(true);
      setSelected(0);
      try {
        const options = { pageSize: 20 };

        const resolvedPath = resolvePath();

        if (resolvedPath !== '/') {
          Object.assign(options, { path: resolvedPath });
        }

        if (isNext && storageList.nextToken)
          Object.assign(options, {
            continuationToken: storageList.nextToken,
          });

        const { data } = await api.post('remote-storage', options);

        const concatArray = [];

        data.folders.forEach((item) => {
          if (path.length === 1) {
            concatArray.push({
              name: item.folder.slice(0, -1),
              isFolder: true,
            });
          } else {
            concatArray.push({
              name: item.folder
                .replace(`${path.slice(1).join('/')}/`, '')
                .slice(0, -1),
              isFolder: true,
            });
          }
        });
        setFilesLength(data.files.length);
        data.files.forEach((item) => {
          concatArray.push({
            selected: false,
            name: getFileName(item.file),
            size: item.size,
            lastModified: item.lastModified,
            isFolder: false,
          });
        });

        concatArray.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase());

        if (!isNext) {
          setStorageList({
            list: concatArray,
            nextToken: data.nextToken,
          });
        } else {
          setStorageList((prev) => ({
            ...prev,
            list: [...prev.list, ...concatArray],
            nextToken: data.nextToken,
          }));
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Ocorreu um erro ao tentar listar!',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Tentar novamente',
          reverseButtons: true,
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            getStorage(isNext);
          }
        });
      }
      setLoading(false);
    },
    [getFileName, path, resolvePath, storageList.nextToken]
  );

  useEffect(() => {
    getStorage();
  }, [path]);

  useEffect(() => {
    const options = {
      root: null,
      ʋ: '20px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver((entities) => {
      const target = entities[0];

      if (target.isIntersecting && storageList.nextToken) {
        getStorage(true);
      }
    }, options);

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [getStorage, storageList.nextToken]);

  useEffect(() => {
    if (!storageList.list) {
      return setAll(false);
    }
    if (storageList.list.filter((item) => !item.isFolder).length === 0) {
      return setAll(false);
    }
    const selectedQuantity = storageList.list.reduce((value, item) => {
      if (!item.isFolder && item.selected) {
        return value + 1;
      }
      return value;
    }, 0);
    setAll(selectedQuantity === filesLength);
    return setSelected(selectedQuantity);
  }, [filesLength, storageList, storageList.list]);

  function intoFolder(folder) {
    // e.preventDefault();
    scrollToTop();
    setPath((prev) => [...prev, folder]);
  }

  function goToFolder(index) {
    scrollToTop();
    if (index === 0) {
      setPath(['~']);
    } else {
      setPath(path.slice(0, index + 1));
    }
  }

  function selectAll() {
    if (all) {
      return handleSelect(null, null, true);
    }

    return handleSelect(null, true);
  }

  function handleSelect(file, selectAll = false, removeSelect = false) {
    setStorageList((prev) => ({
      ...prev,
      list: prev.list.map((item) => {
        if (!item.isFolder) {
          if (selectAll || removeSelect) {
            return {
              ...item,
              selected: selectAll,
            };
          }
          if (item.name === file) {
            return {
              ...item,
              selected: !item.selected,
            };
          }
        }
        return item;
      }),
    }));
  }

  function handleDelete() {
    const makePlural = selected > 1 ? 's' : '';
    Swal.fire({
      title: 'Confirme para excluir',
      text: `Tem certeza que deseja remover o${makePlural} arquivo${makePlural} selecionado${makePlural}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const files = storageList.list.filter(
            (item) => !item.isFolder && item.selected
          );
          const pathResolve = resolvePath().split('/');
          pathResolve.shift();
          const resolvedPathFile = pathResolve.join('/');

          const names = files.map((item) => `${resolvedPathFile}${item.name}`);

          const { data } = await api.post('remote-storage/remove', {
            files: names,
          });

          return data;
        } catch (error) {
          const isPlural = selected > 1 ? 's' : '';

          return Swal.fire(
            responseErrors(
              error.response,
              `Ocorreu um erro ao excluir o${isPlural} arquivo${isPlural}`
            )
          );
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(({ value }) => {
      if (value) {
        const removed = value.totalRemoved;
        const isPlural = removed > 1 ? 's' : '';
        Swal.fire({
          title: 'Sucesso!',
          text: `${removed} arquivo${isPlural} excluído${isPlural} com sucesso`,
          icon: 'success',
        });
        handleSelect(null, null, true);
        getStorage();
      }
    });
  }

  async function downloadFile(filename) {
    try {
      setLoading(true);

      const pathResolve = resolvePath().split('/');
      pathResolve.shift();
      const resolvedPathFile = pathResolve.join('/');

      const path = `${resolvedPathFile}/${filename}`.slice(1);

      const response = await api.get('remote-storage/download', {
        params: { path },
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: response.headers['content-type'],
        })
      );

      const link = window.document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      window.document.body.appendChild(link);
      link.click();
      return link.parentNode.removeChild(link);
    } catch (error) {
      return toast.error(`Ocorreu um erro ao baixar o arquivo`);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Armazenamento"
          subtitle="Gestão de registros"
          className="text-sm-left"
        />
      </Row>

      {/* Default Light Table */}
      <Row>
        <Col>
          <Card small className="mb-5">
            <CardHeader className="pb-0">
              <Breadcrumb>
                {path.map((item, index) => {
                  if (path.length === index + 1)
                    return (
                      <BreadcrumbItem key={item} active>
                        {item}
                      </BreadcrumbItem>
                    );
                  return (
                    <BreadcrumbItem key={item}>
                      <DirButton onClick={() => goToFolder(index)}>
                        {item}
                      </DirButton>
                    </BreadcrumbItem>
                  );
                })}
                {selected > 0 && isAdmin && (
                  <div className="text-dark ml-auto">
                    {selected} arquivo{selected > 1 && 's'} selecionado
                    {selected > 1 && 's'}
                    <Button
                      data-tip={`Excluir arquivo${selected > 1 ? 's' : ''}`}
                      data-for="delete-buttom"
                      className="ml-2"
                      outline
                      pill
                      theme="danger"
                      onClick={handleDelete}
                    >
                      <FaTrash />
                    </Button>
                    <ReactTooltip id="delete-buttom" effect="solid" />
                  </div>
                )}
              </Breadcrumb>
            </CardHeader>
            <CardBody
              style={{ position: 'relative' }}
              className="p-0 pb-3 pl-3 pr-3 overflow-auto"
            >
              <>
                {loading && (
                  <LoadingOverlay>
                    <LoadingContainer loading />
                  </LoadingOverlay>
                )}
                <table className="table mb-0 table-hover">
                  <thead>
                    <tr>
                      {isAdmin && (
                        <th
                          scope="col"
                          className="border-0"
                          colSpan={1}
                          style={{ width: '5px' }}
                        >
                          <div
                            data-tip={all ? 'Desmarcar todos' : 'Marcar todos'}
                            data-for="select-all"
                            style={{ position: 'absolute', top: '10px' }}
                          >
                            <FormCheckbox checked={all} onChange={selectAll} />
                            <ReactTooltip id="select-all" effect="solid" />
                          </div>
                        </th>
                      )}
                      <th scope="col" className="border-0">
                        Nome
                      </th>
                      <th scope="col" className="border-0">
                        Tamanho
                      </th>
                      <th scope="col" className="border-0">
                        Última modificação
                      </th>
                      <th scope="col" className="border-0">
                        Ações
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {path.length > 1 && (
                      <tr>
                        <td />
                        <td>
                          <FolderButton
                            type="button"
                            onClick={() => goToFolder(path.length - 2)}
                          >
                            <FaAngleLeft /> ...
                          </FolderButton>
                        </td>
                        <td />
                        <td />
                      </tr>
                    )}
                    {storageList.list &&
                      storageList.list.map((i) => {
                        if (i.isFolder) {
                          return (
                            <tr key={i.name}>
                              {isAdmin && (
                                <td>
                                  <FormCheckbox checked={false} disabled />
                                </td>
                              )}
                              <td>
                                <FolderButton
                                  type="button"
                                  onClick={() => intoFolder(i.name)}
                                >
                                  <FaFolder color="#F7C327" /> {i.name}
                                </FolderButton>
                              </td>
                              <td />
                              <td />
                              <td />
                            </tr>
                          );
                        }
                        return (
                          <tr key={i.name}>
                            {isAdmin && (
                              <td>
                                <FormCheckbox
                                  checked={i.selected}
                                  onChange={() => handleSelect(i.name)}
                                />
                              </td>
                            )}
                            <td>
                              <FaFile color="#4687FA" /> {i.name}
                            </td>
                            <td>{i.size}</td>
                            <td>
                              {i.lastModified &&
                                getDifference(i.lastModified).value}{' '}
                              {i.lastModified &&
                                getDifference(i.lastModified).type}
                              {i.lastModified && ` atrás`}
                            </td>
                            <td>
                              <Downloadutton
                                type="button"
                                onClick={() => downloadFile(i.name)}
                              >
                                <FaDownload color="#4687FA" />
                              </Downloadutton>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <Row className="mt-4">
                  <p ref={loaderRef} />
                  <Col className="text-center">
                    {storageList.list && !storageList.nextToken && (
                      <h6>
                        <FaCheck /> Sem mais resultados para exibir
                      </h6>
                    )}
                  </Col>
                </Row>
              </>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
