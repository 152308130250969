import { all, takeLatest, call, put, take } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { responseErrors } from '../../../utils/responseErros';
import api from '../../../services/api';
import {
  getAllPreSignersSuccess,
  getPreSignerByIdSuccess,
  storePreSignerSuccess,
  updatePreSignerSuccess,
  deletePreSignerSuccess,
  preSignerFailure,
} from './actions';
import { refreshTokenRequest } from '../auth/actions';
import { store } from '../../index';

export function* getPreSigners({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { limit = 10, page = 1, institutionId } = payload;
    const response = yield call(
      api.get,
      `/management/pre-signers?limit=${limit}&page=${page}&institutionId=${institutionId}`
    );
    const preSigners = response.data;
    yield put(getAllPreSignersSuccess(preSigners));
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(preSignerFailure());
      toast.error(`Falha ao listar assinadores`);
    }
  }
}
export function* getPreSignerById({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, index } = payload;
    const response = yield call(api.get, `/management/signers/${id}`);
    const signer = response.data;

    yield put(getPreSignerByIdSuccess(signer, index));
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(preSignerFailure());
      toast.error('Falha ao obter dados do assinador');
    }
  }
}
export function* storePreSigner({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { data, onSuccess } = payload;

    const response = yield call(api.post, '/management/pre-signers', data);
    const signer = response.data;

    yield put(storePreSignerSuccess(signer));
    toast.success('Assinador criado com sucesso');
    onSuccess && onSuccess();
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(preSignerFailure());
      Swal.fire(responseErrors(err.response, 'Falha ao criar assinador'));
    }
  }
}

export function* updatePreSigner({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, data, onSuccess } = payload;
    const response = yield call(
      api.post,
      `/management/pre-signers/${data}/${id}`
    );
    const signer = response.data;

    yield put(updatePreSignerSuccess(signer));
    toast.success('Pré assinador atualizado com sucesso');
    onSuccess && onSuccess();
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(preSignerFailure());
      Swal.fire(
        responseErrors(err.response, 'Falha ao atualizar pré assinador')
      );
    }
  }
}
export function* updateCancel() {
  yield take('@presigner/UPDATE_PRE_SIGNER_CANCEL');
}

export function* cancelDeleteSigner({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, index } = payload;
    const response = yield call(
      api.post,
      `/management/pre-signers/cancel/${id}`
    );
    const signer = response.data;
    yield put(deletePreSignerSuccess(signer, index));
    toast.success('Pré assinador cancelado com sucesso');
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(preSignerFailure());
      toast.error('Falha ao cancelar pré assinador');
    }
  }
}

export default all([
  takeLatest('@presigner/GET_ALL_PRE_SIGNERS_REQUEST', getPreSigners),
  takeLatest('@presigner/GET_PRE_SIGNER_BY_ID_REQUEST', getPreSignerById),
  takeLatest('@presigner/STORE_PRE_SIGNER_REQUEST', storePreSigner),
  takeLatest('@presigner/UPDATE_PRE_SIGNER_REQUEST', updatePreSigner),
  takeLatest('@presigner/DELETE_PRE_SIGNER_REQUEST', cancelDeleteSigner),
]);
