import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Row, Col, Card, CardHeader, CardBody } from 'shards-react';

import { FiAlertCircle } from 'react-icons/fi';

import PageTitle from '../../components/common/PageTitle';
import LoadingContainer from '../../components/layout/LodingContainer';

import { date } from '../../utils/dateFormatter';

import { getAllDDRConfigRequest } from '../../store/modules/DDRConfig/actions';

export default function XSDValidity() {
  const dispatch = useDispatch();

  const { ddrConfigs, loading } = useSelector((state) => state.ddrconfig);

  useEffect(() => {
    dispatch(getAllDDRConfigRequest());
  }, [dispatch]);

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Vigência dos XSDs"
          subtitle="Dados do Sistema"
          className="text-sm-left"
        />
      </Row>

      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0 font-weight-bold">
                Lista de XSDs cadastrados
              </h6>
            </CardHeader>
            <CardBody className="p-0 pb-3 pl-3 pr-3 overflow-auto">
              {loading ? (
                <LoadingContainer loading={loading} />
              ) : (
                <>
                  {ddrConfigs.length !== 0 ? (
                    <table className="table mb-0">
                      <thead className="bg-light">
                        <tr>
                          <th scope="col" className="border-0">
                            #
                          </th>
                          <th scope="col" className="border-0">
                            Tipo do documento
                          </th>
                          <th scope="col" className="border-0">
                            Versão
                          </th>
                          <th scope="col" className="border-0">
                            Data inicial
                          </th>
                          <th scope="col" className="border-0">
                            Data final
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {ddrConfigs &&
                          ddrConfigs.map((d, index) => (
                            <tr key={index}>
                              <td>{index}</td>
                              <td>{d.doc_type}</td>
                              <td>{d.version}</td>
                              <td>{date(d.startLimit)}</td>
                              <td>{date(d.finalLimit)}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="col-sm-12 d-flex justify-content-center">
                      <h5 className="mt-3">
                        <FiAlertCircle className="mr-2" />
                        Não existem XDSs cadastradas.
                      </h5>
                    </div>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
