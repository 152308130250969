/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  ButtonGroup,
} from 'shards-react';

import {
  FiAlertCircle,
  FiArrowLeft,
  FiRefreshCcw,
  FiZoomIn,
} from 'react-icons/fi';

import Swal from 'sweetalert2';

import { AiOutlineClose } from 'react-icons/ai';
import { dateAndHour } from '../../utils/dateFormatter';

import PageTitle from '../../components/common/PageTitle';
import LoadingContainer from '../../components/layout/LodingContainer';

import {
  cancelBatchRequest,
  closeBatchRequest,
  getBatchByIdRequest,
} from '../../store/modules/batchRegistry/actions';
import { batchRegistriesStatus } from '../../config/Constants';

export default function BatchDetails() {
  const dispatch = useDispatch();
  const { id: selectedId } = useParams();
  const navigate = useNavigate();

  const { batch, loading } = useSelector((state) => state.batchRegistry);

  const getBatch = useCallback(() => {
    dispatch(getBatchByIdRequest(selectedId));
  }, [dispatch, selectedId]);

  useEffect(() => {
    getBatch();
  }, [getBatch, selectedId]);

  function handleBack() {
    navigate(-1);
  }

  function gotToRegistry(id) {
    navigate(`/documents/${id}`);
  }

  function handleCancel() {
    Swal.fire({
      title: 'Confirme para cancelar',
      text: `Tem certeza que deseja cancelar o lote?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      reverseButtons: true,
      cancelButtonText: 'Voltar',
    }).then((result) => {
      if (result.isConfirmed) {
        return dispatch(cancelBatchRequest());
      }
      return false;
    });
  }

  function handleClose() {
    Swal.fire({
      title: 'Confirme para fechar',
      text: `Tem certeza que deseja fechar o lote?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      reverseButtons: true,
      cancelButtonText: 'Voltar',
    }).then((result) => {
      if (result.isConfirmed) {
        return dispatch(closeBatchRequest());
      }
      return false;
    });
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Gerenciar lotes"
          subtitle="Gerenciamento"
          className="text-sm-left"
        />
      </Row>
      {selectedId && (
        <Row className="mb-3">
          <Col md="6">
            <a
              onClick={handleBack}
              className="text-decoration-none h5 text-primary"
              style={{ cursor: 'pointer' }}
            >
              <span>
                <FiArrowLeft />
              </span>{' '}
              <span className="ml-1">Voltar</span>
            </a>
          </Col>
          <Col md="6" className="text-right">
            <Button disabled={loading} onClick={getBatch}>
              <span className="text-white">
                <FiRefreshCcw />
              </span>{' '}
              Atualizar
            </Button>
          </Col>
        </Row>
      )}
      {/* Details */}
      {selectedId && batch && (
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="mt-1 mr-3 mb-0">Detalhes do lote</h6>
              </CardHeader>
              <CardBody className="p-0 overflow-auto">
                {loading && <LoadingContainer loading={loading} />}
                {!loading && batch._id && (
                  <>
                    <table className="table m-0">
                      <tbody>
                        <tr>
                          <td>Identificador</td>
                          <td>{batch._id}</td>
                        </tr>
                        <tr>
                          <td>
                            <span>Identificador alternativo</span>
                          </td>
                          <td>{batch.alternativeId}</td>
                        </tr>
                        <tr>
                          <td>Nome</td>
                          <td>{batch.name}</td>
                        </tr>
                        <tr>
                          <td>Status</td>
                          <td>
                            {batchRegistriesStatus.find(
                              (item) => item.value === batch.statusId
                            )?.label ?? batch.state}
                          </td>
                        </tr>
                        <tr>
                          <td>Data de criação</td>
                          <td>
                            {batch.createdAt
                              ? dateAndHour(batch.createdAt)
                              : 'não disponível'}
                          </td>
                        </tr>
                        <tr>
                          <td>Data de última modificação</td>
                          <td>
                            {batch.updatedAt
                              ? dateAndHour(batch.updatedAt)
                              : 'não disponível'}
                          </td>
                        </tr>
                        {batch.statusId === 1 && (
                          <tr>
                            <td>Ações sobre o registro</td>
                            <td>
                              <Button
                                className="mr-1"
                                theme="warning"
                                onClick={handleClose}
                              >
                                <span>
                                  <FiRefreshCcw />
                                </span>{' '}
                                Fechar lote
                              </Button>
                              <Button
                                className="mr-1"
                                theme="danger"
                                onClick={handleCancel}
                              >
                                <span className="text-white">
                                  <AiOutlineClose />
                                </span>{' '}
                                Cancelar lote
                              </Button>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}

      {/* Registries */}
      {selectedId && batch.registries && !loading && (
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="mt-1 mr-3 mb-0">Registros do lote</h6>
              </CardHeader>
              <CardBody className="p-0 overflow-auto">
                {batch.registries.length === 0 && (
                  <div className="col-sm-12 d-flex justify-content-center">
                    <h5 className="mt-3">
                      <FiAlertCircle className="mr-2" />
                      Não existem registros cadastrados para o lote.
                    </h5>
                  </div>
                )}
                {batch.registries.length > 0 && (
                  <>
                    <table className="table m-0">
                      <thead className="bg-light">
                        <tr>
                          <th scope="col" className="border-0">
                            #
                          </th>
                          <th scope="col" className="border-0">
                            ID do Documento
                          </th>
                          <th scope="col" className="border-0">
                            Criado em
                          </th>
                          <th scope="col" className="border-0">
                            Atualizado em
                          </th>
                          <th>Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        {batch.registries.map((registry) => (
                          <tr key={registry._id}>
                            <td>{registry._id}</td>
                            <td>{registry.documentId}</td>
                            <td>
                              {registry.createdAt
                                ? dateAndHour(registry.createdAt)
                                : 'não disponível'}
                            </td>
                            <td>
                              {registry.updatedAt
                                ? dateAndHour(registry.updatedAt)
                                : 'não disponível'}
                            </td>
                            <td>
                              <ButtonGroup size="sm">
                                <Button
                                  theme="white"
                                  onClick={() =>
                                    gotToRegistry(registry.documentId)
                                  }
                                >
                                  <span className="text-light">
                                    <FiZoomIn />
                                  </span>{' '}
                                  Acessar documento
                                </Button>
                              </ButtonGroup>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
}
