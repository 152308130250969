import iwanthue from 'iwanthue';
import presets from 'iwanthue/presets';

export const generateColors = (length) => {
  const colors = iwanthue(length, {
    colorSpace: presets.pimp,
    quality: 100,
  });

  return colors;
};
