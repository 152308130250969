import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { responseErrors } from '../../../utils/responseErros';
import api from '../../../services/api';
import {
  getAllSignatureConfigsSuccess,
  getSignatureConfigByIdSuccess,
  storeSignatureConfigSuccess,
  updateSignatureConfigSuccess,
  deleteSignatureConfigSuccess,
  signatureConfigFailure,
} from './actions';
import { refreshTokenRequest } from '../auth/actions';
import { store } from '../../index';

export function* getSignConfigs() {
  const { refresh_token } = store.getState().auth;
  const { selectedInstitution } = store.getState().selectedData;

  try {
    const response = yield call((url) => {
      return api.get(url, {
        params: {
          institutionId: selectedInstitution,
        },
      });
    }, '/management/sigconfigs');
    const sigconfigs = response.data;
    yield put(getAllSignatureConfigsSuccess(sigconfigs));
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(signatureConfigFailure());
      toast.error(`Falha ao listar configurações de assinatura`);
    }
  }
}
export function* getSignConfigById({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, index } = payload;
    const response = yield call(api.get, `/management/sigconfigs/${id}`);
    const sigconfig = response.data;

    yield put(getSignatureConfigByIdSuccess(sigconfig, index));
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(signatureConfigFailure());
      toast.error('Falha ao obter dados da configuração de assinatura');
    }
  }
}
export function* storeSignConfig({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { data, onSuccess } = payload;
    const response = yield call(api.post, '/management/sigconfigs', data);
    const sigconfig = response.data;

    yield put(storeSignatureConfigSuccess(sigconfig));
    toast.success('Configuração de assinatura criada com sucesso');
    onSuccess && onSuccess();
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(signatureConfigFailure());
      Swal.fire(
        responseErrors(
          err.response,
          'Falha ao criar configuração de assinatura'
        )
      );
    }
  }
}

export function* updateSignConfig({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, data, index, onSuccess } = payload;
    const response = yield call(
      api.patch,
      `/management/sigconfigs/${id}`,
      data
    );
    const tag = response.data;

    yield put(updateSignatureConfigSuccess(index, tag));
    toast.success('Configuração de assinatura atualizada com sucesso');
    onSuccess && onSuccess();
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(signatureConfigFailure());
      Swal.fire(
        responseErrors(
          err.response,
          'Falha ao atualizar configuração de assinatura'
        )
      );
    }
  }
}

export function* deleteSignConfig({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, index } = payload;
    yield call(api.delete, `/management/sigconfigs/${id}`);

    yield put(deleteSignatureConfigSuccess(index));
    toast.success('Configuração de assinatura excluído com sucesso');
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(signatureConfigFailure());
      toast.error('Falha ao excluir configuração de assinatura');
    }
  }
}

export default all([
  takeLatest('@signconfig/GET_ALL_SIGNCONFIGS_REQUEST', getSignConfigs),
  takeLatest('@signconfig/GET_SIGNCONFIG_BY_ID_REQUEST', getSignConfigById),
  takeLatest('@signconfig/STORE_SIGNCONFIG_REQUEST', storeSignConfig),
  takeLatest('@signconfig/UPDATE_SIGNCONFIG_REQUEST', updateSignConfig),
  takeLatest('@signconfig/DELETE_SIGNCONFIG_REQUEST', deleteSignConfig),
]);
