import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import * as dateFns from 'date-fns';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ButtonGroup,
  Button,
  FormInput,
  FormCheckbox,
  Collapse,
  ListGroup,
  ListGroupItem,
  Form,
  FormSelect,
} from 'shards-react';

import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { FiZoomIn, FiAlertCircle } from 'react-icons/fi';

import { InputWrapper } from '../common/styles';

import PageTitle from '../../components/common/PageTitle';
import LoadingContainer from '../../components/layout/LodingContainer';
import Pagination from '../../components/common/Pagination';
import ErrorBox from '../../components/common/ErrorBox';

import SearchSelect from '../../components/common/SearchSelect';

import {
  getAllDocumentsRequest,
  setGetLastDocuments,
  setSearchQuery,
} from '../../store/modules/documents/actions';

import { getAllInstitutionsRequest } from '../../store/modules/institution/actions';
import * as SelectedData from '../../store/modules/selectedData/actions';

import { scrollToTop, scrollMore } from '../../utils/scrolls';

import { normalOrManager } from '../../config/UserPermission';
import { documentStatus } from '../../utils/documentStatus';
import { useInput } from '../../hooks/input-hook';
import { dateAndHour } from '../../utils/dateFormatter';
import { documentTypes } from '../../config/Constants';

export default function Documents() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const institutions = useSelector((state) => state.institution.institutions);

  const {
    documents,
    total: totalDocuments,
    loading,
    getLast,
    search: searchState,
  } = useSelector((state) => state.documents);

  const selectedInstitution = useSelector(
    (state) => state.selectedData.selectedInstitution
  );

  const [filteredInstitution, setFilteredInstitution] =
    useState(selectedInstitution);

  const { value: ourNumber, setValue: setOurNumber } = useInput('');
  const { value: initialDate, setValue: setInitialDate } = useInput('');
  const { value: finalDate, setValue: setFinalDate } = useInput('');
  const { value: docTypeId, setValue: setDocTypeId } = useInput('');

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [itemsSize, setItemsSize] = useState(10);
  const [search, setSearch] = useState(searchState);
  const [isAllPeriod, setIsAllPeriod] = useState(true);
  const [isAllInstitutions, setIsAllInstitutions] = useState(false);
  const [isAllDocumentType, setIsAllDocumentType] = useState(true);
  const [isAllOurNumbers, setIsAllOurNumbers] = useState(true);
  const [showFilter, setShowFilter] = useState(true);
  const [error, setError] = useState(null);

  const { user, permission } = useSelector((state) => state.auth);
  const { institution } = user;

  const isNormalUser = normalOrManager.includes(permission);

  /*eslint-disable */
  useEffect(() => {
    if (isNormalUser) {
      setFilteredInstitution(institution.id_institution.toString());
    } else {
      dispatch(getAllInstitutionsRequest());
    }
  }, [dispatch]);

  function getDocuments() {
    let querySearch = search

    if (!isAllOurNumbers) {
      querySearch = ourNumber
    }

    const query = {
      ...(!isAllInstitutions && isAllOurNumbers && { institutionId: filteredInstitution }),
      ...(!isAllPeriod && isAllOurNumbers && { initialDate, finalDate }),
      ...(!isAllDocumentType && isAllOurNumbers && { docTypeId }),
      page,
      limit: itemsSize,
      getLast,
      search: querySearch
    };

    dispatch(
      getAllDocumentsRequest(query, () => {
        setShowFilter(false);
        scrollMore(180);
      })
    );
  }

  function getDocumentDetails(id) {
    navigate(`/documents/${id}`);
  }

  const delayedSearch = useCallback(_.debounce(() => {
    if (searchState !== search) {
      dispatch(setSearchQuery(search));
      getDocuments();
    }
  }, 500), [search]);

  useEffect(() => {
    delayedSearch();

    return delayedSearch.cancel;
  }, [search, delayedSearch]);

  useEffect(() => {
    setTotal(totalDocuments);
  }, [documents]);

  useEffect(() => {
    if(total > 0) getDocuments();
  }, [page, itemsSize, getLast]);

  useEffect(() => {
    dispatch(SelectedData.selectInstitution(filteredInstitution));
  }, [filteredInstitution]);

  useEffect(() => {
    if (!isAllOurNumbers && ourNumber.length > 0) {
      setIsAllInstitutions(true);
      setIsAllPeriod(true);
      setIsAllDocumentType(true);
    }
  }, [isAllOurNumbers, ourNumber])

  function handleInstitutionFilter(institution) {
    setFilteredInstitution(institution.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setError(null);

    if (
      !isAllInstitutions &&
      (!filteredInstitution || filteredInstitution === '')
    )
      return setError('Selecione uma instituição');

    if (
      !isAllOurNumbers &&
      (!ourNumber || ourNumber === '')
    )
      return setError('Preencha o campo nosso número ou marque a opção para visualizar todos');


    if (
      !isAllPeriod &&
      initialDate &&
      initialDate !== '' &&
      finalDate &&
      finalDate !== '' &&
      dateFns.isAfter(
        dateFns.parseISO(initialDate),
        dateFns.parseISO(finalDate)
      )
    ) {
      return setError('A data final precisa ser maior que a data inicial');
    }

    getDocuments();
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Gerenciar Registros"
          subtitle="Gestão de registros"
          className="text-sm-left"
        />
      </Row>
      {/* Filters */}
      <Row id="form-container">
        <Col>
          <Card className="mb-3" small>
            <CardHeader className="border-bottom">
              <Row>
                <Col sm="6">
                  <h6 className="m-0 font-weight-bold">Filtros</h6>
                </Col>
                <Col sm="6" className="text-right">
                  <Button
                    theme="light"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <span className="mr-2">
                      {showFilter ? <FaArrowUp /> : <FaArrowDown />}
                    </span>
                    {showFilter ? 'Esconder' : 'Mostrar'}
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <Collapse open={showFilter}>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form onSubmit={handleSubmit}>
                        <Row form>
                          <Col md="6" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Instituição
                            </span>
                            <Row className="pt-2">
                              <Col md="8">
                                <SearchSelect
                                  placeholder="Pesquisa por nome, sigla ou client_id"
                                  onSelect={handleInstitutionFilter}
                                  value={filteredInstitution}
                                  options={
                                    institutions &&
                                    institutions.map(i => ({
                                      label: `${i.name} - ${i.initials}`,
                                      value: i._id,
                                      client_id: i.client_id,
                                    }))
                                  }
                                  styleContainer={{
                                    marginTop: '0px',
                                  }}
                                  disabled={isAllInstitutions}
                                />
                              </Col>
                              <Col md="4" className="pt-2 pl-0">
                                <FormCheckbox
                                  checked={isAllInstitutions}
                                  onChange={() =>
                                    setIsAllInstitutions(!isAllInstitutions)
                                  }
                                >
                                  <span className="text-dark">Ver todas</span>
                                </FormCheckbox>
                              </Col>
                            </Row>
                          </Col>
                          <Col md="3" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Nosso número
                            </span>
                            <Row className="pt-2">
                              <Col md="8">
                                <FormInput
                                  type="text"
                                  onChange={(e) => {
                                    setOurNumber(e.target.value);
                                  }}
                                  value={ourNumber}
                                  disabled={isAllOurNumbers}
                                />
                              </Col>
                              <Col md="4" className="pt-2 pl-0">
                                <FormCheckbox
                                  checked={isAllOurNumbers}
                                  onChange={() =>
                                    setIsAllOurNumbers(!isAllOurNumbers)
                                  }
                                >
                                  <span className="text-dark">Ver todos</span>
                                </FormCheckbox>
                              </Col>
                            </Row>
                          </Col>
                          <Col md="3" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Tipo de documento
                            </span>
                            <Row className="pt-2">
                              <Col md="8">
                                <FormSelect
                                  id="documentType"
                                  value={docTypeId}
                                  onChange={e =>
                                    setDocTypeId(e.target.value)
                                  }
                                  required={!isAllDocumentType}
                                  disabled={isAllDocumentType}
                                >
                                  <option value="" disabled>
                                    Escolher...
                                  </option>
                                  {documentTypes &&
                                    documentTypes.map(dType => (
                                      <option
                                        key={dType.value}
                                        value={dType.value}
                                      >
                                        {dType.label}
                                      </option>
                                    ))}
                                </FormSelect>
                              </Col>
                              <Col md="4" className="pt-2 pl-0">
                                <FormCheckbox
                                  checked={isAllDocumentType}
                                  onChange={() => setIsAllDocumentType(!isAllDocumentType)}
                                >
                                  <span className="text-dark">
                                    Ver todos
                                  </span>
                                </FormCheckbox>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="4" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Data inicial
                            </span>
                            <FormInput
                              type="date"
                              className="mt-2"
                              disabled={isAllPeriod}
                              value={initialDate}
                              onChange={e => setInitialDate(e.target.value)}
                              required={!isAllPeriod}
                            />
                          </Col>
                          <Col md="8" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Data Final
                            </span>
                            <Row className="pt-2">
                              <Col md="6">
                                <FormInput
                                  type="date"
                                  disabled={isAllPeriod}
                                  value={finalDate}
                                  onChange={e => setFinalDate(e.target.value)}
                                  required={!isAllPeriod}
                                />
                              </Col>
                              <Col md="4" className="pt-2 pl-0">
                                <FormCheckbox
                                  checked={isAllPeriod}
                                  onChange={() => setIsAllPeriod(!isAllPeriod)}
                                >
                                  <span className="text-dark">
                                    Intervalo completo
                                  </span>
                                </FormCheckbox>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="8">
                            <ErrorBox error={error} />
                          </Col>
                          <Col md="4" className="text-right">
                            <Button className="btn-primary ml-3">
                              Consultar
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Collapse>
          </Card>
        </Col>
      </Row>
      {loading && (
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3 pl-3 pr-3 overflow-auto">
                {loading && <LoadingContainer loading={loading} />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      {!loading && documents && (
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <Row className="align-middle">
                  <Col md="6">
                    <Row className="d-flex justify-content-start ml-2">
                      <h6 className="mt-1 mr-3">Buscar</h6>
                      <InputWrapper style={{ flex: 1, maxWidth: 500 }}>
                        <FormInput
                          id="search"
                          type="text"
                          placeholder="Seu número, nosso número ou recibo de transação"
                          name="search"
                          required
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                          value={search}
                        />
                      </InputWrapper>
                    </Row>
                  </Col>
                  <Col md="6">
                    <Row className="d-flex justify-content-end mr-2">
                      <FormCheckbox
                        checked={getLast}
                        onChange={() => dispatch(setGetLastDocuments(!getLast))}
                        className="mt-2"
                      >
                        <p
                          className={`mb-0 ${getLast ? 'text-primary' : 'text-secondary'}`}
                          style={{ marginTop: -23 }}>
                          Último registro
                        </p>
                      </FormCheckbox>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-0 pb-3 pl-3 pr-3 overflow-auto">
                {loading && <LoadingContainer loading={loading} />}
                {!loading && documents.length > 0 && (
                  <>
                    <table className="table mb-0">
                      <thead className="bg-light">
                        <tr>
                          <th scope="col" className="border-0">
                            #
                          </th>
                          <th scope="col" className="border-0">
                            Seu número
                          </th>
                          <th scope="col" className="border-0">
                            Nosso número
                          </th>
                          <th scope="col" className="border-0">
                            Criado em
                          </th>
                          <th scope="col" className="border-0">
                            Status
                          </th>
                          <th scope="col" className="border-0">
                            Ações
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {documents &&
                          documents.map((s, index) => (
                            <tr key={s._id}>
                              <td>{s._id}</td>
                              <td>{s.yourNumber}</td>
                              <td>{s.ourNumber}</td>
                              <td>{s.createdAt && dateAndHour(s.createdAt)}</td>
                              <td>{documentStatus[s.documentStatus] || s.documentStatus}</td>
                              <td>
                                <ButtonGroup size="sm">
                                  <Button
                                    theme="white"
                                    onClick={() => getDocumentDetails(s._id)}
                                  >
                                    <span className="text-light">
                                      <FiZoomIn />
                                    </span>{' '}
                                    Detalhes
                                  </Button>
                                </ButtonGroup>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <Pagination
                      total={total}
                      page={page}
                      items={itemsSize}
                      onChangePage={e => {
                        scrollToTop(e);
                        setPage(e);
                      }}
                      onChangeItems={e => {
                        scrollToTop();
                        setItemsSize(e);
                      }}
                    />
                  </>
                )}
                {documents.length === 0 && (
                  <div className="col-sm-12 d-flex justify-content-center">
                    <h5 className="mt-3">
                      <FiAlertCircle className="mr-2" />
                      Não existem documentos cadastrados para os filtros selecionados
                    </h5>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}

    </Container>
  );
}
