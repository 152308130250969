import { produce } from 'immer';

const INITIAL_STATE = {
  signers: [],
  signer: {},
  loading: false,
  isSubmitting: false,
};

export default function signer(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@signer/GET_ALL_SIGNERS_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@signer/GET_ALL_SIGNERS_SUCCESS': {
        draft.signers = action.payload.signers;
        draft.loading = false;
        break;
      }
      case '@signer/GET_SIGNER_BY_ID_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@signer/GET_SIGNER_BY_ID_SUCCESS': {
        draft.loading = false;
        draft.signer = action.payload.signer;
        draft.signer.index = action.payload.index;
        break;
      }
      case '@signer/STORE_SIGNER_REQUEST': {
        // draft.loading = true;
        draft.isSubmitting = true;
        break;
      }
      case '@signer/STORE_SIGNER_SUCCESS': {
        const aux = [...draft.signers];
        aux.push(action.payload.signer);
        draft.signers = aux;
        draft.isSubmitting = false;
        break;
      }
      case '@signer/UPDATE_SIGNER_REQUEST': {
        draft.isSubmitting = true;
        break;
      }
      case '@signer/UPDATE_SIGNER_SUCCESS': {
        const { data } = action.payload;
        const aux = draft.signers;
        const signerAux = state.signers.findIndex((item) => {
          return item.id === data.id;
        });
        aux[signerAux] = data;
        draft.signers = aux;
        draft.isSubmitting = false;
        break;
      }
      case '@signer/UPDATE_SIGNER_CANCEL': {
        draft.signer = {};
        break;
      }
      case '@signer/DELETE_SIGNER_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@signer/DELETE_SIGNER_SUCCESS': {
        const aux = draft.signers;
        aux.splice(action.payload.index, 1);
        draft.signers = aux;
        draft.loading = false;
        break;
      }
      case '@signer/SIGNER_FAILURE': {
        draft.loading = false;
        draft.isSubmitting = false;
        break;
      }
      default:
    }
  });
}
