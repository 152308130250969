import { produce } from 'immer';

const INITIAL_STATE = {
  ddrConfigs: [],
  ddrConfig: {},
  loading: false,
  isSubmitting: false,
};

export default function ddrConfig(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@ddrconfig/GET_ALL_DDRCONFIGS_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@ddrconfig/GET_ALL_DDRCONFIGS_SUCCESS': {
        draft.ddrConfigs = action.payload.ddrConfigs;
        draft.loading = false;
        break;
      }
      case '@ddrconfig/GET_DDRCONFIG_BY_ID_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@ddrconfig/GET_DDRCONFIG_BY_ID_SUCCESS': {
        draft.loading = false;
        draft.ddrConfig = action.payload.DDRConfig;
        draft.ddrConfig.index = action.payload.index;
        break;
      }
      case '@ddrconfig/STORE_DDRCONFIG_REQUEST': {
        draft.isSubmitting = true;
        break;
      }
      case '@ddrconfig/STORE_DDRCONFIG_SUCCESS': {
        const aux = [...draft.ddrConfigs];
        const newDdr = action.payload.DDRConfig;
        if (Array.isArray(newDdr)) {
          newDdr.map((item) => aux.push(item));
        } else {
          aux.push(action.payload.DDRConfig);
        }

        draft.ddrConfigs = aux;
        draft.isSubmitting = false;
        break;
      }
      case '@ddrconfig/UPDATE_DDRCONFIG_REQUEST': {
        draft.isSubmitting = true;
        break;
      }
      case '@ddrconfig/UPDATE_DDRCONFIG_SUCCESS': {
        const { data } = action.payload;
        const aux = draft.ddrConfigs;
        aux[draft.ddrConfig.index] = data;
        draft.ddrConfigs = aux;
        draft.isSubmitting = false;
        break;
      }
      case '@ddrconfig/DELETE_DDRCONFIG_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@ddrconfig/DELETE_DDRCONFIG_SUCCESS': {
        const aux = draft.ddrConfigs;
        aux.splice(action.payload.index, 1);
        draft.ddrConfigs = aux;
        draft.loading = false;
        break;
      }
      case '@ddrconfig/DDRCONFIG_FAILURE': {
        draft.loading = false;
        draft.isSubmitting = false;
        break;
      }
      default:
    }
  });
}
