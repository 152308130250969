export const types = {
  create: 'Criação',
  update: 'Atualização',
  delete: 'Exclusão',
};

export const status = {
  approved: 'Aprovado',
  reproved: 'Reprovado',
  canceled: 'Cancelado',
  waiting: 'Solicitado',
};
