import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { responseErrors } from '../../../utils/responseErros';
import api from '../../../services/api';
import {
  getAllDDRConfigSuccess,
  getDDRConfigByIdSuccess,
  storeDDRConfigSuccess,
  updateDDRConfigSuccess,
  deleteDDRConfigSuccess,
  DDRConfigFailure,
} from './actions';
import { refreshTokenRequest } from '../auth/actions';
import { store } from '../../index';

export function* getDDRConfigs() {
  const { refresh_token } = store.getState().auth;

  try {
    const response = yield call(api.get, '/management/ddrconfig');
    const docgroups = response.data;

    yield put(getAllDDRConfigSuccess(docgroups));
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(DDRConfigFailure());
      toast.error(`Falha ao listar versões`);
    }
  }
}
export function* getDDRConfigById({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, index } = payload;
    const response = yield call(api.get, `/management/ddrconfig/${id}`);
    const ddrconfig = response.data;

    yield put(getDDRConfigByIdSuccess(ddrconfig, index));
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(DDRConfigFailure());
      toast.error('Falha ao obter dados da versão');
    }
  }
}
export function* storeDDRConfig({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { data, onSuccess } = payload;
    const response = yield call(api.post, '/management/ddrconfig', data);
    const ddrconfig = response.data;

    yield put(storeDDRConfigSuccess(ddrconfig));
    toast.success('Versão criado com sucesso');
    onSuccess && onSuccess();
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(DDRConfigFailure());
      Swal.fire(responseErrors(err.response, 'Falha ao criar versão'));
    }
  }
}

export function* updateDDRConfig({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, data, index, onSuccess } = payload;
    const response = yield call(api.patch, `/management/ddrconfig/${id}`, data);
    const docgroup = response.data;

    yield put(updateDDRConfigSuccess(index, docgroup));
    toast.success('Versão atualizada com sucesso');
    onSuccess && onSuccess();
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(DDRConfigFailure());
      Swal.fire(responseErrors(err.response, 'Falha ao atualizar versão'));
    }
  }
}

export function* deleteDDRConfig({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, index } = payload;
    yield call(api.delete, `/management/ddrconfig/${id}`);

    yield put(deleteDDRConfigSuccess(index));
    toast.success('Controle de versão excluído com sucesso');
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(DDRConfigFailure());
      toast.error('Falha ao excluir controle de versão');
    }
  }
}

export default all([
  takeLatest('@ddrconfig/GET_ALL_DDRCONFIGS_REQUEST', getDDRConfigs),
  takeLatest('@ddrconfig/GET_DDRCONFIG_BY_ID_REQUEST', getDDRConfigById),
  takeLatest('@ddrconfig/STORE_DDRCONFIG_REQUEST', storeDDRConfig),
  takeLatest('@ddrconfig/UPDATE_DDRCONFIG_REQUEST', updateDDRConfig),
  takeLatest('@ddrconfig/DELETE_DDRCONFIG_REQUEST', deleteDDRConfig),
]);
