/* eslint-disable import/no-cycle */
import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import { generalDocumentsFailure, getGeneralDocumentsSuccess } from './actions';
import { refreshTokenRequest } from '../auth/actions';
import { store } from '../../index';

export function* getDocuments({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { client_id, onSuccess } = payload;
    const url = `/documents/${client_id}`;

    const response = yield call(api.get, url);
    const { data } = response;

    yield put(getGeneralDocumentsSuccess(data));

    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(generalDocumentsFailure());
      toast.error('Falha ao listar');
    }
  }
}

export default all([
  takeLatest('@generalDocuments/GET_GENERAL_DOCUMENTS', getDocuments),
]);
