/* eslint-disable import/no-cycle */
import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import {
  LogsFailure,
  getVersionsSuccess,
  getSignLogsSuccess,
  getSignLogsByIdSuccess,
  getTimestampLogsSuccess,
  getTimestampLogByIdSuccess,
} from './actions';
import { refreshTokenRequest } from '../auth/actions';
import { store } from '../../index';

export function* getLogVersions({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { client_id, onSuccess, page, limit } = payload;
    let url = '/management/logs/versions?';

    if (client_id && client_id !== '') {
      url += `&client_id=${client_id}`;
    }
    if (page && page !== '') {
      url += `&page=${page}`;
    }
    if (limit && limit !== '') {
      url += `&limit=${limit}`;
    }

    const response = yield call(api.get, url);
    const { data } = response;

    yield put(getVersionsSuccess(data));

    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(LogsFailure());
      toast.error(`Falha ao listar registro de versão`);
    }
  }
}

export function* getSignLogs({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { client_id, your_number, provider, policy, onSuccess, page, limit } =
      payload;
    let url = '/management/logs/signlogs?';

    if (client_id && client_id !== '') {
      url += `&client_id=${client_id}`;
    }
    if (your_number && your_number !== '') {
      url += `&your_number=${your_number}`;
    }
    if (provider && provider !== '') {
      url += `&provider=${provider}`;
    }
    if (policy && policy !== '') {
      url += `&policy=${policy}`;
    }
    if (page && page !== '') {
      url += `&page=${page}`;
    }
    if (limit && limit !== '') {
      url += `&limit=${limit}`;
    }

    const response = yield call(api.get, url);
    const { data } = response;

    yield put(getSignLogsSuccess(data));

    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(LogsFailure());
      toast.error(`Falha ao listar registros de assinatura`);
    }
  }
}

export function* getSignLogById({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, onSuccess } = payload;

    const response = yield call(api.get, `/management/logs/signlogs/${id}`);
    const { data } = response;

    yield put(getSignLogsByIdSuccess(data));

    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(LogsFailure());
      toast.error(`Falha ao listar registros de assinatura`);
    }
  }
}

export function* getTimestampLogs({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const {
      client_id,
      your_number,
      sign_provider,
      timestamp_type,
      timestamp_provider,
      policy,
      page,
      limit,
      onSuccess,
    } = payload;
    let url = '/management/logs/sign-timestamp?';

    if (client_id && client_id !== '') {
      url += `&client_id=${client_id}`;
    }
    if (your_number && your_number !== '') {
      url += `&your_number=${your_number}`;
    }
    if (sign_provider && sign_provider !== '') {
      url += `&sign_provider=${sign_provider}`;
    }
    if (timestamp_type && timestamp_type !== '') {
      url += `&timestamp_type=${timestamp_type}`;
    }
    if (timestamp_provider && timestamp_provider !== '') {
      url += `&timestamp_provider=${timestamp_provider}`;
    }
    if (policy && policy !== '') {
      url += `&policy=${policy}`;
    }
    if (page && page !== '') {
      url += `&page=${page}`;
    }
    if (limit && limit !== '') {
      url += `&limit=${limit}`;
    }

    const response = yield call(api.get, url);
    const { data } = response;

    yield put(getTimestampLogsSuccess(data));

    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(LogsFailure());
      toast.error(`Falha ao listar registros de carimbo`);
    }
  }
}

export function* getTimestampLogById({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, onSuccess } = payload;

    const response = yield call(
      api.get,
      `/management/logs/sign-timestamp/${id}`
    );
    const { data } = response;

    yield put(getTimestampLogByIdSuccess(data));

    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(LogsFailure());
      toast.error(`Falha ao listar registro de carimbo`);
    }
  }
}

export default all([
  takeLatest('@logs/GET_VERSIONS', getLogVersions),
  takeLatest('@logs/GET_SIGN_LOGS', getSignLogs),
  takeLatest('@logs/GET_SIGN_LOGS_BY_ID', getSignLogById),
  takeLatest('@logs/GET_TIMESTAMP_LOGS', getTimestampLogs),
  takeLatest('@logs/GET_TIMESTAMP_LOGS_BY_ID', getTimestampLogById),
]);
