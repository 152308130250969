import forge from 'node-forge';
import { isValidCPF, isValidCpfCnpjLength } from './validations';

export const extractCert = (file, password) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const contents = e.target.result;
        const pkcs12Der = arrayBufferToString(contents);
        // const pkcs12B64 = forge.util.encode64(pkcs12Der);
        const pkcs12Asn1 = forge.asn1.fromDer(pkcs12Der);
        const p12 = forge.pkcs12.pkcs12FromAsn1(pkcs12Asn1, false, password);

        // get bags by type
        const certBags = p12.getBags({ bagType: forge.pki.oids.certBag });
        // const pkeyBags = p12.getBags({
        //   bagType: forge.pki.oids.pkcs8ShroudedKeyBag,
        // });
        // fetching certBag
        const certBag = certBags[forge.pki.oids.certBag][0];
        // fetching keyBag
        // const keybag = pkeyBags[forge.pki.oids.pkcs8ShroudedKeyBag][0];
        // generate pem from private key
        // const privateKeyPem = forge.pki.privateKeyToPem(keybag.key);
        // generate pem from cert
        const certificate = forge.pki
          .certificateToPem(certBag.cert)
          .split('\n')
          .slice(1, -2)
          .join('')
          .replace(/\r?\n|\r/g, '');

        const data = getDataFromSubject(certBag.cert.subject);

        resolve({ cert: certificate, data });
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsArrayBuffer(file);
  });
};

export const extractTextFromCer = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const contents = e.target.result;
        const pkcs12Der = arrayBufferToString(contents);

        const text = pkcs12Der;

        const findCertificateOne = findCertificateFromText(text);

        if (findCertificateOne) {
          const certPem = extractCertFromCer(findCertificateOne);

          const data = getDataFromSubject(certPem.subject);

          resolve({ cert: findCertificateOne, data });
          return;
        }

        const pkcs12Asn1 = forge.asn1.fromDer(pkcs12Der);
        const asn1Cert = forge.pki.certificateFromAsn1(pkcs12Asn1);

        const certPem = forge.pki.certificateToPem(asn1Cert);

        const findCertificateTwo = findCertificateFromText(certPem);

        if (findCertificateTwo) {
          const data = getDataFromSubject(asn1Cert.subject);

          resolve({ cert: findCertificateTwo, data });
          return;
        }

        throw new Error();
      } catch (error) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('Certificado no formato inválido');
      }
    };
    reader.onerror = () => {
      reject();
    };
    reader.readAsArrayBuffer(file);
  });
};

export const extractCertFromCer = (base64Certificate) => {
  const certificate = `
          -----BEGIN CERTIFICATE-----
          ${base64Certificate}
          -----END CERTIFICATE-----
          `;
  return forge.pki.certificateFromPem(certificate);
};

function arrayBufferToString(buffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return binary;
}

function findCertificateFromText(text) {
  const textArray = text.split('\n');

  const beginIndex = textArray.findIndex((text) => {
    return String(text).includes('-----BEGIN CERTIFICATE-----');
  });
  const endIndex = textArray.findIndex((text) =>
    String(text).includes('-----END CERTIFICATE-----')
  );

  if (beginIndex !== -1 || endIndex !== -1) {
    const cert = textArray
      .slice(beginIndex + 1, endIndex)
      .join('')
      .replaceAll('\r', '');
    return cert;
  }

  return null;
}

function getDataFromSubject(subject) {
  const getData = subject.getField('CN').value;

  let data = null;
  if (getData && String(getData).split(':').length === 2) {
    const name = String(getData).split(':')[0];
    const number = String(getData).split(':')[1];
    if (isValidCpfCnpjLength(number)) data = { name, number };
  } else if (getData && typeof getData === 'string') {
    data = {
      name: String(getData).replace(/[^A-zÀ-ú ]/g, ''),
      number: '',
    };
  }

  return data;
}

export function getAdditionalDataFromCnpj(data, cnpj) {
  try {
    if (!data || !cnpj) {
      return null;
    }

    const info = data?.extensions.find((v) => v.name === 'subjectAltName');

    const altNamesArray = info?.altNames.filter((v) => Array.isArray(v.value));
    const additionalInfoValue = altNamesArray
      ?.map((v) => v.value)
      ?.flat()
      .filter((v) => Array.isArray(v.value))
      ?.map((v) => v.value)
      ?.flat()
      ?.filter((v) => /^\d+$/.test(v.value.replace(/\D/g, '')))
      ?.filter((v) => v.value.length >= 19)
      ?.find(({ value }) => {
        const day = value.slice(0, 2);
        const month = value.slice(2, 4);
        const year = value.slice(4, 8);

        const date = new Date(`${year}-${month}-${day}`);

        return !Number.isNaN(date.getTime());
      });

    if (additionalInfoValue.value) {
      const cpfNumber = additionalInfoValue.value.slice(8, 19);

      return {
        completeValue: additionalInfoValue.value,
        cpf: verifyCpf(cpfNumber, cnpj) && cpfNumber,
      };
    }

    return null;
  } catch (error) {
    return null;
  }
}

function verifyCpf(cpf, cnpj) {
  if (isValidCPF(cpf)) {
    return true;
  }

  if (cnpj.includes(cpf)) {
    return true;
  }

  return false;
}
