import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 700px;

  border-radius: 10px;

  form {
    background: #fff;
    border-radius: 10px;
    margin: 80px 0;
    width: 340px;
    text-align: center;
    padding: 30px;

    img {
      width: 100px;
      height: auto;
      margin: 15px 0;
    }

    h1 {
      font-size: 25px;
      margin-bottom: 24px;
    }
  }
`;
