import { produce } from 'immer';

const INITIAL_STATE = {
  sigItems: [],
  sigItem: {},
  loading: false,
  isSubmitting: false,
};

export default function signatureItem(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@signatureItem/GET_ALL_SIGITEMS_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@signatureItem/GET_ALL_SIGITEMS_SUCCESS': {
        draft.sigItems = action.payload.sigItems;
        draft.loading = false;
        break;
      }
      case '@signatureItem/GET_SIGITEM_BY_ID_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@signatureItem/GET_SIGITEM_BY_ID_SUCCESS': {
        draft.loading = false;
        draft.sigItem = action.payload.sigItem;
        draft.sigItem.index = action.payload.index;
        break;
      }
      case '@signatureItem/STORE_SIGITEM_REQUEST': {
        // draft.loading = true;
        draft.isSubmitting = true;
        break;
      }
      case '@signatureItem/STORE_SIGITEM_SUCCESS': {
        const aux = [...draft.sigItems];
        aux.push(action.payload.sigItem);
        draft.sigItems = aux;
        // draft.loading = false;
        draft.isSubmitting = false;
        break;
      }
      case '@signatureItem/UPDATE_SIGITEM_REQUEST': {
        // draft.loading = true;
        draft.isSubmitting = true;
        break;
      }
      case '@signatureItem/UPDATE_SIGITEM_SUCCESS': {
        const { data } = action.payload;
        const aux = draft.sigItems;
        const sigItemIndex = state.sigItems.findIndex((item) => {
          return item._id === data._id;
        });
        aux[sigItemIndex] = data;
        draft.sigItems = aux;
        // draft.loading = false;
        draft.isSubmitting = false;
        break;
      }
      case '@signatureItem/UPDATE_SIGITEM_CANCEL': {
        draft.sigItem = {};
        break;
      }
      case '@signatureItem/DELETE_SIGITEM_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@signatureItem/DELETE_SIGITEM_SUCCESS': {
        const aux = draft.sigItems;
        aux.splice(action.payload.index, 1);
        draft.sigItems = aux;
        draft.loading = false;
        break;
      }
      case '@signatureItem/SIGITEM_FAILURE': {
        draft.loading = false;
        draft.isSubmitting = false;
        break;
      }
      default:
    }
  });
}
