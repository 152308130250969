import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormCheckbox,
  Collapse,
  ListGroup,
  ListGroupItem,
  Form,
  FormInput,
  FormSelect,
  ButtonGroup,
} from 'shards-react';

import { FiAlertCircle, FiZoomIn, FiSearch } from 'react-icons/fi';

import { FaArrowUp, FaArrowDown } from 'react-icons/fa';

import { useNavigate } from 'react-router-dom';
import PageTitle from '../../components/common/PageTitle';
import LoadingContainer from '../../components/layout/LodingContainer';
import Pagination from '../../components/common/Pagination';

import SearchSelect from '../../components/common/SearchSelect';

import {
  getSignLogs,
  cleanState,
  setMutualYourNumber,
  cleanMutualYourNumber,
} from '../../store/modules/logs/actions';

import { getAllInstitutionsRequest } from '../../store/modules/institution/actions';
import * as SelectedData from '../../store/modules/selectedData/actions';

import { scrollToTop } from '../../utils/scrolls';

import { normalOrManager } from '../../config/UserPermission';

import ErrorBox from '../../components/common/ErrorBox';
import { dateAndHour } from '../../utils/dateFormatter';
import { useInput } from '../../hooks/input-hook';
import { documentTypes, providers } from '../../config/Constants';

export default function SignLogs() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const institutions = useSelector((state) => state.institution.institutions);

  const { signLogs, loading, mutualYourNumber } = useSelector(
    (state) => state.logs
  );

  const selectedInstitution = useSelector(
    (state) => state.selectedData.selectedInstitution
  );

  const [filteredInstitution, setFilteredInstitution] =
    useState(selectedInstitution);

  const { value: yourNumber, setValue: setYourNumber } = useInput('');
  const { value: provider, setValue: setProvider } = useInput('');
  const { value: policy, setValue: setPolicy } = useInput('');

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [itemsSize, setItemsSize] = useState(20);
  const [isAll, setIsAll] = useState({
    institution: false,
    yourNumber: true,
    provider: true,
    policy: true,
  });

  const [error, setError] = useState(null);
  const [showFilter, setShowFilter] = useState(true);

  const { user, permission } = useSelector((state) => state.auth);
  const { institution } = user;

  const isNormalUser = normalOrManager.includes(permission);

  /*eslint-disable */
  useEffect(() => {
    if (isNormalUser) {
      setFilteredInstitution(institution.id_institution.toString());
    } else {
      dispatch(getAllInstitutionsRequest());
    }

    return () => {
      dispatch(cleanState());
    }
  }, [dispatch]);

  const handleGetSignLogs = useCallback(() => {
    const querySearch = {
      page,
      limit: itemsSize
    };

    const findInstitution = institutions
      .find(item => item._id === filteredInstitution);

    Object.assign(
      querySearch,
      !isAll.institution && findInstitution && {
        client_id: findInstitution.client_id,
      },
      !isAll.provider && {
        provider
      },
      !isAll.yourNumber && {
        your_number: yourNumber
      },
      !isAll.policy && {
        policy
      },
    )

    dispatch(getSignLogs(querySearch, () => {
      setShowFilter(false);
      dispatch(cleanMutualYourNumber());
    }))
  }, [
    dispatch,
    filteredInstitution,
    institutions,
    isAll.institution,
    isAll.policy,
    isAll.provider,
    isAll.yourNumber,
    itemsSize,
    page,
    policy,
    yourNumber,
  ]);

  useEffect(() => {
    if (mutualYourNumber) {
      setYourNumber(mutualYourNumber);
      setIsAll({
        institution: true,
        yourNumber: false,
        provider: true,
        policy: true,
      });
      handleGetSignLogs();
    }
  }, [dispatch, handleGetSignLogs, mutualYourNumber, setYourNumber]);

  useEffect(() => {
    if (signLogs.content)
      setTotal(signLogs.total);
  }, [signLogs.total]);

  useEffect(() => {
    if (signLogs.content)
      handleGetSignLogs();
  }, [page, itemsSize]);

  useEffect(() => {
    dispatch(SelectedData.selectInstitution(filteredInstitution));
  }, [filteredInstitution]);


  function handleInstitutionFilter(institution) {
    setFilteredInstitution(institution.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setError(null);
    setPage(1);

    if (!isAll.institution &&
      (!filteredInstitution || filteredInstitution === ''))
      return setError("Selecione uma instituição");

    handleGetSignLogs();
  }

  function changeIsAll(name) {
    setIsAll(old => ({ ...old, [name]: !old[name] }))
  }

  function handleDetails(id) {
    navigate(`/logs/signature/${id}`);
  }

  function handleTimestampSearch(yourNumber) {
    dispatch(setMutualYourNumber(yourNumber));
    navigate(`/logs/timestamp`);
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Registros de Assinatura"
          subtitle="Logs"
          className="text-sm-left"
        />
      </Row>
      {/* Filters */}
      <Row id="form-container">
        <Col>
          <Card className="mb-3" small>
            <CardHeader className="border-bottom">
              <Row>
                <Col sm="6">
                  <h6 className="m-0 font-weight-bold">
                    Filtros
                  </h6>
                </Col>
                <Col sm="6" className="text-right">
                  <Button theme="light" onClick={() => setShowFilter(!showFilter)}>
                    <span className="mr-2">
                      {showFilter ? <FaArrowUp /> : <FaArrowDown />}
                    </span>
                    {showFilter ? "Esconder" : "Mostrar"}
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <Collapse open={showFilter}>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form onSubmit={handleSubmit}>
                        <Row form>
                          <Col md="6" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Instituição
                            </span>
                            <Row className="pt-2">
                              <Col md="8">
                                <SearchSelect
                                  placeholder="Pesquisa por nome, sigla ou client_id"
                                  onSelect={handleInstitutionFilter}
                                  value={filteredInstitution}
                                  options={
                                    institutions &&
                                    institutions.map(i => ({
                                      label: `${i.name} - ${i.initials}`,
                                      value: i._id,
                                      client_id: i.client_id
                                    }))
                                  }
                                  styleContainer={{
                                    marginTop: '0px'
                                  }}
                                  disabled={isAll.institution}
                                />
                              </Col>
                              <Col md="4" className="pt-2 pl-0">
                                <FormCheckbox
                                  checked={isAll.institution}
                                  onChange={() => changeIsAll('institution')}
                                >
                                  <span className="text-dark">
                                    Ver todas
                                  </span>
                                </FormCheckbox>
                              </Col>
                            </Row>
                          </Col>
                          <Col md="6" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Your number
                            </span>
                            <Row className="pt-2">
                              <Col md="8">
                                <FormInput
                                  type="text"
                                  disabled={isAll.yourNumber}
                                  value={yourNumber}
                                  onChange={(e) => setYourNumber(e.target.value)}
                                  required={!isAll.yourNumber}
                                />
                              </Col>
                              <Col md="4" className="pt-2 pl-0">
                                <FormCheckbox
                                  checked={isAll.yourNumber}
                                  onChange={() => changeIsAll('yourNumber')}
                                >
                                  <span className="text-dark">
                                    Ver todos
                                  </span>
                                </FormCheckbox>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="6" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Provedor de assinatura
                            </span>
                            <Row className="pt-2">
                              <Col md="8">
                                <FormSelect
                                  id="providers"
                                  value={provider}
                                  onChange={e =>
                                    setProvider(e.target.value)
                                  }
                                  required={!isAll.provider}
                                  disabled={isAll.provider}
                                >
                                  <option value="" disabled>
                                    Escolher...
                                  </option>
                                  {providers &&
                                    providers.map(item => (
                                      <option
                                        key={item.value}
                                        value={item.value}
                                      >
                                        {item.label}
                                      </option>
                                    ))}
                                </FormSelect>
                              </Col>
                              <Col md="4" className="pt-2 pl-0">
                                <FormCheckbox
                                  checked={isAll.provider}
                                  onChange={() => changeIsAll('provider')}
                                >
                                  <span className="text-dark">
                                    Ver todos
                                  </span>
                                </FormCheckbox>
                              </Col>
                            </Row>
                          </Col>

                          <Col md="6" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Tipo de documento
                            </span>
                            <Row className="pt-2">
                              <Col md="8">
                                <FormSelect
                                  id="documentType"
                                  value={policy}
                                  onChange={e =>
                                    setPolicy(e.target.value)
                                  }
                                  required={!isAll.policy}
                                  disabled={isAll.policy}
                                >
                                  <option value="" disabled>
                                    Escolher...
                                  </option>
                                  {documentTypes &&
                                    documentTypes.map(dType => (
                                      <option
                                        key={dType.value}
                                        value={dType.value}
                                      >
                                        {dType.label}
                                      </option>
                                    ))}
                                </FormSelect>
                              </Col>
                              <Col md="4" className="pt-2 pl-0">
                                <FormCheckbox
                                  checked={isAll.policy}
                                  onChange={() => changeIsAll('policy')}
                                >
                                  <span className="text-dark">
                                    Ver todos
                                  </span>
                                </FormCheckbox>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="8">
                            <ErrorBox error={error} />
                          </Col>
                          <Col md="4" className="text-right">
                            <Button
                              className="btn-primary ml-3"
                            // onClick={handleCleanState}
                            >
                              Consultar
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Collapse>
          </Card>
        </Col>
      </Row>
      {loading && (
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3 pl-3 pr-3 overflow-auto">
                {loading && <LoadingContainer loading={loading} />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      {!loading && signLogs.content && signLogs.content.length > 0 && (
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3 pl-3 pr-3 overflow-auto">
                <>
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          #
                        </th>
                        <th scope="col" className="border-0">
                          Your number
                        </th>
                        <th scope="col" className="border-0">
                          ID do cliente
                        </th>
                        <th scope="col" className="border-0">
                          Data
                        </th>
                        <th scope="col" className="border-0">
                          Ações
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {signLogs.content &&
                        signLogs.content.map((s, index) => (
                          <tr key={s._id}>
                            <td>{s._id}</td>
                            <td>{s.yourNumber}</td>
                            <td>{s.client_id}</td>
                            <td>{dateAndHour(s.date)}</td>
                            <td>
                              <ButtonGroup size="sm">
                                <Button
                                  theme="white"
                                  onClick={() => handleDetails(s._id)}
                                >
                                  <span className="text-light">
                                    <FiZoomIn />
                                  </span>{' '}
                                  Detalhes
                                </Button>
                                <Button
                                  theme="white"
                                  onClick={() => handleTimestampSearch(s.yourNumber)}
                                >
                                  <span className="text-light">
                                    <FiSearch />
                                  </span>{' '}
                                  Carimbos
                                </Button>
                              </ButtonGroup>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <Pagination
                    total={total}
                    page={page}
                    items={itemsSize}
                    onChangePage={e => {
                      scrollToTop(e);
                      setPage(e);
                    }}
                    onChangeItems={e => {
                      scrollToTop();
                      setItemsSize(e);
                    }}
                  />
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      {!loading && signLogs.content && signLogs.content.length === 0 && (
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3 pl-3 pr-3 overflow-auto">
                <div className="col-sm-12 d-flex justify-content-center">
                  <h5 className="mt-3">
                    <FiAlertCircle className="mr-2" />
                    Não existem logs encontrados para os filtros escolhidos.
                  </h5>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
}
