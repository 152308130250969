/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'shards-react';

import { FiAlertCircle } from 'react-icons/fi';

import PageTitle from '../../components/common/PageTitle';
import LoadingContainer from '../../components/layout/LodingContainer';
import CollapsibleRow from '../../components/common/CollapsibleRow';

import { getGeneralDocumentsRequest } from '../../store/modules/generalDocuments/actions';

export default function SignatureItemsNormalUser() {
  const dispatch = useDispatch();

  const { loading, documents } = useSelector((state) => state.generalDocuments);
  const [showRows, setShowRows] = useState(false);
  const [signItems, setSignItems] = useState([]);
  const { user } = useSelector((state) => state.auth);

  /*eslint-disable */
  useEffect(() => {
    const clientId = user?.institution?.client_id;
    if(!clientId) return;

    dispatch(getGeneralDocumentsRequest({client_id: clientId}));
  }, [dispatch]);
  /* eslint-enable */

  useEffect(() => {
    const signItemsArray = [];

    documents.forEach((document) => {
      const tags = document.signatureConfig?.tags;
      tags &&
        document.signatureConfig.tags.forEach((tag) => {
          tag.sigItens.forEach((signItem) => {
            const alreadyIncluded = signItemsArray.some(
              (item) => item.name === signItem.name
            );
            if (!alreadyIncluded) signItemsArray.push(signItem);
          });
        });
    });

    setSignItems(signItemsArray);
  }, [documents]);

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Item de assinatura"
          subtitle="Gerenciamento"
          className="text-sm-left"
        />
      </Row>

      {/* Default Light Table */}
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <Row>
                <Col md="6">
                  <h6 className="m-0 font-weight-bold">
                    Lista de items de assinatura
                  </h6>
                </Col>
                <Col md="6">
                  <Row className="d-flex justify-content-end mr-2">
                    <Button
                      theme="secondary"
                      size="sm"
                      onClick={() => setShowRows(!showRows)}
                    >
                      {showRows ? 'Ocultar assinadores' : 'Mostrar assinadores'}
                    </Button>
                  </Row>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="p-0 pb-3 pl-3 pr-3 overflow-auto">
              {loading ? (
                <LoadingContainer loading={loading} />
              ) : (
                <>
                  {signItems.length !== 0 ? (
                    <table className="table mb-0">
                      <thead className="bg-light">
                        <tr>
                          <th scope="col" className="border-0">
                            #
                          </th>
                          <th scope="col" className="border-0">
                            Item de assinatura
                          </th>
                          <th scope="col" className="border-0">
                            Grupo de curso
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {signItems &&
                          signItems.map((s) => (
                            <CollapsibleRow
                              show={showRows}
                              key={s._id}
                              // eslint-disable-next-line react/no-unstable-nested-components
                              Component={() => (
                                <tr>
                                  <td colSpan={4}>
                                    <h6>Assinadores para {s.name}</h6>
                                    <table className="table mb-5 table-hover">
                                      <thead className="bg-info">
                                        <tr>
                                          <th scope="col" className="border-0">
                                            #
                                          </th>
                                          <th scope="col" className="border-0">
                                            Nome
                                          </th>
                                          <th scope="col" className="border-0">
                                            Tipo
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>{s.signer?._id}</td>
                                          <td>{s.signer?.name}</td>
                                          <td>Assinador principal</td>
                                        </tr>
                                        {s.signerSubstitutes?.map(
                                          (substitute) => (
                                            <tr key={substitute._id}>
                                              <td>{substitute._id}</td>
                                              <td>{substitute.name}</td>
                                              <td>Assinador substituto</td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              )}
                            >
                              <td>{s._id}</td>
                              <td>{s.name}</td>
                              <td>{s.signer?.courseGroup?.tag}</td>
                            </CollapsibleRow>
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="col-sm-12 d-flex justify-content-center">
                      <h5 className="mt-3">
                        <FiAlertCircle className="mr-2" />
                        Não existem itens de assinatura cadastrados na sua
                        instituição.
                      </h5>
                    </div>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
