import { produce } from 'immer';

const INITIAL_STATE = {
  data: {},
  list: null,
  total: 0,
  loading: false,
};

export default function reports(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@reports/REQUEST_DOCUMENTS': {
        draft.loading = true;
        break;
      }
      case '@reports/REQUEST_DOCUMENTS_SUCCESS': {
        draft.loading = false;
        draft.data = { ...draft.data, ...action.payload.data };
        break;
      }
      case '@reports/REQUEST_SIGNATURES': {
        draft.loading = true;
        break;
      }
      case '@reports/REQUEST_SIGNATURES_SUCCESS': {
        draft.loading = false;
        draft.data = { ...action.payload.data };
        break;
      }
      case '@reports/REQUEST_TIMESTAMP': {
        draft.loading = true;
        break;
      }
      case '@reports/REQUEST_TIMESTAMP_SUCCESS': {
        draft.loading = false;
        draft.data = { ...action.payload.data };
        break;
      }
      case '@reports/REQUEST_RANKING': {
        draft.loading = true;
        break;
      }
      case '@reports/REQUEST_RANKING_SUCCESS': {
        draft.loading = false;
        draft.data = {
          ...draft.data,
          ranking: [...action.payload.data],
        };
        break;
      }
      case '@reports/REQUEST_BLOCKCHAINSTATS': {
        draft.loading = true;
        break;
      }
      case '@reports/REQUEST_BLOCKCHAINSTATS_SUCCESS': {
        draft.loading = false;
        draft.list = action.payload.data.content;
        draft.total = action.payload.data.total;
        break;
      }
      case '@reports/REQUEST_FAILURE': {
        draft.loading = false;
        break;
      }
      case '@reports/CLEAN': {
        draft.loading = false;
        draft.data = {};
        draft.total = 0;
        draft.list = null;
        break;
      }
      default:
    }
  });
}
