import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Button } from 'shards-react';
import PropTypes from 'prop-types';

const ErrorContainer = ({ code, alert, message }) => (
  <Container fluid className="main-content-container px-4 pb-4">
    <div className="error">
      <div className="error__content">
        <h2>{code}</h2>
        <h3 className="text-white">{alert}</h3>
        <p>{message}</p>
        <Link to="/">
          <Button pill>&larr; Voltar</Button>
        </Link>
      </div>
    </div>
  </Container>
);

export default ErrorContainer;

ErrorContainer.propTypes = {
  code: PropTypes.string.isRequired,
  alert: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};
