/* List */
export function getAllDocTypesRequest() {
  return {
    type: '@doctype/GET_ALL_DOCTYPES_REQUEST',
  };
}
export function getAllDocTypesSuccess(doctypes) {
  return {
    type: '@doctype/GET_ALL_DOCTYPES_SUCCESS',
    payload: { doctypes },
  };
}
export function getDocTypeByIdRequest(id, index) {
  return {
    type: '@doctype/GET_DOCTYPE_BY_ID_REQUEST',
    payload: { id, index },
  };
}
export function getDocTypeByIdSuccess(doctype, index) {
  return {
    type: '@doctype/GET_DOCTYPE_BY_ID_SUCCESS',
    payload: { doctype, index },
  };
}

/* Store */
export function storeDocTypeRequest(data, onSuccess) {
  return {
    type: '@doctype/STORE_DOCTYPE_REQUEST',
    payload: { data, onSuccess },
  };
}
export function storeDocTypeSuccess(doctype) {
  return {
    type: '@doctype/STORE_DOCTYPE_SUCCESS',
    payload: { doctype },
  };
}

/* Update */
export function updateDocTypeRequest(id, data, index, onSuccess) {
  return {
    type: '@doctype/UPDATE_DOCTYPE_REQUEST',
    payload: { id, data, index, onSuccess },
  };
}
export function updateDocTypeSuccess(index, data) {
  return {
    type: '@doctype/UPDATE_DOCTYPE_SUCCESS',
    payload: { index, data },
  };
}

/* Delete */
export function deleteDocTypeRequest(id, index) {
  return {
    type: '@doctype/DELETE_DOCTYPE_REQUEST',
    payload: { id, index },
  };
}
export function deleteDocTypeSuccess(index) {
  return {
    type: '@doctype/DELETE_DOCTYPE_SUCCESS',
    payload: { index },
  };
}

/* Failure */
export function docTypeFailure() {
  return {
    type: '@doctype/DOCTYPE_FAILURE',
  };
}
