/* eslint-disable */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col } from 'shards-react';

import SidebarMainNavbar from './SidebarMainNavbar';
import SidebarNavItems from './SidebarNavItems';

// import { Store } from '../../../flux';

function MainSidebar(props) {
  const classes = classNames('main-sidebar', 'px-0', 'col-12');
  useEffect(() => {
    const menu = document.querySelectorAll('.nav-item');
    menu.forEach(item => {
      const isDropdown = item.querySelectorAll('.navbar-toggler');
      if (isDropdown.length === 0) {
        return item.addEventListener('click', () => {
          document
            .querySelectorAll('.main-sidebar')[0]
            .classList.remove('open');
        });
      }
    });
  }, []);
  return (
    <Col tag="aside" className={classes} lg={{ size: 2 }} md={{ size: 3 }}>
      <SidebarMainNavbar hideLogoText={props.hideLogoText} />
      <SidebarNavItems />
    </Col>
  );
}

MainSidebar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool,
};

MainSidebar.defaultProps = {
  hideLogoText: false,
};

export default MainSidebar;
