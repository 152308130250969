import { all, takeLatest, call, put, take } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { responseErrors } from '../../../utils/responseErros';
import api from '../../../services/api';
import {
  getAllInstitutionsSuccess,
  getInstitutionByIdSuccess,
  storeInstitutionSuccess,
  updateInstitutionSuccess,
  deleteInstitutionSuccess,
  institutionFailure,
} from './actions';
import { refreshTokenRequest } from '../auth/actions';
import { store } from '../../index';

export function* getInstitutions() {
  const { refresh_token } = store.getState().auth;

  try {
    const response = yield call(api.get, '/management/institutions');
    const institutions = response.data;

    yield put(getAllInstitutionsSuccess(institutions));
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(institutionFailure());
      toast.error(`Falha ao listar instituições`);
    }
  }
}
export function* getInstitutionById({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, index } = payload;
    const response = yield call(api.get, `/management/institutions/${id}`);
    const institution = response.data;
    yield put(getInstitutionByIdSuccess(institution, index));
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(institutionFailure());
      toast.error('Falha ao obter dados da instituição');
    }
  }
}
export function* storeInstitution({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { data } = payload;
    const response = yield call(api.post, '/management/institutions', data);
    const institution = response.data;
    payload.onSuccess();
    yield put(storeInstitutionSuccess(institution));
    toast.success('Instituição criada com sucesso');
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(institutionFailure());
      Swal.fire(responseErrors(err.response, 'Falha ao criar instituição'));
    }
  }
}

export function* updateInstitution({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, data, index } = payload;
    const response = yield call(
      api.patch,
      `/management/institutions/${id}`,
      data
    );
    const institution = response.data;
    payload.onSuccess();
    yield put(updateInstitutionSuccess(index, institution));
    toast.success('Instituição atualizada com sucesso');
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(institutionFailure());
      Swal.fire(responseErrors(err.response, 'Falha ao atualizar instituição'));
    }
  }
}

export function* updateCancel() {
  yield take('@institution/UPDATE_INSTITUTION_CANCEL');
}

export function* deleteInstitution({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, index } = payload;
    yield call(api.delete, `/management/institutions/${id}`);

    yield put(deleteInstitutionSuccess(index));
    toast.success('Instituição excluída com sucesso');
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(institutionFailure());
      toast.error('Falha ao excluir instituição');
    }
  }
}

export default all([
  takeLatest('@institution/GET_ALL_INSTITUTIONS_REQUEST', getInstitutions),
  takeLatest('@institution/GET_INSTITUTION_BY_ID_REQUEST', getInstitutionById),
  takeLatest('@institution/STORE_INSTITUTION_REQUEST', storeInstitution),
  takeLatest('@institution/UPDATE_INSTITUTION_REQUEST', updateInstitution),
  takeLatest('@institution/UPDATE_INSTITUTION_CANCEL', updateCancel),
  takeLatest('@institution/DELETE_INSTITUTION_REQUEST', deleteInstitution),
]);
