export const documentStatus = {
  validated: 'Validado',
  sendingToRegistry: 'Enviando para registro',
  sentToRegistry: 'Enviado para registro',
  receivedFromRegistry: 'Recebido do registro',
  registering: 'Registrando',
  registered: 'Registrado',
  registerFail: 'Falha no registro',
  sendingToPreservation: 'Enviando para preservação',
  receivedFromPreservation: 'Recebido da preservação',
  preserving: 'Preservando',
  preservationFail: 'Falha na preservação',
  preserved: 'Preservado',
  revoked: 'Revogado',
  canceled: 'Cancelado',
  sentToPreservation: 'Enviado para preservação',
};
