import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as dateFns from 'date-fns';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormInput,
  ListGroup,
  ListGroupItem,
  Form,
  FormCheckbox,
  Collapse,
  FormSelect,
  ButtonGroup,
} from 'shards-react';

import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { FiAlertCircle, FiZoomIn } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useInput } from '../../hooks/input-hook';

import PageTitle from '../../components/common/PageTitle';
import LoadingContainer from '../../components/layout/LodingContainer';
import Pagination from '../../components/common/Pagination';

import SearchSelect from '../../components/common/SearchSelect';

import { getAllInstitutionsRequest } from '../../store/modules/institution/actions';
import {
  getAllBatchesRequest,
  cleanState,
} from '../../store/modules/batchRegistry/actions';
import * as SelectedData from '../../store/modules/selectedData/actions';

import { normalOrManager } from '../../config/UserPermission';
import ErrorBox from '../../components/common/ErrorBox';
import { scrollMore, scrollToTop } from '../../utils/scrolls';
import { batchRegistriesStatus } from '../../config/Constants';
import { dateAndHour } from '../../utils/dateFormatter';

export default function RegistryBatch() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const institutions = useSelector((state) => state.institution.institutions);
  const { loading, batches, total } = useSelector(
    (state) => state.batchRegistry
  );

  const selectedInstitution = useSelector(
    (state) => state.selectedData.selectedInstitution
  );

  const { user, permission } = useSelector((state) => state.auth);
  const { institution } = user;

  const isNormalUser = normalOrManager.includes(permission);

  const [error, setError] = useState(null);
  const [showFilter, setShowFilter] = useState(true);
  const [isAllPeriod, setIsAllPeriod] = useState(true);
  const [isAllInstitutions, setIsAllInstitutions] = useState(false);
  const [page, setPage] = useState(1);
  const [itemsSize, setItemsSize] = useState(10);

  const { value: filteredInstitution, setValue: setFilteredInstitution } =
    useInput(selectedInstitution);

  const { value: documentId, setValue: setDocumentId } = useInput('');
  const { value: batchId, setValue: setBatchId } = useInput('');
  const { value: status, setValue: setStatus } = useInput('');
  const { value: initialDate, setValue: setInitialDate } = useInput('');
  const { value: finalDate, setValue: setFinalDate } = useInput('');

  /*eslint-disable */
  useEffect(() => {
    if (isNormalUser) {
      setFilteredInstitution(institution.id_institution.toString());
    } else {
      dispatch(getAllInstitutionsRequest());
    }
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(cleanState());
    };
  }, []);

  useEffect(() => {
    dispatch(SelectedData.selectInstitution(filteredInstitution));
  }, [filteredInstitution]);

  useEffect(() => {
    if (isAllInstitutions || filteredInstitution) setError(null);
  }, [isAllInstitutions, filteredInstitution]);

  function handleInstitutionFilter(institution) {
    setFilteredInstitution(institution.value);
  }

  const getRegistries = useCallback(() => {
    const query = {
      ...(!isAllInstitutions && { institutionId: filteredInstitution }),
      ...(!isAllPeriod && { initialDate, finalDate }),
      status,
      documentId,
      batchId,
      page,
      limit: itemsSize,
    };

    dispatch(
      getAllBatchesRequest(query, () => {
        setShowFilter(false);
        scrollMore(180);
      })
    );
  }, [
    isAllInstitutions,
    filteredInstitution,
    isAllPeriod,
    initialDate,
    finalDate,
    status,
    documentId,
    batchId,
    itemsSize,
    page,
  ]);

  useEffect(() => {
    if (total > 0) getRegistries();
  }, [page, itemsSize]);

  function handleSubmit(e) {
    e.preventDefault();
    setError(null);

    if (
      !isAllInstitutions &&
      (!filteredInstitution || filteredInstitution === '')
    )
      return setError('Selecione uma instituição');

    if (
      !isAllPeriod &&
      initialDate &&
      initialDate !== '' &&
      finalDate &&
      finalDate !== '' &&
      dateFns.isAfter(
        dateFns.parseISO(initialDate),
        dateFns.parseISO(finalDate)
      )
    ) {
      return setError('A data final precisa ser maior que a data inicial');
    }

    getRegistries();
  }

  function getBatchDetails(id) {
    navigate(`/registry-batch/${id}`);
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Gerenciar lotes"
          subtitle="Gestão de registros"
          className="text-sm-left"
        />
      </Row>
      {/* Filters */}
      <Row id="form-container">
        <Col>
          <Card className="mb-3" small>
            <CardHeader className="border-bottom">
              <Row>
                <Col sm="6">
                  <h6 className="m-0 font-weight-bold">Filtros</h6>
                </Col>
                <Col sm="6" className="text-right">
                  <Button
                    theme="light"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <span className="mr-2">
                      {showFilter ? <FaArrowUp /> : <FaArrowDown />}
                    </span>
                    {showFilter ? 'Esconder' : 'Mostrar'}
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <Collapse open={showFilter}>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form onSubmit={handleSubmit}>
                        <Row form>
                          <Col md="6" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Instituição
                            </span>
                            <Row className="pt-2">
                              <Col md="8">
                                <SearchSelect
                                  placeholder="Pesquisa por nome, sigla ou client_id"
                                  onSelect={handleInstitutionFilter}
                                  value={filteredInstitution}
                                  options={
                                    institutions &&
                                    institutions.map(i => ({
                                      label: `${i.name} - ${i.initials}`,
                                      value: i._id,
                                      client_id: i.client_id,
                                    }))
                                  }
                                  styleContainer={{
                                    marginTop: '0px',
                                  }}
                                  disabled={isAllInstitutions}
                                />
                              </Col>
                              <Col md="4" className="pt-2 pl-0">
                                <FormCheckbox
                                  checked={isAllInstitutions}
                                  onChange={() =>
                                    setIsAllInstitutions(!isAllInstitutions)
                                  }
                                >
                                  <span className="text-dark">Ver todas</span>
                                </FormCheckbox>
                              </Col>
                            </Row>
                          </Col>
                          <Col md="6" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Status
                            </span>
                            <Row className="pt-2">
                              <Col md="12">
                                <FormSelect
                                  id="status"
                                  value={status}
                                  onChange={e => setStatus(e.target.value)}
                                >
                                  <option value="">Todos</option>
                                  {batchRegistriesStatus &&
                                    batchRegistriesStatus.map(dType => (
                                      <option
                                        key={dType.value}
                                        value={dType.value}
                                      >
                                        {dType.label}
                                      </option>
                                    ))}
                                </FormSelect>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="6" className="form-group">
                            <span className="text-dark font-weight-bold">
                              ID do lote
                            </span>
                            <Row className="pt-2">
                              <Col md="12">
                                <FormInput
                                  type="number"
                                  value={batchId}
                                  onChange={e => setBatchId(e.target.value)}
                                  min="1"
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col md="6" className="form-group">
                            <span className="text-dark font-weight-bold">
                              ID do documento
                            </span>
                            <Row className="pt-2">
                              <Col md="12">
                                <FormInput
                                  type="number"
                                  value={documentId}
                                  onChange={e => setDocumentId(e.target.value)}
                                  min="1"
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="4" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Data inicial
                            </span>
                            <FormInput
                              type="date"
                              className="mt-2"
                              disabled={isAllPeriod}
                              value={initialDate}
                              onChange={e => setInitialDate(e.target.value)}
                              required={!isAllPeriod}
                            />
                          </Col>
                          <Col md="8" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Data Final
                            </span>
                            <Row className="pt-2">
                              <Col md="6">
                                <FormInput
                                  type="date"
                                  disabled={isAllPeriod}
                                  value={finalDate}
                                  onChange={e => setFinalDate(e.target.value)}
                                  required={!isAllPeriod}
                                />
                              </Col>
                              <Col md="4" className="pt-2 pl-0">
                                <FormCheckbox
                                  checked={isAllPeriod}
                                  onChange={() => setIsAllPeriod(!isAllPeriod)}
                                >
                                  <span className="text-dark">
                                    Intervalo completo
                                  </span>
                                </FormCheckbox>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="8">
                            <ErrorBox error={error} />
                          </Col>
                          <Col md="4" className="text-right">
                            <Button className="btn-primary ml-3">
                              Consultar
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Collapse>
          </Card>
        </Col>
      </Row>
      {loading && <LoadingContainer loading={loading} />}
      {/* DASHBOARD */}
      {!loading && batches && (
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3 pl-3 pr-3 overflow-auto">
                {loading && <LoadingContainer loading={loading} />}
                {!loading && batches.length > 0 && (
                  <>
                    <table className="table mb-0">
                      <thead className="bg-light">
                        <tr>
                          <th scope="col" className="border-0">
                            #
                          </th>
                          <th scope="col" className="border-0">
                            Status
                          </th>
                          <th scope="col" className="border-0">
                            Criado em
                          </th>
                          <th scope="col" className="border-0">
                            Modificado em
                          </th>
                          <th scope="col" className="border-0">
                            Ações
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {batches &&
                          batches.map(s => (
                            <tr key={s._id}>
                              <td>{s._id}</td>
                              <td>
                                {batchRegistriesStatus.find(
                                  item => item.value === s.statusId
                                )?.label ?? s.state}
                              </td>
                              <td>{dateAndHour(s.createdAt)}</td>
                              <td>{dateAndHour(s.updatedAt)}</td>
                              <td>
                                <ButtonGroup size="sm">
                                  <Button
                                    theme="white"
                                    onClick={() => getBatchDetails(s._id)}
                                  >
                                    <span className="text-light">
                                      <FiZoomIn />
                                    </span>{' '}
                                    Detalhes
                                  </Button>
                                </ButtonGroup>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <Pagination
                      total={total}
                      page={page}
                      items={itemsSize}
                      onChangePage={e => {
                        scrollToTop(e);
                        setPage(e);
                      }}
                      onChangeItems={e => {
                        scrollToTop();
                        setItemsSize(e);
                      }}
                    />
                  </>
                )}
                {batches.length === 0 && (
                  <div className="col-sm-12 d-flex justify-content-center">
                    <h5 className="mt-3">
                      <FiAlertCircle className="mr-2" />
                      Não existem lotes cadastrados para os filtros selecionados
                    </h5>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
}
