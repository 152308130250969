/* getUserData */
export function getUserRequest() {
  return {
    type: '@profile/GET_USER_REQUEST',
  };
}
export function getUserSuccess(data) {
  return {
    type: '@profile/GET_USER_SUCCESS',
    payload: { data },
  };
}
/* Update */
export function updateUserRequest(data) {
  return {
    type: '@profile/UPDATE_USER_REQUEST',
    payload: { data },
  };
}
export function updateUserSuccess(data) {
  return {
    type: '@profile/UPDATE_USER_SUCCESS',
    payload: { data },
  };
}
/* Failure */
export function userFailure() {
  return {
    type: '@profile/USER_FAILURE',
  };
}
