/* List */
export function getAllDDRConfigRequest() {
  return {
    type: '@ddrconfig/GET_ALL_DDRCONFIGS_REQUEST',
  };
}
export function getAllDDRConfigSuccess(ddrConfigs) {
  return {
    type: '@ddrconfig/GET_ALL_DDRCONFIGS_SUCCESS',
    payload: { ddrConfigs },
  };
}
export function getDDRConfigByIdRequest(id, index) {
  return {
    type: '@ddrconfig/GET_DDRCONFIG_BY_ID_REQUEST',
    payload: { id, index },
  };
}
export function getDDRConfigByIdSuccess(DDRConfig, index) {
  return {
    type: '@ddrconfig/GET_DDRCONFIG_BY_ID_SUCCESS',
    payload: { DDRConfig, index },
  };
}

/* Store */
export function storeDDRConfigRequest(data, onSuccess) {
  return {
    type: '@ddrconfig/STORE_DDRCONFIG_REQUEST',
    payload: { data, onSuccess },
  };
}
export function storeDDRConfigSuccess(DDRConfig) {
  return {
    type: '@ddrconfig/STORE_DDRCONFIG_SUCCESS',
    payload: { DDRConfig },
  };
}

/* Update */
export function updateDDRConfigRequest(id, data, index, onSuccess) {
  return {
    type: '@ddrconfig/UPDATE_DDRCONFIG_REQUEST',
    payload: { id, data, index, onSuccess },
  };
}
export function updateDDRConfigSuccess(index, data) {
  return {
    type: '@ddrconfig/UPDATE_DDRCONFIG_SUCCESS',
    payload: { index, data },
  };
}

/* Delete */
export function deleteDDRConfigRequest(id, index) {
  return {
    type: '@ddrconfig/DELETE_DDRCONFIG_REQUEST',
    payload: { id, index },
  };
}
export function deleteDDRConfigSuccess(index) {
  return {
    type: '@ddrconfig/DELETE_DDRCONFIG_SUCCESS',
    payload: { index },
  };
}

/* Failure */
export function DDRConfigFailure() {
  return {
    type: '@ddrconfig/DDRCONFIG_FAILURE',
  };
}
