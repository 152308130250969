import { combineReducers } from 'redux';

import auth from './auth/reducer';
import institution from './institution/reducer';
import signer from './signer/reducer';
import presigner from './presigner/reducer';
import precourse from './preCourse/reducer';
import signatureItem from './signatureItem/reducer';
import tag from './tag/reducer';
import course from './course/reducer';
import documentType from './documentType/reducer';
import documentGroup from './documentGroup/reducer';
import signatureConfiguration from './signatureConfig/reducer';
import documentConfiguration from './documentConfig/reducer';
import user from './user/reducer';
import profile from './profile/reducer';
import selectedData from './selectedData/reducer';
import documents from './documents/reducer';
import reports from './reports/reducer';
import logs from './logs/reducer';
import ddrconfig from './DDRConfig/reducer';
import generalDocuments from './generalDocuments/reducer';
import batchRegistry from './batchRegistry/reducer';

export default combineReducers({
  auth,
  selectedData,
  institution,
  signer,
  presigner,
  precourse,
  signatureItem,
  tag,
  course,
  documentType,
  documentGroup,
  signatureConfiguration,
  documentConfiguration,
  user,
  profile,
  documents,
  reports,
  logs,
  ddrconfig,
  generalDocuments,
  batchRegistry,
});
