import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ButtonGroup,
  Button,
  Form,
  ListGroup,
  ListGroupItem,
  FormInput,
  FormSelect,
  FormCheckbox,
} from 'shards-react';

import { FaRegEdit, FaRegTrashAlt, FaPlus } from 'react-icons/fa';
import { FiAlertCircle } from 'react-icons/fi';

import Swal from 'sweetalert2';
import { ButtonWrapper, InputWrapper } from '../common/styles';

import { ValueInput } from './styles';

import PageTitle from '../../components/common/PageTitle';
import LoadingContainer from '../../components/layout/LodingContainer';

import { useInput } from '../../hooks/input-hook';

import ErrorBox from '../../components/common/ErrorBox';
import {
  isValidField,
  isNumber,
  isValidMimeType,
  isValidJson,
  isValidProperties,
} from '../../utils/validations';
import { scrollToTop, scrollToForm } from '../../utils/scrolls';

import {
  getAllDocTypesRequest,
  getDocTypeByIdRequest,
  storeDocTypeRequest,
  updateDocTypeRequest,
  deleteDocTypeRequest,
} from '../../store/modules/documentType/actions';

export default function DocumentType() {
  const dispatch = useDispatch();

  const docTypes = useSelector((state) => state.documentType.doctypes);
  const docType = useSelector((state) => state.documentType.doctype);
  const loading = useSelector((state) => state.documentType.loading);
  const isSubmitting = useSelector((state) => state.documentType.isSubmitting);

  const {
    value: name,
    setValue: setName,
    bind: bindName,
    reset: resetName,
  } = useInput('');
  const {
    value: docId,
    setValue: setDocId,
    bind: bindDocId,
    reset: resetDocId,
  } = useInput('');
  const {
    value: mimeType,
    setValue: setMimeType,
    bind: bindMimeType,
    reset: resetMimeType,
  } = useInput('');
  const {
    value: documentSchemeData,
    setValue: setDocumentSchemeData,
    bind: bindDocumentSchemeData,
    reset: resetDocumentSchemeData,
  } = useInput('');

  const { value: authRequired, setValue: setAuthRequired } = useInput(false);
  const { value: skipRegistry, setValue: setSkipRegistry } = useInput(false);

  const [visibleProperties, setVisibleProperties] = useState([]);
  const [docTypeIdToUpdate, setDocTypeIdToUpdate] = useState('');
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(null);

  /*eslint-disable */
  useEffect(() => {
    dispatch(getAllDocTypesRequest());
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(docType).length !== 0 && updating) {
      setName(docType.name);
      setDocId(docType.docId);
      setMimeType(docType.mimeType);
      setDocumentSchemeData(docType.documentSchemeData || '');
      setVisibleProperties(docType.visibleProperties);
      setAuthRequired(docType.authRequired);
      setSkipRegistry(docType.skipRegistry);
      setDocTypeIdToUpdate(docType._id);
    }
  }, [docType]);
  /* eslint-enable */

  function resetState() {
    resetName();
    resetDocId();
    resetMimeType();
    resetDocumentSchemeData();
    setVisibleProperties([]);

    setDocTypeIdToUpdate('');
    setUpdating(false);
    setAuthRequired(false);
    setSkipRegistry(false);
    scrollToTop();
  }

  function handleCleanState(e) {
    e.preventDefault();
    resetName();
    resetDocId();
    resetMimeType();
    resetDocumentSchemeData();
    setVisibleProperties([]);

    setDocTypeIdToUpdate('');
    setUpdating(false);
    setAuthRequired(false);
    setSkipRegistry(false);
    scrollToTop();
  }

  function getDocTypeData(e, identifier, index) {
    e.preventDefault();
    dispatch(getDocTypeByIdRequest(identifier, index));
    setUpdating(true);
    scrollToForm();
  }

  async function handleUpdateDocType(e) {
    e.preventDefault();
    scrollToForm();
    const identifier = docTypeIdToUpdate;

    const data = {
      name,
      docId,
      mimeType,
      documentSchemeData,
      visibleProperties,
      authRequired,
      skipRegistry,
    };

    if (!validationSchema(data)) return;

    dispatch(
      updateDocTypeRequest(identifier, data, docType.index, () => {
        resetState();
        setDocTypeIdToUpdate('');
        setUpdating(false);
      })
    );
  }

  function handleDeleteDocType(e, identifier, index) {
    e.preventDefault();
    dispatch(deleteDocTypeRequest(identifier, index));
  }

  // eslint-disable-next-line no-shadow
  function handleConfirmation(e, identifier, index, name) {
    e.preventDefault();
    Swal.fire({
      title: 'Confirme para excluir',
      text: `Tem certeza que deseja remover ${name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        return handleDeleteDocType(e, identifier, index);
      }
      return false;
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    scrollToForm();
    const obj = {
      name,
      docId,
      mimeType,
      documentSchemeData,
      visibleProperties:
        visibleProperties.length > 0 ? visibleProperties : undefined,
      authRequired,
      skipRegistry,
    };
    if (!validationSchema(obj)) return;
    dispatch(storeDocTypeRequest(obj, resetState));
  }

  function addInput(e) {
    e.preventDefault();
    const aux = [];
    if (visibleProperties !== undefined) {
      setVisibleProperties([...visibleProperties, {}]);
    } else {
      aux.push(undefined);
      setVisibleProperties(aux);
    }
  }
  async function handleVisiblePropertiesChange(e, index) {
    e.preventDefault();
    const { value, name: targetName } = e.target;
    const aux = visibleProperties.map((item) => ({ ...item }));

    if (targetName === 'name') {
      aux[index].name = value;
    } else {
      aux[index].key = value;
    }
    setVisibleProperties(aux);
  }
  function handleVisiblePropertiesDelete(e, index) {
    e.preventDefault();
    const aux = [...visibleProperties];
    aux.splice(index, 1);
    setVisibleProperties(aux);
  }

  function validationSchema(obj) {
    if (!isValidField(obj.name)) {
      setError('O nome é obrigatório');
      return false;
    }
    if (!isNumber(obj.docId)) {
      setError('O identificador contém um formato inválido');
      return false;
    }
    if (!isValidMimeType(obj.mimeType)) {
      setError('O MimeType contém um formato inválido');
      return false;
    }
    if (!isValidField(obj.documentSchemeData)) {
      setError('O esquema de dados é obrigatório');
      return false;
    }
    if (!isValidJson(obj.documentSchemeData)) {
      setError('O esquema de dados contém um formato JSON inválido');
      return false;
    }
    if (obj.visibleProperties) {
      if (!isValidProperties(obj.visibleProperties)) {
        setError('A propriedade informada contém um formato inválido');
        return false;
      }
    }

    setError(null);
    return true;
  }
  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Tipo de documento"
          subtitle="Gerenciamento"
          className="text-sm-left"
        />
      </Row>

      {/* Default Light Table */}
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0 font-weight-bold">
                Lista de tipos de documentos
              </h6>
            </CardHeader>
            <CardBody className="p-0 pb-3 pl-3 pr-3 overflow-auto">
              {loading ? (
                <LoadingContainer loading={loading} />
              ) : (
                <>
                  {docTypes.length !== 0 ? (
                    <table className="table mb-0">
                      <thead className="bg-light">
                        <tr>
                          <th scope="col" className="border-0">
                            #
                          </th>
                          <th scope="col" className="border-0">
                            Nome
                          </th>
                          <th scope="col" className="border-0">
                            Mimetype do documento
                          </th>
                          <th scope="col" className="border-0">
                            Ações
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {docTypes &&
                          docTypes.map((d, index) => (
                            <tr key={d._id}>
                              <td>{index}</td>
                              <td>{d.name}</td>
                              <td>{d.mimeType}</td>
                              <td>
                                <ButtonGroup size="sm">
                                  <Button
                                    theme="white"
                                    onClick={(e) =>
                                      getDocTypeData(e, d._id, index)
                                    }
                                  >
                                    <span className="text-light">
                                      <FaRegEdit />
                                    </span>{' '}
                                    Editar
                                  </Button>
                                  <Button
                                    onClick={(e) =>
                                      handleConfirmation(
                                        e,
                                        d._id,
                                        index,
                                        d.name
                                      )
                                    }
                                    theme="white"
                                  >
                                    <span className="text-light">
                                      <FaRegTrashAlt />
                                    </span>{' '}
                                    Excluir
                                  </Button>
                                </ButtonGroup>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="col-sm-12 d-flex justify-content-center">
                      <h5 className="mt-3">
                        <FiAlertCircle className="mr-2" />
                        Não existem tipos de documentos cadastrados.
                      </h5>
                    </div>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row id="form-container">
        <Col>
          <Card className="mb-3" small>
            <CardHeader className="border-bottom">
              <h6 className="m-0 font-weight-bold">
                Informações do tipo de documento
              </h6>
            </CardHeader>
            <ListGroup flush>
              <ListGroupItem className="p-3">
                <Row>
                  <Col>
                    <ErrorBox error={error} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form onSubmit={handleSubmit} id="edit">
                      <Row form>
                        <Col md="6" className="form-group">
                          <InputWrapper>
                            <label htmlFor="docTypeName">Nome</label>
                            <FormInput
                              id="docTypeName"
                              name="name"
                              type="text"
                              placeholder="Histórico"
                              {...bindName}
                            />
                            <span className="text-muted">
                              *Insira o nome do tipo de documento.
                            </span>
                          </InputWrapper>
                        </Col>
                        <Col md="6" className="form-group">
                          <InputWrapper>
                            <label htmlFor="docTypeId">
                              Identificador do tipo de documento
                            </label>
                            <FormInput
                              id="docTypeId"
                              type="text"
                              placeholder="3"
                              name="docId"
                              {...bindDocId}
                            />
                            <span className="text-muted">
                              *Insira um valor que identificará o tipo do
                              documento.
                            </span>
                          </InputWrapper>
                        </Col>
                      </Row>
                      <Row form>
                        <Col md="6" className="form-group">
                          <InputWrapper>
                            <label htmlFor="docMimeType">
                              MimeType do documento
                            </label>

                            <FormSelect
                              name="mimeType"
                              {...bindMimeType}
                              id="docMimeType"
                            >
                              <option disabled value="">
                                Escolher...
                              </option>
                              <option value="application/xml">
                                application/xml
                              </option>
                              <option value="application/pdf">
                                application/pdf
                              </option>
                            </FormSelect>
                            <span className="text-muted">
                              *Insira a topologia de metadados que o documento
                              transportará.
                            </span>
                          </InputWrapper>
                        </Col>
                        <Col md="6" className="form-group">
                          <InputWrapper>
                            <label htmlFor="docTypeSchema">
                              Esquema de dados utilizado pelo documento
                            </label>
                            <FormInput
                              id="docTypeSchema"
                              type="text"
                              placeholder='{"valor":"1,30"}'
                              name="documentSchemeData"
                              {...bindDocumentSchemeData}
                            />
                            <span className="text-muted">
                              *Insira um Json que represente a estrutura do
                              documento.
                            </span>
                          </InputWrapper>
                        </Col>
                      </Row>

                      {visibleProperties !== undefined &&
                        visibleProperties.length !== 0 &&
                        visibleProperties.map((v, index) => (
                          <Row form key={index}>
                            <Col md="4" className="form-group">
                              <InputWrapper>
                                <label htmlFor="docPropName">
                                  Propriedades visíveis do documento
                                </label>
                                <FormInput
                                  id="docPropName"
                                  type="text"
                                  placeholder="Curse"
                                  value={v.name === undefined ? '' : v.name}
                                  name="name"
                                  onChange={(e) =>
                                    handleVisiblePropertiesChange(e, index)
                                  }
                                />
                                <span className="text-muted">
                                  *Nome da propriedade.
                                </span>
                              </InputWrapper>
                            </Col>
                            <Col md="4" className="form-group">
                              <ValueInput>
                                <InputWrapper>
                                  <FormInput
                                    id="docPropValue"
                                    name="key"
                                    value={v.key === undefined ? '' : v.key}
                                    type="text"
                                    placeholder="data.infDiploma.DadosDiploma.Diplomado.Curse"
                                    onChange={(e) =>
                                      handleVisiblePropertiesChange(e, index)
                                    }
                                  />
                                  <span className="text-muted">
                                    *Valor da propriedade.
                                  </span>
                                </InputWrapper>
                              </ValueInput>
                            </Col>
                            <Col md="4">
                              <ButtonWrapper>
                                <Button
                                  onClick={(e) =>
                                    handleVisiblePropertiesDelete(e, index)
                                  }
                                  theme="white"
                                >
                                  <span className="text-light">
                                    <FaRegTrashAlt />
                                  </span>{' '}
                                  Excluir
                                </Button>
                              </ButtonWrapper>
                            </Col>
                          </Row>
                        ))}
                      <Row className="mt-0">
                        <Col md="6" className="p-0 d-flex">
                          {!isSubmitting && (
                            <>
                              <Button
                                onClick={(e) => addInput(e)}
                                theme="white"
                                className="ml-3"
                              >
                                <span className="text-light">
                                  <FaPlus />
                                </span>{' '}
                                Adicionar propriedade
                              </Button>
                              {visibleProperties.length === 0 && (
                                <p className="m-2 text-secondary">
                                  Nenhuma propriedade adicionada
                                </p>
                              )}
                            </>
                          )}
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col md="6" className="form-group mt-0">
                          <FormCheckbox
                            inline
                            toggle
                            checked={authRequired}
                            onChange={() => setAuthRequired((prev) => !prev)}
                          >
                            <span className="text-dark">
                              Requer autorização para processar
                            </span>
                          </FormCheckbox>
                        </Col>
                        <Col md="6" className="form-group mt-0">
                          <FormCheckbox
                            inline
                            toggle
                            checked={skipRegistry}
                            onChange={() => setSkipRegistry((prev) => !prev)}
                          >
                            <span className="text-dark">
                              Ignorar o registro no RAP
                            </span>
                          </FormCheckbox>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col md="12" className="text-right">
                          {updating ? (
                            <>
                              {!isSubmitting && (
                                <Button
                                  className="btn btn-danger btn-sm"
                                  onClick={handleCleanState}
                                >
                                  Cancelar
                                </Button>
                              )}
                              <Button
                                className="btn-primary ml-3"
                                onClick={handleUpdateDocType}
                                disabled={isSubmitting}
                              >
                                {isSubmitting ? 'Carregando...' : 'Atualizar'}
                              </Button>
                            </>
                          ) : (
                            <Button type="submit" disabled={isSubmitting}>
                              {isSubmitting
                                ? 'Carregando...'
                                : 'Criar novo tipo'}
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
