import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  background: #5a6169;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  form {
    min-width: 180px;
    min-height: 180px;
    input {
      width: 100%;
      height: 38px;

      &::placeholder {
        font-weight: 400;
      }
    }
    button {
      width: 100%;
      height: 38px;
      font-weight: bold;
    }
  }

  img {
    width: 120px;
    height: 49px;
  }
`;
