import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

/* signItems */
export default function CollapsibleRow({
  children,
  key,
  show = false,
  Component,
}) {
  const [open, setOpen] = useState(false);

  function handleShow(e) {
    if (e.target.type === 'button') return;

    setOpen((prev) => !prev);
  }

  useEffect(() => {
    setOpen(show);
  }, [show]);

  return (
    <>
      <tr key={key} onClick={handleShow}>
        {children}
      </tr>
      {open && <Component />}
    </>
  );
}

CollapsibleRow.propTypes = {
  children: PropTypes.shape(),
  key: PropTypes.string,
  show: PropTypes.bool,
  Component: PropTypes.func,
};

CollapsibleRow.defaultProps = {
  children: {},
  key: '',
  show: false,
  Component: () => {},
};
