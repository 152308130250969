import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Tooltip,
  Cell,
  Legend,
} from 'recharts';
import * as dateFns from 'date-fns';
import { useCurrentPng } from 'recharts-to-png';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { toast } from 'react-toastify';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  ListGroup,
  ListGroupItem,
  Form,
  FormCheckbox,
  Collapse,
  FormSelect,
  FormInput,
} from 'shards-react';

import { FaArrowUp, FaArrowDown, FaRegCopy } from 'react-icons/fa';

import {
  ImFilePdf,
  ImProfile,
  ImFileText2,
  ImPencil,
  ImFilePlay,
} from 'react-icons/im';

import { useInput } from '../../hooks/input-hook';

import PageTitle from '../../components/common/PageTitle';
import LoadingContainer from '../../components/layout/LodingContainer';

import SearchSelect from '../../components/common/SearchSelect';

import { getAllInstitutionsRequest } from '../../store/modules/institution/actions';
import {
  getSignatureReport,
  cleanState,
} from '../../store/modules/reports/actions';
import * as SelectedData from '../../store/modules/selectedData/actions';

import { normalOrManager } from '../../config/UserPermission';
import ErrorBox from '../../components/common/ErrorBox';
import { scrollToBottom } from '../../utils/scrolls';
import { generateColors } from '../../utils/chartColors';
import { documentTypes } from '../../config/Constants';

export default function SignatureReports() {
  const dispatch = useDispatch();

  const [getStatusChart, statusChartRef] = useCurrentPng();
  const [getDocTypeChart, docTypeChartRef] = useCurrentPng();

  const institutions = useSelector((state) => state.institution.institutions);
  const { loading, data } = useSelector((state) => state.reports);

  const selectedInstitution = useSelector(
    (state) => state.selectedData.selectedInstitution
  );

  const { user, permission } = useSelector((state) => state.auth);
  const { institution } = user;

  const isNormalUser = normalOrManager.includes(permission);

  const [error, setError] = useState(null);
  const [showFilter, setShowFilter] = useState(true);
  const [isAllInstitutions, setIsAllInstitutions] = useState(false);
  const [isAllDocumentType, setIsAllDocumentType] = useState(false);
  const [isAllPeriod, setIsAllPeriod] = useState(true);
  const { value: filteredInstitution, setValue: setFilteredInstitution } =
    useInput(selectedInstitution);

  const { value: docTypeId, setValue: setDocTypeId } = useInput('');
  const { value: initialDate, setValue: setInitialDate } = useInput('');
  const { value: finalDate, setValue: setFinalDate } = useInput('');

  const [chartDocTypeData, setChartDocTypeData] = useState([]);
  const [chartStatusData, setChartStatusData] = useState([]);

  /*eslint-disable */
  useEffect(() => {
    if (isNormalUser) {
      setFilteredInstitution(institution.id_institution.toString());
    } else {
      dispatch(getAllInstitutionsRequest());
    }
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(cleanState());
    }
  }, []);

  useEffect(() => {
    if (isAllInstitutions || filteredInstitution)
      setError(null);
  }, [isAllInstitutions, filteredInstitution]);

  useEffect(() => {
    if (!data.signaturesByInstitution) return;
    const colorsToDocTypeChart = generateColors(documentTypes.length);
    setChartDocTypeData(documentTypes.map((documentType, index) => ({
      name: documentType.label,
      value: getQuantityByDocType(data.quantityByDocumentType, documentType.value),
      color: colorsToDocTypeChart[index]
    })));

    const colorsToInstitutionChart = generateColors(data.signaturesByInstitution.length);
    setChartStatusData(data.signaturesByInstitution.map((institution, index) => ({
      name: institution.name.slice(0, 30),
      value: institution.quantity,
      color: colorsToInstitutionChart[index]
    })));
  }, [data]);

  useEffect(() => {
    dispatch(SelectedData.selectInstitution(filteredInstitution));
  }, [filteredInstitution]);


  function handleInstitutionFilter(institution) {
    setFilteredInstitution(institution.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setError(null);

    if (!isAllInstitutions &&
      (!filteredInstitution || filteredInstitution === ''))
      return setError("Selecione uma instituição");
    if (!isAllPeriod
      && (initialDate && initialDate !== '')
      && (finalDate && finalDate !== '')
      && dateFns.isAfter(dateFns.parseISO(initialDate),
        dateFns.parseISO(finalDate))
    ) {
      return setError("A data final precisa ser maior que a data inicial");
    }

    const query = {};

    Object.assign(
      query,
      !isAllInstitutions && {
        institutionId: filteredInstitution,
      },
      !isAllDocumentType && {
        docTypeId
      },
      !isAllPeriod && {
        initialDate,
        finalDate
      }
    )


    dispatch(getSignatureReport(query, () => {
      setShowFilter(false);
      scrollToBottom();

    }))
  }

  function getQuantityByDocType(listOsDocTypes, docTypeId) {
    if (!listOsDocTypes) return 0;
    const find = listOsDocTypes.find(item => {
      return item.id_document_type === docTypeId;
    });

    return find ? find.quantity : 0;
  }

  const generateImageFromChart = useCallback(async (getPngFromChart) => {
    const png = await getPngFromChart();

    try {
      if (!png) throw new Error();

      const data = await fetch(png);
      const blob = await data.blob();

      await navigator.clipboard.write([
        new ClipboardItem({
          [blob.type]: blob
        })
      ]);

      toast.info('Copiado!', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 'custom-id-yes',
      });
    } catch (error) {
      toast.error('Erro ao copiar gráfico', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 'custom-id-yes',
      });
    }
  }, [getStatusChart, getDocTypeChart]);

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Registros de assinatura"
          subtitle="Estatísticas"
          className="text-sm-left"
        />
      </Row>
      {/* Filters */}
      <Row id="form-container">
        <Col>
          <Card className="mb-3" small>
            <CardHeader className="border-bottom">
              <Row>
                <Col sm="6">
                  <h6 className="m-0 font-weight-bold">
                    Filtros
                  </h6>
                </Col>
                <Col sm="6" className="text-right">
                  <Button theme="light" onClick={() => setShowFilter(!showFilter)}>
                    <span className="mr-2">
                      {showFilter ? <FaArrowUp /> : <FaArrowDown />}
                    </span>
                    {showFilter ? "Esconder" : "Mostrar"}
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <Collapse open={showFilter}>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form onSubmit={handleSubmit}>
                        <Row form>
                          <Col md="6" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Instituição
                            </span>
                            <Row className="pt-2">
                              <Col md="8">
                                <SearchSelect
                                  placeholder="Pesquisa por nome, sigla ou client_id"
                                  onSelect={handleInstitutionFilter}
                                  value={filteredInstitution}
                                  options={
                                    institutions &&
                                    institutions.map(i => ({
                                      label: `${i.name} - ${i.initials}`,
                                      value: i._id,
                                      client_id: i.client_id
                                    }))
                                  }
                                  styleContainer={{
                                    marginTop: '0px'
                                  }}
                                  disabled={isAllInstitutions}
                                />
                              </Col>
                              <Col md="4" className="pt-2 pl-0">
                                <FormCheckbox
                                  checked={isAllInstitutions}
                                  onChange={() => setIsAllInstitutions(!isAllInstitutions)}
                                >
                                  <span className="text-dark">
                                    Ver todas
                                  </span>
                                </FormCheckbox>
                              </Col>
                            </Row>
                          </Col>
                          <Col md="6" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Tipo de documento
                            </span>
                            <Row className="pt-2">
                              <Col md="8">
                                <FormSelect
                                  id="documentType"
                                  value={docTypeId}
                                  onChange={e =>
                                    setDocTypeId(e.target.value)
                                  }
                                  required={!isAllDocumentType}
                                  disabled={isAllDocumentType}
                                >
                                  <option value="" disabled>
                                    Escolher...
                                  </option>
                                  {documentTypes &&
                                    documentTypes.map(dType => (
                                      <option
                                        key={dType.value}
                                        value={dType.value}
                                      >
                                        {dType.label}
                                      </option>
                                    ))}
                                </FormSelect>
                              </Col>
                              <Col md="4" className="pt-2 pl-0">
                                <FormCheckbox
                                  checked={isAllDocumentType}
                                  onChange={() => setIsAllDocumentType(!isAllDocumentType)}
                                >
                                  <span className="text-dark">
                                    Ver todos
                                  </span>
                                </FormCheckbox>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="4" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Data inicial
                            </span>
                            <FormInput
                              type="date"
                              className="mt-2"
                              disabled={isAllPeriod}
                              value={initialDate}
                              onChange={(e) => setInitialDate(e.target.value)}
                              required={!isAllPeriod}
                            />
                          </Col>
                          <Col md="8" className="form-group">
                            <span className="text-dark font-weight-bold">
                              Data Final
                            </span>
                            <Row className="pt-2">
                              <Col md="6">
                                <FormInput
                                  type="date"
                                  disabled={isAllPeriod}
                                  value={finalDate}
                                  onChange={(e) => setFinalDate(e.target.value)}
                                  required={!isAllPeriod}
                                />
                              </Col>
                              <Col md="4" className="pt-2 pl-0">
                                <FormCheckbox
                                  checked={isAllPeriod}
                                  onChange={() => setIsAllPeriod(!isAllPeriod)}
                                >
                                  <span className="text-dark">
                                    Intervalo completo
                                  </span>
                                </FormCheckbox>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="8">
                            <ErrorBox error={error} />
                          </Col>
                          <Col md="4" className="text-right">
                            <Button
                              className="btn-primary ml-3"
                            // onClick={handleCleanState}
                            >
                              Consultar
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Collapse>
          </Card>
        </Col>
      </Row>
      {loading && <LoadingContainer loading={loading} />}
      {/* DASHBOARD */}
      {!loading && data && data.quantityByDocumentType && (
        <>
          <Row className="p-3">
            <Col sm="12" className="text-center mt-2">
              <h4>
                Resultados obtidos
              </h4>
            </Col>
          </Row>
          <Row>
            <Col sm="4">
              <Card>
                <CardBody className="text-center px-3 py-2">
                  <h4 className="text-primary mb-1">
                    <ImPencil />
                  </h4>
                  <h3 className="m-0 font-weight-bold">
                    {data.total || 0}
                  </h3>
                  <span className="text-muted">
                    Total de assinaturas
                  </span>
                </CardBody>
              </Card>
            </Col>
            <Col sm="4">
              <Card>
                <CardBody className="text-center px-3 py-2">
                  <h4 className="text-primary mb-1">
                    <ImProfile />
                  </h4>
                  <h3 className="m-0 font-weight-bold">
                    {getQuantityByDocType(data.quantityByDocumentType, documentTypes[2].value)}
                  </h3>
                  <span className="text-muted">
                    Documentação acadêmica
                  </span>
                </CardBody>
              </Card>
            </Col>
            <Col sm="4">
              <Card>
                <CardBody className="text-center px-3 py-2">
                  <h4 className="text-primary mb-1">
                    <ImProfile />
                  </h4>
                  <h3 className="m-0 font-weight-bold">
                    {getQuantityByDocType(data.quantityByDocumentType, documentTypes[4].value)}
                  </h3>
                  <span className="text-muted">
                    Documentação acadêmica externa
                  </span>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col sm="3">
              <Card>
                <CardBody className="text-center px-3 py-2">
                  <h4 className="text-primary mb-1">
                    <ImFileText2 />
                  </h4>
                  <h3 className="m-0 font-weight-bold">
                    {getQuantityByDocType(data.quantityByDocumentType, documentTypes[0].value)}
                  </h3>
                  <span className="text-muted">
                    Diploma digital
                  </span>
                </CardBody>
              </Card>
            </Col>
            <Col sm="3">
              <Card>
                <CardBody className="text-center px-3 py-2">
                  <h4 className="text-primary mb-1">
                    <ImFileText2 />
                  </h4>
                  <h3 className="m-0 font-weight-bold">
                    {getQuantityByDocType(data.quantityByDocumentType, documentTypes[1].value)}
                  </h3>
                  <span className="text-muted">
                    Diploma externo
                  </span>
                </CardBody>
              </Card>
            </Col>
            <Col sm="3">
              <Card>
                <CardBody className="text-center px-3 py-2">
                  <h4 className="text-primary mb-1">
                    <ImFilePdf />
                  </h4>
                  <h3 className="m-0 font-weight-bold">
                    {getQuantityByDocType(data.quantityByDocumentType, documentTypes[5].value)}
                  </h3>
                  <span className="text-muted">
                    Representação visual
                  </span>
                </CardBody>
              </Card>
            </Col>
            <Col sm="3">
              <Card>
                <CardBody className="text-center px-3 py-2">
                  <h4 className="text-primary mb-1">
                    <ImFilePlay />
                  </h4>
                  <h3 className="m-0 font-weight-bold">
                    {getQuantityByDocType(data.quantityByDocumentType, documentTypes[4].value)}
                  </h3>
                  <span className="text-muted">
                    Prova de Existência de Vídeo
                  </span>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="6" className="mt-4">
              <Card>
                <CardBody className="text-center px-3 py-0">
                  <Col md="12" className="d-flex pt-1">
                    <h5 className="pt-3 flex-fill">
                      Tipos de documento
                    </h5>
                    <Button
                      data-tip="Copiar gráfico"
                      outline
                      pill
                      onClick={() => generateImageFromChart(getDocTypeChart)}
                      theme="light"
                      disabled={docTypeChartRef.isLoading}
                    >
                      <FaRegCopy />
                    </Button>
                  </Col>
                  <Col md="12" className="px-0">
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart width={400} height={400} ref={docTypeChartRef.ref}>
                        <Pie
                          dataKey="value"
                          isAnimationActive={true}
                          data={chartDocTypeData}
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          label
                        >
                          {chartDocTypeData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend
                          layout="vertical"
                          verticalAlign="middle"
                          align="left"
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  </Col>
                </CardBody>
              </Card>
            </Col>

            <Col md="6" className="mt-4">
              <Card>
                <CardBody className="text-center px-3 py-0">
                  <Col md="12" className="d-flex pt-1">
                    <h5 className="pt-3 flex-fill">
                      Instituições
                    </h5>
                    <Button
                      data-tip="Copiar gráfico"
                      outline
                      pill
                      onClick={() => generateImageFromChart(getStatusChart)}
                      theme="light"
                      disabled={statusChartRef.isLoading}
                    >
                      <FaRegCopy />
                    </Button>
                  </Col>
                  <Col md="12" className="px-0">
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart width={400} height={400} ref={statusChartRef.ref}>
                        <Pie
                          dataKey="value"
                          isAnimationActive={true}
                          data={chartStatusData}
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          label
                        >
                          {chartDocTypeData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend
                          layout="vertical"
                          verticalAlign="middle"
                          align="left"
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  </Col>
                </CardBody>
              </Card>
            </Col>
            <ReactTooltip />
          </Row>
        </>
      )}
    </Container>
  );
}
