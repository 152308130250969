/* List */
export function getAllSignatureItemsRequest() {
  return {
    type: '@signatureItem/GET_ALL_SIGITEMS_REQUEST',
  };
}
export function getAllSignatureItemsSuccess(sigItems) {
  return {
    type: '@signatureItem/GET_ALL_SIGITEMS_SUCCESS',
    payload: { sigItems },
  };
}
export function getSignatureItemByIdRequest(id, index) {
  return {
    type: '@signatureItem/GET_SIGITEM_BY_ID_REQUEST',
    payload: { id, index },
  };
}
export function getSignatureItemByIdSuccess(sigItem, index) {
  return {
    type: '@signatureItem/GET_SIGITEM_BY_ID_SUCCESS',
    payload: { sigItem, index },
  };
}

/* Store */
export function storeSignatureItemRequest(data, onSuccess) {
  return {
    type: '@signatureItem/STORE_SIGITEM_REQUEST',
    payload: { data, onSuccess },
  };
}
export function storeSignatureItemSuccess(sigItem) {
  return {
    type: '@signatureItem/STORE_SIGITEM_SUCCESS',
    payload: { sigItem },
  };
}

/* Update */
export function updateSignatureItemRequest(id, data, index, onSuccess) {
  return {
    type: '@signatureItem/UPDATE_SIGITEM_REQUEST',
    payload: { id, data, index, onSuccess },
  };
}
export function updateSignatureItemSuccess(index, data) {
  return {
    type: '@signatureItem/UPDATE_SIGITEM_SUCCESS',
    payload: { index, data },
  };
}
export function updateSignatureItemCancel() {
  return {
    type: '@signatureItem/UPDATE_SIGITEM_CANCEL',
  };
}
/* Delete */
export function deleteSignatureItemRequest(id, index) {
  return {
    type: '@signatureItem/DELETE_SIGITEM_REQUEST',
    payload: { id, index },
  };
}
export function deleteSignatureItemSuccess(index) {
  return {
    type: '@signatureItem/DELETE_SIGITEM_SUCCESS',
    payload: { index },
  };
}

/* Failure */
export function signatureItemFailure() {
  return {
    type: '@signatureItem/SIGITEM_FAILURE',
  };
}
