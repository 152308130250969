import { produce } from 'immer';

const INITIAL_STATE = {
  loading: false,
  isSubmitting: false,
  user: null,
};
export default function profile(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@profile/GET_USER_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@profile/GET_USER_SUCCESS': {
        draft.user = action.payload.data;
        draft.loading = false;
        break;
      }
      case '@profile/UPDATE_USER_REQUEST': {
        draft.isSubmitting = true;
        break;
      }
      case '@profile/UPDATE_USER_SUCCESS': {
        draft.isSubmitting = false;
        break;
      }
      case '@user/USER_FAILURE': {
        draft.isSubmitting = false;
        break;
      }
      default:
    }
  });
}
