import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ButtonGroup,
  Button,
  Form,
  ListGroup,
  ListGroupItem,
  FormInput,
} from 'shards-react';

import { FiAlertCircle } from 'react-icons/fi';
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';

import Swal from 'sweetalert2';
import { InputWrapper } from '../common/styles';

import { useInput } from '../../hooks/input-hook';

import PageTitle from '../../components/common/PageTitle';
import LoadingContainer from '../../components/layout/LodingContainer';

import { date, ISOToInputFormat } from '../../utils/dateFormatter';

import { scrollToForm, scrollToTop } from '../../utils/scrolls';

import {
  deleteDDRConfigRequest,
  getAllDDRConfigRequest,
  getDDRConfigByIdRequest,
  storeDDRConfigRequest,
  updateDDRConfigRequest,
} from '../../store/modules/DDRConfig/actions';

export default function DDRConfig() {
  const dispatch = useDispatch();

  const { ddrConfigs, ddrConfig, loading, isSubmitting } = useSelector(
    (state) => state.ddrconfig
  );

  const {
    value: docType,
    bind: bindDocType,
    reset: resetDocType,
    setValue: setDocType,
  } = useInput('');

  const {
    value: version,
    bind: bindVersion,
    reset: resetVersion,
    setValue: setVersion,
  } = useInput('');

  const {
    value: startDate,
    bind: bindStartDate,
    reset: resetStartDate,
    setValue: setStartDate,
  } = useInput('');

  const {
    value: finalDate,
    bind: bindFinalDate,
    reset: resetFinalDate,
    setValue: setFinalDate,
  } = useInput('');

  const [ddrConfigIdToUpdate, setDocGroupIdToUpdate] = useState('');
  const [updating, setUpdating] = useState(false);

  /*eslint-disable */
  useEffect(() => {
    dispatch(getAllDDRConfigRequest());
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(ddrConfig).length !== 0 && updating) {
      setDocType(ddrConfig.doc_type)
      setVersion(ddrConfig.version)
      setStartDate(ISOToInputFormat(ddrConfig.startLimit))
      setFinalDate(ISOToInputFormat(ddrConfig.finalLimit))
      setDocGroupIdToUpdate(ddrConfig.id)
    }
  }, [ddrConfig]);
  /* eslint-enable */

  function handleCleanState() {
    resetDocType();
    resetFinalDate();
    resetStartDate();
    resetVersion();

    setDocGroupIdToUpdate('');
    setUpdating(false);
    scrollToTop();
  }

  function getDdrConfigData(e, identifier, index) {
    e.preventDefault();
    dispatch(getDDRConfigByIdRequest(identifier, index));
    setUpdating(true);
    scrollToForm();
  }

  async function handleUpdateDdr(e) {
    e.preventDefault();

    const identifier = ddrConfigIdToUpdate;

    const data = {
      doc_type: docType,
      version,
      startLimit: startDate,
      finalLimit: finalDate,
    };
    dispatch(
      updateDDRConfigRequest(identifier, data, ddrConfig.index, () => {
        handleCleanState();
        setDocGroupIdToUpdate('');
        setUpdating(false);
      })
    );
  }

  function handleDeleteDdr(e, identifier, index) {
    e.preventDefault();
    dispatch(deleteDDRConfigRequest(identifier, index));
    handleCleanState();
  }
  // eslint-disable-next-line no-shadow
  function handleConfirmation(e, identifier, index, name) {
    e.preventDefault();
    Swal.fire({
      title: 'Confirme para excluir',
      text: `Tem certeza que deseja remover ${name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        return handleDeleteDdr(e, identifier, index);
      }
      return false;
    });
  }

  function handleSubmit(e) {
    e.preventDefault();

    const obj = {
      doc_type: docType,
      version,
      startLimit: startDate,
      finalLimit: finalDate,
    };

    dispatch(storeDDRConfigRequest(obj, handleCleanState));
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Controle de versão"
          subtitle="Configurações gerais"
          className="text-sm-left"
        />
      </Row>

      {/* Default Light Table */}
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0 font-weight-bold">
                Lista de controle de versão
              </h6>
            </CardHeader>
            <CardBody className="p-0 pb-3 pl-3 pr-3 overflow-auto">
              {loading ? (
                <LoadingContainer loading={loading} />
              ) : (
                <>
                  {ddrConfigs.length !== 0 ? (
                    <table className="table mb-0">
                      <thead className="bg-light">
                        <tr>
                          <th scope="col" className="border-0">
                            #
                          </th>
                          <th scope="col" className="border-0">
                            Tipo do documento
                          </th>
                          <th scope="col" className="border-0">
                            Versão
                          </th>
                          <th scope="col" className="border-0">
                            Data inicial
                          </th>
                          <th scope="col" className="border-0">
                            Data final
                          </th>
                          <th scope="col" className="border-0">
                            Ações
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {ddrConfigs &&
                          ddrConfigs.map((d, index) => (
                            <tr key={index}>
                              <td>{index}</td>
                              <td>{d.doc_type}</td>
                              <td>{d.version}</td>
                              <td>{date(d.startLimit)}</td>
                              <td>{date(d.finalLimit)}</td>
                              <td>
                                <ButtonGroup size="sm">
                                  <Button
                                    theme="white"
                                    onClick={(e) =>
                                      getDdrConfigData(e, d.id, index)
                                    }
                                  >
                                    <span className="text-light">
                                      <FaRegEdit />
                                    </span>{' '}
                                    Editar
                                  </Button>
                                  <Button
                                    onClick={(e) =>
                                      handleConfirmation(
                                        e,
                                        d.id,
                                        index,
                                        `${d.doc_type} ${d.version}`
                                      )
                                    }
                                    theme="white"
                                  >
                                    <span className="text-light">
                                      <FaRegTrashAlt />
                                    </span>{' '}
                                    Excluir
                                  </Button>
                                </ButtonGroup>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="col-sm-12 d-flex justify-content-center">
                      <h5 className="mt-3">
                        <FiAlertCircle className="mr-2" />
                        Não existem versões cadastradas.
                      </h5>
                    </div>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row id="form-container">
        <Col>
          <Card className="mb-3" small>
            <CardHeader className="border-bottom">
              <h6 className="m-0 font-weight-bold">Informações da versão</h6>
            </CardHeader>
            <ListGroup flush>
              <ListGroupItem className="p-3">
                <Row>
                  <Col>
                    <Form onSubmit={handleSubmit} id="edit">
                      <Row form>
                        <Col md="6" className="form-group">
                          <InputWrapper>
                            <label htmlFor="docTypeName">
                              Tipo do documento
                            </label>
                            <FormInput
                              id="docTypeName"
                              type="text"
                              name="name"
                              placeholder="Diploma"
                              value={docType}
                              required
                              {...bindDocType}
                            />
                            <span className="text-muted">
                              *Insira o tipo do documento.
                            </span>
                          </InputWrapper>
                        </Col>
                        <Col md="6" className="form-group">
                          <InputWrapper>
                            <label htmlFor="version">Versão</label>
                            <FormInput
                              id="version"
                              type="text"
                              name="version"
                              placeholder="1.0"
                              value={version}
                              required
                              {...bindVersion}
                            />
                            <span className="text-muted">
                              *Insira a versão.
                            </span>
                          </InputWrapper>
                        </Col>
                      </Row>
                      <Row form>
                        <Col md="6" className="form-group">
                          <InputWrapper>
                            <label htmlFor="startDate">Data inicial</label>
                            <FormInput
                              id="startDate"
                              type="date"
                              name="startDate"
                              value={startDate}
                              required
                              {...bindStartDate}
                            />
                            <span className="text-muted">
                              *Insira a data inicial da versão.
                            </span>
                          </InputWrapper>
                        </Col>
                        <Col md="6" className="form-group">
                          <InputWrapper>
                            <label htmlFor="finalLimit">Data final</label>
                            <FormInput
                              id="finalLimit"
                              type="date"
                              name="finalLimit"
                              value={finalDate}
                              required
                              {...bindFinalDate}
                            />
                            <span className="text-muted">
                              *Insira a data final da versão.
                            </span>
                          </InputWrapper>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col className="text-right">
                          {updating ? (
                            <>
                              {!isSubmitting && (
                                <Button
                                  className="btn btn-danger btn-sm"
                                  onClick={handleCleanState}
                                >
                                  Cancelar
                                </Button>
                              )}
                              <Button
                                className="btn-primary ml-3"
                                onClick={handleUpdateDdr}
                                disabled={isSubmitting}
                              >
                                {isSubmitting ? 'Carregando...' : 'Atualizar'}
                              </Button>
                            </>
                          ) : (
                            <Button disabled={isSubmitting} type="submit">
                              {isSubmitting
                                ? 'Carregando...'
                                : 'Criar nova versão'}
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
