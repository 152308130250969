import { produce } from 'immer';

const INITIAL_STATE = {
  docConfigs: [],
  docConfig: {},
  loading: false,
  isSubmitting: false,
};

export default function documentConfiguration(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@docconfig/GET_ALL_DOCCONFIGS_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@docconfig/GET_ALL_DOCCONFIGS_SUCCESS': {
        draft.docConfigs = action.payload.docConfigs;
        draft.loading = false;
        break;
      }
      case '@docconfig/GET_DOCCONFIG_BY_ID_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@docconfig/GET_DOCCONFIG_BY_ID_SUCCESS': {
        draft.loading = false;
        draft.docConfig = action.payload.docConfig;
        draft.docConfig.index = action.payload.index;
        break;
      }
      case '@docconfig/STORE_DOCCONFIG_REQUEST': {
        draft.isSubmitting = true;
        break;
      }
      case '@docconfig/STORE_DOCCONFIG_SUCCESS': {
        const aux = [...draft.docConfigs];
        aux.push(action.payload.docConfig);
        draft.docConfigs = aux;
        // draft.loading = false;
        draft.isSubmitting = false;
        break;
      }
      case '@docconfig/UPDATE_DOCCONFIG_REQUEST': {
        // draft.loading = true;
        draft.isSubmitting = true;
        break;
      }
      case '@docconfig/UPDATE_DOCCONFIG_SUCCESS': {
        const { data } = action.payload;
        const aux = draft.docConfigs;
        const docConfigIndex = state.docConfigs.findIndex((item) => {
          return item._id === data._id;
        });
        aux[docConfigIndex] = data;
        draft.docConfigs = aux;
        // draft.loading = false;
        draft.isSubmitting = false;
        break;
      }
      case '@docconfig/DELETE_DOCCONFIG_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@docconfig/DELETE_DOCCONFIG_SUCCESS': {
        const aux = draft.docConfigs;
        aux.splice(action.payload.index, 1);
        draft.docConfigs = aux;
        draft.loading = false;
        break;
      }
      case '@docconfig/DOCCONFIG_FAILURE': {
        draft.loading = false;
        draft.isSubmitting = false;
        break;
      }
      default:
    }
  });
}
