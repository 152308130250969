import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ButtonGroup,
  Button,
  Form,
  ListGroup,
  ListGroupItem,
  FormInput,
  FormGroup,
  FormSelect,
} from 'shards-react';

import { FiAlertCircle } from 'react-icons/fi';
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';

import Swal from 'sweetalert2';
import { InputWrapper } from '../common/styles';

import { useInput } from '../../hooks/input-hook';

import PageTitle from '../../components/common/PageTitle';
import LoadingContainer from '../../components/layout/LodingContainer';
import ErrorBox from '../../components/common/ErrorBox';

import {
  getAllDocGroupsRequest,
  getDocGroupByIdRequest,
  storeDocGroupRequest,
  updateDocGroupRequest,
  deleteDocGroupRequest,
} from '../../store/modules/documentGroup/actions';
import {
  isValidField,
  isNumber,
  isValidFieldArray,
} from '../../utils/validations';
import { scrollToForm, scrollToTop } from '../../utils/scrolls';

import { getAllDocTypesRequest } from '../../store/modules/documentType/actions';

export default function DocumentGroup() {
  const dispatch = useDispatch();

  const docTypes = useSelector((state) => state.documentType.doctypes);
  const docGroups = useSelector((state) => state.documentGroup.docGroups);
  const docGroup = useSelector((state) => state.documentGroup.docGroup);
  const { loading, isSubmitting } = useSelector((state) => state.documentGroup);

  const {
    value: name,
    setValue: setName,
    bind: bindName,
    reset: resetName,
  } = useInput('');

  const {
    value: minConectorVersion,
    setValue: setMinConectorVersion,
    bind: bindMinConectorVersion,
    reset: resetMinConectorVersion,
  } = useInput('');

  const {
    value: maxConectorVersion,
    setValue: setMaxConectorVersion,
    bind: bindMaxConectorVersion,
    reset: resetMaxConectorVersion,
  } = useInput('');

  const [size, setSize] = useState(1);
  const [documentsTypes, setDocumentsTypes] = useState([undefined]);
  const [docGroupIdToUpdate, setDocGroupIdToUpdate] = useState('');
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(null);

  /*eslint-disable */
  useEffect(() => {
    dispatch(getAllDocGroupsRequest());
    dispatch(getAllDocTypesRequest());
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(docGroup).length !== 0 && updating) {
      setName(docGroup.name);
      setSize(docGroup.size);
      setDocumentsTypes(docGroup.documentsTypes);
      setMinConectorVersion(docGroup.minConectorVersion || '');
      setMaxConectorVersion(docGroup.maxConectorVersion || '');
      setDocGroupIdToUpdate(docGroup._id);
    }
  }, [docGroup]);
  /* eslint-enable */

  function resetState(e) {
    e?.preventDefault();
    resetName();
    setSize(1);
    setDocumentsTypes([undefined]);

    setDocGroupIdToUpdate('');
    resetMinConectorVersion('');
    resetMaxConectorVersion('');
    setUpdating(false);
    scrollToTop();
  }
  function getDocGroupData(e, identifier, index) {
    e.preventDefault();
    dispatch(getDocGroupByIdRequest(identifier, index));
    setUpdating(true);
    scrollToForm();
  }

  async function handleUpdateDocGroup(e) {
    e.preventDefault();
    const identifier = docGroupIdToUpdate;
    const aux = [];
    for (let i = 0; i < documentsTypes.length; i += 1) {
      if (documentsTypes[i]._id !== undefined) {
        // eslint-disable-next-line no-await-in-loop
        await aux.push(documentsTypes[i]._id);
      } else {
        // eslint-disable-next-line no-await-in-loop
        await aux.push(documentsTypes[i]);
      }
    }
    const data = {
      name,
      size,
      documentsTypes: aux,
      ...(minConectorVersion !== '' && { minConectorVersion }),
      ...(maxConectorVersion !== '' && { maxConectorVersion }),
    };
    if (!validationSchema(data)) return;
    dispatch(
      updateDocGroupRequest(identifier, data, docGroup.index, () => {
        resetState();
        setDocGroupIdToUpdate('');
        setUpdating(false);
      })
    );
  }
  function handleDeleteDocGroup(e, identifier, index) {
    e.preventDefault();
    dispatch(deleteDocGroupRequest(identifier, index));
    resetState();
  }
  // eslint-disable-next-line no-shadow
  function handleConfirmation(e, identifier, index, name) {
    e.preventDefault();
    Swal.fire({
      title: 'Confirme para excluir',
      text: `Tem certeza que deseja remover ${name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        return handleDeleteDocGroup(e, identifier, index);
      }
      return false;
    });
  }
  function handleSubmit(e) {
    e.preventDefault();

    const obj = {
      name,
      size,
      documentsTypes,
      ...(minConectorVersion !== '' && { minConectorVersion }),
      ...(maxConectorVersion !== '' && { maxConectorVersion }),
    };
    if (!validationSchema(obj)) return;
    dispatch(storeDocGroupRequest(obj, resetState));
  }
  function handleDocumentTypesChange(e, index) {
    e.preventDefault();
    const { value } = e.target;
    const document_types = [...documentsTypes];

    document_types[index] = value;
    setDocumentsTypes(document_types);
  }
  function handleSizeChange(e) {
    e.preventDefault();
    const { value } = e.target;
    const aux = [...documentsTypes];
    let current_values = [];

    if (value < size) {
      current_values = aux.slice(0, value);
    } else {
      for (let i = 0; i < value; i += 1) {
        if (aux[i] === undefined) {
          current_values.push(undefined);
        } else {
          current_values.push(aux[i]);
        }
      }
    }
    setDocumentsTypes(current_values);
    setSize(value);
  }
  function validationSchema(obj) {
    if (!isValidField(obj.name)) {
      setError('O nome é obrigatório');
      return false;
    }
    if (!isNumber(obj.size)) {
      setError('O tamanho deve ser um número');
      return false;
    }
    if (!isValidFieldArray(obj.documentsTypes)) {
      setError('Selecione o tipo do documento');
      return false;
    }

    setError(null);
    return true;
  }
  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Grupo de documentos"
          subtitle="Gerenciamento"
          className="text-sm-left"
        />
      </Row>

      {/* Default Light Table */}
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0 font-weight-bold">
                Lista de grupos de documentos
              </h6>
            </CardHeader>
            <CardBody className="p-0 pb-3 pl-3 pr-3 overflow-auto">
              {loading ? (
                <LoadingContainer loading={loading} />
              ) : (
                <>
                  {docGroups.length !== 0 ? (
                    <table className="table mb-0">
                      <thead className="bg-light">
                        <tr>
                          <th scope="col" className="border-0">
                            #
                          </th>
                          <th scope="col" className="border-0">
                            Nome do grupo
                          </th>
                          <th scope="col" className="border-0">
                            Tamanho
                          </th>
                          <th scope="col" className="border-0">
                            Ações
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {docGroups &&
                          docGroups.map((d, index) => (
                            <tr key={index}>
                              <td>{index}</td>
                              <td>{d.name}</td>
                              <td>{d.size}</td>
                              <td>
                                <ButtonGroup size="sm">
                                  <Button
                                    theme="white"
                                    onClick={(e) =>
                                      getDocGroupData(e, d.id, index)
                                    }
                                  >
                                    <span className="text-light">
                                      <FaRegEdit />
                                    </span>{' '}
                                    Editar
                                  </Button>
                                  <Button
                                    onClick={(e) =>
                                      handleConfirmation(e, d.id, index, d.name)
                                    }
                                    theme="white"
                                  >
                                    <span className="text-light">
                                      <FaRegTrashAlt />
                                    </span>{' '}
                                    Excluir
                                  </Button>
                                </ButtonGroup>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="col-sm-12 d-flex justify-content-center">
                      <h5 className="mt-3">
                        <FiAlertCircle className="mr-2" />
                        Não existem grupo de documentos cadastrados.
                      </h5>
                    </div>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row id="form-container">
        <Col>
          <Card className="mb-3" small>
            <CardHeader className="border-bottom">
              <h6 className="m-0 font-weight-bold">
                Informações do grupo de documentos
              </h6>
            </CardHeader>
            {docTypes.length !== 0 ? (
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <ErrorBox error={error} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form onSubmit={handleSubmit} id="edit">
                        <Row form>
                          <Col md="6" className="form-group">
                            <InputWrapper>
                              <label htmlFor="docGroupName">Nome</label>
                              <FormInput
                                id="docGroupName"
                                type="text"
                                name="name"
                                placeholder="Diploma"
                                {...bindName}
                              />
                              <span className="text-muted">
                                *Insira o nome de referência ao grupo.
                              </span>
                            </InputWrapper>
                          </Col>
                          <Col md="6" className="form-group">
                            <InputWrapper>
                              <label htmlFor="docGroupSize">Tamanho</label>
                              <FormInput
                                id="docGroupSize"
                                type="number"
                                min="1"
                                placeholder="10"
                                value={size}
                                onChange={handleSizeChange}
                              />
                              <span className="text-muted">
                                *Insira o tamanho do grupo de documentos.
                              </span>
                            </InputWrapper>
                          </Col>
                        </Row>
                        <Row form>
                          {documentsTypes !== undefined &&
                            documentsTypes.length !== 0 &&
                            documentsTypes.map((d, index) => (
                              <Col md="6" key={index}>
                                <FormGroup>
                                  <InputWrapper>
                                    <label htmlFor="documentType">
                                      Tipo do documento
                                    </label>
                                    <FormSelect
                                      id="documentType"
                                      value={d === undefined ? '' : d._id}
                                      onChange={(e) =>
                                        handleDocumentTypesChange(e, index)
                                      }
                                    >
                                      <option value="" disabled>
                                        Escolher...
                                      </option>
                                      {docTypes &&
                                        docTypes.map((dType) => (
                                          <option
                                            key={dType._id}
                                            value={dType._id}
                                          >
                                            {dType.name}
                                          </option>
                                        ))}
                                    </FormSelect>
                                    <span className="text-muted">
                                      *Selecione o tipo de documento a ser
                                      utilizado.
                                    </span>
                                  </InputWrapper>
                                </FormGroup>
                              </Col>
                            ))}
                        </Row>
                        <Row form>
                          <Col md="6">
                            <FormGroup>
                              <InputWrapper>
                                <label htmlFor="minConectorVersion">
                                  Versão Mínima do Conector
                                </label>
                                <FormInput
                                  id="minConectorVersion"
                                  type="text"
                                  name="minConectorVersion"
                                  placeholder="v0.1.0"
                                  {...bindMinConectorVersion}
                                />
                                <span className="text-muted">
                                  Versão mínima do conector suportada
                                </span>
                              </InputWrapper>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <InputWrapper>
                                <label htmlFor="maxConectorVersion">
                                  Versão Máxima do Conector
                                </label>
                                <FormInput
                                  id="maxConectorVersion"
                                  type="text"
                                  name="maxConectorVersion"
                                  placeholder="v0.2.0"
                                  {...bindMaxConectorVersion}
                                />
                                <span className="text-muted">
                                  Versão máxima do conector suportada
                                </span>
                              </InputWrapper>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col className="text-right">
                            {updating ? (
                              <>
                                {!isSubmitting && (
                                  <Button
                                    className="btn btn-danger btn-sm"
                                    onClick={resetState}
                                  >
                                    Cancelar
                                  </Button>
                                )}
                                <Button
                                  className="btn-primary ml-3"
                                  onClick={handleUpdateDocGroup}
                                  disabled={isSubmitting}
                                >
                                  {isSubmitting ? 'Carregando...' : 'Atualizar'}
                                </Button>
                              </>
                            ) : (
                              <Button disabled={isSubmitting} type="submit">
                                {isSubmitting
                                  ? 'Carregando...'
                                  : 'Criar novo grupo'}
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            ) : (
              <div className="col-sm-12 d-flex justify-content-center">
                <h5 className="mt-3">
                  <FiAlertCircle className="mr-2" />
                  Não é possível continuar, não encontramos um tipo de documento
                  cadastrado, para continuar é preciso cadastrar ao menos um
                  tipo de documento!
                </h5>
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
