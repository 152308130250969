import { produce } from 'immer';

const INITIAL_STATE = {
  versions: {},
  signLogs: {},
  timestampLogs: {},
  signLog: null,
  timestampLog: null,
  mutualYourNumber: null,
  loading: false,
};

export default function reports(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@logs/GET_VERSIONS': {
        draft.loading = true;
        break;
      }
      case '@logs/GET_VERSIONS_SUCCESS': {
        draft.loading = false;
        draft.versions = { ...action.payload.data };
        break;
      }
      case '@logs/GET_SIGN_LOGS': {
        draft.loading = true;
        break;
      }
      case '@logs/GET_SIGN_LOGS_SUCCESS': {
        draft.loading = false;
        draft.signLogs = { ...action.payload.data };
        break;
      }
      case '@logs/GET_SIGN_LOGS_BY_ID': {
        draft.loading = true;
        break;
      }
      case '@logs/GET_SIGN_LOGS_BY_ID_SUCCESS': {
        draft.loading = false;
        draft.signLog = action.payload.signLog;
        break;
      }
      case '@logs/GET_TIMESTAMP_LOGS': {
        draft.loading = true;
        break;
      }
      case '@logs/GET_TIMESTAMP_LOGS_SUCCESS': {
        draft.loading = false;
        draft.timestampLogs = { ...action.payload.data };
        break;
      }
      case '@logs/GET_TIMESTAMP_LOGS_BY_ID': {
        draft.loading = true;
        break;
      }
      case '@logs/GET_TIMESTAMP_LOGS_BY_ID_SUCCESS': {
        draft.loading = false;
        draft.timestampLog = action.payload.timestampLog;
        break;
      }
      case '@logs/REQUEST_FAILURE': {
        draft.loading = false;
        break;
      }
      case '@logs/SET_YOUR_NUMBER': {
        draft.mutualYourNumber = action.payload.yourNumber;
        break;
      }
      case '@logs/CLEAN_YOUR_NUMBER': {
        draft.mutualYourNumber = null;
        break;
      }
      case '@logs/CLEAN': {
        draft.loading = false;
        draft.versions = {};
        draft.signLogs = {};
        draft.timestampLogs = {};
        break;
      }
      default:
    }
  });
}
