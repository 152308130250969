import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { responseErrors } from '../../../utils/responseErros';
import api from '../../../services/api';
import {
  getAllTagsSuccess,
  getTagByIdSuccess,
  storeTagSuccess,
  updateTagSuccess,
  deleteTagSuccess,
  tagFailure,
} from './actions';
import { refreshTokenRequest } from '../auth/actions';
import { store } from '../../index';

export function* getTags() {
  const { refresh_token } = store.getState().auth;
  const { selectedInstitution } = store.getState().selectedData;

  try {
    const response = yield call((url) => {
      return api.get(url, {
        params: {
          institutionId: selectedInstitution,
        },
      });
    }, '/management/tags');

    const tags = response.data;

    yield put(getAllTagsSuccess(tags));
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(tagFailure());
      toast.error(`Falha ao listar tags`);
    }
  }
}
export function* getTagById({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, index } = payload;
    const response = yield call(api.get, `/management/tags/${id}`);
    const tag = response.data;

    yield put(getTagByIdSuccess(tag, index));
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(tagFailure());
      toast.error('Falha ao obter dados da tag');
    }
  }
}
export function* storeTag({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { data, onSuccess } = payload;
    const response = yield call(api.post, '/management/tags', data);

    const tag = response.data;

    yield put(storeTagSuccess(tag));
    toast.success('Tag criada com sucesso');
    onSuccess && onSuccess();
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(tagFailure());
      Swal.fire(responseErrors(err.response, 'Falha ao criar tag'));
    }
  }
}

export function* updateTag({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, data, index, onSuccess } = payload;
    const response = yield call(api.patch, `/management/tags/${id}`, data);
    const tag = response.data;

    yield put(updateTagSuccess(index, tag));
    toast.success('Tag atualizada com sucesso');
    onSuccess && onSuccess();
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(tagFailure());
      Swal.fire(responseErrors(err.response, 'Falha ao atualizar tag'));
    }
  }
}

export function* deleteTag({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, index } = payload;
    yield call(api.delete, `/management/tags/${id}`);

    yield put(deleteTagSuccess(index));
    toast.success('Tag excluída com sucesso');
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(tagFailure());
      toast.error('Falha ao excluir tag');
    }
  }
}

export default all([
  takeLatest('@tag/GET_ALL_TAGS_REQUEST', getTags),
  takeLatest('@tag/GET_TAG_BY_ID_REQUEST', getTagById),
  takeLatest('@tag/STORE_TAG_REQUEST', storeTag),
  takeLatest('@tag/UPDATE_TAG_REQUEST', updateTag),
  takeLatest('@tag/DELETE_TAG_REQUEST', deleteTag),
]);
