/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactJson from 'react-json-view';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'shards-react';

import {
  FiAlertCircle,
  FiArrowLeft,
  FiDownload,
  FiRefreshCcw,
} from 'react-icons/fi';

import { AiOutlineClose, AiOutlineRollback } from 'react-icons/ai';

import Swal from 'sweetalert2';

import { dateAndHour } from '../../utils/dateFormatter';

import PageTitle from '../../components/common/PageTitle';
import LoadingContainer from '../../components/layout/LodingContainer';

import {
  getDocumentByIdRequest,
  revokeDocumentRequest,
  cancelDocumentRequest,
  updateStatusRequest,
  downloadRequest,
} from '../../store/modules/documents/actions';
import { documentStatus } from '../../utils/documentStatus';

export default function DocumentDetails() {
  const dispatch = useDispatch();
  const { id: selectedId } = useParams();
  const navigate = useNavigate();

  const { document, loading } = useSelector((state) => state.documents);

  const getDocument = useCallback(() => {
    dispatch(getDocumentByIdRequest(selectedId));
  }, [dispatch, selectedId]);

  useEffect(() => {
    getDocument();
  }, [getDocument, selectedId]);

  function handleDownload() {
    dispatch(downloadRequest());
  }

  function handleRevoke() {
    Swal.fire({
      title: 'Confirme para revogar',
      text: `Tem certeza que deseja revogar o registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        return dispatch(revokeDocumentRequest());
      }
      return false;
    });
  }

  function handleCancel() {
    Swal.fire({
      title: 'Confirme para cancelar',
      text: `Tem certeza que deseja cancelar o registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        return dispatch(cancelDocumentRequest());
      }
      return false;
    });
  }

  async function handleChangeStatus() {
    const { value: newStatus } = await Swal.fire({
      title: 'Alteração de status',
      input: 'select',
      inputOptions: {
        validated: 'validated',
        sendingToRegistry: 'sendingToRegistry',
        sentToRegistry: 'sentToRegistry',
        receivedFromRegistry: 'receivedFromRegistry',
        registering: 'registering',
        registered: 'registered',
        registerFail: 'registerFail',
        sendingToPreservation: 'sendingToPreservation',
        receivedFromPreservation: 'receivedFromPreservation',
        preserving: 'preserving',
        preservationFail: 'preservationFail',
        preserved: 'preserved',
        revoked: 'revoked',
        canceled: 'canceled',
        sentToPreservation: 'sentToPreservation',
      },
      inputPlaceholder: 'Selecione o novo status',
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Prosseguir',
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value && value !== '') {
            resolve();
          } else {
            resolve('Você deve selecionar o status');
          }
        });
      },
    });
    if (!newStatus) return;
    const { value: confirmation } = await Swal.fire({
      title: 'Confirmação de alteração de status',
      input: 'text',
      inputValue: '',
      html: `Confirme digitando o novo status para o documento <i style="font-weight: 700">${newStatus}</i>`,
      showCancelButton: true,
      showCloseButton: true,
      allowOutsideClick: true,
      allowEscapeKey: false,
      focusCancel: false,
      focusConfirm: false,
      focusDeny: false,
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Atualizar status',
      inputAutoFocus: true,
      // eslint-disable-next-line consistent-return
      inputValidator: (value) => {
        if (!value) {
          return 'Você deve digitar o valor do novo status do documento';
        }
        if (value !== newStatus) {
          return 'O valor digitado não confere com o status desejado';
        }
      },
    });

    if (!confirmation) return;

    dispatch(updateStatusRequest(newStatus));
  }

  function handleBack() {
    navigate(-1);
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Gerenciar Registros"
          subtitle="Gerenciamento"
          className="text-sm-left"
        />
      </Row>
      {selectedId && (
        <Row className="mb-3">
          <Col md="6">
            <a
              onClick={handleBack}
              className="text-decoration-none h5 text-primary"
              style={{ cursor: 'pointer' }}
            >
              <span>
                <FiArrowLeft />
              </span>{' '}
              <span className="ml-1">Voltar</span>
            </a>
          </Col>
          <Col md="6" className="text-right">
            <Button disabled={loading} onClick={getDocument}>
              <span className="text-white">
                <FiRefreshCcw />
              </span>{' '}
              Atualizar
            </Button>
          </Col>
        </Row>
      )}
      {/* Details */}
      {selectedId && document && (
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="mt-1 mr-3 mb-0">Detalhes do documento</h6>
              </CardHeader>
              <CardBody className="p-0 overflow-auto">
                {loading && <LoadingContainer loading={loading} />}
                {!loading && document._id && (
                  <>
                    <table className="table m-0">
                      <tbody>
                        <tr>
                          <td>Identificador</td>
                          <td>{document._id}</td>
                        </tr>
                        <tr>
                          <td>
                            <span>Seu número</span>
                          </td>
                          <td>{document.yourNumber}</td>
                        </tr>
                        <tr>
                          <td>Nosso número</td>
                          <td>{document.ourNumber}</td>
                        </tr>
                        <tr>
                          <td>ID do cliente</td>
                          <td>{document?.institution?.client_id}</td>
                        </tr>
                        <tr>
                          <td>Hash</td>
                          <td>{document.docHash}</td>
                        </tr>
                        <tr>
                          <td>Tipo de documento</td>
                          <td>{document.docType}</td>
                        </tr>
                        <tr>
                          <td>Mime type</td>
                          <td>{document.mimeType}</td>
                        </tr>
                        <tr>
                          <td>Documento assinado</td>
                          <td>{document.isSigned ? 'Sim' : 'Não'}</td>
                        </tr>
                        <tr>
                          <td>Recibo de transação</td>
                          <td>
                            {document.txReceipt
                              ? document.txReceipt
                              : 'não disponível'}
                          </td>
                        </tr>
                        <tr>
                          <td>Data de transação</td>
                          <td>
                            {document.txDate
                              ? dateAndHour(document.txDate)
                              : 'não disponível'}
                          </td>
                        </tr>
                        <tr>
                          <td>Data de criação</td>
                          <td>
                            {document.createdAt
                              ? dateAndHour(document.createdAt)
                              : 'não disponível'}
                          </td>
                        </tr>
                        <tr>
                          <td>Data de última modificação</td>
                          <td>
                            {document.updatedAt
                              ? dateAndHour(document.updatedAt)
                              : 'não disponível'}
                          </td>
                        </tr>
                        <tr>
                          <td>Status</td>
                          <td>
                            {documentStatus[document.documentStatus] ||
                              document.documentStatus}
                          </td>
                        </tr>
                        <tr>
                          <td>Ações sobre o registro</td>
                          <td>
                            <Button
                              className="mr-1"
                              disabled={document.documentStatus !== 'preserved'}
                              onClick={handleDownload}
                            >
                              <span className="text-white">
                                <FiDownload />
                              </span>{' '}
                              Download
                            </Button>
                            <Button
                              className="mr-1"
                              theme="warning"
                              disabled={
                                document.documentStatus === 'revoked' ||
                                document.documentStatus === 'canceled'
                              }
                              onClick={handleRevoke}
                            >
                              <span>
                                <AiOutlineRollback />
                              </span>{' '}
                              Revogar
                            </Button>
                            <Button
                              className="mr-1"
                              theme="danger"
                              disabled={
                                document.documentStatus === 'preserved' ||
                                document.documentStatus === 'revoked' ||
                                document.documentStatus === 'canceled'
                              }
                              onClick={handleCancel}
                            >
                              <span>
                                <AiOutlineClose />
                              </span>{' '}
                              Cancelar
                            </Button>
                            <Button
                              theme="secondary"
                              onClick={handleChangeStatus}
                            >
                              <span>
                                <FiRefreshCcw />
                              </span>{' '}
                              Alterar status
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      {/* json data */}
      {selectedId && document.data && !loading && (
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="mt-1 mr-3 mb-0">Metadata do documento</h6>
              </CardHeader>
              <CardBody className="p-3 overflow-auto">
                {document.data && (
                  <>
                    <ReactJson
                      src={document.data}
                      name={false}
                      displayDataTypes={false}
                      collapsed={4}
                    />
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}

      {/* Registers */}
      {selectedId && document.registries && !loading && (
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="mt-1 mr-3 mb-0">Registros do documento</h6>
              </CardHeader>
              <CardBody className="p-0 overflow-auto">
                {document.registries.length === 0 && (
                  <div className="col-sm-12 d-flex justify-content-center">
                    <h5 className="mt-3">
                      <FiAlertCircle className="mr-2" />
                      Não existem registros cadastrados para o documento.
                    </h5>
                  </div>
                )}
                {document.registries.length > 0 && (
                  <>
                    <table className="table m-0">
                      <thead className="bg-light">
                        <tr>
                          <th scope="col" className="border-0">
                            #
                          </th>
                          <th scope="col" className="border-0">
                            Criado em
                          </th>
                          <th scope="col" className="border-0">
                            Atualizado em
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {document.registries.map((registry) => (
                          <tr key={registry.id_registry}>
                            <td>{registry.id_registry}</td>
                            <td>{dateAndHour(registry.created)}</td>
                            <td>{dateAndHour(registry.updated)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
}
