import styled from 'styled-components';

export const FolderButton = styled.button`
  background-color: transparent;
  border: 0;
  outline: 0;

  &:hover {
    color: #006fe6;
  }
`;

export const Downloadutton = styled.button`
  background-color: transparent;
  border: 0;
  outline: 0;

  &:hover {
    color: #006fe6;
  }
`;

export const DirButton = styled.button`
  background-color: transparent;
  border: 0;
  outline: 0;
  color: #006fe6;
`;

export const LoadingOverlay = styled.div`
  position: absolute;
  background-color: rgba(233, 236, 239, 0.7);
  width: 97%;
  height: 99%;
  z-index: 1000;
`;
