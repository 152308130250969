export const SignatureTypes = {
  institution: {
    name: '<INST> CNPJ ADRB COMPOSITE ENVELOPED',
    signPacking: 'enveloped',
    signPolicy: 'AD_RB',
    policyVersion: '2.4',
    hashAlgorithm: 'sha-256',
    level: 1,
    signType: 'COMPOSITE_SIGNATURE',
  },
  archive: {
    name: '<INST> CNPJ ADRA COMPOSITE ENVELOPED',
    signPacking: 'enveloped',
    signPolicy: 'AD_RA',
    policyVersion: '2.4',
    hashAlgorithm: 'sha-256',
    level: 1,
    signType: 'COMPOSITE_SIGNATURE',
  },
  person: {
    name: '<NÓ ASSINADO> <INST> ADRB PARALLEL ENVELOPED',
    signPacking: 'enveloped',
    signPolicy: 'AD_RB',
    policyVersion: '2.4',
    hashAlgorithm: 'sha-256',
    level: 1,
    signType: 'PARALLEL_SIGNATURE',
  },
};

export const TagTypes = {
  Diploma: {
    tagID: 'TAG Diploma RAIZ <INST>',
    tagName: 'Diploma',
  },
  DadosDiploma: {
    tagID: 'TAG Doc Acad DadosDiploma <INST>',
    tagName: 'DadosDiploma',
  },
  DadosRegistro: {
    tagID: 'TAG Diploma DadosRegistro <INST>',
    tagName: 'DadosRegistro',
  },
  DocumentacaoAcademicaRegistro: {
    tagID: 'TAG Doc Acad RAIZ <INST>',
    tagName: 'DocumentacaoAcademicaRegistro',
  },
};

export const Permissions = {
  NORMAL_USER: 1,
  REPORTER: 256,
  MANAGER: 512,
  MAINTAINER: 1024,
  ADMIN: 2048,
};

export const timestampTypes = [
  {
    label: 'Carimbo de Assinatura',
    value: 1,
  },
  {
    label: 'Carimbo de Referências',
    value: 2,
  },
  {
    label: 'Carimbo de Arquivamento',
    value: 3,
  },
];

export const documentTypes = [
  {
    label: 'Documentação acadêmica',
    value: 1,
  },
  {
    label: 'Diploma',
    value: 2,
  },
  {
    label: 'Prova de Existência de Vídeo',
    value: 6,
  },
  {
    label: 'Representação visual',
    value: 5,
  },
  {
    label: 'Diploma externo',
    value: 7,
  },
  {
    label: 'Documentação acadêmica externa',
    value: 8,
  },
  {
    label: 'Histórico Final',
    value: 9,
  },
  {
    label: 'Arquivo de Fiscalização da Registradora',
    value: 10,
  },
  {
    label: 'Arquivo de Fiscalização da Emissora',
    value: 11,
  },
];

export const batchRegistriesStatus = [
  {
    label: 'Aberto',
    value: 1,
  },
  {
    label: 'Fechado',
    value: 2,
  },
  {
    label: 'Registrado',
    value: 3,
  },
  {
    label: 'Com falha',
    value: 4,
  },
];

export const providers = [
  {
    label: 'Ledger',
    value: 1,
  },
  {
    label: 'Bry',
    value: 2,
  },
];

export const dateFilterCriteriaList = [
  {
    label: 'Data de criação',
    value: 'CREATE',
  },
  {
    label: 'Data de última modificação',
    value: 'UPDATE',
  },
];

export const SignConfigName = 'Configuração Assinatura <Tipo Doc> <INST>';

export const DocConfigName = 'Configuração <Tipo Doc> <INST>';
