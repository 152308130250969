import { produce } from 'immer';

const INITIAL_STATE = {
  signConfigs: [],
  signConfig: {},
  loading: false,
  isSubmitting: false,
};

export default function signatureConfiguration(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@signconfig/GET_ALL_SIGNCONFIGS_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@signconfig/GET_ALL_SIGNCONFIGS_SUCCESS': {
        draft.signConfigs = action.payload.signConfigs;
        draft.loading = false;
        break;
      }
      case '@signconfig/GET_SIGNCONFIG_BY_ID_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@signconfig/GET_SIGNCONFIG_BY_ID_SUCCESS': {
        draft.loading = false;
        draft.signConfig = action.payload.signConfig;
        draft.signConfig.index = action.payload.index;
        break;
      }
      case '@signconfig/STORE_SIGNCONFIG_REQUEST': {
        // draft.loading = true;
        draft.isSubmitting = false;
        break;
      }
      case '@signconfig/STORE_SIGNCONFIG_SUCCESS': {
        const aux = [...draft.signConfigs];
        aux.push(action.payload.signConfig);
        draft.signConfigs = aux;
        // draft.loading = false;
        draft.isSubmitting = false;
        break;
      }
      case '@signconfig/UPDATE_SIGNCONFIG_REQUEST': {
        // draft.loading = true;
        draft.isSubmitting = true;
        break;
      }
      case '@signconfig/UPDATE_SIGNCONFIG_SUCCESS': {
        const { data } = action.payload;
        const aux = draft.signConfigs;
        const signConfigIndex = state.signConfigs.findIndex((item) => {
          return item._id === data._id;
        });
        aux[signConfigIndex] = data;
        draft.signConfigs = aux;
        // draft.loading = false;
        draft.isSubmitting = false;
        break;
      }
      case '@signconfig/UPDATE_SIGNCONFIG_CANCEL': {
        draft.signConfig = {};
        break;
      }
      case '@signconfig/DELETE_SIGNCONFIG_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@signconfig/DELETE_SIGNCONFIG_SUCCESS': {
        const aux = draft.signConfigs;
        aux.splice(action.payload.index, 1);
        draft.signConfigs = aux;
        draft.loading = false;
        break;
      }
      case '@signconfig/SIGNCONFIG_FAILURE': {
        draft.loading = false;
        draft.isSubmitting = false;
        break;
      }
      default:
    }
  });
}
