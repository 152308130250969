import { produce } from 'immer';

const INITIAL_STATE = {
  courses: [],
  course: {},
  loading: false,
  isSubmitting: false,
};

export default function course(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@course/GET_ALL_COURSES_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@course/GET_ALL_COURSES_SUCCESS': {
        draft.courses = action.payload.courses;
        draft.loading = false;
        break;
      }
      case '@course/GET_COURSE_BY_ID_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@course/GET_COURSE_BY_ID_SUCCESS': {
        draft.loading = false;
        draft.course = action.payload.course;
        draft.course.index = action.payload.index;
        break;
      }
      case '@course/STORE_COURSE_REQUEST': {
        // draft.loading = true;
        draft.isSubmitting = true;
        break;
      }
      case '@course/STORE_COURSE_SUCCESS': {
        const aux = [...draft.courses];
        aux.push(action.payload.course);
        draft.courses = aux;
        // draft.loading = false;
        draft.isSubmitting = false;
        break;
      }
      case '@course/UPDATE_COURSE_REQUEST': {
        draft.isSubmitting = true;
        break;
      }
      case '@course/UPDATE_COURSE_SUCCESS': {
        const { data } = action.payload;
        const aux = draft.courses;
        const courseIndex = state.courses.findIndex((item) => {
          return item._id === data._id;
        });
        aux[courseIndex] = data;
        draft.courses = aux;
        // draft.loading = false;
        draft.isSubmitting = false;
        break;
      }
      case '@course/DELETE_COURSE_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@course/DELETE_COURSE_SUCCESS': {
        const aux = draft.courses;
        aux.splice(action.payload.index, 1);
        draft.courses = aux;
        draft.loading = false;
        break;
      }
      case '@course/COURSE_FAILURE': {
        draft.loading = false;
        draft.isSubmitting = false;
        break;
      }
      default:
    }
  });
}
