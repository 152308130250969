import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ButtonGroup,
  Button,
  Form,
  ListGroup,
  ListGroupItem,
  FormInput,
  FormSelect,
} from 'shards-react';

import { FiAlertCircle } from 'react-icons/fi';
import { FaMagic, FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';

import Swal from 'sweetalert2';
import { InputWrapper, FilterWrapper } from '../common/styles';

import PageTitle from '../../components/common/PageTitle';
import LoadingContainer from '../../components/layout/LodingContainer';
import SearchSelect from '../../components/common/SearchSelect';
import { useInput } from '../../hooks/input-hook';
import {
  getAllDocConfigsRequest,
  getDocConfigByIdRequest,
  storeDocConfigRequest,
  updateDocConfigRequest,
  deleteDocConfigRequest,
} from '../../store/modules/documentConfig/actions';

import { getAllInstitutionsRequest } from '../../store/modules/institution/actions';
import { getAllDocTypesRequest } from '../../store/modules/documentType/actions';
import { getAllSignatureConfigsRequest } from '../../store/modules/signatureConfig/actions';
import { getAllDocGroupsRequest } from '../../store/modules/documentGroup/actions';
import * as SelectedData from '../../store/modules/selectedData/actions';

import { scrollToTop, scrollToForm } from '../../utils/scrolls';
import { DocConfigName } from '../../config/Constants';
import { getInitials } from '../../utils/getIntitutionInitials';

export default function DocumentConfiguration() {
  const dispatch = useDispatch();

  const docConfigs = useSelector(
    (state) => state.documentConfiguration.docConfigs
  );
  const docConfig = useSelector(
    (state) => state.documentConfiguration.docConfig
  );

  const institutions = useSelector((state) => state.institution.institutions);
  const documentTypes = useSelector((state) => state.documentType.doctypes);
  const signatureConfigs = useSelector(
    (state) => state.signatureConfiguration.signConfigs
  );
  const documentGroups = useSelector((state) => state.documentGroup.docGroups);
  const { loading, isSubmitting } = useSelector(
    (state) => state.documentConfiguration
  );
  const selectedInstitution = useSelector(
    (state) => state.selectedData.selectedInstitution
  );

  const {
    value: name,
    setValue: setName,
    bind: bindName,
    reset: resetName,
  } = useInput('');
  const {
    value: documentType,
    setValue: setDocumentType,
    bind: bindDocumentType,
    reset: resetDocumentType,
  } = useInput('');
  const { value: institution, setValue: setInstitution } = useInput('');
  const {
    value: signatureConfig,
    setValue: setSignatureConfig,
    bind: bindSignatureConfig,
    reset: resetSignatureConfig,
  } = useInput('');
  const {
    value: documentGroup,
    setValue: setDocumentGroup,
    bind: bindDocumentGroup,
    reset: resetDocumentGroup,
  } = useInput('');

  const [filteredInstitution, setFilteredInstitution] =
    useState(selectedInstitution);
  const [docConfigIdToUpdate, setDocConfigIdToUpdate] = useState('');
  const [updating, setUpdating] = useState(false);
  const [filteredDocConfig, setFilteredDocConfig] = useState([]);
  const [filteredSignatureConfigs, setFilteredSignatureConfigs] = useState([]);
  const [institutionFilterStatus, setInstitutionFilterStatus] = useState(false);

  /*eslint-disable */
  useEffect(() => {
    dispatch(getAllInstitutionsRequest());
    dispatch(getAllDocTypesRequest());
    dispatch(getAllSignatureConfigsRequest());
    dispatch(getAllDocGroupsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(docConfig).length !== 0 && updating) {
      setName(docConfig.name);
      setInstitution(docConfig.institution._id);
      setDocumentType(docConfig.documentType._id);
      setDocumentGroup(docConfig.documentGroup._id);
      setDocConfigIdToUpdate(docConfig._id);

      if (!docConfig.signatureConfig) {
        setSignatureConfig('');
      } else {
        setSignatureConfig(docConfig.signatureConfig._id);
      }
    }
  }, [docConfig]);

  useEffect(() => {
    setFilteredDocConfig(docConfigs);
  }, [docConfigs]);
  useEffect(() => {
    setFilteredSignatureConfigs(signatureConfigs);
  }, [signatureConfigs]);
  /* eslint-enable */
  useEffect(() => {
    handleFiltering();
  }, [filteredInstitution]);
  function resetState() {
    resetName();
    // resetInstitution();
    resetDocumentType();
    resetSignatureConfig();
    resetDocumentGroup();

    setDocConfigIdToUpdate('');
    setUpdating(false);
  }

  function handleCleanState(e) {
    e.preventDefault();
    resetName();
    // resetInstitution();
    resetDocumentType();
    resetSignatureConfig();
    resetDocumentGroup();

    setDocConfigIdToUpdate('');
    setUpdating(false);
    scrollToTop();
  }
  function getDocConfigData(e, identifier, index) {
    e.preventDefault();
    dispatch(getDocConfigByIdRequest(identifier, index));
    setUpdating(true);
    scrollToForm();
  }
  async function handleUpdateDocConfig(e) {
    e.preventDefault();
    const identifier = docConfigIdToUpdate;

    const data = {
      name,
      documentType,
      institution,
      signatureConfig,
      documentGroup,
    };

    const docConfigIndex = docConfigs.findIndex(
      (item) => item._id === identifier
    );

    dispatch(
      updateDocConfigRequest(identifier, data, docConfigIndex, () => {
        resetState();
        scrollToTop();
      })
    );
  }
  function handleDeleteDocConfig(e, identifier) {
    e.preventDefault();
    const index = docConfigs.findIndex(
      (indexDocConfig) => indexDocConfig._id === identifier
    );
    dispatch(deleteDocConfigRequest(identifier, index));
  }
  // eslint-disable-next-line no-shadow
  function handleConfirmation(e, identifier, name) {
    e.preventDefault();
    Swal.fire({
      title: 'Confirme para excluir',
      text: `Tem certeza que deseja remover ${name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        return handleDeleteDocConfig(e, identifier);
      }
      return false;
    });
  }
  async function handleSubmit(e) {
    e.preventDefault();
    const obj = {
      name,
      documentType,
      institution,
      documentGroup,
    };
    if (signatureConfig) {
      Object.assign(obj, { signatureConfig });
    }
    dispatch(
      storeDocConfigRequest(obj, () => {
        scrollToTop();
        resetState();
      })
    );
  }

  function handleFiltering() {
    if (!filteredInstitution) {
      setFilteredSignatureConfigs([]);
      setFilteredDocConfig([]);
      setInstitution([]);
      setInstitutionFilterStatus(false);

      return;
    }
    resetState();

    dispatch(SelectedData.selectInstitution(filteredInstitution));
    dispatch(getAllDocConfigsRequest());
    dispatch(getAllSignatureConfigsRequest());
    setInstitution(filteredInstitution);
    setInstitutionFilterStatus(true);
  }
  // eslint-disable-next-line no-shadow
  function handleInstitutionFilter(institution) {
    setFilteredInstitution(institution.value);
  }

  function handleAutoFill() {
    const nameInput = document.getElementById('docConfigName');

    const initials = getInitials(filteredInstitution, institutions);
    const newName = initials
      ? DocConfigName.replace('<INST>', initials)
      : DocConfigName;

    setName(newName);

    setTimeout(() => {
      // eslint-disable-next-line no-unused-expressions
      nameInput && nameInput.focus();
      // eslint-disable-next-line no-unused-expressions
      nameInput && nameInput.setSelectionRange(13, 23);
    }, 50);
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Configuração de documentos"
          subtitle="Gerenciamento"
          className="text-sm-left"
        />
      </Row>
      {/* Default Light Table */}
      <Row>
        <Col>
          <FilterWrapper>
            <Card small className="col-sm-6 mb-3">
              <CardHeader className="border-bottom">
                <h6 className="m-0 font-weight-bold">
                  <FiAlertCircle className="mr-2 " />
                  Por favor, antes de prosseguir selecione uma instituição:
                </h6>
                <div className="col-sm-12">
                  <SearchSelect
                    onSelect={handleInstitutionFilter}
                    value={filteredInstitution}
                    options={
                      institutions &&
                      institutions.map((i) => ({
                        label: `${i.name} - ${i.initials}`,
                        value: i._id,
                      }))
                    }
                  />
                </div>
              </CardHeader>
            </Card>
          </FilterWrapper>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0 font-weight-bold">
                Lista de configurações de documentos
              </h6>
            </CardHeader>
            <CardBody className="p-0 pb-3 pl-3 pr-3 overflow-auto">
              {loading ? (
                <LoadingContainer loading={loading} />
              ) : (
                <>
                  {filteredDocConfig.length !== 0 ? (
                    <table className="table mb-0">
                      <thead className="bg-light">
                        <tr>
                          <th scope="col" className="border-0">
                            #
                          </th>
                          <th scope="col" className="border-0">
                            Nome da configuração
                          </th>
                          {!institutionFilterStatus ? null : (
                            <th scope="col" className="border-0">
                              Ações
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {filteredDocConfig &&
                          filteredDocConfig.map((d, index) => (
                            <tr key={d._id}>
                              <td>{index}</td>
                              <td>{d.name}</td>
                              {!institutionFilterStatus ? null : (
                                <td>
                                  <ButtonGroup size="sm">
                                    <Button
                                      theme="white"
                                      onClick={(e) =>
                                        getDocConfigData(e, d._id, index)
                                      }
                                    >
                                      <span className="text-light">
                                        <FaRegEdit />
                                      </span>{' '}
                                      Editar
                                    </Button>
                                    <Button
                                      onClick={(e) =>
                                        handleConfirmation(e, d._id, d.name)
                                      }
                                      theme="white"
                                    >
                                      <span className="text-light">
                                        <FaRegTrashAlt />
                                      </span>{' '}
                                      Excluir
                                    </Button>
                                  </ButtonGroup>
                                </td>
                              )}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="col-sm-12 d-flex justify-content-center">
                      <h5 className="mt-3">
                        <FiAlertCircle className="mr-2" />
                        Não existem configurações de documentos cadastrados.
                      </h5>
                    </div>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {!institutionFilterStatus ? null : (
        <Row id="form-container">
          <Col>
            <Card className="mb-3" small>
              <CardHeader className="border-bottom">
                <h6 className="m-0 font-weight-bold">
                  Informações sobre a configuração de documentos
                </h6>
              </CardHeader>
              {institutions.length !== 0 &&
              documentTypes.length !== 0 &&
              signatureConfigs.length !== 0 &&
              documentGroups.length !== 0 ? (
                <ListGroup flush>
                  <ListGroupItem className="p-3">
                    <Row>
                      <Col>
                        <Form onSubmit={handleSubmit} id="edit">
                          <Row form>
                            <Col md="6" className="form-group">
                              <InputWrapper>
                                <Row>
                                  <Col md="6">
                                    <label
                                      htmlFor="docConfigName"
                                      className="d-block"
                                    >
                                      Nome
                                    </label>
                                  </Col>
                                  <Col md="6" className="text-right mb-1">
                                    <Button
                                      onClick={handleAutoFill}
                                      outline
                                      pill
                                      size="sm"
                                    >
                                      <span className="text-primary">
                                        <FaMagic />
                                      </span>{' '}
                                      Auto preencher
                                    </Button>
                                  </Col>
                                </Row>
                                <FormInput
                                  id="docConfigName"
                                  type="text"
                                  placeholder="Configuração exemplo"
                                  name="name"
                                  required
                                  {...bindName}
                                />
                                <span className="text-muted">
                                  *Insira um nome para a configuração de
                                  documento.
                                </span>
                              </InputWrapper>
                            </Col>
                            <Col md="6" className="form-group">
                              <InputWrapper>
                                <label htmlFor="docConfigType">
                                  Tipo de documento
                                </label>
                                <FormSelect
                                  id="docConfigType"
                                  name="documentType"
                                  required
                                  {...bindDocumentType}
                                >
                                  <option disabled value="">
                                    Escolher...
                                  </option>
                                  {documentTypes &&
                                    documentTypes.map((d) => (
                                      <option key={d._id} value={d._id}>
                                        {d.name}
                                      </option>
                                    ))}
                                </FormSelect>
                                <span className="text-muted">
                                  *Selecione um tipo de documento.
                                </span>
                              </InputWrapper>
                            </Col>
                          </Row>
                          <Row form>
                            <Col md="6" className="form-group">
                              <InputWrapper>
                                <label htmlFor="signatureConfig">
                                  Configuração de assinatura
                                </label>
                                <FormSelect
                                  id="signatureConfig"
                                  name="signatureConfig"
                                  {...bindSignatureConfig}
                                >
                                  <option value="">Sem configuração</option>
                                  {filteredSignatureConfigs &&
                                    filteredSignatureConfigs.map((s) => (
                                      <option key={s._id} value={s._id}>
                                        {s.name}
                                      </option>
                                    ))}
                                </FormSelect>
                                <span className="text-muted">
                                  Selecione a configuração que deve ser
                                  associada ao documento.
                                </span>
                              </InputWrapper>
                            </Col>
                            <Col md="6" className="form-group">
                              <InputWrapper>
                                <label htmlFor="docConfigDocGroup">
                                  Grupo de documentos
                                </label>
                                <FormSelect
                                  id="docConfigDocGroup"
                                  name="documentGroup"
                                  required
                                  {...bindDocumentGroup}
                                >
                                  <option value="">Escolher...</option>
                                  {documentGroups &&
                                    documentGroups.map((d) => (
                                      <option key={d._id} value={d._id}>
                                        {d.name}
                                      </option>
                                    ))}
                                </FormSelect>
                                <span className="text-muted">
                                  *Selecione o grupo de documentos associado ao
                                  documento.
                                </span>
                              </InputWrapper>
                            </Col>
                          </Row>
                          <Row className="mt-2">
                            <Col className="text-right">
                              {updating ? (
                                <>
                                  <Button
                                    className="btn btn-danger btn-sm"
                                    onClick={handleCleanState}
                                    disabled={isSubmitting}
                                  >
                                    Cancelar
                                  </Button>
                                  <Button
                                    className="btn-primary ml-3"
                                    onClick={handleUpdateDocConfig}
                                    disabled={isSubmitting}
                                  >
                                    {isSubmitting
                                      ? 'Carregando...'
                                      : 'Atualizar'}
                                  </Button>
                                </>
                              ) : (
                                <Button disabled={isSubmitting} type="submit">
                                  {isSubmitting
                                    ? 'Carregando...'
                                    : 'Criar nova configuração'}
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              ) : (
                <div className="col-sm-12 d-flex justify-content-center py-2">
                  <h5 className="mt-3">
                    <FiAlertCircle className="mr-2" />
                    Não é possível continuar, não encontramos instituições ou
                    assinadores ou itens de assinatura cadastradas, para
                    continuar é preciso cadastrar ao menos uma dessas entidades!
                  </h5>
                </div>
              )}
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
}
