/* List */
export function getAllDocGroupsRequest() {
  return {
    type: '@docgroups/GET_ALL_DOCGROUPS_REQUEST',
  };
}
export function getAllDocGroupsSuccess(docGroups) {
  return {
    type: '@docgroups/GET_ALL_DOCGROUPS_SUCCESS',
    payload: { docGroups },
  };
}
export function getDocGroupByIdRequest(id, index) {
  return {
    type: '@docgroups/GET_DOCGROUP_BY_ID_REQUEST',
    payload: { id, index },
  };
}
export function getDocGroupByIdSuccess(docGroup, index) {
  return {
    type: '@docgroups/GET_DOCGROUP_BY_ID_SUCCESS',
    payload: { docGroup, index },
  };
}

/* Store */
export function storeDocGroupRequest(data, onSuccess) {
  return {
    type: '@docgroups/STORE_DOCGROUP_REQUEST',
    payload: { data, onSuccess },
  };
}
export function storeDocGroupSuccess(docGroup) {
  return {
    type: '@docgroups/STORE_DOCGROUP_SUCCESS',
    payload: { docGroup },
  };
}

/* Update */
export function updateDocGroupRequest(id, data, index, onSuccess) {
  return {
    type: '@docgroups/UPDATE_DOCGROUP_REQUEST',
    payload: { id, data, index, onSuccess },
  };
}
export function updateDocGroupSuccess(index, data) {
  return {
    type: '@docgroups/UPDATE_DOCGROUP_SUCCESS',
    payload: { index, data },
  };
}

/* Delete */
export function deleteDocGroupRequest(id, index) {
  return {
    type: '@docgroups/DELETE_DOCGROUP_REQUEST',
    payload: { id, index },
  };
}
export function deleteDocGroupSuccess(index) {
  return {
    type: '@docgroups/DELETE_DOCGROUP_SUCCESS',
    payload: { index },
  };
}

/* Failure */
export function docGroupFailure() {
  return {
    type: '@docgroups/DOCGROUP_FAILURE',
  };
}
