import { all, takeLatest, call, put, take } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { responseErrors } from '../../../utils/responseErros';
import api from '../../../services/api';
import {
  getAllUsersSuccess,
  getUserByIdSuccess,
  storeUserSuccess,
  updateUserSuccess,
  deleteUserSuccess,
  userFailure,
} from './actions';

import { refreshTokenRequest } from '../auth/actions';
import { store } from '../../index';

export function* getUsers() {
  const { refresh_token } = store.getState().auth;

  try {
    const response = yield call(api.get, '/users');
    const users = response.data;

    yield put(getAllUsersSuccess(users));
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(userFailure());
      toast.error(`Falha ao listar usuários`);
    }
  }
}
export function* getUserById({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, index } = payload;
    const response = yield call(api.get, `/users/${id}`);
    const user = response.data;
    yield put(getUserByIdSuccess(user, index));
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(userFailure());
      toast.error('Falha ao obter dados do usuário');
    }
  }
}
// eslint-disable-next-line consistent-return
export function* storeUser({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { data, onSuccess } = payload;
    const { id_permission } = data;
    let user;
    if (id_permission !== '1024') {
      const response = yield call(api.post, '/users', data);
      user = response.data;
    } else {
      delete data.id_permission;
      const response = yield call(api.post, '/maintainer', data);
      user = response.data;
    }

    if (user.error) {
      yield put(userFailure());
      return Swal.fire(
        responseErrors(
          { data: { error: user.error.errmsg || 'Erro desconhecido' } },
          'Falha ao criar usuário'
        )
      );
    }

    yield put(storeUserSuccess(user));
    toast.success('Usuário criado com sucesso');

    onSuccess && onSuccess();
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(userFailure());
      Swal.fire(responseErrors(err.response, 'Falha ao criar usuário'));
    }
  }
}

export function* updateUser({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, data, index } = payload;
    const response = yield call(api.patch, `/users/${id}`, data);
    const user = response.data;
    yield put(updateUserSuccess(index, user));
    toast.success('Usuário atualizado com sucesso');
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(userFailure());
      Swal.fire(responseErrors(err.response, 'Falha ao atualizar usuário'));
    }
  }
}

export function* updateCancel() {
  yield take('@user/UPDATE_USER_CANCEL');
}

export function* deleteUser({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, index } = payload;
    yield call(api.delete, `/users/${id}`);

    yield put(deleteUserSuccess(index));
    toast.success('Usuário excluído com sucesso');
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(userFailure());
      toast.error('Falha ao excluir usuário');
    }
  }
}

export default all([
  takeLatest('@user/GET_ALL_USERS_REQUEST', getUsers),
  takeLatest('@user/GET_USER_BY_ID_REQUEST', getUserById),
  takeLatest('@user/STORE_USER_REQUEST', storeUser),
  takeLatest('@user/UPDATE_USER_REQUEST', updateUser),
  takeLatest('@user/UPDATE_USER_CANCEL', updateCancel),
  takeLatest('@user/DELETE_USER_REQUEST', deleteUser),
]);
