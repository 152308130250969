/* eslint-disable react/prop-types */
import React from 'react';
import { Badge } from 'shards-react';

export default function ErrorBox({ error }) {
  return (
    <>
      {error && (
        <Badge outline pill theme="danger">
          <span className="font-weight-bold">{error}</span>
        </Badge>
      )}
    </>
  );
}
