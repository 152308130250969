/* List */
export function getAllSignersRequest() {
  return {
    type: '@signer/GET_ALL_SIGNERS_REQUEST',
  };
}
export function getAllSignersSuccess(signers) {
  return {
    type: '@signer/GET_ALL_SIGNERS_SUCCESS',
    payload: { signers },
  };
}
export function getSignerByIdRequest(id, index) {
  return {
    type: '@signer/GET_SIGNER_BY_ID_REQUEST',
    payload: { id, index },
  };
}
export function getSignerByIdSuccess(signer, index) {
  return {
    type: '@signer/GET_SIGNER_BY_ID_SUCCESS',
    payload: { signer, index },
  };
}

/* Store */
export function storeSignerRequest(data, onSuccess) {
  return {
    type: '@signer/STORE_SIGNER_REQUEST',
    payload: { data, onSuccess },
  };
}
export function storeSignerSuccess(signer) {
  return {
    type: '@signer/STORE_SIGNER_SUCCESS',
    payload: { signer },
  };
}

/* Update */
export function updateSignerRequest(id, data, index, onSuccess) {
  return {
    type: '@signer/UPDATE_SIGNER_REQUEST',
    payload: { id, data, index, onSuccess },
  };
}
export function updateSignerSuccess(index, data) {
  return {
    type: '@signer/UPDATE_SIGNER_SUCCESS',
    payload: { index, data },
  };
}
export function updateSignerCancel() {
  return {
    type: '@signer/UPDATE_SIGNER_CANCEL',
  };
}
/* Delete */
export function deleteSignerRequest(id, index) {
  return {
    type: '@signer/DELETE_SIGNER_REQUEST',
    payload: { id, index },
  };
}
export function deleteSignerSuccess(index) {
  return {
    type: '@signer/DELETE_SIGNER_SUCCESS',
    payload: { index },
  };
}

/* Failure */
export function signerFailure() {
  return {
    type: '@signer/SIGNER_FAILURE',
  };
}
