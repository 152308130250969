import React, { useState, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ButtonGroup,
  Button,
  Form,
  ListGroup,
  ListGroupItem,
  FormInput,
  FormGroup,
} from 'shards-react';
import InputMask from 'react-input-mask';
import Swal from 'sweetalert2';
import { FaRegEdit, FaRegTrashAlt, FaPlus } from 'react-icons/fa';
import { FiAlertCircle } from 'react-icons/fi';
import { ButtonWrapper, InputWrapper } from '../common/styles';
import PageTitle from '../../components/common/PageTitle';
import LoadingContainer from '../../components/layout/LodingContainer';
import ErrorBox from '../../components/common/ErrorBox';

import {
  getAllInstitutionsRequest,
  getInstitutionByIdRequest,
  updateInstitutionRequest,
  updateInstitutionCancel,
  deleteInstitutionRequest,
  storeInstitutionRequest,
} from '../../store/modules/institution/actions';

import { isValidCnpjArray } from '../../utils/validations';
import { scrollToForm, scrollToTop } from '../../utils/scrolls';

export default function Institution() {
  const dispatch = useDispatch();
  const { institutions, institution, loading, isSubmitting } = useSelector(
    (state) => state.institution
  );

  const [input, setInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      name: '',
      initials: '',
      cnpj: [undefined],
    }
  );

  const [clientIdToUpdate, setClientIdToUpdate] = useState('');
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(null);

  /*eslint-disable */
  useEffect(() => {
    dispatch(getAllInstitutionsRequest());
  }, []);
  /* eslint-enable */

  useEffect(() => {
    if (Object.keys(institution).length !== 0) {
      setInput({
        name: institution.name,
        initials: institution.initials,
        cnpj: institution.cnpj,
      });
      setClientIdToUpdate(institution.client_id);
    }
  }, [institution]);

  function handleInputChange(e) {
    e.preventDefault();
    const { value, name } = e.target;
    setInput({ [name]: name === 'initials' ? value.toUpperCase() : value });
  }
  function addInput(e) {
    e.preventDefault();
    const cnpjs = [];
    const { cnpj } = input;
    if (cnpj !== undefined) {
      setInput({ cnpj: [...cnpj, undefined] });
    } else {
      cnpjs.push(undefined);
      setInput({ cnpj: cnpjs });
    }
  }
  async function handleCnpjChange(e, index) {
    e.preventDefault();
    const { value } = e.target;
    const { cnpj } = input;
    const cnpjs = [...cnpj];
    const new_value = await value
      .split('/')
      .join('')
      .split('.')
      .join('')
      .split('-')
      .join('');
    cnpjs[index] = new_value;
    setInput({ cnpj: cnpjs });
  }
  function handleCnpjDelete(e, index) {
    e.preventDefault();
    const { cnpj } = input;
    const cnpjs = [...cnpj];
    if (cnpjs.length !== 1) {
      cnpjs.splice(index, 1);
      setInput({ cnpj: cnpjs });
    }
  }
  function getInstitutionData(e, id, index) {
    e.preventDefault();
    scrollToForm();
    setUpdating(true);
    dispatch(getInstitutionByIdRequest(id, index));
  }
  // eslint-disable-next-line consistent-return
  async function handleUpdateInstitution(e) {
    e.preventDefault();
    scrollToForm();
    const id = clientIdToUpdate;
    const data = {
      name: input.name,
      initials: input.initials,
      cnpj: input.cnpj,
    };

    if (!isValidCnpjArray(data.cnpj)) {
      return setError('CNPJ inválido');
    }
    setError(null);
    dispatch(
      updateInstitutionRequest(id, data, institution.index, () => {
        setInput({ name: '', initials: '', cnpj: [undefined] });
        setClientIdToUpdate('');
        scrollToTop();
        return setUpdating(false);
      })
    );
  }
  function handleDeleteInstitution(e, id, index) {
    e.preventDefault();
    dispatch(deleteInstitutionRequest(id, index));
  }

  function handleConfirmation(e, id, index, name) {
    Swal.fire({
      title: 'Confirme para excluir',
      text: `Tem certeza que deseja remover ${name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        return handleDeleteInstitution(e, id, index);
      }
      return false;
    });
  }

  function handleCleanState(e) {
    e.preventDefault();
    dispatch(updateInstitutionCancel());
    setInput({ name: '', initials: '', cnpj: [undefined] });
    setClientIdToUpdate('');
    setUpdating(false);
    setError(null);
    scrollToTop();
  }

  // eslint-disable-next-line consistent-return
  async function handleSubmit(e) {
    e.preventDefault();
    scrollToForm();
    const obj = {
      name: input.name,
      initials: input.initials,
      cnpj: input.cnpj,
    };

    if (!isValidCnpjArray(obj.cnpj)) {
      return setError('CNPJ inválido');
    }
    setError(null);
    dispatch(
      storeInstitutionRequest(obj, () => {
        scrollToTop();
        return setInput({ name: '', initials: '', cnpj: [undefined] });
      })
    );
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Instituições"
          subtitle="Gerenciamento"
          className="text-sm-left"
        />
      </Row>

      {/* Default Light Table */}
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0 font-weight-bold">Lista de instituições</h6>
            </CardHeader>
            <CardBody className="p-0 pb-3 pl-3 pr-3 overflow-auto">
              {loading ? (
                <LoadingContainer loading={loading} />
              ) : (
                <>
                  {institutions.length !== 0 ? (
                    <table className="table mb-0">
                      <thead className="bg-light">
                        <tr>
                          <th scope="col" className="border-0">
                            #
                          </th>
                          <th scope="col" className="border-0">
                            Nome
                          </th>
                          <th scope="col" className="border-0">
                            Sigla
                          </th>
                          <th scope="col" className="border-0">
                            Ações
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {institutions &&
                          institutions.map((i, index) => (
                            <tr key={i._id}>
                              <td>{index}</td>
                              <td>{i.name}</td>
                              <td>{i.initials}</td>
                              <td>
                                <ButtonGroup size="sm">
                                  <Button
                                    theme="white"
                                    onClick={(e) =>
                                      getInstitutionData(e, i.client_id, index)
                                    }
                                  >
                                    <span className="text-light">
                                      <FaRegEdit />
                                    </span>{' '}
                                    Editar
                                  </Button>
                                  <Button
                                    onClick={(e) =>
                                      handleConfirmation(
                                        e,
                                        i.client_id,
                                        index,
                                        i.name
                                      )
                                    }
                                    theme="white"
                                  >
                                    <span className="text-light">
                                      <FaRegTrashAlt />
                                    </span>{' '}
                                    Excluir
                                  </Button>
                                </ButtonGroup>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="col-sm-12 d-flex justify-content-center">
                      <h5 className="mt-3">
                        <FiAlertCircle className="mr-2" />
                        Não existem instituições cadastradas.
                      </h5>
                    </div>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row id="form-container">
        <Col>
          <Card className="mb-3" small>
            <CardHeader className="border-bottom">
              <h6 className="m-0 font-weight-bold">
                Informações sobre a instituição
              </h6>
              {!(institution && institution.client_id && input.name) ? null : (
                <h6>client_id: {institution.client_id}</h6>
              )}
            </CardHeader>
            <ListGroup flush>
              <ListGroupItem className="p-3">
                <Row>
                  <Col>
                    <ErrorBox error={error} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form onSubmit={handleSubmit} id="edit">
                      <Row form>
                        <Col md="6" className="form-group" id="#edit">
                          <InputWrapper>
                            <label htmlFor="institutionName">
                              Nome da instituição
                            </label>
                            <FormInput
                              value={input.name}
                              name="name"
                              onChange={handleInputChange}
                              id="institutionName"
                              type="text"
                              placeholder="Universidade Federal da Paraíba"
                              required
                            />
                            <span className="text-muted">
                              *Insira o nome completo da instituição.
                            </span>
                          </InputWrapper>
                        </Col>
                        <Col md="6" className="form-group">
                          <InputWrapper>
                            <label htmlFor="institutionInitial">Sigla</label>
                            <FormInput
                              value={input.initials}
                              name="initials"
                              onChange={handleInputChange}
                              id="institutionInitial"
                              type="text"
                              placeholder="UFPB"
                              pattern="[A-Z]*"
                              required
                            />
                            <span className="text-muted">
                              *Insira a sigla da instituição.
                            </span>
                          </InputWrapper>
                        </Col>
                      </Row>
                      {input.cnpj !== undefined &&
                        input.cnpj.length !== 0 &&
                        input.cnpj.map((c, index) => (
                          <Row form key={index}>
                            <Col md="6">
                              <FormGroup>
                                <InputWrapper>
                                  <label htmlFor={`institutioncnpj_${index}`}>
                                    CNPJ da instituição
                                  </label>
                                  <InputMask
                                    className="form-control"
                                    mask="99.999.999/9999-99"
                                    maskChar={null}
                                    onChange={(e) => handleCnpjChange(e, index)}
                                    value={c || ''}
                                    id={`institutioncnpj_${index}`}
                                    placeholder="50.366.212/0001-65"
                                    required
                                  />
                                  <span className="text-muted">
                                    *Insira o CNPJ da instituição.
                                  </span>
                                </InputWrapper>
                              </FormGroup>
                            </Col>
                            {index !== 0 && (
                              <Col md="6">
                                <ButtonWrapper>
                                  <Button
                                    onClick={(e) => handleCnpjDelete(e, index)}
                                    theme="white"
                                  >
                                    <span className="text-light">
                                      <FaRegTrashAlt />
                                    </span>{' '}
                                    Excluir
                                  </Button>
                                </ButtonWrapper>
                              </Col>
                            )}
                          </Row>
                        ))}
                      <Row className="mt-2">
                        <Col md="6" className="p-0">
                          <Button
                            name="tags"
                            onClick={(e) => addInput(e)}
                            theme="white"
                            className="ml-3"
                            disabled={isSubmitting}
                          >
                            <span className="text-light">
                              <FaPlus />
                            </span>{' '}
                            Adicionar CNPJ
                          </Button>
                        </Col>
                        <Col md="6" className="text-right">
                          {updating ? (
                            <>
                              <Button
                                className="btn btn-danger btn-sm"
                                onClick={handleCleanState}
                                disabled={isSubmitting}
                              >
                                Cancelar
                              </Button>
                              <Button
                                className="btn-primary ml-3"
                                onClick={handleUpdateInstitution}
                                disabled={isSubmitting}
                              >
                                {isSubmitting ? 'Carregando...' : 'Atualizar'}
                              </Button>
                            </>
                          ) : (
                            <Button disabled={isSubmitting} type="submit">
                              {isSubmitting
                                ? 'Carregando...'
                                : 'Criar instituição'}
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
