/* List */
export function getAllSignatureConfigsRequest() {
  return {
    type: '@signconfig/GET_ALL_SIGNCONFIGS_REQUEST',
  };
}
export function getAllSignatureConfigsSuccess(signConfigs) {
  return {
    type: '@signconfig/GET_ALL_SIGNCONFIGS_SUCCESS',
    payload: { signConfigs },
  };
}
export function getSignatureConfigByIdRequest(id, index) {
  return {
    type: '@signconfig/GET_SIGNCONFIG_BY_ID_REQUEST',
    payload: { id, index },
  };
}
export function getSignatureConfigByIdSuccess(signConfig, index) {
  return {
    type: '@signconfig/GET_SIGNCONFIG_BY_ID_SUCCESS',
    payload: { signConfig, index },
  };
}

/* Store */
export function storeSignatureConfigRequest(data, onSuccess) {
  return {
    type: '@signconfig/STORE_SIGNCONFIG_REQUEST',
    payload: { data, onSuccess },
  };
}
export function storeSignatureConfigSuccess(signConfig) {
  return {
    type: '@signconfig/STORE_SIGNCONFIG_SUCCESS',
    payload: { signConfig },
  };
}

/* Update */
export function updateSignatureConfigRequest(id, data, index, onSuccess) {
  return {
    type: '@signconfig/UPDATE_SIGNCONFIG_REQUEST',
    payload: { id, data, index, onSuccess },
  };
}
export function updateSignatureConfigSuccess(index, data) {
  return {
    type: '@signconfig/UPDATE_SIGNCONFIG_SUCCESS',
    payload: { index, data },
  };
}
export function updateSignatureCancel() {
  return {
    type: '@signconfig/UPDATE_SIGNCONFIG_CANCEL',
  };
}

/* Delete */
export function deleteSignatureConfigRequest(id, index) {
  return {
    type: '@signconfig/DELETE_SIGNCONFIG_REQUEST',
    payload: { id, index },
  };
}
export function deleteSignatureConfigSuccess(index) {
  return {
    type: '@signconfig/DELETE_SIGNCONFIG_SUCCESS',
    payload: { index },
  };
}

/* Failure */
export function signatureConfigFailure() {
  return {
    type: '@signconfig/SIGNCONFIG_FAILURE',
  };
}
