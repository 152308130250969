/* List */
export function getAllInstitutionsRequest() {
  return {
    type: '@institution/GET_ALL_INSTITUTIONS_REQUEST',
  };
}
export function getAllInstitutionsSuccess(institutions) {
  return {
    type: '@institution/GET_ALL_INSTITUTIONS_SUCCESS',
    payload: { institutions },
  };
}
export function getInstitutionByIdRequest(id, index) {
  return {
    type: '@institution/GET_INSTITUTION_BY_ID_REQUEST',
    payload: { id, index },
  };
}
export function getInstitutionByIdSuccess(institution, index) {
  return {
    type: '@institution/GET_INSTITUTION_BY_ID_SUCCESS',
    payload: { institution, index },
  };
}
/* Store */
export function storeInstitutionRequest(data, onSuccess) {
  return {
    type: '@institution/STORE_INSTITUTION_REQUEST',
    payload: { data, onSuccess },
  };
}
export function storeInstitutionSuccess(institution) {
  return {
    type: '@institution/STORE_INSTITUTION_SUCCESS',
    payload: { institution },
  };
}
/* Update */
export function updateInstitutionRequest(id, data, index, onSuccess) {
  return {
    type: '@institution/UPDATE_INSTITUTION_REQUEST',
    payload: { id, data, index, onSuccess },
  };
}
export function updateInstitutionSuccess(index, data) {
  return {
    type: '@institution/UPDATE_INSTITUTION_SUCCESS',
    payload: { index, data },
  };
}
export function updateInstitutionCancel() {
  return {
    type: '@institution/UPDATE_INSTITUTION_CANCEL',
  };
}
/* Delete */
export function deleteInstitutionRequest(id, index) {
  return {
    type: '@institution/DELETE_INSTITUTION_REQUEST',
    payload: { id, index },
  };
}
export function deleteInstitutionSuccess(index) {
  return {
    type: '@institution/DELETE_INSTITUTION_SUCCESS',
    payload: { index },
  };
}
/* Failure */
export function institutionFailure() {
  return {
    type: '@institution/INSTITUTION_FAILURE',
  };
}
