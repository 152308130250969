export function selectInstitution(selectedInstitution) {
  return {
    type: '@selectedData/SELECTED_INSTITUTION',
    selectedInstitution,
  };
}

export function selectPermissionType(selectedPermissionType) {
  return {
    type: '@selectedData/PERMISSION_TYPE',
    selectedPermissionType,
  };
}
