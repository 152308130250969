import { all, takeLatest, call, put, take } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { responseErrors } from '../../../utils/responseErros';
import api from '../../../services/api';
import {
  getAllPreCoursesSuccess,
  storePreCourseSuccess,
  updatePreCourseSuccess,
  deletePreCourseSuccess,
  preCourseFailure,
} from './actions';
import { refreshTokenRequest } from '../auth/actions';
import { store } from '../../index';

export function* getPreCourse({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { limit = 10, page = 1, institutionId } = payload;
    const response = yield call(
      api.get,
      `/management/pre-course?limit=${limit}&page=${page}&institutionId=${institutionId}`
    );
    const preCourses = response.data;
    yield put(getAllPreCoursesSuccess(preCourses));
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(preCourseFailure());
      toast.error(`Falha ao listar grupos de cursos`);
    }
  }
}
// export function* getPreCourseById({ payload }) {
//   const { refresh_token } = store.getState().auth;

//   try {
//     const { id, index } = payload;
//     const response = yield call(api.get, `/management/signers/${id}`);
//     const signer = response.data;

//     yield put(getPreCourseByIdSuccess(signer, index));
//   } catch (err) {
//     if (err.response.status === 403) {
//       yield put(refreshTokenRequest(refresh_token));
//     } else {
//       yield put(preCourseFailure());
//       toast.error('Falha ao obter dados do assinador');
//     }
//   }
// }
export function* storePreCourse({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { data, onSuccess } = payload;

    const response = yield call(api.post, '/management/pre-course', data);
    const preCourse = response.data;

    yield put(storePreCourseSuccess(preCourse));
    toast.success('Grupo de curso criado com sucesso');
    onSuccess && onSuccess();
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(preCourseFailure());
      Swal.fire(responseErrors(err.response, 'Falha ao criar grupo de curso'));
    }
  }
}

export function* updatePreCourse({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, data, onSuccess } = payload;
    const response = yield call(
      api.post,
      `/management/pre-course/${data}/${id}`
    );
    const preCourse = response.data;

    yield put(updatePreCourseSuccess(preCourse));
    toast.success('Pré grupo de curso atualizado com sucesso');
    onSuccess && onSuccess();
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(preCourseFailure());
      Swal.fire(
        responseErrors(err.response, 'Falha ao atualizar pré grupo de curso')
      );
    }
  }
}
export function* updateCancel() {
  yield take('@precourse/UPDATE_PRE_SIGNER_CANCEL');
}

export function* cancelDeleteCourse({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, index } = payload;
    const response = yield call(
      api.post,
      `/management/pre-course/cancel/${id}`
    );
    const preCourse = response.data;

    yield put(deletePreCourseSuccess(preCourse, index));
    toast.success('Pré grupo de curso cancelado com sucesso');
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(preCourseFailure());
      toast.error('Falha ao cancelar pré grupo de curso');
    }
  }
}

export default all([
  takeLatest('@precourse/GET_ALL_PRE_COURSES_REQUEST', getPreCourse),
  // takeLatest('@precourse/GET_PRE_COURSE_BY_ID_REQUEST', getPreCourseById),
  takeLatest('@precourse/STORE_PRE_COURSE_REQUEST', storePreCourse),
  takeLatest('@precourse/UPDATE_PRE_COURSE_REQUEST', updatePreCourse),
  takeLatest('@precourse/DELETE_PRE_COURSE_REQUEST', cancelDeleteCourse),
]);
