import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ButtonGroup,
  Button,
  Form,
  ListGroup,
  ListGroupItem,
  FormInput,
  FormGroup,
  FormSelect,
  InputGroup,
  InputGroupAddon,
} from 'shards-react';

import { FiAlertCircle } from 'react-icons/fi';
import { FaRegEdit, FaRegTrashAlt, FaPlus, FaMagic } from 'react-icons/fa';

import Swal from 'sweetalert2';

import { InputWrapper, FilterWrapper } from '../common/styles';
import SearchSelect from '../../components/common/SearchSelect';
import PageTitle from '../../components/common/PageTitle';
import LoadingContainer from '../../components/layout/LodingContainer';

import { useInput } from '../../hooks/input-hook';
import {
  getAllSignatureConfigsRequest,
  getSignatureConfigByIdRequest,
  storeSignatureConfigRequest,
  updateSignatureConfigRequest,
  updateSignatureCancel,
  deleteSignatureConfigRequest,
} from '../../store/modules/signatureConfig/actions';

import { getAllInstitutionsRequest } from '../../store/modules/institution/actions';
import { getAllTagsRequest } from '../../store/modules/tag/actions';
import { getAllSignersRequest } from '../../store/modules/signer/actions';
import * as SelectedData from '../../store/modules/selectedData/actions';

import { scrollToTop, scrollToForm } from '../../utils/scrolls';
import { SignConfigName } from '../../config/Constants';
import { getInitials } from '../../utils/getIntitutionInitials';

export default function SignatureConfig() {
  const dispatch = useDispatch();

  const sigConfigSigners = useSelector((state) => state.signer.signers);
  const sigConfigTags = useSelector((state) => state.tag.tags);

  const signConfig = useSelector(
    (state) => state.signatureConfiguration.signConfig
  );
  const signConfigs = useSelector(
    (state) => state.signatureConfiguration.signConfigs
  );
  const { loading, isSubmitting } = useSelector(
    (state) => state.signatureConfiguration
  );
  const institutions = useSelector((state) => state.institution.institutions);
  const selectedInstitution = useSelector(
    (state) => state.selectedData.selectedInstitution
  );

  const {
    value: name,
    setValue: setName,
    bind: bindName,
    reset: resetName,
  } = useInput('');
  const {
    value: standard,
    setValue: setStandard,
    bind: bindStandard,
    reset: resetStandard,
  } = useInput('');

  const [filteredInstitution, setFilteredInstitution] =
    useState(selectedInstitution);
  const [institutionFilterStatus, setInstitutionFilterStatus] = useState(false);
  const [filteredSignConfigs, setFilteredSignConfigs] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [tags, setTags] = useState([undefined]);
  const [signConfigIdToUpdate, setSignConfigIdToUpdate] = useState('');
  const [updating, setUpdating] = useState(false);

  /*eslint-disable */
  useEffect(() => {
    dispatch(getAllInstitutionsRequest());
  }, []);

  useEffect(() => {
    if (Object.keys(signConfig).length !== 0 && updating) {
      setName(signConfig.name);
      setStandard(signConfig.standard);
      setTags(signConfig.tags);
      setSignConfigIdToUpdate(signConfig._id);
    }
  }, [signConfig]);

  useEffect(() => {
    setFilteredSignConfigs(signConfigs);
  }, [signConfigs]);

  useEffect(() => {
    setFilteredTags(sigConfigTags);
  }, [sigConfigTags]);

  /* eslint-enable */
  useEffect(() => {
    handleFiltering();
  }, [filteredInstitution]);

  function resetState() {
    resetName();
    resetStandard();

    setTags([undefined]);
    setSignConfigIdToUpdate('');
    setUpdating(false);
  }

  function handleCleanState(e) {
    e.preventDefault();
    resetName();
    resetStandard();

    setTags([undefined]);
    setSignConfigIdToUpdate('');
    setUpdating(false);
    dispatch(updateSignatureCancel());
    scrollToTop();
  }

  function getSigConfigData(e, identifier, index) {
    e.preventDefault();
    dispatch(getSignatureConfigByIdRequest(identifier, index));
    setUpdating(true);
    scrollToForm();
  }

  function handleUpdateSigConfig(e) {
    e.preventDefault();
    const identifier = signConfigIdToUpdate;
    const aux_tags = [];

    for (let i = 0; i < tags.length; i += 1) {
      if (tags[i]._id !== undefined) {
        // eslint-disable-next-line no-await-in-loop
        aux_tags.push(tags[i]._id);
      } else {
        // eslint-disable-next-line no-await-in-loop
        aux_tags.push(tags[i]);
      }
    }

    const signConfigIndex = signConfigs.findIndex(
      (item) => item._id === identifier
    );

    const data = {
      name,
      standard,
      tags: aux_tags,
    };
    dispatch(
      updateSignatureConfigRequest(identifier, data, signConfigIndex, () => {
        resetState();
        scrollToTop();
      })
    );
  }

  async function handleDeleteSigConfig(e, identifier) {
    e.preventDefault();
    const index = signConfigs.findIndex(
      (indexSignConfig) => indexSignConfig._id === identifier
    );
    dispatch(deleteSignatureConfigRequest(identifier, index));
    resetState();
  }

  function handleConfirmation(e, identifier, name) {
    e.preventDefault();
    Swal.fire({
      title: 'Confirme para excluir',
      text: `Tem certeza que deseja remover ${name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        return handleDeleteSigConfig(e, identifier);
      }
      return false;
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const obj = {
      name,
      standard,
      tags,
    };
    dispatch(
      storeSignatureConfigRequest(obj, () => {
        scrollToTop();
        resetState();
      })
    );
  }

  function addInput(e) {
    e.preventDefault();
    if (e.target.name === 'tags') {
      const aux_tags = [];
      if (tags !== undefined) {
        setTags([...tags, undefined]);
      } else {
        aux_tags.push(undefined);
        setTags(aux_tags);
      }
    }
  }

  async function handleDinamicInputChange(e, index) {
    e.preventDefault();
    const { value, name: targetName } = e.target;

    const aux_tags = [...tags];

    if (targetName === 'tags') {
      aux_tags[index] = value;
      setTags(aux_tags);
    }
  }

  function handleTagDelete(e, index) {
    e.preventDefault();
    const aux_tags = [...tags];

    aux_tags.splice(index, 1);
    setTags(aux_tags);
  }

  function handleFiltering() {
    if (!filteredInstitution) {
      setFilteredTags([]);
      setInstitutionFilterStatus(false);

      return;
    }
    resetState();

    dispatch(SelectedData.selectInstitution(filteredInstitution));
    dispatch(getAllSignersRequest());
    dispatch(getAllSignatureConfigsRequest());
    dispatch(getAllTagsRequest());
    setInstitutionFilterStatus(true);
  }
  function handleInstitutionFilter(institution) {
    setFilteredInstitution(institution.value);
  }

  function handleAutoFill() {
    const nameInput = document.getElementById('signConfigName');

    const initials = getInitials(filteredInstitution, institutions);
    const newName = initials
      ? SignConfigName.replace('<INST>', initials)
      : SignConfigName;

    setName(newName);

    setTimeout(() => {
      // eslint-disable-next-line no-unused-expressions
      nameInput && nameInput.focus();
      // eslint-disable-next-line no-unused-expressions
      nameInput && nameInput.setSelectionRange(24, 34);
    }, 50);
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Configuração de assinatura"
          subtitle="Gerenciamento"
          className="text-sm-left"
        />
      </Row>
      {/* Default Light Table */}
      <Row>
        <Col>
          <FilterWrapper>
            <Card small className="col-sm-6 mb-3">
              <CardHeader className="border-bottom">
                <h6 className="m-0 font-weight-bold">
                  <FiAlertCircle className="mr-2 " />
                  Por favor, antes de prosseguir selecione uma instituição:
                </h6>
                <div className="col-sm-12">
                  <SearchSelect
                    onSelect={handleInstitutionFilter}
                    value={filteredInstitution}
                    options={
                      institutions &&
                      institutions.map((i) => ({
                        label: `${i.name} - ${i.initials}`,
                        value: i._id,
                      }))
                    }
                  />
                </div>
              </CardHeader>
            </Card>
          </FilterWrapper>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0 font-weight-bold">
                Lista de configurações de assinatura
              </h6>
            </CardHeader>
            <CardBody className="p-0 pb-3 pl-3 pr-3 overflow-auto">
              {loading ? (
                <LoadingContainer loading={loading} />
              ) : (
                <>
                  {filteredSignConfigs.length !== 0 ? (
                    <table className="table mb-0">
                      <thead className="bg-light">
                        <tr>
                          <th scope="col" className="border-0">
                            #
                          </th>
                          <th scope="col" className="border-0">
                            Nome
                          </th>
                          <th scope="col" className="border-0">
                            Padrão de configuração
                          </th>
                          {!institutionFilterStatus ? null : (
                            <th scope="col" className="border-0">
                              Ações
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {filteredSignConfigs &&
                          filteredSignConfigs.map((s, index) => (
                            <tr key={index}>
                              <td>{index}</td>
                              <td>{s.name}</td>
                              <td>{s.standard}</td>
                              {!institutionFilterStatus ? null : (
                                <td>
                                  <ButtonGroup size="sm">
                                    <Button
                                      theme="white"
                                      onClick={(e) =>
                                        getSigConfigData(e, s._id, index)
                                      }
                                    >
                                      <span className="text-light">
                                        <FaRegEdit />
                                      </span>{' '}
                                      Editar
                                    </Button>
                                    <Button
                                      onClick={(e) =>
                                        handleConfirmation(e, s._id, s.name)
                                      }
                                      theme="white"
                                    >
                                      <span className="text-light">
                                        <FaRegTrashAlt />
                                      </span>{' '}
                                      Excluir
                                    </Button>
                                  </ButtonGroup>
                                </td>
                              )}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="col-sm-12 d-flex justify-content-center">
                      <h5 className="mt-3">
                        <FiAlertCircle className="mr-2" />
                        Não existem configurações de assinaturas cadastradas.
                      </h5>
                    </div>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {!institutionFilterStatus ? null : (
        <Row id="form-container">
          <Col>
            <Card className="mb-3" small>
              <CardHeader className="border-bottom">
                <h6 className="m-0 font-weight-bold">
                  Informações sobre configuração de assinatura
                </h6>
              </CardHeader>
              {sigConfigTags.length !== 0 && sigConfigSigners.length !== 0 ? (
                <ListGroup flush>
                  <ListGroupItem className="p-3">
                    <Row>
                      <Col>
                        <Form onSubmit={handleSubmit} id="edit">
                          <Row form>
                            <Col md="6" className="form-group">
                              <InputWrapper>
                                <Row>
                                  <Col md="6">
                                    <label
                                      htmlFor="signConfigName"
                                      className="d-block"
                                    >
                                      Nome identificador
                                    </label>
                                  </Col>
                                  <Col md="6" className="text-right mb-1">
                                    <Button
                                      onClick={handleAutoFill}
                                      outline
                                      pill
                                      size="sm"
                                    >
                                      <span className="text-primary">
                                        <FaMagic />
                                      </span>{' '}
                                      Auto preencher
                                    </Button>
                                  </Col>
                                </Row>

                                <FormInput
                                  id="signConfigName"
                                  type="text"
                                  placeholder="Configuração Diploma"
                                  name="name"
                                  required
                                  {...bindName}
                                />
                                <span className="text-muted">
                                  *Insira o nome da configuração de assinatura.
                                </span>
                              </InputWrapper>
                            </Col>
                            <Col md="6" className="form-group">
                              <InputWrapper>
                                <label htmlFor="signConfigPattern">
                                  Padrão de assinatura
                                </label>
                                <FormSelect
                                  id="signConfigPattern"
                                  name="standard"
                                  required
                                  {...bindStandard}
                                >
                                  <option disabled value="">
                                    Escolher...
                                  </option>
                                  <option value="XADES">XADES</option>
                                  <option value="PADES">PADES</option>
                                  <option value="CADES">CADES</option>
                                  <option value="CMS">CMS</option>
                                  <option value="XMLDSIG">XMLDSIG</option>
                                </FormSelect>
                                <span className="text-muted">
                                  *Informe qual o padrão de assinatura.
                                </span>
                              </InputWrapper>
                            </Col>
                          </Row>
                          <Row form>
                            <Col md="6">
                              {tags !== undefined &&
                                tags.length !== 0 &&
                                tags.map((t, index) => (
                                  <FormGroup key={index}>
                                    <label htmlFor="signConfigTag">Tags</label>
                                    <InputWrapper>
                                      <InputGroup className="mb-1">
                                        <FormSelect
                                          name="tags"
                                          required
                                          value={t === undefined ? '' : t._id}
                                          onChange={(e) =>
                                            handleDinamicInputChange(e, index)
                                          }
                                        >
                                          <option disabled value="">
                                            Escolher...
                                          </option>
                                          {filteredTags &&
                                            filteredTags.map((s) => (
                                              <option key={s._id} value={s._id}>
                                                {s.tagID}
                                              </option>
                                            ))}
                                        </FormSelect>
                                        <InputGroupAddon type="append">
                                          {index !== 0 && (
                                            <Button
                                              onClick={(e) =>
                                                handleTagDelete(e, index)
                                              }
                                              theme="white"
                                            >
                                              <span className="text-light">
                                                <FaRegTrashAlt />
                                              </span>{' '}
                                              Excluir
                                            </Button>
                                          )}
                                        </InputGroupAddon>
                                      </InputGroup>
                                      <span className="text-muted">
                                        *Selecione uma tag.
                                      </span>
                                    </InputWrapper>
                                  </FormGroup>
                                ))}
                            </Col>
                          </Row>

                          <Row className="mt-2">
                            <Col md="6" className="p-0">
                              <Button
                                name="tags"
                                onClick={(e) => addInput(e)}
                                theme="white"
                                className="ml-3"
                                disabled={isSubmitting}
                              >
                                <FaPlus /> Adicionar Tag
                              </Button>
                            </Col>
                            <Col md="6" className="text-right">
                              {updating ? (
                                <>
                                  <Button
                                    className="btn btn-danger btn-sm"
                                    onClick={handleCleanState}
                                    disabled={isSubmitting}
                                  >
                                    Cancelar
                                  </Button>
                                  <Button
                                    className="btn-primary ml-3"
                                    onClick={handleUpdateSigConfig}
                                    disabled={isSubmitting}
                                  >
                                    {isSubmitting
                                      ? 'Carregando...'
                                      : 'Atualizar'}
                                  </Button>
                                </>
                              ) : (
                                <Button disabled={isSubmitting} type="submit">
                                  {isSubmitting
                                    ? 'Carregando...'
                                    : 'Criar configuração de assinatura'}
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              ) : (
                <div className="col-sm-12 d-flex justify-content-center">
                  <h5 className="mt-3">
                    <FiAlertCircle className="mr-2" />
                    Não é possível continuar, não encontramos tags ou
                    assinadores cadastrados, para continuar é preciso cadastrar
                    ao menos uma dessas entidades!
                  </h5>
                </div>
              )}
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
}
