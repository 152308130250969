/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import RcPagination from 'rc-pagination';
import { FormSelect } from 'shards-react';
import { Container, SetRows } from './style';
import 'rc-pagination/assets/index.css';

export default function Pagination({
  page = 1,
  total = 1,
  items = 10,
  onChangePage,
  onChangeItems,
}) {
  const [currentPage, setCurrentPage] = useState(page);
  const [currentItems, setCurrentItems] = useState(items);

  // eslint-disable-next-line no-shadow
  function handleChangePage(page) {
    setCurrentPage(page);
    onChangePage(page);
  }

  function handleChangeItem(e) {
    const { value } = e.target;
    const number = parseFloat(value);
    if (number > total && currentPage !== 1) {
      handleChangePage(1);
    }
    setCurrentItems(number);
    onChangeItems(number);
  }

  return (
    <Container>
      <SetRows>
        <FormSelect
          id="rowsPerPage"
          name="rowsPerPage"
          value={currentItems}
          onChange={handleChangeItem}
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="40">40</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </FormSelect>
        <label htmlFor="rowsPerPage">Itens por página</label>
      </SetRows>
      <RcPagination
        total={total}
        current={currentPage}
        onChange={handleChangePage}
        pageSize={currentItems}
      />
    </Container>
  );
}
