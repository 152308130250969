import { produce } from 'immer';

const INITIAL_STATE = {
  batches: null,
  batch: {},
  total: 0,
  numberOfPages: 0,
  loading: false,
  isSubmitting: false,
};

export default function batch(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@batches/CANCEL_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@batches/CANCEL_SUCCESS': {
        draft.loading = false;
        break;
      }
      case '@batches/CLOSE_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@batches/CLOSE_SUCCESS': {
        draft.loading = false;
        break;
      }
      case '@batches/GET_ALL_BATCHES_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@batches/GET_ALL_BATCHES_SUCCESS': {
        draft.batches = action.payload.batches.content;
        draft.total = action.payload.batches.total;
        draft.numberOfPages = action.payload.batches.numberOfPages;
        draft.loading = false;
        break;
      }
      case '@batches/GET_BATCH_BY_ID_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@batches/GET_BATCH_BY_ID_SUCCESS': {
        draft.loading = false;
        draft.batch = action.payload.batch;
        break;
      }
      case '@batches/BATCH_FAILURE': {
        draft.loading = false;
        draft.isSubmitting = false;
        break;
      }
      case '@batches/CLEAN': {
        draft.loading = false;
        draft.batch = {};
        draft.batches = null;
        break;
      }
      default:
    }
  });
}
