import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import api from '../../../services/api';
import {
  getAllDocTypesSuccess,
  getDocTypeByIdSuccess,
  storeDocTypeSuccess,
  updateDocTypeSuccess,
  deleteDocTypeSuccess,
  docTypeFailure,
} from './actions';
import { refreshTokenRequest } from '../auth/actions';
import { store } from '../../index';
import { responseErrors } from '../../../utils/responseErros';

export function* getDocTypes() {
  const { refresh_token } = store.getState().auth;

  try {
    const response = yield call(api.get, '/management/doctypes');
    const doctypes = response.data;

    yield put(getAllDocTypesSuccess(doctypes));
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(docTypeFailure());
      toast.error(`Falha ao listar tipos de documentos`);
    }
  }
}
export function* getDocTypeById({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, index } = payload;
    const response = yield call(api.get, `/management/doctypes/${id}`);
    const doctype = response.data;

    yield put(getDocTypeByIdSuccess(doctype, index));
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(docTypeFailure());
      toast.error('Falha ao obter dados do tipo de documento');
    }
  }
}
export function* storeDocType({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { data, onSuccess } = payload;

    const response = yield call(api.post, '/management/doctypes', data);
    const doctype = response.data;
    yield put(storeDocTypeSuccess(doctype));
    toast.success('Tipo de documento criado com sucesso');
    onSuccess && onSuccess();
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(docTypeFailure());
      Swal.fire(
        responseErrors(err.response, 'Falha ao criar tipo de documento')
      );
    }
  }
}

export function* updateDocType({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, data, index, onSuccess } = payload;
    const response = yield call(api.patch, `/management/doctypes/${id}`, data);
    const doctype = response.data;

    yield put(updateDocTypeSuccess(index, doctype));
    toast.success('Tipo de documento atualizado com sucesso');
    onSuccess && onSuccess();
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(docTypeFailure());
      Swal.fire(
        responseErrors(err.response, 'Falha ao atualizar tipo de documento')
      );
    }
  }
}

export function* deleteDocType({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, index } = payload;
    yield call(api.delete, `/management/doctypes/${id}`);

    yield put(deleteDocTypeSuccess(index));
    toast.success('Tipo de documento excluído com sucesso');
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(docTypeFailure());
      toast.error('Falha ao excluir tipo de documento');
    }
  }
}

export default all([
  takeLatest('@doctype/GET_ALL_DOCTYPES_REQUEST', getDocTypes),
  takeLatest('@doctype/GET_DOCTYPE_BY_ID_REQUEST', getDocTypeById),
  takeLatest('@doctype/STORE_DOCTYPE_REQUEST', storeDocType),
  takeLatest('@doctype/UPDATE_DOCTYPE_REQUEST', updateDocType),
  takeLatest('@doctype/DELETE_DOCTYPE_REQUEST', deleteDocType),
]);
