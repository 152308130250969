/* List */
export function getAllPreCoursesRequest({ limit, page, institutionId } = {}) {
  return {
    type: '@precourse/GET_ALL_PRE_COURSES_REQUEST',
    payload: { limit, page, institutionId },
  };
}
export function getAllPreCoursesSuccess(preCourses) {
  return {
    type: '@precourse/GET_ALL_PRE_COURSES_SUCCESS',
    payload: { preCourses },
  };
}
export function getPreCourseByIdRequest(id, index) {
  return {
    type: '@precourse/GET_PRE_COURSE_BY_ID_REQUEST',
    payload: { id, index },
  };
}
export function getPreCourseByIdSuccess(course, index) {
  return {
    type: '@precourse/GET_PRE_COURSE_BY_ID_SUCCESS',
    payload: { course, index },
  };
}

/* Store */
export function storePreCourseRequest(data, onSuccess) {
  return {
    type: '@precourse/STORE_PRE_COURSE_REQUEST',
    payload: { data, onSuccess },
  };
}
export function storePreCourseSuccess(course) {
  return {
    type: '@precourse/STORE_PRE_COURSE_SUCCESS',
    payload: { course },
  };
}

/* Update */
export function updatePreCourseRequest(id, data, onSuccess) {
  return {
    type: '@precourse/UPDATE_PRE_COURSE_REQUEST',
    payload: { id, data, onSuccess },
  };
}
export function updatePreCourseSuccess(data) {
  return {
    type: '@precourse/UPDATE_PRE_COURSE_SUCCESS',
    payload: { data },
  };
}
export function updatePreCourseCancel() {
  return {
    type: '@precourse/UPDATE_PRE_COURSE_CANCEL',
  };
}
/* Delete */
export function cancelPreCourseRequest(id, index) {
  return {
    type: '@precourse/DELETE_PRE_COURSE_REQUEST',
    payload: { id, index },
  };
}
export function deletePreCourseSuccess(data, index) {
  return {
    type: '@precourse/DELETE_PRE_COURSE_SUCCESS',
    payload: { data, index },
  };
}

/* Failure */
export function preCourseFailure() {
  return {
    type: '@precourse/PRE_COURSE_FAILURE',
  };
}
