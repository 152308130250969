import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { toast } from 'react-toastify';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ButtonGroup,
  Button,
  Form,
  ListGroup,
  ListGroupItem,
  FormInput,
} from 'shards-react';

import { FiAlertCircle } from 'react-icons/fi';
import {
  FaRegEdit,
  FaRegTrashAlt,
  FaPlus,
  FaGlobe,
  FaFileUpload,
} from 'react-icons/fa';

import Swal from 'sweetalert2';
import { ButtonWrapper, InputWrapper, FilterWrapper } from '../common/styles';

import { useInput } from '../../hooks/input-hook';

import PageTitle from '../../components/common/PageTitle';
import LoadingContainer from '../../components/layout/LodingContainer';

import SearchSelect from '../../components/common/SearchSelect';

import {
  getAllCoursesRequest,
  getCourseByIdRequest,
  storeCourseRequest,
  updateCourseRequest,
  deleteCourseRequest,
} from '../../store/modules/course/actions';

import { ValueInput } from './styles';

import { getAllInstitutionsRequest } from '../../store/modules/institution/actions';
import * as SelectedData from '../../store/modules/selectedData/actions';

import { scrollToForm, scrollToTop } from '../../utils/scrolls';
import { readCsv } from '../../utils/readCsvFile';

export default function Tag() {
  const dispatch = useDispatch();

  const institutions = useSelector((state) => state.institution.institutions);
  const courses = useSelector((state) => state.course.courses);
  const course = useSelector((state) => state.course.course);
  const { loading, isSubmitting } = useSelector((state) => state.course);
  const selectedInstitution = useSelector(
    (state) => state.selectedData.selectedInstitution
  );

  const {
    value: tagDescription,
    setValue: setTagDescription,
    bind: bindTagDescription,
    reset: resetTagDescription,
  } = useInput('');

  const [filteredInstitution, setFilteredInstitution] =
    useState(selectedInstitution);

  const [courseItens, setCourseItens] = useState([{}]);
  const [courseIdToUpdate, setTagIdToUpdate] = useState('');
  const [updating, setUpdating] = useState(false);
  const [institutionFilterStatus, setInstitutionFilterStatus] = useState(false);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const inputElement = useRef();

  /*eslint-disable */
  useEffect(() => {
    dispatch(getAllInstitutionsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(course).length !== 0 && updating) {
      setTagDescription(course.tag);
      setCourseItens(course.course);
      setTagIdToUpdate(course.courseGroupId);
    }
  }, [course]);

  useEffect(() => {
    setFilteredCourses(courses);
  }, [courses]);
  /* eslint-enable */
  useEffect(() => {
    handleFiltering();
  }, [filteredInstitution]);
  function resetState() {
    setCourseItens([{}]);
    resetTagDescription('');
    setTagIdToUpdate('');
    setUpdating(false);
    resetFile();
    scrollToTop();
  }

  function handleCleanState(e) {
    e.preventDefault();
    setCourseItens([{}]);
    resetTagDescription('');
    setTagIdToUpdate('');
    setUpdating(false);
    resetFile();
    scrollToTop();
  }
  function getCourseData(e, identifier, index) {
    e.preventDefault();
    dispatch(getCourseByIdRequest(identifier, index));
    setUpdating(true);
    scrollToForm();
  }

  async function handleUpdateCourse(e) {
    e.preventDefault();
    const identifier = courseIdToUpdate;

    const courseIndex = courses.findIndex(
      (item) => item.courseGroupId === identifier
    );

    const data = {
      institution: course.institution._id,
      tag: tagDescription,
      course: courseItens,
      client_id: course.institution._id,
    };

    dispatch(
      updateCourseRequest(courseIdToUpdate, data, courseIndex, () => {
        scrollToTop();
        resetState();
      })
    );
  }
  function handleDeleteCourse(e, identifier) {
    e.preventDefault();

    const index = courses.findIndex(
      (element) => element.courseGroupId === identifier
    );

    dispatch(deleteCourseRequest(identifier, index));

    resetState();
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const institution = institutions.find(
      (item) => item._id === filteredInstitution
    );

    const obj = {
      institution,
      tag: tagDescription,
      course: courseItens,
    };

    dispatch(
      storeCourseRequest(obj, () => {
        scrollToTop();
        resetState();
      })
    );
  }

  async function handleSubmitDefaultCourse() {
    Swal.fire({
      title: 'Atenção',
      html: `
      <p>Tem certeza que deseja adicionar um grupo com todos os cursos? </p>
      <p class="font-weight-bold">É permitido o cadastro somente de 1 grupo com todos os cursos.</p>`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        const institution = institutions.find(
          (item) => item._id === filteredInstitution
        );

        const obj = {
          institution,
          tag: 'Todos os Cursos',
          course: [
            {
              course_name: 'Todos os Cursos',
              emec_code: '000000',
            },
          ],
        };

        return dispatch(
          storeCourseRequest(obj, () => {
            scrollToTop();
            resetState();
          })
        );
      }
      return false;
    });
  }

  function handleConfirmation(e, identifier, refCourse, name) {
    Swal.fire({
      title: 'Confirme para excluir',
      text: `Tem certeza que deseja remover ${name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        return handleDeleteCourse(e, identifier, refCourse);
      }
      return false;
    });
  }

  function addInput(e) {
    e.preventDefault();
    const aux = [];
    if (courseItens !== undefined) {
      setCourseItens([...courseItens, {}]);
    } else {
      aux.push(undefined);
      courseItens(aux);
    }
  }

  function handleFiltering() {
    if (!filteredInstitution) {
      setFilteredCourses([]);
      setInstitutionFilterStatus(false);

      return;
    }
    resetState();

    dispatch(SelectedData.selectInstitution(filteredInstitution));
    dispatch(getAllCoursesRequest());
    setInstitutionFilterStatus(true);
  }
  function handleInstitutionFilter(institution) {
    setFilteredInstitution(institution.value);
  }

  async function handleCourseItensChange(e, index) {
    e.preventDefault();
    const { value, name: targetName } = e.target;
    const aux = _.cloneDeep(courseItens);

    if (targetName === 'course_name') {
      aux[index].course_name = value;
    } else {
      aux[index].emec_code = value;
    }
    setCourseItens(aux);
  }
  function handleCourseItensDelete(e, index) {
    e.preventDefault();
    const aux = [...courseItens];
    aux.splice(index, 1);
    setCourseItens(aux);
  }

  function handleChoseFile() {
    if (inputElement.current) {
      inputElement.current.click();
    }
  }

  function resetFile() {
    if (inputElement.current) {
      inputElement.current.value = '';
    }
  }

  async function handleFile(e) {
    const { value } = e.target;

    if (!value) return;
    const extension = value
      .split(/(\\|\/)/g)
      .pop()
      .split('.')
      .pop();

    if (!['csv'].includes(extension)) {
      toast.error('Formato não suportado', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 'custom-id-yes',
      });
      return;
    }

    const file = e.target.files[0];

    try {
      const csv = await readCsv(file);

      const lines = String(csv).split('\n');

      const groupCouseName = String(lines[0])
        .split(',')[1]
        .replace(/[\r\n]/g, '');

      const courses = lines
        .slice(2)
        .filter((course) => course !== '' && course !== '\n')
        .map((course) => {
          const [course_name, emec_code] = course.split(',');

          return {
            course_name: course_name.replace(/[\r\n]/g, ''),
            emec_code: emec_code.replace(/[\r\n]/g, ''),
          };
        });

      setTagDescription(groupCouseName);
      setCourseItens(courses);

      scrollToForm();

      toast.success('Arquivo importado', {
        position: 'bottom-right',
        autoClose: 2000,
        hideProgressBar: false,
        toastId: 'custom-id-yes',
      });
    } catch (error) {
      toast.error('Ocorreu um erro na importação', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 'custom-id-yes',
      });
    } finally {
      resetFile();
    }
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Cursos"
          subtitle="Gerenciamento"
          className="text-sm-left"
        />
      </Row>
      {/* Default Light Table */}
      <Row>
        <Col>
          <FilterWrapper>
            <Card small className="col-sm-6 mb-3">
              <CardHeader className="border-bottom">
                <h6 className="m-0 font-weight-bold">
                  <FiAlertCircle className="mr-2 " />
                  Por favor, antes de prosseguir selecione uma instituição:
                </h6>
                <div className="col-sm-12">
                  <SearchSelect
                    onSelect={handleInstitutionFilter}
                    value={filteredInstitution}
                    options={
                      institutions &&
                      institutions.map((i) => ({
                        label: `${i.name} - ${i.initials}`,
                        value: i._id,
                      }))
                    }
                  />
                </div>
              </CardHeader>
            </Card>
          </FilterWrapper>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0 font-weight-bold">
                Lista de Grupos de Cursos
              </h6>
            </CardHeader>
            <CardBody className="p-0 pb-3 pl-3 pr-3 overflow-auto">
              {loading ? (
                <LoadingContainer loading={loading} />
              ) : (
                <>
                  {filteredCourses.length !== 0 ? (
                    <table className="table mb-0">
                      <thead className="bg-light">
                        <tr>
                          <th scope="col" className="border-0">
                            #
                          </th>
                          <th scope="col" className="border-0">
                            Tag de Identificação
                          </th>
                          <th scope="col" className="border-0">
                            Instituição
                          </th>

                          {!institutionFilterStatus ? null : (
                            <th scope="col" className="border-0">
                              Ações
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {filteredCourses &&
                          filteredCourses.map((s, index) => (
                            <tr key={s._id}>
                              <td>{index}</td>
                              <td>{s.tag}</td>
                              <td>{s.institution.name}</td>
                              {!institutionFilterStatus ? null : (
                                <td>
                                  <ButtonGroup size="sm">
                                    <Button
                                      theme="white"
                                      onClick={(e) => {
                                        getCourseData(
                                          e,
                                          s.courseGroupId,
                                          index
                                        );
                                      }}
                                    >
                                      <span className="text-light">
                                        <FaRegEdit />
                                      </span>{' '}
                                      Editar
                                    </Button>
                                    <Button
                                      onClick={(e) =>
                                        handleConfirmation(
                                          e,
                                          s.courseGroupId,
                                          s,
                                          s.tag
                                        )
                                      }
                                      theme="white"
                                    >
                                      <span className="text-light">
                                        <FaRegTrashAlt />
                                      </span>{' '}
                                      Excluir
                                    </Button>
                                  </ButtonGroup>
                                </td>
                              )}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="col-sm-12 d-flex justify-content-center">
                      <h5 className="mt-3">
                        <FiAlertCircle className="mr-2" />
                        Não existem courses cadastradas.
                      </h5>
                    </div>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {!institutionFilterStatus ? null : (
        <Row id="form-container">
          <Col>
            <Card className="mb-3" small>
              <CardHeader className="border-bottom d-flex justify-content-between align-items-center">
                <h6 className="m-0 font-weight-bold">
                  Informações sobre cursos
                </h6>
                {!updating && (
                  <>
                    <Button
                      onClick={handleChoseFile}
                      theme="secondary"
                      pill
                      outline
                      size="sm"
                      className="justify-content-between align-items-center"
                      disabled={loading}
                    >
                      <span>
                        <FaFileUpload />
                      </span>{' '}
                      Importar .csv
                    </Button>
                    <input
                      type="file"
                      ref={inputElement}
                      onChange={handleFile}
                      hidden
                      accept=".csv"
                    />
                  </>
                )}
              </CardHeader>
              {filteredInstitution ? (
                <ListGroup flush>
                  <ListGroupItem className="p-3">
                    <Row>
                      <Col>
                        <Form onSubmit={handleSubmit} id="edit">
                          <Row form>
                            <Col md="12" className="form-group">
                              <InputWrapper>
                                <label htmlFor="tagIdentifier">
                                  Nome do Grupo
                                </label>
                                <FormInput
                                  id="tagIdentifier"
                                  type="text"
                                  placeholder="Grupo exemplo"
                                  name="courseID"
                                  required
                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                  {...bindTagDescription}
                                />
                                <span className="text-muted">
                                  *Insira o identificador dos cursos.
                                </span>
                              </InputWrapper>
                            </Col>
                          </Row>
                          {courseItens !== undefined &&
                            courseItens.length !== 0 &&
                            courseItens.map((v, index) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <Row form key={index}>
                                <Col md="4" className="form-group">
                                  <InputWrapper>
                                    <label htmlFor="course_name">
                                      Nome do Curso
                                    </label>
                                    <FormInput
                                      id="course_name"
                                      type="text"
                                      placeholder="Curso exemplo"
                                      required
                                      value={
                                        v.course_name === undefined
                                          ? ''
                                          : v.course_name
                                      }
                                      name="course_name"
                                      onChange={(e) =>
                                        handleCourseItensChange(e, index)
                                      }
                                    />
                                    <span className="text-muted">
                                      *Nome do Curso
                                    </span>
                                  </InputWrapper>
                                </Col>
                                <Col md="4" className="form-group">
                                  <ValueInput>
                                    <InputWrapper>
                                      <FormInput
                                        id="emec_code"
                                        name="emec_code"
                                        value={
                                          v.emec_code === undefined
                                            ? ''
                                            : v.emec_code
                                        }
                                        required
                                        type="text"
                                        placeholder="1234"
                                        onChange={(e) =>
                                          handleCourseItensChange(e, index)
                                        }
                                        pattern="^[0-9]+$"
                                      />
                                      <span className="text-muted">
                                        *Código E-Mec
                                      </span>
                                    </InputWrapper>
                                  </ValueInput>
                                </Col>
                                <Col md="4">
                                  <ButtonWrapper>
                                    {index !== 0 && (
                                      <Button
                                        onClick={(e) =>
                                          handleCourseItensDelete(e, index)
                                        }
                                        theme="white"
                                      >
                                        <span className="text-light">
                                          <FaRegTrashAlt />
                                        </span>{' '}
                                        Excluir
                                      </Button>
                                    )}
                                  </ButtonWrapper>
                                </Col>
                              </Row>
                            ))}
                          <Row className="mt-2">
                            <Col md="6" className="p-0">
                              <Button
                                onClick={(e) => addInput(e)}
                                theme="white"
                                className="ml-3"
                                disabled={isSubmitting}
                              >
                                <span className="text-light">
                                  <FaPlus />
                                </span>{' '}
                                Adicionar Curso
                              </Button>
                              <Button
                                onClick={(e) => handleSubmitDefaultCourse(e)}
                                theme="white"
                                className="ml-3"
                              >
                                <span className="text-light">
                                  <FaGlobe />
                                </span>{' '}
                                Adicionar grupo com TODOS os cursos
                              </Button>
                            </Col>
                            <Col md="6" className="text-right">
                              {updating ? (
                                <>
                                  <Button
                                    className="btn btn-danger btn-sm"
                                    onClick={handleCleanState}
                                    disabled={isSubmitting}
                                  >
                                    Cancelar
                                  </Button>
                                  <Button
                                    className="btn-primary ml-3"
                                    onClick={handleUpdateCourse}
                                    disabled={isSubmitting}
                                  >
                                    {isSubmitting
                                      ? 'Carregando...'
                                      : 'Atualizar'}
                                  </Button>
                                </>
                              ) : (
                                <Button disabled={isSubmitting} type="submit">
                                  {isSubmitting
                                    ? 'Carregando...'
                                    : 'Criar novo grupo de cursos'}
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              ) : (
                <div className="col-sm-12 d-flex justify-content-center py-2">
                  <h5 className="mt-3">
                    <FiAlertCircle className="mr-2" />
                    Não é possível continuar, não encontramos instituições ou
                    assinadores ou itens de assinatura cadastradas, para
                    continuar é preciso cadastrar ao menos uma dessas entidades!
                  </h5>
                </div>
              )}
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
}
