import { configureStore } from '@reduxjs/toolkit';

const createStore = (reducers, sagaMiddleware) => {
  return configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(sagaMiddleware),
  });
};

export default createStore;
