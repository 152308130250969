export function setGetLastDocuments(getLast) {
  return {
    type: '@documents/SET_GET_LAST_DOCUMENT',
    payload: { getLast },
  };
}

export function setSearchQuery(search) {
  return {
    type: '@documents/SET_SEARCH_QUERY',
    payload: { search },
  };
}

export function setSelectedId(selectedId) {
  return {
    type: '@documents/SET_SELECTED_ID',
    payload: { selectedId },
  };
}

/* List */
export function getAllDocumentsRequest(
  {
    limit,
    page,
    institutionId,
    getLast,
    search,
    initialDate,
    finalDate,
    docTypeId,
  } = {},
  onSuccess
) {
  return {
    type: '@documents/GET_ALL_DOCUMENTS_REQUEST',
    payload: {
      limit,
      page,
      institutionId,
      getLast,
      search,
      initialDate,
      finalDate,
      docTypeId,
      onSuccess,
    },
  };
}
export function getAllDocumentsSuccess(documents) {
  return {
    type: '@documents/GET_ALL_DOCUMENTS_SUCCESS',
    payload: { documents },
  };
}
export function getDocumentByIdRequest(id) {
  return {
    type: '@documents/GET_DOCUMENT_BY_ID_REQUEST',
    payload: { id },
  };
}
export function getDocumentByIdSuccess(document) {
  return {
    type: '@documents/GET_DOCUMENT_BY_ID_SUCCESS',
    payload: { document },
  };
}

/* Update */
export function updateDocumentRequest(id, data, onSuccess) {
  return {
    type: '@documents/UPDATE_DOCUMENT_REQUEST',
    payload: { id, data, onSuccess },
  };
}
export function updateDocumentSuccess(data) {
  return {
    type: '@documents/UPDATE_DOCUMENT_SUCCESS',
    payload: { data },
  };
}
export function updateDocumentCancel() {
  return {
    type: '@documents/UPDATE_DOCUMENT_CANCEL',
  };
}

/* REVOKE */
export function revokeDocumentRequest() {
  return {
    type: '@documents/REVOKE_REQUEST',
  };
}
export function revokeDocumentRequestSuccess() {
  return {
    type: '@documents/REVOKE_SUCCESS',
  };
}

/* CANCEL */
export function cancelDocumentRequest() {
  return {
    type: '@documents/CANCEL_REQUEST',
  };
}
export function cancelDocumentRequestSuccess() {
  return {
    type: '@documents/CANCEL_SUCCESS',
  };
}

/* UPDATE STATUS */
export function updateStatusRequest(status) {
  return {
    type: '@documents/UPDATE_STATUS_REQUEST',
    payload: { status },
  };
}
export function updateStatusRequestSuccess(status) {
  return {
    type: '@documents/UPDATE_STATUS_SUCCESS',
    payload: { status },
  };
}

/* DOWNLOAD */
export function downloadRequest() {
  return {
    type: '@documents/DOWNLOAD_REQUEST',
  };
}
export function downloadRequestSuccess() {
  return {
    type: '@documents/DOWNLOAD_SUCCESS',
  };
}

/* Failure */
export function DocumentFailure() {
  return {
    type: '@documents/DOCUMENT_FAILURE',
  };
}
