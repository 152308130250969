import { produce } from 'immer';

const INITIAL_STATE = {
  docGroups: [],
  docGroup: {},
  loading: false,
  isSubmitting: false,
};

export default function documentGroup(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@docgroups/GET_ALL_DOCGROUPS_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@docgroups/GET_ALL_DOCGROUPS_SUCCESS': {
        draft.docGroups = action.payload.docGroups;
        draft.loading = false;
        break;
      }
      case '@docgroups/GET_DOCGROUP_BY_ID_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@docgroups/GET_DOCGROUP_BY_ID_SUCCESS': {
        draft.loading = false;
        draft.docGroup = action.payload.docGroup;
        draft.docGroup.index = action.payload.index;
        break;
      }
      case '@docgroups/STORE_DOCGROUP_REQUEST': {
        draft.isSubmitting = true;
        break;
      }
      case '@docgroups/STORE_DOCGROUP_SUCCESS': {
        const aux = [...draft.docGroups];
        aux.push(action.payload.docGroup);
        draft.docGroups = aux;
        draft.isSubmitting = false;
        break;
      }
      case '@docgroups/UPDATE_DOCGROUP_REQUEST': {
        draft.isSubmitting = true;
        break;
      }
      case '@docgroups/UPDATE_DOCGROUP_SUCCESS': {
        const { data } = action.payload;
        const aux = draft.docGroups;
        aux[draft.docGroup.index] = data;
        draft.docGroups = aux;
        draft.isSubmitting = false;
        break;
      }
      case '@docgroups/DELETE_DOCGROUP_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@docgroups/DELETE_DOCGROUP_SUCCESS': {
        const aux = draft.docGroups;
        aux.splice(action.payload.index, 1);
        draft.docGroups = aux;
        draft.loading = false;
        break;
      }
      case '@docgroups/DOCGROUP_FAILURE': {
        draft.loading = false;
        draft.isSubmitting = false;
        break;
      }
      default:
    }
  });
}
