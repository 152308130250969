import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { responseErrors } from '../../../utils/responseErros';
import api from '../../../services/api';
import {
  getAllDocGroupsSuccess,
  getDocGroupByIdSuccess,
  storeDocGroupSuccess,
  updateDocGroupSuccess,
  deleteDocGroupSuccess,
  docGroupFailure,
} from './actions';
import { refreshTokenRequest } from '../auth/actions';
import { store } from '../../index';

export function* getDocGroups() {
  const { refresh_token } = store.getState().auth;

  try {
    const response = yield call(api.get, '/management/docgroups');
    const docgroups = response.data;

    yield put(getAllDocGroupsSuccess(docgroups));
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(docGroupFailure());
      toast.error(`Falha ao listar grupos de documentos`);
    }
  }
}
export function* getDocGroupById({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, index } = payload;
    const response = yield call(api.get, `/management/docgroups/${id}`);
    const docgroup = response.data;

    yield put(getDocGroupByIdSuccess(docgroup, index));
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(docGroupFailure());
      toast.error('Falha ao obter dados do grupo de documentos');
    }
  }
}
export function* storeDocGroup({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { data, onSuccess } = payload;
    const response = yield call(api.post, '/management/docgroups', data);
    const docgroup = response.data;

    yield put(storeDocGroupSuccess(docgroup));
    toast.success('Grupo criado com sucesso');
    onSuccess && onSuccess();
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(docGroupFailure());
      Swal.fire(
        responseErrors(err.response, 'Falha ao criar grupo de documento')
      );
    }
  }
}

export function* updateDocGroup({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, data, index, onSuccess } = payload;
    const response = yield call(api.patch, `/management/docgroups/${id}`, data);
    const docgroup = response.data;

    yield put(updateDocGroupSuccess(index, docgroup));
    toast.success('Grupo de documentos atualizado com sucesso');
    onSuccess && onSuccess();
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(docGroupFailure());
      Swal.fire(
        responseErrors(err.response, 'Falha ao atualizar grupo de documento')
      );
    }
  }
}

export function* deleteDocGroup({ payload }) {
  const { refresh_token } = store.getState().auth;

  try {
    const { id, index } = payload;
    yield call(api.delete, `/management/docgroups/${id}`);

    yield put(deleteDocGroupSuccess(index));
    toast.success('Grupo de documentos excluído com sucesso');
  } catch (err) {
    if (err.response.status === 403) {
      yield put(refreshTokenRequest(refresh_token));
    } else {
      yield put(docGroupFailure());
      toast.error('Falha ao excluir grupo de documentos');
    }
  }
}

export default all([
  takeLatest('@docgroups/GET_ALL_DOCGROUPS_REQUEST', getDocGroups),
  takeLatest('@docgroups/GET_DOCGROUP_BY_ID_REQUEST', getDocGroupById),
  takeLatest('@docgroups/STORE_DOCGROUP_REQUEST', storeDocGroup),
  takeLatest('@docgroups/UPDATE_DOCGROUP_REQUEST', updateDocGroup),
  takeLatest('@docgroups/DELETE_DOCGROUP_REQUEST', deleteDocGroup),
]);
