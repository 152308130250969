import styled, { css } from 'styled-components';

export const RadioContainer = styled.div`
  background: #fcfdfd;
  border-radius: 3px;
  /* padding: 4px;
  box-shadow: inset 0 0 0 1px rgba(35, 33, 45, 0.3),
    0 0 0 1px rgba(185, 185, 185, 0.3); */
  display: flex;
  justify-content: space-between;

  @media (max-width: 1375px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    row-gap: 10px;
  }
  @media (max-width: 700px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 10px;
  }
`;
export const RadioInput = styled.input`
  height: 100%;
  appearance: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  background: #fff;
  color: #74b0f0;
  transition: all 100ms linear;
  ${({ padding }) => css`
    padding: ${padding ? padding[0] : '1'}px ${padding ? padding[1] : '20'}px !important;
  `}
  font-weight: 500;
  border: 1px solid #74b0f0;

  &:checked {
    background: #74b0f0;
    color: #fff;
    box-shadow: 0 1px 1px #0000002e;
  }

  &:before {
    content: attr(label);
    display: inline-block;
    text-align: center;
    width: 100%;
  }
`;
