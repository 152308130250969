/* List */
export function getAllBatchesRequest(
  {
    limit,
    page,
    institutionId,
    status,
    batchId,
    documentId,
    initialDate,
    finalDate,
  } = {},
  onSuccess
) {
  return {
    type: '@batches/GET_ALL_BATCHES_REQUEST',
    payload: {
      limit,
      page,
      institutionId,
      status,
      batchId,
      documentId,
      initialDate,
      finalDate,
      onSuccess,
    },
  };
}
export function getAllBatchesSuccess(batches) {
  return {
    type: '@batches/GET_ALL_BATCHES_SUCCESS',
    payload: { batches },
  };
}
export function getBatchByIdRequest(id) {
  return {
    type: '@batches/GET_BATCH_BY_ID_REQUEST',
    payload: { id },
  };
}
export function getBatchByIdSuccess(batch) {
  return {
    type: '@batches/GET_BATCH_BY_ID_SUCCESS',
    payload: { batch },
  };
}

export function cleanState() {
  return {
    type: '@batches/CLEAN',
  };
}

/* CANCEL */
export function cancelBatchRequest() {
  return {
    type: '@batches/CANCEL_REQUEST',
  };
}
export function cancelBatchRequestSuccess() {
  return {
    type: '@batches/CANCEL_SUCCESS',
  };
}

/* CLOSE */
export function closeBatchRequest() {
  return {
    type: '@batches/CLOSE_REQUEST',
  };
}
export function closeBatchRequestSuccess() {
  return {
    type: '@batches/CLOSE_SUCCESS',
  };
}

/* Failure */
export function BatchFailure() {
  return {
    type: '@batches/BATCH_FAILURE',
  };
}
