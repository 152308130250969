/* VERSION */
export function getVersions({ client_id, page, limit } = {}, onSuccess) {
  return {
    type: '@logs/GET_VERSIONS',
    payload: {
      client_id,
      page,
      limit,
      onSuccess,
    },
  };
}
export function getVersionsSuccess(data) {
  return {
    type: '@logs/GET_VERSIONS_SUCCESS',
    payload: { data },
  };
}

/* SIGN LOGS */
export function getSignLogs(
  { client_id, your_number, provider, policy, page, limit } = {},
  onSuccess
) {
  return {
    type: '@logs/GET_SIGN_LOGS',
    payload: {
      client_id,
      your_number,
      provider,
      policy,
      page,
      limit,
      onSuccess,
    },
  };
}
export function getSignLogsSuccess(data) {
  return {
    type: '@logs/GET_SIGN_LOGS_SUCCESS',
    payload: { data },
  };
}

export function getSignLogsById(id) {
  return {
    type: '@logs/GET_SIGN_LOGS_BY_ID',
    payload: { id },
  };
}

export function getSignLogsByIdSuccess(signLog) {
  return {
    type: '@logs/GET_SIGN_LOGS_BY_ID_SUCCESS',
    payload: { signLog },
  };
}

/* TIMESTAMP LOGS */
export function getTimestampLogs(
  {
    client_id,
    your_number,
    sign_provider,
    timestamp_provider,
    timestamp_type,
    policy,
    page,
    limit,
  } = {},
  onSuccess
) {
  return {
    type: '@logs/GET_TIMESTAMP_LOGS',
    payload: {
      client_id,
      your_number,
      sign_provider,
      timestamp_provider,
      timestamp_type,
      policy,
      page,
      limit,
      onSuccess,
    },
  };
}
export function getTimestampLogsSuccess(data) {
  return {
    type: '@logs/GET_TIMESTAMP_LOGS_SUCCESS',
    payload: { data },
  };
}

export function getTimestampLogById(id) {
  return {
    type: '@logs/GET_TIMESTAMP_LOGS_BY_ID',
    payload: { id },
  };
}

export function getTimestampLogByIdSuccess(timestampLog) {
  return {
    type: '@logs/GET_TIMESTAMP_LOGS_BY_ID_SUCCESS',
    payload: { timestampLog },
  };
}

/* GENERAL ACTIONS */
export function cleanState() {
  return {
    type: '@logs/CLEAN',
  };
}

export function setMutualYourNumber(yourNumber) {
  return {
    type: '@logs/SET_YOUR_NUMBER',
    payload: { yourNumber },
  };
}
export function cleanMutualYourNumber() {
  return {
    type: '@logs/CLEAN_YOUR_NUMBER',
  };
}

/* Failure */
export function LogsFailure() {
  return {
    type: '@logs/REQUEST_FAILURE',
  };
}
