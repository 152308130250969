const admin = ['ADMIN'];
const normal = ['NORMAL_USER'];
const manager = ['MANAGER'];
const adminAndMaintainer = ['ADMIN', 'MAINTAINER'];
const normalOrManager = [...normal, ...manager];
const allUsers = [...normal, ...adminAndMaintainer, ...manager];
const adminAndMaintainerHome = '/institution';
const normalUserHome = '/profile';
export {
  admin,
  normal,
  manager,
  adminAndMaintainer,
  normalOrManager,
  adminAndMaintainerHome,
  normalUserHome,
  allUsers,
};
