/* eslint-disable import/no-cycle */
import axios from 'axios';
import { toast } from 'react-toastify';
// import jwtDecode from 'jwt-decode';
import { store } from '../store';
import { signOut, updateRefreshToken } from '../store/modules/auth/actions';
// import { getSession, saveSession } from './auth';

const defaultUrl = 'http://localhost:8050';

function makeLog(log) {
  console.log(`ApiService: ${log}`);
}

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL || defaultUrl,
});

let isRefreshing = false;
let subscribers = [];

function onRefreshed(tokens) {
  subscribers.map((cb) => cb(tokens));
}

function subscribeTokenRefresh(cb) {
  subscribers.push(cb);
}

// api.interceptors.request.use(async config => {
//   if (config.url !== 'auth') {
//     const { token, refreshToken } = getSession() ?? {};
//     const decoded = jwtDecode(token);
//     const now = Date.now();
//     const exp = (decoded.exp - 10) * 1000;
//     const isExpired = now > exp;

//     if (isExpired) {
//       await axios
//         .post(
//           `${config.baseURL}/auth/refresh`,
//           {
//             refresh_token: refreshToken,
//           },
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         )
//         .then(({ data: responseData }) => {
//           const { accessToken, refreshToken } = responseData;

//           store.dispatch(updateRefreshToken(accessToken, refreshToken));

//           saveSession({ token, refreshToken });

//           api.defaults.headers.Authorization = `Bearer ${accessToken}`;
//           api.defaults.headers['X-RefreshToken'] = refreshToken;
//           config.headers.Authorization = `Bearer ${accessToken}`;
//           config.headers['X-RefreshToken'] = refreshToken;

//           makeLog('update token per request interceptor');
//         })
//         .catch(() => {
//           makeLog('refresh fails trying sign out');
//           toast.error('Faça login novamente', {
//             toastId: 'logout-info',
//           });
//           store.dispatch(signOut());
//         });
//     }
//   }
//   return config;
// }, null);

api.interceptors.response.use(null, async (err) => {
  const {
    config,
    response: { status },
  } = err;
  const originalRequest = config;

  const requestInfo = `${String(originalRequest.method).toUpperCase()} ${
    originalRequest.url
  }`;

  if (status === 403) {
    makeLog(`catch 403 error on ${requestInfo}`);
    makeLog(`isRefreshing ${isRefreshing}`);
    makeLog(`originalRequest.url ${originalRequest.url}`);

    if (isRefreshing && originalRequest.url === '/auth/refresh') {
      makeLog('refresh fails trying sign out');
      toast.error('Faça login novamente');
      store.dispatch(signOut());
    }

    if (!isRefreshing) {
      isRefreshing = true;

      const refresh_token = originalRequest.headers['X-RefreshToken'];

      makeLog('trying refresh');

      api
        .post('/auth/refresh', {
          refresh_token,
        })
        .then(({ data: responseData }) => {
          const { accessToken, refreshToken } = responseData;

          store.dispatch(updateRefreshToken(accessToken, refreshToken));

          api.defaults.headers.Authorization = `Bearer ${accessToken}`;
          api.defaults.headers['X-RefreshToken'] = refreshToken;

          makeLog(`refreshed ${accessToken}`);

          onRefreshed({
            accessToken,
            refreshToken,
          });

          subscribers = [];
        })
        .catch(() => {
          makeLog('refresh fails trying sign out');
          toast.error('Faça login novamente');
          store.dispatch(signOut());
        })
        .finally(() => {
          isRefreshing = false;
        });
    }
    return new Promise((resolve) => {
      subscribeTokenRefresh(({ accessToken, refreshToken }) => {
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        originalRequest.headers['X-RefreshToken'] = refreshToken;
        makeLog(`calling request again ${requestInfo}`);
        resolve(api(originalRequest));
      });
    });
  }

  return Promise.reject(err);
});

export default api;
