import { produce } from 'immer';

const INITIAL_STATE = {
  preSigners: [],
  preSigner: {},
  total: 0,
  numberOfPages: 0,
  loading: false,
  isSubmitting: false,
};

export default function preSigner(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@presigner/GET_ALL_PRE_SIGNERS_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@presigner/GET_ALL_PRE_SIGNERS_SUCCESS': {
        draft.preSigners = action.payload.preSigners.content;
        draft.total = action.payload.preSigners.total;
        draft.numberOfPages = action.payload.preSigners.numberOfPages;
        draft.loading = false;
        break;
      }
      case '@presigner/GET_PRE_SIGNER_BY_ID_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@presigner/GET_PRE_SIGNER_BY_ID_SUCCESS': {
        draft.loading = false;
        draft.signer = action.payload.signer;
        draft.signer.index = action.payload.index;
        break;
      }
      case '@presigner/STORE_PRE_SIGNER_REQUEST': {
        // draft.loading = true;
        draft.isSubmitting = true;
        break;
      }
      case '@presigner/STORE_PRE_SIGNER_SUCCESS': {
        const aux = [...draft.preSigners];
        aux.unshift(action.payload.signer);
        draft.preSigners = aux;
        draft.total += 1;
        // draft.loading = false;
        draft.isSubmitting = false;
        break;
      }
      case '@presigner/UPDATE_PRE_SIGNER_REQUEST': {
        // draft.loading = true;
        draft.isSubmitting = true;
        break;
      }
      case '@presigner/UPDATE_PRE_SIGNER_SUCCESS': {
        const { data } = action.payload;
        const aux = draft.preSigners;
        const signerAux = state.preSigners.findIndex((item) => {
          return item._id === data._id;
        });
        aux[signerAux] = data;
        draft.preSigners = aux;
        draft.isSubmitting = false;
        break;
      }
      case '@presigner/UPDATE_PRE_SIGNER_CANCEL': {
        draft.preSigner = {};
        break;
      }
      case '@presigner/DELETE_PRE_SIGNER_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@presigner/DELETE_PRE_SIGNER_SUCCESS': {
        const aux = draft.preSigners;
        aux[action.payload.index] = action.payload.data;
        draft.preSigners = aux;
        draft.loading = false;
        break;
      }
      case '@presigner/PRE_SIGNER_FAILURE': {
        draft.loading = false;
        draft.isSubmitting = false;
        break;
      }
      default:
    }
  });
}
