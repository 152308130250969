import React from 'react';
import { Routes as Router, Route } from 'react-router-dom';

import SignIn from '../pages/SignIn';
import Institution from '../pages/Institution';
import Signer from '../pages/Signer';
import SignatureItem from '../pages/SignatureItem';
import Tag from '../pages/Tag';
import DocType from '../pages/DocumentType';
import DocGroup from '../pages/DocumentGroup';
import SignConfig from '../pages/SignatureConfig';
import DocConfig from '../pages/DocumentConfiguration';
import User from '../pages/User';
import Course from '../pages/Course';
import Profile from '../pages/Profile';

import ErrorContainer from '../components/errors/ErrorContainer';

import PreSigners from '../pages/PreSigner';
import PreCourses from '../pages/PreCourse';
import Documents from '../pages/Documents';
import DocumentDetails from '../pages/Documents/details';
import DocumentsReports from '../pages/DocumentsReports';
import SignatureReports from '../pages/SignatureReports';
import LogVersions from '../pages/Logs/LogVersions';
import SignLogs from '../pages/SignLogs';
import SignLogDetails from '../pages/SignLogs/details';
import SignTimestampLogs from '../pages/SignTimestampLogs';
import TimestampLogDetails from '../pages/SignTimestampLogs/details';
import TimestampReports from '../pages/TimestampReports';
import Storage from '../pages/Storage';
import DDRConfig from '../pages/DDRConfig';
import SignatureItemsNormalUser from '../pages/SignatureItemNormalUser';
import RegistryBatch from '../pages/RegistryBatch';
import BatchDetails from '../pages/RegistryBatch/details';
import XSDValidity from '../pages/XSDValidity';
import BlockChainStats from '../pages/BlockchainStats';
import AuthLayout from '../pages/_layouts/Auth';
import { RequireAuth } from './Route';
import { Permissions } from '../config/Constants';

export default function Routes() {
  return (
    <Router>
      <Route
        path="/"
        element={
          <AuthLayout>
            <SignIn />
          </AuthLayout>
        }
      />

      <Route
        path="/profile"
        element={
          <RequireAuth permissionRequired={Permissions.NORMAL_USER}>
            <Profile />
          </RequireAuth>
        }
      />

      <Route
        path="/presigners"
        element={
          <RequireAuth permissionRequired={Permissions.NORMAL_USER}>
            <PreSigners />
          </RequireAuth>
        }
      />

      <Route
        path="/precourses"
        element={
          <RequireAuth permissionRequired={Permissions.NORMAL_USER}>
            <PreCourses />
          </RequireAuth>
        }
      />

      <Route
        path="/documents/:id"
        element={
          <RequireAuth permissionRequired={Permissions.ADMIN}>
            <DocumentDetails />
          </RequireAuth>
        }
      />

      <Route
        path="/documents"
        element={
          <RequireAuth permissionRequired={Permissions.ADMIN}>
            <Documents />
          </RequireAuth>
        }
      />

      <Route
        path="/registry-batch/:id"
        element={
          <RequireAuth permissionRequired={Permissions.ADMIN}>
            <BatchDetails />
          </RequireAuth>
        }
      />

      <Route
        path="/registry-batch"
        element={
          <RequireAuth permissionRequired={Permissions.ADMIN}>
            <RegistryBatch />
          </RequireAuth>
        }
      />

      <Route
        path="/user"
        element={
          <RequireAuth permissionRequired={Permissions.MAINTAINER}>
            <User />
          </RequireAuth>
        }
      />

      <Route
        path="/institution"
        element={
          <RequireAuth permissionRequired={Permissions.MAINTAINER}>
            <Institution />
          </RequireAuth>
        }
      />

      <Route
        path="/course"
        element={
          <RequireAuth permissionRequired={Permissions.MAINTAINER}>
            <Course />
          </RequireAuth>
        }
      />

      <Route
        path="/signers"
        element={
          <RequireAuth permissionRequired={Permissions.MAINTAINER}>
            <Signer />
          </RequireAuth>
        }
      />

      <Route
        path="/sigitems"
        element={
          <RequireAuth permissionRequired={Permissions.MAINTAINER}>
            <SignatureItem />
          </RequireAuth>
        }
      />

      <Route
        path="/tags"
        element={
          <RequireAuth permissionRequired={Permissions.MAINTAINER}>
            <Tag />
          </RequireAuth>
        }
      />

      <Route
        path="/doctype"
        element={
          <RequireAuth permissionRequired={Permissions.ADMIN}>
            <DocType />
          </RequireAuth>
        }
      />

      <Route
        path="/docgroup"
        element={
          <RequireAuth permissionRequired={Permissions.ADMIN}>
            <DocGroup />
          </RequireAuth>
        }
      />

      <Route
        path="/docconfig"
        element={
          <RequireAuth permissionRequired={Permissions.MAINTAINER}>
            <DocConfig />
          </RequireAuth>
        }
      />

      <Route
        path="/signconfig"
        element={
          <RequireAuth permissionRequired={Permissions.MAINTAINER}>
            <SignConfig />
          </RequireAuth>
        }
      />

      <Route
        path="/ddrconfig"
        element={
          <RequireAuth permissionRequired={Permissions.ADMIN}>
            <DDRConfig />
          </RequireAuth>
        }
      />

      <Route
        path="/reports/documents"
        element={
          <RequireAuth permissionRequired={Permissions.MAINTAINER}>
            <DocumentsReports />
          </RequireAuth>
        }
      />

      <Route
        path="/reports/signatures"
        element={
          <RequireAuth permissionRequired={Permissions.MAINTAINER}>
            <SignatureReports />
          </RequireAuth>
        }
      />

      <Route
        path="/reports/timestamp"
        element={
          <RequireAuth permissionRequired={Permissions.MAINTAINER}>
            <TimestampReports />
          </RequireAuth>
        }
      />

      <Route
        path="/reports/blockchain"
        element={
          <RequireAuth permissionRequired={Permissions.MAINTAINER}>
            <BlockChainStats />
          </RequireAuth>
        }
      />

      <Route
        path="/logs/version"
        element={
          <RequireAuth permissionRequired={Permissions.ADMIN}>
            <LogVersions />
          </RequireAuth>
        }
      />

      <Route
        path="/logs/signature/:id"
        element={
          <RequireAuth permissionRequired={Permissions.ADMIN}>
            <SignLogDetails />
          </RequireAuth>
        }
      />

      <Route
        path="/logs/signature"
        element={
          <RequireAuth permissionRequired={Permissions.ADMIN}>
            <SignLogs />
          </RequireAuth>
        }
      />

      <Route
        path="/logs/timestamp/:id"
        element={
          <RequireAuth permissionRequired={Permissions.ADMIN}>
            <TimestampLogDetails />
          </RequireAuth>
        }
      />

      <Route
        path="/logs/timestamp"
        element={
          <RequireAuth permissionRequired={Permissions.ADMIN}>
            <SignTimestampLogs />
          </RequireAuth>
        }
      />

      <Route
        path="/storage"
        element={
          <RequireAuth permissionRequired={Permissions.MAINTAINER}>
            <Storage />
          </RequireAuth>
        }
      />

      <Route
        path="/general/signitems"
        element={
          <RequireAuth permissionRequired={Permissions.NORMAL_USER}>
            <SignatureItemsNormalUser />
          </RequireAuth>
        }
      />

      <Route
        path="/system-data/xsd-validity"
        element={
          <RequireAuth permissionRequired={Permissions.MAINTAINER}>
            <XSDValidity />
          </RequireAuth>
        }
      />

      <Route
        path="/"
        element={
          <ErrorContainer
            code="404"
            alert="Something went wrong!"
            message="There was a problem on your link. Please try again."
          />
        }
      />
      {/* </Switch> */}
    </Router>
  );
}
