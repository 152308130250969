import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  margin-top: 32px;
`;
export const InputWrapper = styled.div`
  label {
    color: black;
  }
  span {
    font-size: 11px;
    margin-top: 3px !important;
    font-weight: bold;
  }
`;
export const FilterWrapper = styled.div`
  .card-header {
    border-radius: 0.625rem !important;
  }
  button {
    margin-top: 17px;
    margin-left: 10px;
  }
`;
