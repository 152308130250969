import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
`;
export const SetRows = styled.div`
  display: flex;
  align-items: baseline;
  label {
    margin-left: 15px;
    font-size: 13px;
  }
  select {
    width: 100px;
  }
`;
