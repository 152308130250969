import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ButtonGroup,
  Button,
  Form,
  ListGroup,
  ListGroupItem,
  FormInput,
  FormGroup,
  FormSelect,
} from 'shards-react';

import { FiAlertCircle } from 'react-icons/fi';
import { FaRegEdit, FaRegTrashAlt, FaPlus } from 'react-icons/fa';

import Swal from 'sweetalert2';
import { ButtonWrapper, InputWrapper, FilterWrapper } from '../common/styles';

import { useInput } from '../../hooks/input-hook';

import PageTitle from '../../components/common/PageTitle';
import LoadingContainer from '../../components/layout/LodingContainer';
import SearchSelect from '../../components/common/SearchSelect';
import {
  getAllTagsRequest,
  getTagByIdRequest,
  storeTagRequest,
  updateTagRequest,
  updateTagCancel,
  deleteTagRequest,
} from '../../store/modules/tag/actions';

import { getAllSignatureItemsRequest } from '../../store/modules/signatureItem/actions';
import { getAllInstitutionsRequest } from '../../store/modules/institution/actions';
import * as SelectedData from '../../store/modules/selectedData/actions';

import { scrollToTop, scrollToForm } from '../../utils/scrolls';
import OptionsBox from '../../components/common/OptionsBox';
import { TagTypes } from '../../config/Constants';
import { getInitials } from '../../utils/getIntitutionInitials';

export default function Tag() {
  const dispatch = useDispatch();

  const institutions = useSelector((state) => state.institution.institutions);
  const sigItems = useSelector((state) => state.signatureItem.sigItems);
  const tags = useSelector((state) => state.tag.tags);
  const tag = useSelector((state) => state.tag.tag);
  const { loading, isSubmitting } = useSelector((state) => state.tag);
  const selectedInstitution = useSelector(
    (state) => state.selectedData.selectedInstitution
  );

  const {
    value: tagID,
    setValue: setTagID,
    bind: bindTagID,
    reset: resetTagID,
  } = useInput('');
  const {
    value: tagName,
    setValue: setTagName,
    bind: bindTagName,
    reset: resetTagName,
  } = useInput('');

  const [filteredInstitution, setFilteredInstitution] =
    useState(selectedInstitution);
  const [sigItens, setSigItens] = useState([undefined]);
  const [tagIdToUpdate, setTagIdToUpdate] = useState('');
  const [updating, setUpdating] = useState(false);
  const [institutionFilterStatus, setInstitutionFilterStatus] = useState(false);
  const [filteredTags, setFilteredTags] = useState([]);
  const [filteredSigItems, setFilteredSigItems] = useState([]);
  const optionsData = [
    { label: 'Diploma', value: 'Diploma' },
    { label: 'DadosDiploma', value: 'DadosDiploma' },
    { label: 'DadosRegistro', value: 'DadosRegistro' },
    {
      label: 'DocumentacaoAcademicaRegistro',
      value: 'DocumentacaoAcademicaRegistro',
    },
    { label: 'Customizado', value: 'custom' },
  ];

  /*eslint-disable */
  useEffect(() => {
    dispatch(getAllInstitutionsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(tag).length !== 0 && updating) {
      setTagID(tag.tagID);
      setTagName(tag.tagName);
      setSigItens(tag.sigItens);
      setTagIdToUpdate(tag._id);
    }
  }, [tag]);

  useEffect(() => {
    if (tags.length !== 0) {
      setFilteredTags(tags);
    }
  }, [tags]);

  useEffect(() => {
    setFilteredSigItems(sigItems);
  }, [sigItems]);

  /* eslint-enable */
  useEffect(() => {
    handleFiltering();
  }, [filteredInstitution]);

  function resetState() {
    resetTagID();
    resetTagName();
    setSigItens([undefined]);

    setTagIdToUpdate('');
    setUpdating(false);
  }

  function handleCleanState(e) {
    e.preventDefault();
    resetTagID();
    resetTagName();
    setSigItens([undefined]);
    dispatch(updateTagCancel());
    setTagIdToUpdate('');
    setUpdating(false);
    scrollToTop();
  }

  function getTagData(e, identifier, index) {
    e.preventDefault();
    dispatch(getTagByIdRequest(identifier, index));
    setUpdating(true);
    scrollToForm();
  }

  async function handleUpdateTag(e) {
    e.preventDefault();
    const identifier = tagIdToUpdate;
    const aux = [];
    for (let i = 0; i < sigItens.length; i += 1) {
      if (sigItens[i]._id !== undefined) {
        // eslint-disable-next-line no-await-in-loop
        await aux.push(sigItens[i]._id);
      } else {
        // eslint-disable-next-line no-await-in-loop
        await aux.push(sigItens[i]);
      }
    }

    const tagIndex = tags.findIndex((item) => item._id === identifier);

    const data = {
      tagID,
      tagName,
      sigItens: aux,
    };
    dispatch(updateTagRequest(identifier, data, tagIndex, resetState()));
  }

  function handleDeleteTag(e, identifier) {
    const index = tags.findIndex((element) => element._id === identifier);
    e.preventDefault();
    dispatch(deleteTagRequest(identifier, index));
    resetState();
  }

  function handleSubmit(e) {
    e.preventDefault();
    const obj = {
      tagID,
      tagName,
      sigItens,
      institution_id: filteredInstitution,
    };
    dispatch(
      storeTagRequest(obj, () => {
        scrollToTop();
        resetState();
      })
    );
  }

  function handleConfirmation(e, identifier, name) {
    Swal.fire({
      title: 'Confirme para excluir',
      text: `Tem certeza que deseja remover ${name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        return handleDeleteTag(e, identifier);
      }
      return false;
    });
  }

  function addInput(e) {
    e.preventDefault();
    const signature_items = [];
    if (sigItens !== undefined) {
      setSigItens([...sigItens, undefined]);
    } else {
      signature_items.push(undefined);
      setSigItens(signature_items);
    }
  }

  async function handleSignatureItemsChange(e, index) {
    e.preventDefault();
    const { value } = e.target;
    const signature_items = [...sigItens];
    signature_items[index] = value;
    setSigItens(signature_items);
  }

  function handleSignatureItemsDelete(e, index) {
    e.preventDefault();
    const signature_items = [...sigItens];

    signature_items.splice(index, 1);
    setSigItens(signature_items);
  }

  function handleFiltering() {
    if (!filteredInstitution) {
      setFilteredSigItems([]);
      setFilteredTags([]);
      setInstitutionFilterStatus(false);

      return;
    }
    resetState();

    dispatch(SelectedData.selectInstitution(filteredInstitution));
    dispatch(getAllTagsRequest());
    dispatch(getAllSignatureItemsRequest());
    setInstitutionFilterStatus(true);
  }

  function handleInstitutionFilter(institution) {
    setFilteredInstitution(institution.value);
  }

  function handleTagType(type) {
    scrollToForm();
    const tagIdInput = document.getElementById('tagIdentifier');
    const signatureItemInput = document.getElementById('signatureItem');

    if (type === 'custom') {
      resetState();
      // eslint-disable-next-line no-unused-expressions
      tagIdInput && tagIdInput.focus();
    }

    const data = TagTypes[type];

    if (data) {
      const initials = getInitials(filteredInstitution, institutions);
      const newId = initials
        ? data.tagID.replace('<INST>', initials)
        : data.tagID;
      setTagID(newId);
      setTagName(data.tagName);

      // eslint-disable-next-line no-unused-expressions
      signatureItemInput && signatureItemInput.focus();
    }
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Tags"
          subtitle="Gerenciamento"
          className="text-sm-left"
        />
      </Row>
      {/* Default Light Table */}
      <Row>
        <Col>
          <FilterWrapper>
            <Card small className="col-sm-6 mb-3">
              <CardHeader className="border-bottom">
                <h6 className="m-0 font-weight-bold">
                  <FiAlertCircle className="mr-2 " />
                  Por favor, antes de prosseguir selecione uma instituição:
                </h6>
                <div className="col-sm-12">
                  <SearchSelect
                    onSelect={handleInstitutionFilter}
                    value={filteredInstitution}
                    options={
                      institutions &&
                      institutions.map((i) => ({
                        label: `${i.name} - ${i.initials}`,
                        value: i._id,
                      }))
                    }
                  />
                </div>
              </CardHeader>
            </Card>
          </FilterWrapper>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0 font-weight-bold">Lista de TAGs</h6>
            </CardHeader>
            <CardBody className="p-0 pb-3 pl-3 pr-3 overflow-auto">
              {loading ? (
                <LoadingContainer loading={loading} />
              ) : (
                <>
                  {filteredTags.length !== 0 ? (
                    <table className="table mb-0">
                      <thead className="bg-light">
                        <tr>
                          <th scope="col" className="border-0">
                            #
                          </th>
                          <th scope="col" className="border-0">
                            Identificador da Tag
                          </th>
                          <th scope="col" className="border-0">
                            Nome da Tag
                          </th>
                          {!institutionFilterStatus ? null : (
                            <th scope="col" className="border-0">
                              Ações
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {filteredTags &&
                          filteredTags.map((t, index) => (
                            <tr key={t._id}>
                              <td>{index}</td>
                              <td>{t.tagID}</td>
                              <td>{t.tagName}</td>
                              {!institutionFilterStatus ? null : (
                                <td>
                                  <ButtonGroup size="sm">
                                    <Button
                                      theme="white"
                                      onClick={(e) =>
                                        getTagData(e, t._id, index)
                                      }
                                    >
                                      <span className="text-light">
                                        <FaRegEdit />
                                      </span>{' '}
                                      Editar
                                    </Button>
                                    <Button
                                      onClick={(e) =>
                                        handleConfirmation(e, t._id, t.tagName)
                                      }
                                      theme="white"
                                    >
                                      <span className="text-light">
                                        <FaRegTrashAlt />
                                      </span>{' '}
                                      Excluir
                                    </Button>
                                  </ButtonGroup>
                                </td>
                              )}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="col-sm-12 d-flex justify-content-center">
                      <h5 className="mt-3">
                        <FiAlertCircle className="mr-2" />
                        Não existem tags cadastradas.
                      </h5>
                    </div>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {!institutionFilterStatus ? null : (
        <>
          {!updating && (
            <Row>
              <Col>
                <Card className="mb-3" small>
                  <CardHeader className="border-bottom">
                    <h6 className="m-0 font-weight-bold">
                      Selecione o tipo da Tag
                    </h6>
                  </CardHeader>
                  {sigItens.length !== 0 && (
                    <ListGroup flush>
                      <ListGroupItem className="p-3">
                        <Row>
                          <Col>
                            <OptionsBox
                              data={optionsData}
                              checked="custom"
                              onChange={handleTagType}
                            />
                          </Col>
                        </Row>
                      </ListGroupItem>
                    </ListGroup>
                  )}
                </Card>
              </Col>
            </Row>
          )}
          <Row id="form-container">
            <Col>
              <Card className="mb-3" small>
                <CardHeader className="border-bottom">
                  <h6 className="m-0 font-weight-bold">
                    Informações sobre a TAG
                  </h6>
                </CardHeader>
                {sigItens.length !== 0 ? (
                  <ListGroup flush>
                    <ListGroupItem className="p-3">
                      <Row>
                        <Col>
                          <Form onSubmit={handleSubmit} id="edit">
                            <Row form>
                              <Col md="6" className="form-group">
                                <InputWrapper>
                                  <label htmlFor="tagIdentifier">
                                    Identificador da Tag
                                  </label>
                                  <FormInput
                                    id="tagIdentifier"
                                    type="text"
                                    placeholder="Tag exemplo"
                                    name="tagID"
                                    required
                                    pattern="^[A-ZÁÉÍÓÚÀÂÊÔÃÕÜÇa-záéíóúàâêôãõüç ]+$"
                                    {...bindTagID}
                                  />
                                  <span className="text-muted">
                                    *Insira o identificador da Tag (somente
                                    letras).
                                  </span>
                                </InputWrapper>
                              </Col>
                              <Col md="6" className="form-group">
                                <InputWrapper>
                                  <label htmlFor="tagName">Nome da tag</label>
                                  <FormInput
                                    id="tagName"
                                    type="text"
                                    placeholder="tag_exemplo"
                                    name="tagName"
                                    required
                                    pattern="^[a-zA-Z0-9_-]+$"
                                    {...bindTagName}
                                  />
                                  <span className="text-muted">
                                    *Denomine a Tag.
                                  </span>
                                </InputWrapper>
                              </Col>
                            </Row>
                            {sigItens !== undefined &&
                              sigItens.length !== 0 &&
                              sigItens.map((s, index) => (
                                <Row form key={index}>
                                  <Col md="6">
                                    <FormGroup>
                                      <InputWrapper>
                                        <label htmlFor="signatureItem">
                                          Item de assinatura
                                        </label>
                                        <FormSelect
                                          id="signatureItem"
                                          name="sigItens"
                                          value={s === undefined ? '' : s._id}
                                          required
                                          onChange={(e) =>
                                            handleSignatureItemsChange(e, index)
                                          }
                                        >
                                          <option disabled value="">
                                            Escolher...
                                          </option>
                                          {filteredSigItems &&
                                            filteredSigItems.map((sig) => (
                                              <option
                                                key={sig._id}
                                                value={sig._id}
                                              >
                                                {sig.name}
                                              </option>
                                            ))}
                                        </FormSelect>
                                        <span className="text-muted">
                                          *Selecione um item de assinatura.
                                        </span>
                                      </InputWrapper>
                                    </FormGroup>
                                  </Col>
                                  {index !== 0 && (
                                    <Col md="6">
                                      <ButtonWrapper>
                                        <Button
                                          onClick={(e) =>
                                            handleSignatureItemsDelete(e, index)
                                          }
                                          theme="white"
                                        >
                                          <span className="text-light">
                                            <FaRegTrashAlt />
                                          </span>{' '}
                                          Excluir
                                        </Button>
                                      </ButtonWrapper>
                                    </Col>
                                  )}
                                </Row>
                              ))}
                            <Row className="mt-2">
                              <Col md="6" className="p-0">
                                <Button
                                  onClick={(e) => addInput(e)}
                                  theme="white"
                                  className="ml-3"
                                  disabled={isSubmitting}
                                >
                                  <span className="text-light">
                                    <FaPlus />
                                  </span>{' '}
                                  Adicionar Item
                                </Button>
                              </Col>
                              <Col md="6" className="text-right">
                                {updating ? (
                                  <>
                                    <Button
                                      className="btn btn-danger btn-sm"
                                      onClick={handleCleanState}
                                      disabled={isSubmitting}
                                    >
                                      Cancelar
                                    </Button>
                                    <Button
                                      className="btn-primary ml-3"
                                      onClick={handleUpdateTag}
                                      disabled={isSubmitting}
                                    >
                                      {isSubmitting
                                        ? 'Carregando...'
                                        : 'Atualizar'}
                                    </Button>
                                  </>
                                ) : (
                                  <Button disabled={isSubmitting} type="submit">
                                    {isSubmitting
                                      ? 'Carregando...'
                                      : 'Criar nova tag'}
                                  </Button>
                                )}
                              </Col>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  </ListGroup>
                ) : (
                  <div className="col-sm-12 d-flex justify-content-center py-2">
                    <h5 className="mt-3">
                      <FiAlertCircle className="mr-2" />
                      Não é possível continuar, não encontramos instituições,
                      assinadores ou itens de assinatura cadastrados. Para
                      continuar é preciso cadastrar ao menos uma dessas
                      entidades!
                    </h5>
                  </div>
                )}
              </Card>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}
