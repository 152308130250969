export const readCsv = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target.result;
      resolve(content);
    };
    reader.onerror = () => {
      reject();
    };
    reader.readAsText(file);
  });
};
